import React from "react";

class Icon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73.732"
        height="73.732"
        viewBox="0 0 73.732 73.732"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              id="path3330"
              fill="none"
              d="M0-682.665h73.732v73.732H0z"
              transform="translate(0 682.665)"
            ></path>
          </clipPath>
        </defs>
        <g id="g3324">
          <g id="g3326">
            <g id="g3328" clipPath="url(#clip-path)">
              <g id="g3334" transform="translate(54.654 52.193)">
                <path
                  id="path3336"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M16.18 16.18h0a4.976 4.976 0 01-7.037 0L0 7.037 7.037 0l9.143 9.143a4.976 4.976 0 010 7.037z"
                ></path>
              </g>
              <g id="g3338" transform="translate(51.042 48.574)">
                <path
                  id="path3340"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M4.536 0l4.865 4.866L4.889 9.38.001 4.491a12.435 12.435 0 002.6-1.991A12.4 12.4 0 004.536 0z"
                ></path>
              </g>
              <g id="g3342" transform="translate(32.491 29.917)">
                <path
                  id="path3344"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M21.155 3.63a12.393 12.393 0 010 17.526 12.392 12.392 0 01-17.526 0 12.392 12.392 0 010-17.526 12.393 12.393 0 0117.526 0z"
                ></path>
              </g>
              <g id="g3346" transform="translate(1.44 3.901)">
                <path
                  id="path3348"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M46.255 8.117v-5.1a3.01 3.01 0 00-3.009-3.01H3.01a3.01 3.01 0 00-3.009 3.01v55.2a3.01 3.01 0 003.009 3.009h40.236a3.01 3.01 0 003.009-3.009V50.48a12.386 12.386 0 01-11.574-3.308 12.393 12.393 0 010-17.527 12.382 12.382 0 0111.574-3.307v-5.546"
                ></path>
              </g>
              <path
                id="path3350"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M17.274 22.267h-8.08V14.19h8.08z"
              ></path>
              <path
                id="path3352"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M17.274 38.552h-8.08v-8.079h8.08z"
              ></path>
              <path
                id="path3354"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M17.274 54.839h-8.08v-8.077h8.08z"
              ></path>
              <g id="g3356" transform="translate(25.396 14.19)">
                <path
                  id="path3358"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 0h14.421"
                ></path>
              </g>
              <g id="g3360" transform="translate(25.396 20.331)">
                <path
                  id="path3362"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M9.995 0H0"
                ></path>
              </g>
              <g id="g3364" transform="translate(25.396 30.533)">
                <path
                  id="path3366"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 0h6.006"
                ></path>
              </g>
              <g id="g3368" transform="translate(25.396 36.674)">
                <path
                  id="path3370"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 0h1.383"
                ></path>
              </g>
              <g id="g3372" transform="translate(25.396 46.761)">
                <path
                  id="path3374"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 0h1.383"
                ></path>
              </g>
              <g id="g3376" transform="translate(25.396 52.902)">
                <path
                  id="path3378"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 0h6.006"
                ></path>
              </g>
              <g id="g3380" transform="translate(39.32 39.252)">
                <path
                  id="path3382"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 3.585L3.435 7.02 10.455 0"
                ></path>
              </g>
              <g id="g3384" transform="translate(47.695 18.373)">
                <path
                  id="path3386"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M0 0h0"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default Icon;
