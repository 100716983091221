import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useStateMounted } from '../../../Core/Hooks';
import FieldComponent from '../../../FieldComponent';
import { Http } from '../../../Core/Services/HttpService';
import { init, multijsoninit, initselect, inputEventChecker, climInvoiceDateaddMoreInit, climInvoiceDateaEditInit } from '../../../assets/js/formbuilder';
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import Header from "../../../Layout/Default/Element/Header";
import Footer from "../../../Layout/Default/Element/Footer";
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import swal from 'sweetalert';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Storage } from '../../../Core/Services/StorageService';
import FdDiscomName from "../FdDiscomName";

type MultiChildProps = {
  field: any;
  pIndex: number
  jIndex: number
  index?: string | number,
  totalFields: any,
  view: any
};

const multiChildPropTypes = {
  field: PropTypes.array.isRequired,
  index: PropTypes.any,
  pIndex: PropTypes.number.isRequired,
  totalFields: PropTypes.any.isRequired
};

function MultiChild({ field, index, pIndex, totalFields, jIndex, view }: MultiChildProps) {

  let srNo = 1;
  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
  const [fields, setFields] = useStateMounted<any>([]);
  const [newFields, setNewFields] = useStateMounted<any>();
  const [roleid, setRoleid] = useState<any>(0);

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
  }, []);

  useEffect(() => {
    if (field) {
      setHasFieldDelete(false);
      setFields([]);
      setNewFields(field.addnew);
      const fieldsMap = field.childrens.map((items: any) => {
        return {
          fields: items,
          delete: false
        }
      })
      setFields(fieldsMap);
    }
  }, [field]);

  useEffect(() => {
    if (fields.length) {
      const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
      setHasFieldDelete(hasDelete);
      srNo = 1;
      inputEventChecker.removeAllEvent();
      setTimeout(() => {
        init();
        multijsoninit();
      }, 1000);
    }
  }, [fields]);

  const addNewField = (i: any) => {
    setFields((prev: any[]) => {
      return [...prev, { fields: newFields, delete: false }];
    });
    setTimeout(() => {
      init();
      multijsoninit();
      climInvoiceDateaddMoreInit('claim_fd_claim_forms2', pIndex);
    }, 500);
  }

  const removeField = (index: number) => {
    setFields((prev: any[]) => {
      const newFields = prev.map((item, i) => {
        if (i === index) {
          item.delete = true;
        }
        return item;
      })
      return newFields;
    });
    setTimeout(() => {
      init();
      multijsoninit();
    }, 1000);
  }
  const extTD = <td colSpan={6} ></td>;

  return (
    <>

      {
        fields.map((item: any, i: number) => {
          return (item.delete === false)
            ? (
              <>
                <tr key={`${idRef.current}-${index}`}>
                  {
                    item.fields.map((field: any, j: number) => {
                      return ([100].includes(j))
                        ? null
                        : <td className={'td' + (jIndex + j)} key={`${idRef.current}-${index}-${field.key}`}>
                          <FieldComponent
                            options={
                              { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                            }
                            fieldName={field.key}
                            field={field} module="claim_" />
                        </td>
                    })
                  }

                  {((view != 'Y') && (roleid == 2 || roleid == 3 || roleid == 4)) ?
                    <>
                      {(i != 0 && hasFieldDelete)
                        ? <>
                          <td className="td18 text-end">
                            <Link className='text-danger' to="#" onClick={() => removeField(i)}>
                              <FiTrash2 className="p-0 mb-0 h5" />
                            </Link>
                          </td>
                        </>
                        : null
                      }

                      {i == 0 ?
                        <td className="td18 text-end">
                          <Link to="#" className='p-0 text-success' onClick={() => addNewField(i)}>
                            <FiPlusCircle className="h5 p-0 mb-0" />
                          </Link>
                        </td>
                        : null
                      }
                    </>
                    : null
                  }
                </tr>
              </>
            )
            : null;
        })
      }

      {
        totalFields && (
          <ChildInput fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
        )
      }

    </>
  );
}

MultiChild.propTypes = multiChildPropTypes;

// ChildInput
type ChildInputProps = {
  fields: any[];
  index?: string | number,
  showTotal?: any
};

const childInputPropTypes = {
  fields: PropTypes.array.isRequired,
  index: PropTypes.any,
  showTotal: PropTypes.any
};

function ChildInput({ fields, index, showTotal }: ChildInputProps) {

  const [_fields, _setFields] = useStateMounted<string[]>([]);

  useEffect(() => {
    if (fields.length) {
      inputEventChecker.removeAllEvent();
      setTimeout(() => {
        initselect();
        multijsoninit();
      }, 1000);
      _setFields(fields);
    }
  }, [fields]);

  return (
    <>
      {
        (_fields.length)
          ? _fields.map((field: any, i: number) => (
            <>
              <td key={index + '-' + i + '-' + field.key}>
                <FieldComponent
                  options={{ showTitle: false, extraId: '_' + index + '_' + i }}
                  fieldName={field.key}
                  field={field} module="claim_" />
              </td>
              {
                ([0, 4].includes(i)) && (
                  <>
                    <td colSpan={2}>&nbsp;</td>
                  </>
                )
              }
            </>
          ))
          : null
      }
    </>
  );
}

ChildInput.propTypes = childInputPropTypes;

// Multijson
type MultijsonProps = {
  field: any;
  index: number,
  totalField: any,
  view: any,
};

const multijsonPropTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  totalField: PropTypes.any.isRequired,
  view: PropTypes.any.isRequired
};

function Multijson({ field, index, totalField, view }: MultijsonProps) {

  let srNo = 1;
  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
  const [fields, setFields] = useStateMounted<any>([]);
  const [newFields, setNewFields] = useStateMounted<any>();
  const TotalsInvoiceAmount = (props: any) => (<Tooltip id="button-tooltip" {...props}>Base Amount+CGST+SGST+IGST</Tooltip>);
  const TotalsEligibleAmount = (props: any) => (<Tooltip id="button-tooltip" {...props}>(50% * (RCM/GST paid by the Utility+GST paid to the agency)</Tooltip>);
  const TotalClaimedAmount = (props: any) => (<Tooltip id="button-tooltip" {...props}>(Other Taxes ( TDS, cess, emtry tax etc ) + Payment to Agency + Taxes eligible for claim under RDSS)</Tooltip>);
  const [roleid, setRoleid] = useState<any>(0);

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
  }, []);

  useEffect(() => {
    if (field) {
      setHasFieldDelete(false);
      setFields([]);
      setNewFields(field.addnew);
      const fieldsMap = field.childrens.map((items: any) => {
        return {
          fields: items,
          delete: false
        }
      })
      setFields(fieldsMap);
    }
  }, [field]);

  useEffect(() => {
    if (fields.length) {
      const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
      setHasFieldDelete(hasDelete);
      srNo = 1;
      inputEventChecker.removeAllEvent();
      setTimeout(() => {
        init();
        multijsoninit();
        adjustwidth();
      }, 1000);
    }
  }, [fields]);

  const addNewField = () => {
    setFields((prev: any[]) => {
      return [...prev, { fields: newFields, delete: false }];
    });
    setTimeout(() => {
      init();
      multijsoninit();
    }, 1000);
  }

  const removeField = (index: number) => {
    setFields((prev: any[]) => {
      const newFields = prev.map((item, i) => {
        if (i === index) {
          item.delete = true;
        }
        return item;
      })
      return newFields;
    });
    setTimeout(() => {
      init();
      multijsoninit();
    }, 1000);
  }

  const adjustwidth = () => {
    let awards: any = document.querySelectorAll('.award-td');
    for (let i = 0; i < awards.length; i++) {
      let awardid = awards[i].getAttribute('id');
      if (awardid) {
        let allcss: any = document.querySelectorAll('.' + awardid);
        if (allcss.length) {
          if (awardid == 'td1') {
            for (let j = 0; j < allcss.length; j++) {
              allcss[j].width = awards[i].offsetWidth;
            }
          }
          else
            awards[i].width = allcss[0].offsetWidth;
        }
      }
      let footid = document.getElementById('tdf' + i);
      if (footid)
        footid.style.width = awards[i].offsetWidth + 'px';
    }
  }

  return (
    <>
      <thead>
        <tr>
          <th rowSpan={3} className="award-td" style={{ minWidth: 50 }}><span className="text-center">S.No.</span></th>
          <th colSpan={7} className="award-td text-center" style={{ minWidth: 100 }}><span className="text-center">Invoice Details</span></th>
          <th colSpan={12} className="award-td text-center" style={{ minWidth: 100 }}><span className="text-center">Payment to contractor</span></th>
          {(roleid == 5 || roleid == 6) ?
            <th rowSpan={2} className="award-td text-center" style={{ minWidth: 100 }}><span className="text-center">Amount Considered</span></th>
            : null
          }

          {((view != 'Y') && (roleid == 2 || roleid == 3 || roleid == 4)) ?
            <th rowSpan={2} className="award-td text-end action-td" style={{ minWidth: 15, paddingLeft: 15 }}>
              <Link to="#" className='p-0 text-success' onClick={addNewField}>
                <FiPlusCircle className="h5 p-0 mb-0" />
              </Link>
            </th>
            :
            <th rowSpan={2} className="award-td text-end action-td" style={{ minWidth: 15, paddingLeft: 15 }}>
              &nbsp;
            </th>
          }
        </tr>
        <tr>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Invoice No</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Invoice Date</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Base Amount (INR)</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">CGST (INR)</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">SGST (INR)</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">IGST (INR)</span></th>

          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Total Invoice Amount (INR)</span>
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={TotalsInvoiceAmount}>
              <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
            </OverlayTrigger>
          </th>

          <th style={{ minWidth: 100 }}><span className="text-center">Other Taxes <br /> ( TDS, cess, <br />  entry tax etc ) (INR)</span></th>
          <th style={{ minWidth: 110 }}><span className="text-center">Challan no.</span></th>
          <th className="award-td" style={{ minWidth: 103 }}><span className="text-center">Challan Date</span></th>
          <th className="award-td" style={{ minWidth: 105 }}><span className="text-center">Liquidated Damage/Other Recovery if any) (INR) </span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">RCM/GST  <br /> paid by <br /> the Utility (INR)</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">GST paid to <br /> the agency (INR)</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Payment <br /> to Agency (INR) </span></th>
          <th className="award-td" style={{ minWidth: 95 }}><span className="text-center">Payment reference no.</span></th>
          <th className="award-td" style={{ minWidth: 112 }}><span className="text-center">Payment reference <br /> date</span></th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Taxes eligible <br /> for claim  <br /> under RDSS * (INR)</span>
            {view != 'Y' ?
              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={TotalsEligibleAmount}>
                <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
              </OverlayTrigger>
              : null
            }
          </th>
          <th className="award-td" style={{ minWidth: 100 }}><span className="text-center">Total <br /> Claimed <br /> Amount (INR)</span>
            {view != 'Y' ?
              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={TotalClaimedAmount}>
                <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
              </OverlayTrigger>
              : null
            }
          </th>


          {(roleid == 2 || roleid == 3 || roleid == 4) ?
            <th className="award-td"></th>
            : null
          }
        </tr>
      </thead>
      <tbody>
        {
          fields.map((item: any, i: number) => {
            return (item.delete === false)
              ? (
                <>
                  <tr className="align-top" data-id={i}>
                    <td className="td1"><span className="text-center">{srNo++}</span></td>
                    {
                      item.fields.map((field: any, j: number) => {
                        if (field.type === 'multijson') {
                          const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
                          const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
                          return (<td colSpan={12} className="multiple-award-items" style={{ paddingTop: 0 }}>
                            <table className="table-responsive RevenueDetailsTable PqTable" id={'invoice_date_' + i}>
                              <tbody>
                                <MultiChild field={field} pIndex={(srNo - 2)} jIndex={10} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} view={view} />
                              </tbody>
                            </table>
                          </td>);
                        } else {
                          let extraId = '_' + i + '_' + j;
                          let style: any = {};
                          let hidefield = false;
                          if ([8].includes(j)) {
                            style.display = 'none';
                            hidefield = true;
                          }

                          if (roleid == 2 || roleid == 3 || roleid == 4) {
                            return (field.type !== 'multijson' && !field.childrens)
                              ? (
                                !hidefield ?
                                  <td key={`${idRef.current}-${index}-${field.key}`}>
                                    <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module="claim_" />
                                  </td>
                                  :
                                  <span style={style}>
                                    <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module="claim_" />
                                  </span>
                              ) : null;

                          } else {
                            return (
                              <td key={`${idRef.current}-${index}-${field.key}`}>
                                <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module="claim_" />
                              </td>
                            );
                          }
                        }
                      })
                    }

                    {((view != 'Y') && (roleid == 2 || roleid == 3 || roleid == 4)) ?
                      <td className="text-end align-top">
                        {
                          (hasFieldDelete)
                            ? <>
                              <Link className='text-danger' to="#" onClick={() => removeField(i)}>
                                <FiTrash2 className="p-0 mb-0 h5" />
                              </Link>
                            </>
                            : null
                        }
                      </td>
                      : null
                    }

                  </tr>
                </>
              )
              : null;
          })
        }
        {
          (totalField) && (
            <tr>
              <td colSpan={3}><span className="text-center">Grand Total</span></td>
              <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[0].key} field={totalField[0]} module="claim_" /></td>
              <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[1].key} field={totalField[1]} module="claim_" /></td>
              <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[2].key} field={totalField[2]} module="claim_" /></td>
              <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[3].key} field={totalField[3]} module="claim_" /></td>
              <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[4].key} field={totalField[4]} module="claim_" /></td>
              <td colSpan={12}>
                <table className="table-responsive RevenueDetailsTable PqTable award-footer-table">
                  <tbody>
                    <tr>
                      <td style={{ minWidth: 100, width: 100 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[5].key} field={totalField[5]} module="claim_" /></td>
                      <td style={{ minWidth: 100, width: 100 }}>&nbsp;</td>
                      <td style={{ minWidth: 90, width: 100 }}>&nbsp;</td>
                      <td style={{ minWidth: 92, width: 100 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[6].key} field={totalField[6]} module="claim_" /></td>
                      <td style={{ minWidth: 100, width: 80 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[7].key} field={totalField[7]} module="claim_" /></td>
                      <td style={{ minWidth: 90, width: 100 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[8].key} field={totalField[8]} module="claim_" /></td>
                      <td style={{ minWidth: 100, width: 100 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[9].key} field={totalField[9]} module="claim_" /></td>
                      <td style={{ minWidth: 100, width: 100 }}>&nbsp;</td>
                      <td style={{ minWidth: 86, width: 100 }}>&nbsp;</td>
                      <td style={{ minWidth: 76, width: 100 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[10].key} field={totalField[10]} module="claim_" /></td>
                      <td style={{ minWidth: 90, width: 100 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[11].key} field={totalField[11]} module="claim_" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>

              {(roleid == 2 || roleid == 3 || roleid == 4) ?
                <span style={{ display: 'none' }}>
                  <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[12].key} field={totalField[12]} module="claim_" /></td>
                </span>
                :
                <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[12].key} field={totalField[12]} module="claim_" /></td>
              }
            </tr>
          )
        }
      </tbody>
    </>
  );
}
// NewClaimForm
function ClaimForm2(props?: any) {

  const [loading, setLoading] = useStateMounted<boolean>(true);
  const [roleid, setRoleid] = useState<any>(0);
  const formData: any = useRef(null);
  const [username, setUsername] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [error, setError] = useStateMounted<string>();
  const [getdocumentation, getDocumentation] = useState<any[]>([]);
  const [getcomponentname, setComponentname] = useState<any>(null);
  const [gettranchename, setTranchename] = useState<any>(null);
  const [getpagelink, setPageLink] = useState<any>(null);
  const [getcomponentid, setComponentId] = useState<any>(0);
  const [gettrancheid, setTrancheId] = useState<any>(0);
  const [getreleasefundid, setReleaseFundId] = useState<any>(0);
  const [fields, setFields] = useState<any[]>([]);
  const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
  const [gettrackerid, setTrackerId] = useState<any>(0);
  const [districtId, setDistrictId] = useState<any>(0);
  const [claimFormId, setClimFormId] = useState<any>(0);
  const [agencyName, setAgencyName] = useState<any>(null);
  const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
  const [view, setView] = useState<any[]>([]);
  const layout = useLayout();
  let layoutDataSubscribe: any;

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    (async () => {
      if (location.state) {
        // console.log(location.state);
        getDocumentation(location.state.data);
        setComponentname(location.state.component_name);
        setTranchename(location.state.tranche_name);
        setComponentId(location.state.component_id);
        setTrancheId(location.state.tranche_id);
        setReleaseFundId(location.state.id);
        setAplicationTrackerId(location.state.aplication_tracker_id);
        setTrackerId(location.state.ref_id);
        setDistrictId(location.state.district_id);
        setClimFormId(location.state.id);
        setAgencyName(location.state.agency_name);
      }
      else {
        navigate('/fund-disbursal');
      }
    })();
  }, [getcomponentid, gettrancheid]);

  const goBack = () => {
    setPageLink('/claim-forms-agency-link');
  }

  useEffect(() => {
    (async () => {
      if (getpagelink) {
        navigate(getpagelink, { state: { ref_id: gettrackerid, id: claimFormId, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid, claimformid: claimFormId, district_id: districtId } });
      }
    })();
  }, [getpagelink]);

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    setUsername(Storage.get('username'));
    setDiscomid(Storage.get('discom_id'));
    setDiscomUserid(Storage.get('discom_user_id'));
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append("release_fund_id", getreleasefundid);
    myFormData.append('component_id', getcomponentid);
    myFormData.append("tranche_id", gettrancheid);
    myFormData.append("aplication_tracker_id", getaplicationtrackerid);
    myFormData.append("roleid", roleid);
    myFormData.append("district_id", districtId);
    myFormData.append("agency_name", agencyName);

    Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          swal("Warning", res.message, "info");
        } else {
          swal("Input Form", "Data have been saved", "success");
          navigate('/claim-forms-agency-link', { state: { ref_id: gettrackerid, id: claimFormId, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid, claimformid: claimFormId, district_id: districtId } });
        }
      });

  }

  const getFields = () => {
    (async () => {
      setLoading(true);
      setError('');
      setFields([]);
      if (discomid && getaplicationtrackerid && roleid && districtId && agencyName) {
        const params = {
          form_id: 20,
          roleid: roleid,
          discom_id: discomid,
          discom_user_id: discomuserid,
          release_fund_id: getreleasefundid,
          component_id: getcomponentid,
          tranche_id: gettrancheid,
          aplication_tracker_id: getaplicationtrackerid,
          district_id: districtId,
          agency_name: agencyName
        };

        await Http.get(`/apiv1/claimfields`, { params })
          .then((res) => {
            if (res.resultflag && res.fields) {
              if (res.fields && Array.isArray(res.fields)) {
                if (!res.fields.length) setError("No data found.");
                setFields(res.fields);
                setSubmittedStatus(res.submittedStatus);
                setView(res.view);
              } else {
                setError("Invalid data type.");
              }
            } else if (!res.resultflag) {
              setError(res.message);
            } else {
              setError('No data found.');
            }
          })
          .catch((error) => {
            setError('System error occurred!! please try again.');
          })
          .finally(() => {
            setLoading(false);
          });
      }

    })();
  };

  useEffect(() => {
    getFields();
  }, [discomid, discomuserid, getreleasefundid, getcomponentid, gettrancheid, getaplicationtrackerid, roleid, districtId, agencyName]);

  useEffect(() => {
    if (fields.length) {
      setTimeout(() => {
        init();
        climInvoiceDateaEditInit('claim_fd_claim_forms2');
      }, 500);
    }
  }, [fields]);

  return (
    <>
      {loading && (
        <>
          <div className="content-spinner spinner-container h-50vh">
            <div className="spinner-outer">
              <div className="loading-spinner"></div>
            </div>
          </div>
        </>
      )}
      {error && (
        <>
          <Alert className='mt-3 mb-3' variant="danger">{error}</Alert>
        </>
      )}
      {
        ((!loading && !error) && fields?.length > 0) && (
          <>
            <div className="mainWrap">
              <Header />
              <section className="InnerBanner">
                <div className="container">
                  <ul className="BreadCumb list-unstyled mb-0">
                  <li><Link to="/" className="text-decoration-none">Home</Link></li>
                    {(roleid == 2 || roleid == 3 || roleid == 4) ?
                        <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                        :
                        <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                    }
                    <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                    <li><Link to="/fund-disbursal" className="text-decoration-none">Request for Fund Release</Link></li>
                    <li>Claim Form 2</li>
                    <FdDiscomName />
                  </ul>
                </div>
              </section>
              <section className="financialForm">
                <div className="container">
                  <div className="financialMainFrom">
                    <div className="tab-content border-0 p-0">
                      <div className="app-energy-Subsidy RevenueDetailsTable">
                        <form noValidate onSubmit={onSubmit} ref={formData}>
                          <table className="table-responsive RevenueDetailsTable PqTable w-100">
                            <thead className="text-center">
                              <th>Discom</th>
                              <th>Type Of Work</th>
                              <th>Name of Scheme/ Project/District</th>
                              <th>Approved Project Cost (INR)</th>
                              <th>GoI Grant no.</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className='sperator'></div>
                          <table className="table-responsive RevenueDetailsTable PqTable w-100">
                            <thead className="text-center">
                              <th>Contract or Purchase Order No.</th>
                              <th>Contract or Purchase Order Date</th>
                              <th>Name of Agency</th>
                              <th>Address of Agency</th>
                              <th>Award Value (INR)</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} />
                                </td>
                                <td>
                                  <FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className='sperator'></div>
                          <table className="table-responsive RevenueDetailsTable PqTable" id="claim_tabel_id">
                            {
                              fields.map((field: any, index: number) => {
                                const totalField = (fields.length && fields[11].childrens) ? fields[11].childrens : null;
                                return field.type === 'multijson' && (
                                  <Multijson field={field} index={index} totalField={totalField} view={view} />
                                )
                              })
                            }
                          </table>
                          <div className="formBtnSection justify-content-between mt-4">
                            <Link to="#" className="btn btnSubmit me-4" onClick={() => goBack()}>Back</Link>
                            {(isSubmittedStatus == 1) ?
                              <button className="btnDraft me-0" type="submit">Save & Back</button>
                              : null
                            }
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </div>
          </>
        )
      }
    </>
  );

}

Multijson.propTypes = multijsonPropTypes;

export default ClaimForm2;

