import React, { useEffect, useState } from 'react';
import ClaimForm1 from './ClaimForms/ClaimForm1';
import ClaimForm2 from './ClaimForms/ClaimForm2';
import FdDiscomName from "./FdDiscomName";

const ClaimForms = (props: any) => {
    const [selected, setSelected] = useState("ClaimForm1");
    useEffect(() => {
        setSelected("ClaimForm1");
        if (props.cf) setSelected(props.cf);
    }, []);
    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };
    return (
        <>
            <div className="tab-pane">
                <div className="radioYear">
                    <div className="row justify-content-center align-items-center">
                        {/* <div className="col-sm-3">
                            <div className="form-check ps-0">
                                <input type="text" id='State' className='w-100' placeholder='State' />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-check ps-0">
                                <input type="text" id='Discom' className='w-100' placeholder='Discom' />
                            </div>
                        </div> */}
                        <div className="col-4 col-sm-3 col-lg-2">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="ClaimForm" value="ClaimForm1" id="ClaimForm1" checked={selected === "ClaimForm1"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="ClaimForm1">
                                    ClaimForm 1
                                </label>
                            </div>
                        </div>
                        <div className="col-4 col-sm-3 col-lg-2">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="ClaimForm" value="ClaimForm2" id="ClaimForm2" checked={selected === "ClaimForm2"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="ClaimForm2">ClaimForm 2</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div aria-hidden={selected !== "ClaimForm1" ? true : false}><ClaimForm1 /></div>
                <div aria-hidden={selected !== "ClaimForm2" ? true : false}><ClaimForm2 /></div>
            </div>

        </>
    )
}
export default ClaimForms;