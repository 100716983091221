import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Pq1 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);

    return (
        <>
        {/* PQ-1 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5} className="blankPara">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">PQ-1 : Date of publishing quarterly un-audited accounts <b>*</b></p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[213] && fields[213].value ? fields[213].value : ''} name="formdata[pqsop_details][pq1_nodal]" id="pqsop_details_pq1_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][pq1_remarks_nodal]" id="pqsop_details_pq1_remarks_nodal" defaultValue={fields[228] && fields[228].value ? fields[228].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][pq1_document_nodal]" id="pqsop_details_pq1_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[243].value != '' ?
                                            <a href={fields[243].value} title={fields[243].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>

                                    {fields[213].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[213] && fields[213].value ? fields[213].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[228].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[228] && fields[228].value ? fields[228].value : ''}</div>
                                    {/* : null
                                    } */}

                                    {fields[243].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[243] && fields[243].value ? fields[243].value : ''} title={fields[243] && fields[243].value ? fields[243].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>

                                }
                                </> : null
                            }


                        </div>
                        <div className={props.remove}>
                            <MyTooltip placement="left">
                                <ul>
                                    <li>DISCOMs would publish quarterly un-audited accounts within 60 days of the end of each quarter during first two years of operation of the scheme (i.e. for FY 2021-22 and FY 2022-23) and thereafter audited quarterly accounts within 45 days from 3rd year onwards</li>
                                    <li>Documentary Evidence: Copy of quarterly unaudited accounts shared by the DISCOM</li>
                                </ul>
                            </MyTooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Quarters</td>
                <td style={{ width: '250px' }} className="text-center">Quarter 1</td>
                <td style={{ width: '250px' }} className="text-center">Quarter 2</td>
                <td style={{ width: '250px' }} className="text-center">Quarter 3</td>
                <td style={{ width: '250px' }} className="text-center">Quarter 4</td>
                {/* <td style={{ width: props.width + 'px' }} className="text-center">Quarter 1</td>
                <td style={{ width: props.width + 'px' }} className="text-center">Quarter 2</td>
                <td style={{ width: props.width + 'px' }} className="text-center">Quarter 3</td>
                <td style={{ width: props.width + 'px' }} className="text-center">Quarter 4</td> */}
            </tr>
            <tr>
                <td>Target Date</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                </td>
            </tr>
            <tr>
                <td>Published Date</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} />
                </td>
            </tr>
            <tr>
                <td>Status</td>
                <td className="text-center">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} />
                </td>
                <td className="text-center">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} />
                </td>
                <td className="text-center">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} />
                </td>
                <td className="text-center">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[11].key} field={fields[11]} />
                    {/* <span className="status"></span> */}
                </td>
            </tr>
            <tr>
                <td>Remarks</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[12].key} field={fields[12]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[14].key} field={fields[14]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[15].key} field={fields[15]} />
                </td>
            </tr>
            <tr className="borderThik">
                <td>
                    Upload
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[16].key} field={fields[16]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[17].key} field={fields[17]} />

                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[18].key} field={fields[18]} />

                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[19].key} field={fields[19]} />

                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Pq1;