import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import "../../../RdssForms/rdssforms.scss";
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import { OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useStateMounted } from "../../../Core/Hooks";

// Images
import FieldComponent from "../../../FieldComponent";
import { FiUploadCloud } from "react-icons/fi";
import MyTooltip from "./MyTooltip";
import Pq1 from "./Pq1";
import Pq2 from "./Pq2";
import Pq3 from "./Pq3";
import Sop34 from "./Sop34";
import Pq4 from "./Pq4";
import Sop311 from "./Sop311";
import Sop312 from "./Sop312";
import Sop313 from "./Sop313";
import Pq5 from "./Pq5";
import Pq6 from "./Pq6";
import Sop35 from "./Sop35";
import Pq7 from "./Pq7";
import Sop32 from "./Sop32";
import Sop33 from "./Sop33";
import Pq8 from "./Pq8";
import { Storage } from "../../../Core/Services/StorageService";

type PqSopFormProps = {
    apiurl: string;
    refresh?: boolean;
};

const PqSopForm = (props:any) => {
    const [year, setYear] = useState<any>(props.year);
    const layout = useLayout();
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    // const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [submittedStatus, setSubmittedStatus] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();

    useEffect(() => {
        layout.set({ pdfParams: { form_ids: '15' } });
        // props.tblkey('pqsop_details');
    }, []);

    useEffect(() => {
        setYear(props.year);
    },[props.year]);

    // const getFields = () => {
    //     (async () => {
    //         setFields([]);
    //         let footerspinner:any = document.getElementById('footer-spinner');
    //         if ( footerspinner )
    //             footerspinner.classList.remove('d-none');
    //         await Http.get(props.apiurl)
    //             .then((result) => {
    //                 setIsLoaded(true);
    //                 setFields(result.fields);
    //                 layout.set({ submittedStatus: result });
    //                 setSubmittedStatus(result.submittedStatus);
    //                 setTimeout(() => {
    //                     init();
    //                 }, 2000);
    //             })
    //             .catch((error) => {
    //                 setIsLoaded(true);
    //                 setError(error);
    //             }).finally(() => {
    //                 footerspinner.classList.add('d-none');
    //             })
    //     })();
    // };


    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
                Http.get(props.apiurl)
                .then((res) => {
                  if (res.resultflag && res.fields) {
                      if (res.fields && Array.isArray(res.fields)) {
                          if (!res.fields.length) setError("No data found.");
                          setIsLoaded(true);
                          setFields(res.fields);
                          layout.set({ submittedStatus: res });
                          setSubmittedStatus(res.submittedStatus);
                          setTimeout(() => {
                            init();
                        }, 2000);
                      } else {
                        setError('System error occurred!! please try again.');
                        setIsLoaded(true);
                      }
                      setIsLoaded(true);
                  } else if (!res.resultflag) {
                      setError(res.message);
                  } else {
                      setError('No data found.');
                    
                  }
                  setIsLoaded(true);
              }).catch((error) => {
                setError('System error occurred!! please try again.');
                setIsLoaded(true);
                 
              })
              .finally(() => {
                setIsLoaded(true);;
                    footerspinner.classList.add('d-none');
              });
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                {/* PQ-1 */}
                <Pq1 fields={fields} />
                {/* PQ-2 */}
                <Pq2 fields={fields} />
                {/* PQ-3 */}
                <Pq3 fields={fields}  year={year} />
                {/* SoP-3.4 */}
                <Sop34 fields={fields} year={year} />
                {/* PQ-4 */}
                <Pq4 fields={fields}  year={year} submittedStatus={submittedStatus} />
                {/* Sop-3.1.1 */}
                <Sop311 fields={fields} year={year} />
                {/* SoP-3.1.2 */}
                <Sop312 fields={fields} />
                {/* SoP-3.1.3 */}
                <Sop313 fields={fields} />
                {/* PQ-5 */}
                <Pq5 fields={fields} year={year} />
                {/* PQ-6 */}
                <Pq6 fields={fields} year={year} />
                {/* SoP-3.5 */}
                <Sop35 fields={fields} />
                {/* PQ-7 */}
                <Pq7 fields={fields} />
                {/* SoP-3.2 */}
                <Sop32 fields={fields} />
                {/* SoP-3.3 */}
                <Sop33 fields={fields} />
                {/* PQ-8 */}
                <Pq8 fields={fields} year={year} />
            </>
        );
    }
};

const PqSopForms = (props:any) => {
    const [year, setYear] = useState<any>(0);
    const [roleid, setRoleid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setYear(props.year);
    },[props.year]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    return (
        <>
            <div className="mainWrap">
                <div className="table-responsive RevenueDetailsTable PqTable pqSopFormOnly responsiveFixWidth refUploadSelect">
                    <table className="table">
                        <tbody>
                            { roleid == 0 ? null :
                                <PqSopForm apiurl={"/apiv1/formfields/?form_id=15&roleid="+roleid+"&year="+year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} year={year} />
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default PqSopForms;