import React, { Component } from 'react'
import RevammpImg from '../../images/rewamp.jpg';
export default class SchemeOverview extends Component {
    render() {
        return (
            <>
                <div className='SchemeOverviewSection'>
                    <div className='heading'>
                        <h2>Revamped Distribution Sector Scheme<span>(RDSS)</span></h2>
                        <p>Reforms-Based and Result-Linked</p>
                    </div>
                    <div className='imgBox'>
                        <div className='row align-items-center'>
                            <div className='col-lg-12 col-xl-8 order-2 order-xl-1'>
                                <p className='mb-0'>The government of India has approved the Revamped Distribution Sector Scheme (RDSS) to help DISCOMs improve their operational efficiencies and financial sustainability by providing result-linked financial assistance to DISCOMs to strengthen supply infrastructure based on meeting pre-qualifying criteria and achieving basic minimum benchmarks. The scheme has an outlay of Rs 3,03,758 Crore over 5 years i.e. FY 2021-22 to FY 2025-26. The outlay includes an estimated Government Budgetary Support (GBS) of Rs 97,631 Crore.</p>
                            </div>
                            <div className='col-lg-12 col-xl-4 order-1 order-xl-2'>
                                <div className='imgWrap'>
                                    <img src={RevammpImg} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='schemeFeature'>
                        <h3>REC and PFC have been nominated as nodal agencies for facilitating the implementation of the scheme.</h3>
                        <p>The scheme aims to meet the following objectives:</p>
                        <hr />
                        <ul>
                            <li>Reduction of AT&C losses to pan-India levels of 12-15% by 2024-25.</li>
                            <li>Reduction of ACS-ARR gap to zero by 2024-25.</li>
                            <li>Improvement in the quality, reliability and affordability of power supply to consumers through a financially sustainable and operationally efficient distribution sector.</li>
                        </ul>
                    </div>
                    <div className='contentSec'>
                        <h4>The Scheme has the following components:</h4>
                        <hr/>
                        <div className='row mt-4'>
                            <div className='col-md-6'>
                                <div className='part partA'>
                                    <h5>Part A</h5>
                                    <p>Financial support for Prepaid Smart Metering & System Metering and up-gradation of the Distribution Infrastructure.</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='part'>
                                    <h5>Part B</h5>
                                    <p>Training & Capacity Building and other Enabling & Supporting Activities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='schemeFeature'>
                        <h6>Learning from the experience of previous schemes, the Revamped Distribution Sector Scheme has been developed to address state-specific needs. Some of the salient features are as below:</h6>
                    </div>
                    <ul>
                        <li>Prepaid Smart Metering to be prioritized for
                            <ul>
                                <li>500 AMRUT cities, with AT&C Losses &gt; 15%</li>
                                <li>All Union Territories</li>
                                <li>MSMEs, Industrial and Commercial consumers</li>
                                <li>All Government offices at the Block level and above</li>
                                <li>Other areas with high losses</li>
                            </ul>
                        </li>
                        <li>Prepaid Smart metering for remaining consumers and areas is proposed to be taken up by the respective DISCOMs in a phased manner.</li>
                        <li>Prepaid Smart metering and system metering are proposed to be implemented through PPP on TOTEX (CAPEX+OPEX) mode.</li>
                        <li>Part A of the scheme also provides financial assistance to DISCOMs for infrastructure creation and undertaking reforms to achieve the desired results towards improvement in operational efficiency and financial sustainability.</li>
                        <li>Provision of feeder segregation for unsegregated feeders. Thereafter these feeders are to be solarized under KUSUM – leading to cheap/ free daytime power for irrigation.</li>
                        <li>The pre-qualifying criteria need to be mandatorily met with the DISCOMs before they can be evaluated based on the Result Evaluation Matrix. Thereafter, performance based on Result Evaluation Matrix shall form the basis for the release of funds under the scheme.</li>
                        <li>For Prepaid Smart metering, a grant of Rs 900 or 15% of the cost per consumer meter (whichever is lower), shall be available for “Other than Special Category” States. For “Special Category” States, the grant of Rs 1350 or 22.5% of the cost per consumer (whichever is lower) shall be available.</li>
                        <li>To incentivize the States/UTs to fast-track installation of prepaid Smart Meters by December 2023, an additional incentive of 7.5% of the cost per consumer meter or Rs. 450 (whichever is lower) shall be available. For “Special Category” States the additional incentive shall be 11.25% or Rs. 675 per consumer meter (whichever is lower).</li>
                        <li>For works other than smart metering, maximum financial assistance given to DISCOMs of “Other than Special Category” States will be 60% of the approved cost, while for the DISCOMs in special category states, the maximum financial assistance will be 90% of the approved cost.</li>
                    </ul>
                </div>
            </>
        )
    }
}
