/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import moment from "moment";

export interface TimeByStartEnd {
  time: string;
  time24Hour: string;
  to: string;
  to24Hour: string;
  interverl: number;
  interverlUnit: string;
};

export function timeByStartEnd(startTime: string, endTime: string, interverl = 1): TimeByStartEnd[] {
  const items: TimeByStartEnd[] = [];
  let sTime = moment(startTime, 'hh:mm A');
  const eTime = moment(endTime, 'hh:mm A');
  if (!interverl) { interverl = 1; }
  do {
    let item = {
      time: sTime.format('hh:mm A'),
      time24Hour: sTime.format('kk:mm'),
      interverl: interverl,
      interverlUnit: 'm',
      to: '',
      to24Hour: '',
    };
    sTime = sTime.add(interverl, 'm');
    item.to = sTime.format('hh:mm A');
    item.to24Hour = sTime.format('kk:mm');
    items.push(item);
  }
  while (sTime.format('hh:mm A') !== eTime.format('hh:mm A'));
  return items;
};

export interface YearByStartEnd {
  label: string;
  value: string;
};

export function yearByStartEnd(start: number | string, end: number | string, interverl = 1): YearByStartEnd[] {
  const items: YearByStartEnd[] = [];
  let sYear = moment(start, 'YYYY');
  const eYear = moment(end, 'YYYY');
  if (!interverl) { interverl = 1; }
  do {
    let item = {
      label: sYear.format('YYYY'),
      value: sYear.format('YYYY')
    };
    sYear = sYear.add(interverl, 'y');
    items.push(item);
  }
  while (sYear.format('YYYY') <= eYear.format('YYYY'));
  return items;
};

export interface MonthByStartEnd {
  label: string;
  value: string;
};

export function monthByStartEnd(
  start: number | string,
  end: number | string,
  interval = 1,
  labelFormat = 'MMM',
  valueFormat = 'M'
): MonthByStartEnd[] {
  const items: MonthByStartEnd[] = [];
  let sMonth = moment(start, 'M');
  const eMonth = moment(end, 'M');
  if (!interval) {
    interval = 1;
  }
  do {
    const item: MonthByStartEnd = {
      label: sMonth.format(labelFormat),
      value: sMonth.format(valueFormat)
    };
    sMonth = sMonth.add(interval, 'months');
    items.push(item);
  } while (Number(sMonth.format('M')) < Number(eMonth.format('M')));
  // Add Last Month
  const item: MonthByStartEnd = {
    label: sMonth.format(labelFormat),
    value: sMonth.format(valueFormat)
  };
  items.push(item);
  return items;
}

export interface WeekDays {
  format: string;
  date: string;
  day: number;
  label: string;
  sortLabel: string;
  month: number;
  year: number;
  today: boolean;
  tomorrow: boolean;
  yesterday: boolean;
  moment: moment.Moment;
};

export function weekDays(date: string, format = 'DD/MM/YYYY', displayFormat = 'DD/MM/YYYY'): WeekDays[] {
  const items: WeekDays[] = [];
  const toDay = moment();
  const tomorrowDate = moment().add(1, 'd');
  const yesterdayDate = moment().add(-1, 'd');
  let mDate = moment(moment(date, format)).isoWeekday(1);
  for (var i = 0; i < 7; i++) {
    const response: WeekDays = {
      format: displayFormat,
      date: mDate.format(displayFormat),
      day: Number(mDate.format('DD')),
      label: mDate.format('dddd'),
      sortLabel: mDate.format('ddd'),
      month: Number(mDate.format('MM')),
      year: mDate.year(),
      today: (toDay.format(displayFormat) === mDate.format(displayFormat)),
      tomorrow: (tomorrowDate.format(displayFormat) === mDate.format(displayFormat)),
      yesterday: (yesterdayDate.format(displayFormat) === mDate.format(displayFormat)),
      moment: moment(mDate.format(displayFormat), displayFormat)
    };
    items.push(response);
    mDate = mDate.add(1, 'd');
  }
  return items;
};

// Financial Year Get FY - Start and End 

export function getCurrentFy(month = '04') {
  const cYear = moment();
  if (moment().format('MM') < month) {
    cYear.add(-1, 'y');
  }
  return cYear.format('YYYY');
}

export interface fyByStartEnd {
  label: string;
  value: string;
};

export function fyByStartEnd(start: number | string, end: number | string, month = '04'): fyByStartEnd[] {
  const items: fyByStartEnd[] = [];
  let sYear = moment(start + month, 'YYYYMM');
  let eYear = moment(end + (moment().format('MM')), 'YYYYMM');
  // Check if end year is not april then update end year to previous year
  if (eYear.format('YYYY') === moment().format('YYYY') && moment().format('MM') < month) {
    eYear = moment(getCurrentFy(month), 'YYYY');
  }
  do {
    let item = {
      label: sYear.format('YYYY'),
      value: sYear.format('YYYY')
    };
    sYear = sYear.add(1, 'y');
    items.push(item);
  }
  while (sYear.format('YYYY') <= eYear.format('YYYY'));
  return items;
};