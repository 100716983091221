import React from "react";

class Icon extends React.PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73.651"
        height="66.05"
        viewBox="0 0 73.651 66.05"
      >
        <g
          id="monitor"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.25"
          transform="translate(.125 .125)"
        >
          <path
            id="Path_45161"
            d="M68.926 0H55.095a1.075 1.075 0 100 2.15h13.831a2.327 2.327 0 012.324 2.324v40.855H2.15V4.475a2.327 2.327 0 012.324-2.324h45.6a1.075 1.075 0 100-2.15H4.475A4.48 4.48 0 000 4.475v44.264a4.48 4.48 0 004.475 4.475h10.293a1.075 1.075 0 100-2.15H4.475a2.327 2.327 0 01-2.325-2.325v-1.26h69.1v1.26a2.327 2.327 0 01-2.324 2.324H19.739a1.075 1.075 0 000 2.15h6.767l-1.062 6.135h-1.962a3.226 3.226 0 000 6.451h26.437a3.226 3.226 0 100-6.451h-1.962L46.9 53.214h22.026a4.48 4.48 0 004.474-4.475V4.475A4.48 4.48 0 0068.925 0zM45.774 59.349h-2.927a1.075 1.075 0 100 2.15h7.072a1.075 1.075 0 110 2.15H23.482a1.075 1.075 0 010-2.15h14.346a1.075 1.075 0 100-2.15h-10.2l1.062-6.135h16.023z"
            data-name="Path 45161"
          ></path>
          <path
            id="Path_45162"
            d="M58.165 27.205a1.075 1.075 0 00-.958.588l-5.074 9.973-4.282-8.416a1.075 1.075 0 00-1.917 0l-2.321 4.562h-3.706a1.075 1.075 0 100 2.15h4.364a1.075 1.075 0 00.958-.588l1.662-3.266 4.28 8.42a1.075 1.075 0 001.917 0l5.074-9.973 2.454 4.823a1.075 1.075 0 00.958.588h4.365a1.075 1.075 0 000-2.15h-3.706l-3.113-6.119a1.075 1.075 0 00-.955-.592z"
            data-name="Path 45162"
          ></path>
          <path
            id="Path_45163"
            d="M39.906 24.02h5.579a1.075 1.075 0 001.075-1.075V11.626a1.075 1.075 0 00-1.075-1.075h-5.579a1.075 1.075 0 00-1.075 1.075v11.32a1.075 1.075 0 001.075 1.074zm1.075-11.319h3.429v9.169h-3.429z"
            data-name="Path 45163"
          ></path>
          <path
            id="Path_45164"
            d="M55.752 13.627h-5.579a1.075 1.075 0 00-1.075 1.075v8.243a1.075 1.075 0 001.075 1.075h5.579a1.075 1.075 0 001.075-1.075v-8.243a1.075 1.075 0 00-1.075-1.075zm-1.075 8.243h-3.429v-6.093h3.429z"
            data-name="Path 45164"
          ></path>
          <path
            id="Path_45165"
            d="M65.942 24.02a1.075 1.075 0 001.075-1.075V8.788a1.075 1.075 0 00-1.075-1.075h-5.579a1.075 1.075 0 00-1.075 1.075v14.157a1.075 1.075 0 001.075 1.075zM61.435 9.863h3.429V21.87h-3.429z"
            data-name="Path 45165"
          ></path>
          <path
            id="Path_45166"
            d="M31.812 19.876H7.44a1.075 1.075 0 000 2.15h24.372a1.075 1.075 0 000-2.15z"
            data-name="Path 45166"
          ></path>
          <path
            id="Path_45167"
            d="M7.44 27.738h4.361a1.075 1.075 0 100-2.15H7.44a1.075 1.075 0 100 2.15z"
            data-name="Path 45167"
          ></path>
          <path
            id="Path_45168"
            d="M31.812 25.588H16.728a1.075 1.075 0 100 2.15h15.083a1.075 1.075 0 100-2.15z"
            data-name="Path 45168"
          ></path>
          <path
            id="Path_45169"
            d="M31.812 7.713H7.44a1.075 1.075 0 000 2.15h24.372a1.075 1.075 0 000-2.15z"
            data-name="Path 45169"
          ></path>
          <path
            id="Path_45170"
            d="M7.44 15.573h15.114a1.075 1.075 0 100-2.15H7.44a1.075 1.075 0 100 2.15z"
            data-name="Path 45170"
          ></path>
          <path
            id="Path_45171"
            d="M31.812 13.425H27.48a1.075 1.075 0 000 2.15h4.331a1.075 1.075 0 000-2.15z"
            data-name="Path 45171"
          ></path>
          <path
            id="Path_45172"
            d="M32.887 32.305a1.075 1.075 0 00-1.075-1.075H7.44a1.075 1.075 0 000 2.15h24.372a1.075 1.075 0 001.075-1.075z"
            data-name="Path 45172"
          ></path>
          <path
            id="Path_45173"
            d="M7.44 36.912a1.075 1.075 0 100 2.15h11.469a1.075 1.075 0 000-2.15z"
            data-name="Path 45173"
          ></path>
        </g>
      </svg>
    );
  }
}

export default Icon;
