import { Stepper, Step } from "react-form-stepper";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../../../RdssForms/rdssforms.scss";
import { init, multijsoninit, initselect } from "../../../../assets/js/formbuilder";
import AwardModule from "./AwardModule";
import AwardModuleSm from "./AwardModuleSm";
import AwardModuleLrPma from "./AwardModuleLrPma";
import AwardModuleSmPma from "./AwardModuleSmPma";
import AwardItems from "../DPR/AwardItems";
import SurveyedItems from "../DPR/SurveyedItems";
import BreakupBoQ from "../DPR/BreakupBoQ";
import swal from 'sweetalert';
import { useLayout } from "../../../../Core/Providers/LayoutProvider";

// Forms
import { Http } from "../../../../Core/Services/HttpService";
import { Storage } from "../../../../Core/Services/StorageService";

export default function DPR() {
  return (
    <div className="App">
      <PlaceOrder />
    </div>
  );
}

export const PlaceOrder = (props: any) => {
  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const [refresh, setRefresh] = useState(false);
  const formData: any = useRef(null);
  const [tblkey, setTblkey] = useState<any>('lr_award_module');
  const [roleid, setRoleid] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [steps, setSteps] = useState(['active', '', '', '']);
  const layout = useLayout();
  let layoutDataSubscribe: any;
  const [getselectedaward, setAwardItemsType] = useState("SMAWARD");
  const [getselectedsurveyed, setSurveyedItemsType] = useState("SMSURVEYED");

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    setDiscomid(Storage.get('discom_id'));
    setDiscomUserid(Storage.get('discom_user_id'));
  }, [roleid, discomid, discomuserid]);

  const tabkeyChangeValue = (evt?: any) => {
    setTblkey(evt);
  };

  useEffect(() => {
    (async () => {
      layoutDataSubscribe = await layout.data.subscribe((res: any) => {

        if (res.awarditemstype) {
          setAwardItemsType(res.awarditemstype);
        } else {
          setAwardItemsType("SMAWARD");
        }

        if (res.surveyeditemstype) {
          setSurveyedItemsType(res.surveyeditemstype);
        } else {
          setSurveyedItemsType("SMSURVEYED");
        }

      });
    })();
    return () => {
      if (layoutDataSubscribe !== undefined) {
        layoutDataSubscribe.unsubscribe();
      }
    }
  }, []);

  const onSubmit = (opt: any) => {
    //  e.preventDefault();
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('tbl_key', tblkey);
    myFormData.append('form_tab_no', opt);
    myFormData.append('is_submit', 'yes');
    myFormData.append('award_items_tab', getselectedaward);
    myFormData.append('surveyed_items_tab', getselectedsurveyed);

    Http.post('/apiv1/save-awardmodule-data', myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          setIsLoading(false);
          swal("Warning", res.message, "info");
        } else {
          swal("Input Form", "Data have been saved", "success");
          setIsLoading(false);
          setRefresh(true);
          // setGoSteps(opt);
        }
      });
  }

  // Nav Steps
  const navtab = (opt: number) => {
    // console.log(opt);
    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2) {
      setSteps(['completed', 'completed', 'active', '']);
      setAwardItemsType("SMAWARD");
      setSurveyedItemsType("SMSURVEYED");
    }
    if (opt == 3) {
      setSteps(['completed', 'completed', 'completed', 'active']);
      setAwardItemsType("SMAWARD");
      setSurveyedItemsType("SMSURVEYED");
    }
    setGoSteps(opt);
  }

  const next = (opt: any) => {
    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2) {
      setSteps(['completed', 'completed', 'active', '']);
      setSurveyedItemsType("SMAWARD");
      setSurveyedItemsType("SMSURVEYED");
    }
    if (opt == 3) {
      setSteps(['completed', 'completed', 'completed', 'active']);
      setAwardItemsType("SMAWARD");
      setSurveyedItemsType("SMSURVEYED");
    }

    setGoSteps(opt);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('tbl_key', tblkey);
    myFormData.append('form_tab_no', opt);
    myFormData.append('is_submit', 'yes');
    myFormData.append('award_items_tab', getselectedaward);
    myFormData.append('surveyed_items_tab', getselectedsurveyed);
    let frmAction = '/apiv1/save-awardmodule-data';
    // Sm Award Item
    if (opt === 3 && getselectedaward == 'SMAWARD') {
      frmAction = '/apiv1/dprfields/saveSmAwardItem';
    }
    // Lr Award Item
    if (opt === 3 && getselectedaward == 'LRAWARD') {
      frmAction = '/apiv1/dprfields/saveLrAwardItem'
    }
    // Sm Surveyed Item
    if (opt === 4 && getselectedsurveyed == 'SMSURVEYED') {
      frmAction = '/apiv1/dprfields/saveSmSurveyedItem';
    }
    // Lr Surveyed Item
    if (opt === 4 && getselectedsurveyed == 'LRSURVEYED') {
      frmAction = '/apiv1/dprfields/saveLrSurveyedItem';
    }
    Http.post(frmAction, myFormData, config)
      .then((res) => {
      });
  }

  const saveasdraft = (form_tab_no: any) => {
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('tbl_key', tblkey);
    myFormData.append('form_tab_no', form_tab_no);
    myFormData.append('award_items_tab', getselectedaward);
    myFormData.append('surveyed_items_tab', getselectedsurveyed);

    let frmAction = '/apiv1/save-awardmodule-data';
    // Sm Award Item
    if (form_tab_no === 3 && getselectedaward == 'SMAWARD') {
      frmAction = '/apiv1/dprfields/saveSmAwardItem';
    }
    // Lr Award Item
    if (form_tab_no === 3 && getselectedaward == 'LRAWARD') {
      frmAction = '/apiv1/dprfields/saveLrAwardItem'
    }
    // Sm Surveyed Item
    if (form_tab_no === 4 && getselectedsurveyed == 'SMSURVEYED') {
      frmAction = '/apiv1/dprfields/saveSmSurveyedItem';
    }
    // Lr Surveyed Item
    if (form_tab_no === 4 && getselectedsurveyed == 'LRSURVEYED') {
      frmAction = '/apiv1/dprfields/saveLrSurveyedItem';
    }
    Http.post(frmAction, myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          setIsLoading(false);
          swal("Warning", res.message, "info");
        } else {
          swal("Input Form", "Data have been saved", "success");
          setIsLoading(false);
          setRefresh(true);
        }
      });
  };

  // console.log(getselectedaward);
  // console.log(getselectedsurveyed);

  return (
    <>

      <div>
        {/* <Stepper activeStep={goSteps}>
            <Step onClick={() => setGoSteps(0)} label="Breakup of Sanctioned BoQ" />
            <Step onClick={() => setGoSteps(1)} label="Award Module" />
            <Step onClick={() => setGoSteps(2)} label="Award Items" />
            <Step onClick={() => setGoSteps(3)} label="Surveyed Items" />
          </Stepper> */}
        <div className='container'>
          <div className='customStepper'>
            <div className="StepperContainer-0-2-1">
              <div className="StepContainer-0-2-2">
                <div className="StepMain-0-2-3">
                  <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                  </button>
                  <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Breakup of Sanctioned BoQ</span></div>
                </div>
              </div>
              <div className="StepContainer-0-2-2">
                <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                <div className="StepMain-0-2-3">
                  <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                  </button>
                  <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Award Module</span></div>
                </div>
              </div>
              <div className="StepContainer-0-2-2">
                <div className="ConnectorContainer-0-2-14 ConnectorContainer-d2-0-2-22"><span className="Connector-0-2-15 Connector-d3-0-2-23"></span></div>
                <div className="StepMain-0-2-3">
                  <button onClick={() => navtab(2)} className={"StepButton-0-2-4 StepButton-d4-0-2-20 " + (steps[2] ? steps[2] : '')}>
                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d5-0-2-21 " + (steps[2] ? steps[2] : '')}>3</span>
                  </button>
                  <div className="LabelContainer-0-2-8 LabelContainer-d4-0-2-24"><span className="Label-0-2-9 Label-d5-0-2-25">Award Items</span></div>
                </div>
              </div>
              <div className="StepContainer-0-2-2">
                <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                <div className="StepMain-0-2-3">
                  <button onClick={() => navtab(3)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[3] ? steps[3] : '')}>
                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[3] ? steps[3] : '')}>4</span>
                  </button>
                  <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Surveyed Items</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<form ref={formData} noValidate>*/}
          {goSteps === 0 && (
              <form id={`boq_${idRef.current}`} ref={formData} noValidate>
                <div>
                  <div>
                    <BreakupBoQ idRef={`boq_${idRef.current}`}/>
                  </div>
                  <div className="formBtnSection justify-content-between is-sticky">
                    {
                      roleid == 2 || roleid == 3 || roleid == 4 ?
                          <>
                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)}>
                              Save as Draft
                            </Link>
                            <div className="formBtnSection">
                              <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
                            </div>
                          </>
                          : null
                    }
                  </div>
                </div>
              </form>
          )}
          {goSteps === 1 && (
            <div>
              <div>
                <div className="financialMainFrom">
                  <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
                    <li className="nav-item col-md-3" role="presentation">
                      <button className="nav-link active" onClick={() => tabkeyChangeValue('lr_award_module')} id="AwardModuleLossReductionTab" data-bs-toggle="tab" data-bs-target="#AwardModuleLossReduction" type="button" role="tab" aria-controls="AwardModuleLossReduction" aria-selected="true">LOSS REDUCTION</button>
                    </li>
                    <li className="nav-item col-md-3" role="presentation">
                      <button className="nav-link" id="AwardModuleSmartMeterTab" onClick={() => tabkeyChangeValue('sm_award_module')} data-bs-toggle="tab" data-bs-target="#AwardModuleSmartMeter" type="button" role="tab" aria-controls="AwardModuleSmartMeter" aria-selected="false">SMART METER</button>
                    </li>
                    <li className="nav-item col-md-3" role="presentation">
                      <button className="nav-link" id="AwardModulePMALossReductionTab" onClick={() => tabkeyChangeValue('pma_lr_award_module')} data-bs-toggle="tab" data-bs-target="#AwardModulePMALossReduction" type="button" role="tab" aria-controls="AwardModulePMALossReduction" aria-selected="false">PMA (LOSS REDUCTION)</button>
                    </li>
                    <li className="nav-item col-md-3" role="presentation">
                      <button className="nav-link" id="AwardModulePMASmartMeterTab" onClick={() => tabkeyChangeValue('pma_sm_award_module')} data-bs-toggle="tab" data-bs-target="#AwardModulePMASmartMeter" type="button" role="tab" aria-controls="AwardModulePMASmartMeter" aria-selected="false">PMA (SMART METERING)</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="AwardModuleLossReduction" role="tabpanel" aria-labelledby="AwardModuleLossReductionTab">
                      <div className="accordion" id="accordionExample">
                        <div>
                          <AwardModule refresh={refresh} />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="AwardModuleSmartMeter" role="tabpanel" aria-labelledby="AwardModuleSmartMeterTab">
                      <div className="accordion" id="accordionExample">
                          <div>
                            <AwardModuleSm refresh={refresh}/>
                            {/*<div className="formBtnSection justify-content-between is-sticky">
                              {
                                roleid == 2 || roleid == 3 || roleid == 4 ?
                                    <>
                                      <Link to="#" className="btnDraft" onClick={() => saveasdraft(2)}>
                                        Save as Draft
                                      </Link>
                                    </>
                                    : null
                              }
                            </div>*/}
                          </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="AwardModulePMALossReduction" role="tabpanel" aria-labelledby="AwardModulePMALossReductionTab">
                      <div className="accordion" id="accordionExample">
                        {/*<form id={`lrpma_${idRef.current}`} ref={formData} noValidate>*/}
                          <div>
                            <AwardModuleLrPma refresh={refresh}/>
                            {/*<div className="formBtnSection justify-content-between is-sticky">
                              {
                                roleid == 2 || roleid == 3 || roleid == 4 ?
                                    <>
                                      <Link to="#" className="btnDraft" onClick={() => saveasdraft(2)}>
                                        Save as Draft
                                      </Link>
                                    </>
                                    : null
                              }
                            </div>*/}
                          </div>
                        {/*</form>*/}
                      </div>
                    </div>
                    <div className="tab-pane fade" id="AwardModulePMASmartMeter" role="tabpanel" aria-labelledby="AwardModulePMASmartMeterTab">
                      <div className="accordion" id="accordionExample">
                        <AwardModuleSmPma refresh={refresh} />
                        {/*<form id={`smpma_${idRef.current}`} ref={formData} noValidate>
                          <div>
                            <AwardModuleSmPma refresh={refresh} />
                            <div className="formBtnSection justify-content-between is-sticky">
                              {tblkey == 'pma_sm_award_module' ?
                                <>
                                  <Link to="#" className="btn btnSubmit me-4" onClick={() => setGoSteps(0)}>Previous</Link>
                                </>
                                : null
                              }

                              {
                                roleid == 2 || roleid == 3 || roleid == 4 ?
                                  <>
                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(2)}>
                                      Save as Draft
                                    </Link>
                                    {tblkey == 'pma_sm_award_module' ?
                                      <>
                                        <div className="formBtnSection">
                                          <Link to="#" className="btn btnSubmit" onClick={() => next(2)}>Next</Link>
                                        </div>
                                      </>
                                      : null
                                    }
                                  </>
                                  : null
                              }
                            </div>
                          </div>
                        </form>*/}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Award Module */}
              </div>
            </div>
          )}
          {goSteps === 2 && (
              <form ref={formData} noValidate>
                <div>
                  <AwardItems isLoading={isLoading} refresh={refresh}/>
                  <div className="formBtnSection justify-content-between is-sticky">

                    {getselectedaward == 'LRAWARD' ?
                        <>
                          <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(1)}>Previous</Link>
                        </>
                        : null
                    }

                    {
                      roleid == 2 || roleid == 3 || roleid == 4 ?
                          <>
                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(3)}>
                              Save as Draft
                            </Link>

                            {getselectedaward == 'LRAWARD' ?
                                <>
                                  <Link to="#" className="btn btnSubmit" onClick={() => next(3)}>Next</Link>
                                </>
                                : null
                            }
                          </>
                          : null
                    }
                  </div>
                </div>
              </form>
          )}
          {goSteps === 3 && (
              <form ref={formData} noValidate>
                <div>
                  <SurveyedItems isLoading={isLoading} refresh={refresh}/>
                  <div className="formBtnSection justify-content-between is-sticky">
                    {getselectedsurveyed == 'LRSURVEYED' ?
                        <>
                          <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(2)}>Previous</Link>
                        </>
                        : null
                    }

                    {
                      roleid == 2 || roleid == 3 || roleid == 4 ?
                          <>
                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(4)}>
                              Save as Draft
                            </Link>

                            {getselectedsurveyed == 'LRSURVEYED' ?
                                <>
                                  <Link to="#" className="btn btnSubmit" onClick={() => saveasdraft(4)}>Save &
                                    Submit</Link>
                                </>
                                : null
                            }
                          </>
                          : null
                    }
                  </div>
                </div>
              </form>
          )}
        {/*</form>*/}
      </div>
    </>
  );
};
