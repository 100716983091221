import React, { useEffect, useState } from "react"
import DashboardFooter from '../Components/Footer/DashboardFooter'
import DashboardHeader from '../Components/Header/DashboardHeader'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProgressDetailsMainChart from '../Components/DashboardComponents/Chart/ProgressDetailsChart/ProgressDetailsMain'
import { BsBarChart, BsFileEarmarkPdf, BsTable } from "react-icons/bs"
import './dashboard.scss';
import { useLayout } from "../Core/Providers"
import { RiFileExcel2Line } from "react-icons/ri"
import { Http } from "../Core/Services/HttpService"
import jsPDF from "jspdf";
import { utils, writeFile } from "xlsx";
import _ from "lodash"

const CommonTableData = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const qParams = {
        stateid: new URLSearchParams(document.location.search).get('stateid'),
        indicatorid: new URLSearchParams(document.location.search).get('indicatorid')
    };
    const [params, setParams] = useState<any>();
    const [tableData, setTableData] = useState<any>([]);
    const [selected, setSelected] = useState("TableSection");
    const [chartLink, setChartLink] = useState<string>('');
    const [headingTitle, setHeadingTitle] = useState<string>('State wise Progress');
    const [noDataMessage, setNoDataMessage] = useState<string>('Loading...');

    const layout = useLayout();
    let srNo = 1;
    let reportType = 'DisStates';

    const fetchProgressData = async (fParams: any = {}) => {
        setTableData([]);
        setNoDataMessage('Loading...');
        let theUrl = '/apiv1/getSanctionedCost';
        if (fParams.type == 'sanctioned_cost') {
            theUrl = '/apiv1/getSanctionedCost';
        } else if (fParams.type == 'awarded_cost') {
            theUrl = '/apiv1/getAwardedCost';
        } else if (fParams.type == 'pma_cost') {
            theUrl = '/apiv1/getPMACost';
        } else if (fParams.type == 'gbs_sanctioned') {
            theUrl = '/apiv1/getGbsSanctioned';
        } else if (fParams.type == 'gbs_released') {
            theUrl = '/apiv1/getFundReleased';
        }
        if (fParams.chartfor == 'state') {
            fParams.stateid = fParams.id;
            reportType = 'Discom';
        }
        if (fParams.chartfor == 'discom') {
            fParams.stateid = fParams.state;
            fParams.discomid = fParams.id;
            reportType = 'Districts';
        }
        const fnlParams = {
            ...fParams,
            reportType: reportType
        };
        await Http.get(theUrl, { params: fnlParams })
            .then((res) => {
                if (res._resultflag == "1") {
                    setTableData(res.sanctionCost);
                } else {
                    setNoDataMessage('No Data Found.');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found.');
            });
    };

    const exportToPDF = () => {
        let table = document.getElementById("top-panel-data")?.cloneNode(true) as any;
        table = document.createDocumentFragment().appendChild(table);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const frmData = new FormData();
        frmData.append('content', encodeURIComponent(table.outerHTML));
        frmData.append('filename', 'toppaneldata');
        frmData.append('download', 'no');
        Http.post('/apiv1/contentExportPdf', frmData, config)
            .then((res) => {
                if (res._resultflag == "1") {
                    window.open(res.file, "_blank");
                } else {
                    setNoDataMessage('No Data Found.');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found.');
            });
    };

    const exportToExcel = () => {
        const table = document.getElementById('top-panel-data');
        const wb = utils.table_to_book(table);
        writeFile(wb, 'toppaneldata.xlsx');
    };

    useEffect(() => {
        if (params) {
            setChartLink('');
            //setSelected("ChartSection");
            fetchProgressData(params);
            switch (params.chartfor) {
                case 'discom':
                    setHeadingTitle('District wise Progress');
                    break;
                case 'state':
                    setChartLink(`/common-table-data?chartfor=discom&type=${params.type}&state=${params.id}`);
                    setHeadingTitle('Discom wise Progress');
                    break;
                case 'all':
                    setChartLink(`/common-table-data?chartfor=state&type=${params.type}`);
                    setHeadingTitle('State wise Progress');
                    break;
            }
        }
    }, [params]);
    useEffect(() => {
        const sectionfor = new URLSearchParams(location.search).get('sectionfor');
        if (sectionfor == 'TableSection') { setSelected("TableSection"); }
        setParams({
            id: new URLSearchParams(location.search).get('id') || null,
            state: new URLSearchParams(location.search).get('state') || null,
            discom: new URLSearchParams(location.search).get('discom') || null,
            chartfor: new URLSearchParams(location.search).get('chartfor') || 'all',
            type: new URLSearchParams(location.search).get('type')
        });
    }, [location]);
    return (
        <>
            <div className="mainWrap dashboard">
                <DashboardHeader />
                <div className="container-fluid">
                    <div className="dashboardInner detailedDashboard">
                        <div className="row gx-xxl-5">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="heading mb-2">
                                    <h2 className="mb-0"><Link to={'/dashboard'}><em className="arrow"><BiArrowBack /></em></Link>Dashboard - RDSS</h2>
                                </div>
                                <div className="discomSection">
                                    <div className="discomRight">
                                        <div className="dashChart">
                                            <div className="DiscomSummary">
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <h4 className="mb-2 me-3">
                                                        {params?.type == 'sanctioned_cost' ? headingTitle + ' - Total Sanctioned Cost' : ''}
                                                        {params?.type == 'awarded_cost' ? headingTitle + ' - Total Awarded Cost' : ''}
                                                        {params?.type == 'pma_cost' ? headingTitle + ' - Total PMA Cost' : ''}
                                                        {params?.type == 'gbs_sanctioned' ? headingTitle + ' - Total GBS Sanctioned' : ''}
                                                        {params?.type == 'gbs_released' ? headingTitle + ' - Total GBS Released' : ''}
                                                    </h4>
                                                </div>
                                                <div aria-hidden={selected !== "TableSection" ? true : false}>
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <a title="Export to PDF" onClick={() => exportToPDF()}><div className="pdfBox"><BsFileEarmarkPdf /></div></a>
                                                        <a title="Export to XLS" onClick={() => exportToExcel()}><div className="excelBox bg-success"><RiFileExcel2Line /></div></a>
                                                    </div>
                                                    <div className="table-responsive progressDetailsTable">
                                                        {['awarded_cost', 'pma_cost'].includes(params?.type) ?
                                                            <table className="table-responsive RevenueDetailsTable PqTable mx-auto" id="top-panel-data">
                                                                <thead className="text-center">
                                                                    <tr className="d-none show-pdf">
                                                                        <th colSpan={6} className="text-start">
                                                                            <h6 className="mb-2 text-start"> 
                                                                                {params?.type == 'awarded_cost' ? headingTitle + ' - Total Awarded Cost' : ''}
                                                                                {params?.type == 'pma_cost' ? headingTitle + ' - Total PMA Cost' : ''}
                                                                            </h6>
                                                                            <p className="text-end">{new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</p>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>S.No.</th>
                                                                        <th className="text-start">
                                                                            {params?.chartfor == 'all' ? 'State' : ''}
                                                                            {params?.chartfor == 'state' ? 'Discom' : ''}
                                                                            {params?.chartfor == 'discom' ? 'District' : ''}
                                                                        </th>
                                                                        { params?.type == 'awarded_cost' ? <th>Awarded Cost – Smart Metering (INR Cr)</th> : <th>PMA Cost – Smart Metering (INR Cr)</th> }
                                                                        { params?.type == 'awarded_cost' ? <th>Awarded Cost – Loss Reduction (INR Cr)</th> : <th>PMA Cost – Loss Reduction (INR Cr)</th> }
                                                                        { params?.type == 'awarded_cost' ? <th>Total Awarded Cost (INR Cr)</th> : <th>Total PMA Cost (INR Cr)</th> }
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="text-center">
                                                                    {
                                                                        (tableData.length <= 0)
                                                                            ? <tr><td colSpan={6} className="text-center">{noDataMessage}</td></tr>
                                                                            : null
                                                                    }
                                                                    {
                                                                        tableData.map((item: any, index: any) => (
                                                                            <tr key={index}>
                                                                                <td>{srNo++}</td>
                                                                                {
                                                                                    params?.type == 'awarded_cost' && params?.chartfor == 'discom' || params?.type == 'pma_cost' ? <td className="text-start">{item.title}</td> : <td className="text-start"><Link to={chartLink + '&id=' + item.id + '&sectionfor=TableSection'}>{item.title}</Link></td>
                                                                                }
                                                                                <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sl)}</td>
                                                                                {
                                                                                    params?.type == 'awarded_cost' ? 
                                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.ls + item.itot)}</td> 
                                                                                    : 
                                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.ls)}</td>
                                                                                }
                                                                                {
                                                                                    params?.type == 'awarded_cost' ?
                                                                                    <td>{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sl + item.ls + item.itot)}</td>
                                                                                    :
                                                                                    <td>{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sl + item.ls)}</td>
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <table className="table-responsive RevenueDetailsTable PqTable mx-auto" id="top-panel-data">
                                                                <thead className="text-center">
                                                                    <tr className="d-none show-pdf">
                                                                        <th colSpan={6} className="text-start">
                                                                            <h6 className="mb-2 text-start">
                                                                                {params?.type == 'sanctioned_cost' ? headingTitle + ' - Total Sanctioned Cost' : ''}
                                                                                {params?.type == 'gbs_sanctioned' ? headingTitle + ' - Total GBS Sanctioned' : ''}
                                                                                {params?.type == 'gbs_released' ? headingTitle + ' - Total GBS Released' : ''}
                                                                            </h6>
                                                                            <p className="text-end">{new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</p>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>S.No.</th>
                                                                        <th className="text-start">
                                                                            {params?.chartfor == 'all' ? 'State' : ''}
                                                                            {params?.chartfor == 'state' ? 'Discom' : ''}
                                                                            {params?.chartfor == 'discom' ? 'District' : ''}
                                                                        </th>
                                                                        <th>
                                                                            {params?.type == 'sanctioned_cost' ? 'Sanctioned Cost – Smart Metering (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_sanctioned' ? 'GBS Sanctioned – Smart Metering (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_released' ? 'GBS Released– Smart Metering incl. PMA (INR Cr)' : ''}
                                                                        </th>

                                                                        {params?.chartfor != 'discom' && params?.type != 'gbs_released' ? <th>
                                                                            {params?.type == 'sanctioned_cost' ? 'Sanctioned Cost – PMA for Smart Metering (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_sanctioned' ? 'GBS Sanctioned – PMA for Smart Metering (INR Cr)' : ''}
                                                                        </th> : ''}
                                                                        <th>
                                                                            {params?.type == 'sanctioned_cost' ? 'Sanctioned Cost – Loss Reduction (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_sanctioned' ? 'GBS Sanctioned – Loss Reduction (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_released' ? 'GBS Released– Loss Reduction incl. PMA (INR Cr)' : ''}
                                                                        </th>
                                                                        {params?.chartfor != 'discom' && params?.type != 'gbs_released' ? <th>
                                                                            {params?.type == 'sanctioned_cost' ? 'Sanctioned Cost – PMA for Loss Reduction (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_sanctioned' ? 'GBS Sanctioned – PMA for Loss Reduction (INR Cr)' : ''}
                                                                        </th> : ''}
                                                                        <th>
                                                                            {params?.type == 'sanctioned_cost' ? 'Total Sanctioned Cost (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_sanctioned' ? 'Total GBS Sanctioned (INR Cr)' : ''}
                                                                            {params?.type == 'gbs_released' ? 'Total GBS Released (INR Cr)' : ''}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="text-center">
                                                                    {
                                                                        (tableData.length <= 0)
                                                                            ? <tr><td colSpan={6} className="text-center">{noDataMessage}</td></tr>
                                                                            : null
                                                                    }
                                                                    {
                                                                        tableData.map((item: any, index: any) => (
                                                                            <tr key={index}>
                                                                                <td>{srNo++}</td>
                                                                                {params?.chartfor == 'discom' || (params?.chartfor == 'state' && params?.type == 'gbs_released') ? <td className="text-start">{item.title}</td> : <td className="text-start"><Link to={chartLink + '&id=' + item.id + '&sectionfor=TableSection'}>{item.title}</Link></td>}
                                                                                <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_cost)}</td>
                                                                                {(params?.chartfor != 'discom' && params?.type != 'gbs_released') ?
                                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_pma)}</td>
                                                                                    : ''}
                                                                                <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.ls_cost)}</td>
                                                                                {(params?.chartfor != 'discom' && params?.type != 'gbs_released') ?
                                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.ls_pma)}</td>
                                                                                    : ''}
                                                                                {params?.chartfor != 'discom' ?
                                                                                    <td>{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_cost + item.sm_pma + item.ls_cost + item.ls_pma)}</td>
                                                                                    : <td>{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_cost + item.ls_cost)}</td>}
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        }

                                                    </div>
                                                </div>
                                                <h4 className="mb-0 text-end stickyBack"><a onClick={() => navigate(-1)} className="text-decoration-none text-dark"><em className="arrow"><BiArrowBack onClick={() => navigate(-1)} /></em> Go Back</a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DashboardFooter remove='d-none' />
            </div>
        </>
    );
};

export default CommonTableData;