import React, { useEffect, useState } from "react";
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import { Http } from "../Core/Services/HttpService";
import { init, validatefrm } from "../assets/js/formbuilder";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../Core/Providers/AuthProvider';
import { User } from "../Services/UserService";
import { Jsencrypt } from "../Core/Services/JsencryptService";
import { Storage } from "../Core/Services/StorageService";

const VerificationOtp = () => {

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = (location.state as { returnUrl: any })?.returnUrl || "/rdss";
  const [err, setErr] = useState('');
  const [email, setEmail] = useState('');
  // const [referer, setReferer] = useState<any>('/rdss');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const roleids = [
    process.env.REACT_APP_NDR,
    process.env.REACT_APP_NDA
  ];

  const csrdRoles = [
    process.env.REACT_APP_CSRD_DDE,
    process.env.REACT_APP_CSRD_DDR,
    process.env.REACT_APP_CSRD_DDA,
    process.env.REACT_APP_CSRD_RO
  ];

  const discomirRoles = [
    process.env.REACT_APP_DISCOMIR_CONR,
    process.env.REACT_APP_DISCOMIR_DDE,
    process.env.REACT_APP_DISCOMIR_DDR,
    process.env.REACT_APP_DISCOMIR_DDA,
    process.env.REACT_APP_DISCOMIR_NDA
  ];

  const energyRoles = [
    process.env.REACT_APP_DDEM_ROLE_ID,
    process.env.REACT_APP_DDRA_ROLE_ID,
    process.env.REACT_APP_DDV_ROLE_ID,
    process.env.REACT_APP_NAR_BEE_ROLE_ID,
    process.env.REACT_APP_NAA_BEE_ROLE_ID
  ];

  const agsdpRoles = [
    process.env.REACT_APP_AGSDP_SDE,
    process.env.REACT_APP_AGSDP_DDE,
    process.env.REACT_APP_AGSDP_DDR,
    process.env.REACT_APP_AGSDP_NDR,
    process.env.REACT_APP_AGSDP_NDA
  ];

  if (!location.state.email) {
    navigate('/signin');
  }

  useEffect(() => {
    //setReferer(Storage.get('referer'));
    setErr(err);
    setEmail(location.state.email);
  });

  function onSubmit(e: any) {
    e.preventDefault();
    let submitform = validatefrm();
    if (submitform) {
      setIsSubmitted(true);
      let myFormData = new FormData(e.target);
      // Email Encryption
      const encEmail: any = Jsencrypt.encrypt(email);
      myFormData.append('email', encEmail);
      // OTP Encryption
      const encOtp: any = Jsencrypt.encrypt(myFormData.get('otp'));
      myFormData.set('otp', encOtp);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      Http.post('/apiv1/verification-otp', myFormData, config)
        .then((res: any) => {
          if (res._resultflag == '1') {
            res.data = Jsencrypt.decrypt(res.data);
            const userObj = new User(res.data);
            auth.setUser(userObj)
              .then((user: any) => {
                Storage.set('roleid', user.role_id);
                Storage.set('discom_user_id', user.id);
                Storage.set('username', user.first_name);
                Storage.set('discom_id', user.discom_id);
                Storage.set('state_id', user.state_id);
                Storage.set('state_name', user.state_name);
                Storage.set('discom_name', user.discom_name);
                Storage.set('short_form', user.short_form);
                Storage.set('type_of_entity', user.type_of_entity);

                if (user.role_id == 5 || user.role_id == 6 || user.role_id == 29 || user.role_id == 30) {
                  Storage.set('nodal_agency', user.user_nodal_agency);
                } else {
                  Storage.set('nodal_agency', user.nodal_agency);
                }

                if (user.role_id == 25) {
                  Storage.set('user_state_id', user.user_state_id);
                }
                Storage.set('login_token', user.login_token);
                Storage.set('login_token_at', user.login_token_at);
                if (user.is_password_reset == 1) {
                  navigate('/change-password', { replace: true });
                  return;
                }
                if (roleids.includes(user.role_id.toString()))
                  navigate('/nodal-view', { replace: true });
                else if (energyRoles.includes(user.role_id.toString()))
                  navigate('/energy', { replace: true });
                else if (csrdRoles.includes(user.role_id.toString()))
                  navigate('/csrd', { replace: true });
                else if (discomirRoles.includes(user.role_id.toString()))
                  navigate('/discomir', { replace: true });
                else if (agsdpRoles.includes(user.role_id.toString()))
                  navigate('/agsdp', { replace: true });
                else
                  navigate(returnUrl, { replace: true });
              }).catch(() => { });
          } else {
            setErr(res.message ?? 'Something went wrong, Please try again.');
          }
        })
        .catch((err: any) => {
          setErr('Something went wrong, Please try again.');
        })
        .finally(() => {
          setIsSubmitted(false);
        });
    }
  }
  setTimeout(() => {
    init();
  }, 1000);
  return (
    <>
      <Header />
      {isSubmitted && (<div className="spinner-container">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
      )}
      <div className="mainWrap">
        <div className="loginWrap">
          <div className="login-main verificationOtp">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-8 col-lg-6 col-xl-5 col-xxl-4">
                  <div className="position-relative loginPosition">
                    <div className="loginbox">
                      <h4 className="text-white">Verify OTP</h4>
                      <form noValidate onSubmit={onSubmit}>
                        {err != '' ?
                          <div className="alert alert-danger">{err}</div>
                          : null
                        }
                        <div className="form-group">
                          <div className="form-field">
                            <label className="control-label" htmlFor="email">Enter OTP</label>
                            <input data-required="Y" maxLength={6} autoComplete="off" className="form-control" type="text" name="otp" id="otp" />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="formBtnSection">
                            <button {...(isSubmitted) ? { disabled: true } : {}} className="btnSubmit" type="submit">Verify OTP</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer remove="d-none" />
    </>
  );
}

export default VerificationOtp;