import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../RdssForms/rdssforms.scss";
import { useStateMounted } from '../../../Core/Hooks';
import FieldComponent from "../../../FieldComponent";
import { Alert } from 'react-bootstrap';
import { Storage } from "../../../Core/Services/StorageService";
import FdDiscomName from "../FdDiscomName";

const Block1 = (props: any) => {

  let block1SrNo = 0;
  const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
  const [block1Fields, setBlock1fields] = useState<any>([]);
  const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

  useEffect(() => {
    if (props.field) {
      setNewBlock1fields(props.field.addnew);
      const fields1 = props.field.childrens.map((items: any) => {
        return {
          fields: items,
          delete: false
        }
      })
      setBlock1fields(fields1);
    }
  }, [props.field]);

  useEffect(() => {
    if (block1Fields.length) {
      const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
      setHasBlock1FieldsDeletes(hasDelete);
      block1SrNo = 0;
    }
  }, [block1Fields]);

  const addItem = (block?: any) => {
    setBlock1fields((prev: any[]) => {
      return [...prev, { fields: newBlock1Fields, delete: false }];
    });

    setTimeout(() => {
      multijsoninit();
    }, 500);
  }

  const removeItem = (rindex: number, block?: any) => {
    setBlock1fields((prev: any[]) => {
      const newFields = prev.map((item, index) => {
        if (index === rindex) {
          item.delete = true;
        }
        return item;
      })
      return newFields;
    });

    setTimeout(() => {
      multijsoninit();
    }, 500);
  }

  return (
    <>
      <table className="table caption-top">
        <thead className='text-center'>
          <tr>
            <th style={{ width: "50px" }}>S.No</th>
            <th>Reference Sanction Letter no.</th>
            <th>Reference Sanction Letter Date</th>
            <th>Amount disbursed in current F.Y. (INR Lakh)</th>
            <th>Total unspent balance of P.Y. (INR Lakh) </th>
            <th>Total Expenditure Incurred (INR Lakh)</th>
            <th>Purpose of Expenditure</th>
            <th>Balance remaining unutilized (INR Lakh)</th>
            <th>Challan no. of payment made to gov</th>
            <th>Challan date of payment made to gov</th>
            {props.view != 'Y' ?
              <th className='text-end pe-1' style={{ width: "50px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
              </th>
              : null
            }
          </tr>
        </thead>
        <tbody>
          {
            block1Fields.map((item: any, key: number) => {
              return (item.delete === false) ? <>
                <tr key={key}>
                  <td className="text-center">{(block1SrNo++) + 1}</td>
                  {
                    item.fields.map((field: any, fkey: number) => {
                      return <>
                        <td key={key + '_' + fkey}>
                          <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                      </>;
                    })
                  }
                  {props.view != 'Y' ?
                    <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                    : null
                  }
                </tr>
              </>
                : null;
            })
          }
        </tbody>
      </table>
    </>
  );
}

const GFR12CFForm = (props: any) => {
  const [roleid, setRoleid] = useState<any>(0);
  const [username, setUsername] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [getcomponentid, setComponentId] = useState<any>(0);
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [getaplicationtrackerid, setAplicationTrackerid] = useState<any>(0);
  const [error, setError] = useStateMounted<string>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [fields, setFields] = useState<any[]>([]);
  const [view, setView] = useState<any[]>([]);

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    setUsername(Storage.get('username'));
    setDiscomid(Storage.get('discom_id'));
    setDiscomUserid(Storage.get('discom_user_id'));
    setAplicationTrackerid(props.getaplicationtrackerid);
  }, [roleid]);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    (async () => {
      if (location.state) {
        setAplicationTrackerid(location.state.aplication_tracker_id);
        setComponentId(location.state.component_id);
      }
      else {
        navigate('/fund-disbursal');
      }
    })();
  }, []);

  setTimeout(() => {
    init();
    multijsoninit();
  }, 500);

  const getFields = () => {
    (async () => {
      setIsLoaded(true);
      setError('');
      setFields([]);
      if (roleid && discomid && getaplicationtrackerid && getcomponentid) {
        const params = {
          roleid: roleid,
          discom_id: discomid,
          form_id: 23,
          discom_user_id: discomuserid,
          application_id: getaplicationtrackerid,
          component_id : getcomponentid
        };
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner)
          footerspinner.classList.remove('d-none');
        await Http.get(`/apiv1/fdfields`, { params })
          .then((res) => {
            if (res.fields) {
              if (res.fields && Array.isArray(res.fields)) {
                if (!res.fields.length) setError("No data found.");
                setIsLoaded(true);
                setFields(res.fields);
                setView(res.view);
                setTimeout(() => {
                  init();
                  multijsoninit();
                }, 1000);
              } else {
                setError("Invalid data type.");
              }
            } else if (!res.resultflag) {
              setError(res.message);
            } else {
              setError('No data found.');
            }
          })
          .catch((error) => {
            setError('System error occurred!! please try again.');
          }).finally(() => {
            setIsLoaded(false);
            footerspinner.classList.add('d-none');
          });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      getFields();
    })();
  }, [getaplicationtrackerid]);

  return (
    <>
      {error && (
        <>
          <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
        </>
      )}

      {((!error) && fields?.length > 0) && (
        <>
          <div className='RevenueDetailsTable mt-4 overflow-visible'>
            <div className='caption py-2'>
              <div className='row align-items-center justify-content-between'>
                <div className='col-sm-4'>
                  <p className='mb-0'>FORM GFR 12-C  (UTILIZATION CERTIFICATE)</p>
                </div>
                <div className='col-sm-8'>
                  <div className="d-flex justify-content-end align-items-center gap-2 pe-3 formLabel">
                    <label className='mb-0'>State Power Department Name</label>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive RevenueDetailsTable mt-4">
            <Block1 field={fields[0]} view={view} />
          </div>
          <div className="table-responsive RevenueDetailsTable mt-4">
            <table className="table caption-top">
              <caption>Details of Fund Utilization</caption>
              <thead className='text-center'>
                <tr>
                  <th>Particulars</th>
                  <th>GOI</th>
                  <th>CP</th>
                  <th>Own Resources</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cumulative Fund received</td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} /></td>
                </tr>
                <tr>
                  <td>Cumulative Fund Utilized</td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
      }
    </>
  );
}
export default GFR12CFForm;
