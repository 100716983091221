import React, { useEffect, Component, useState } from 'react'
import { Http } from '../../Core/Services/HttpService';
import { Tabs, Tab, Accordion } from 'react-bootstrap'
import { BsFileEarmarkPdf, BsFillFileEarmarkWordFill, BsFilePpt } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { RiFileExcel2Line } from 'react-icons/ri'

const InformationDocuments = (props: any) => {

    const defaultActiveKey = "2";
    const [categoryId, setCategoryId] = useState<any>(defaultActiveKey);
    const [categoryList, setCategoryList] = useState<any>([]);

    const fetchCategorydocsList = async (catId: any, params: any = {}) => {
        setCategoryList([]);
        await Http.get('/apiv1/informationdocs/' + catId, { ...params })
            .then((res) => {
                setCategoryList(res.rows);
            }).catch((e) => {
                console.log(e);
            }).finally(() => {

            });
    }

    useEffect(() => {
        if (categoryId) fetchCategorydocsList(categoryId);
    }, [categoryId]);

    const onChangeTab = (eventKey: any) => {
        setCategoryId(eventKey);
    };

    return (
        <>
            <div className='rdssGuideline'>
                <Tabs onSelect={onChangeTab} defaultActiveKey={defaultActiveKey} id="uncontrolled-tab-example">
                    <Tab eventKey="2" title="Guidelines">
                        {
                            (categoryList && categoryList.length > 0) ? (
                                categoryList.map((item: any, i: number) => {
                                    return <div key={`${i}`}>
                                        <h4>{item.title}</h4>
                                        {
                                            (item.documents && item.documents.length > 0) ? (
                                                <ul>
                                                    {
                                                        item.documents.map((doc: any, ii: number) => {
                                                            return (doc.document_type == 'link')
                                                                ? <li key={`${i}-${ii}`}>
                                                                    <Link to={doc.external_link} target="_blank">{doc.document_title}
                                                                        {doc.document_extension == 'pdf' && <BsFileEarmarkPdf className="text-danger ms-2" />}
                                                                        {doc.document_extension == 'docx' && (<BsFillFileEarmarkWordFill
                                                                            className="text-primary ms-2" />)}
                                                                        {doc.document_extension == 'xlsx' && (<RiFileExcel2Line
                                                                            className="text-success ms-2" />)}
                                                                        {doc.document_extension == 'pptx' && (<BsFilePpt className="text-danger ms-2" />)}
                                                                        {doc.document_size != 0 ?
                                                                            <span className='ms-1'>{'(' + doc.document_size + ')'}</span>
                                                                            : null
                                                                        }
                                                                    </Link>
                                                                </li>
                                                                : <li key={`${i}-${ii}`}>
                                                                    <Link to={doc.document_file} target="_blank">{doc.document_title}
                                                                        {doc.document_extension == 'pdf' && (<BsFileEarmarkPdf className="text-danger ms-2" />)}
                                                                        {doc.document_extension == 'docx' && (<BsFillFileEarmarkWordFill
                                                                            className="text-primary ms-2" />)}
                                                                        {doc.document_extension == 'xlsx' && (<RiFileExcel2Line
                                                                            className="text-success ms-2" />)}
                                                                        {doc.document_extension == 'pptx' && (<BsFilePpt className="text-danger ms-2" />)}
                                                                        {doc.document_size != 0 ?
                                                                            <span className='ms-1'>{'(' + doc.document_size + ')'}</span>
                                                                            : null
                                                                        }
                                                                    </Link>
                                                                </li>
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div className="text-center">No documents found.</div>
                                            )
                                        }
                                    </div>
                                })
                            ) : null
                        }
                    </Tab>
                    <Tab eventKey="3" title="SBD">
                        {
                            (categoryList && categoryList.length > 0) ? (
                                categoryList.map((item: any, i: number) => {
                                    return <div key={`${i}`}>
                                        <h4>{item.title}</h4>
                                        {
                                            (item.documents && item.documents.length > 0) ? (
                                                <ul>
                                                    {
                                                        item.documents.map((doc: any, ii: number) => {
                                                            return (doc.document_type == 'link')
                                                                ? <li key={`${i}-${ii}`}>
                                                                    <Link to={doc.external_link} target="_blank">{doc.document_title}
                                                                        {doc.document_extension == 'pdf' && <BsFileEarmarkPdf className="text-danger ms-2" />}
                                                                        {doc.document_extension == 'docx' && (<BsFillFileEarmarkWordFill
                                                                            className="text-primary ms-2" />)}
                                                                        {doc.document_extension == 'xlsx' && (<RiFileExcel2Line
                                                                            className="text-success ms-2" />)}
                                                                        {doc.document_extension == 'pptx' && (<BsFilePpt className="text-danger ms-2" />)}
                                                                        {doc.document_size != 0 ?
                                                                            <span className='ms-1'>{'(' + doc.document_size + ')'}</span>
                                                                            : null
                                                                        }
                                                                    </Link>
                                                                </li>
                                                                : <li key={`${i}-${ii}`}>
                                                                    <Link to={doc.document_file} target="_blank">{doc.document_title}
                                                                        {doc.document_extension == 'pdf' && (<BsFileEarmarkPdf className="text-danger ms-2" />)}
                                                                        {doc.document_extension == 'docx' && (<BsFillFileEarmarkWordFill
                                                                            className="text-primary ms-2" />)}
                                                                        {doc.document_extension == 'xlsx' && (<RiFileExcel2Line
                                                                            className="text-success ms-2" />)}
                                                                        {doc.document_extension == 'pptx' && (<BsFilePpt className="text-danger ms-2" />)}
                                                                        {doc.document_size != 0 ?
                                                                            <span className='ms-1'>{'(' + doc.document_size + ')'}</span>
                                                                            : null
                                                                        }
                                                                    </Link>
                                                                </li>
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div className="text-center">No documents found.</div>
                                            )
                                        }
                                    </div>
                                })
                            ) : null
                        }
                    </Tab>
                    <Tab eventKey="4" title="Sanction Letter">
                        <h4>Sanction Letter</h4>
                        <Accordion defaultActiveKey="0">
                            {
                                (categoryList && categoryList.length > 0) ? (
                                    categoryList.map((item: any, i: number) => {
                                        return <>
                                            <Accordion.Item eventKey={`${i}`}>
                                                <Accordion.Header>{item.title}</Accordion.Header>
                                                <Accordion.Body>
                                                    {
                                                        (item.documents && item.documents.length > 0) ? (
                                                            <ul className="ps-3">
                                                                {
                                                                    item.documents.map((doc: any, ii: number) => {
                                                                        return (doc.document_type == 'link')
                                                                            ? <li key={`${i}-${ii}`}>
                                                                                <Link to={doc.external_link} target="_blank">{doc.document_title}
                                                                                    {doc.document_extension == 'pdf' && <BsFileEarmarkPdf className="text-danger ms-2" />}
                                                                                    {doc.document_extension == 'docx' && (<BsFillFileEarmarkWordFill
                                                                                        className="text-primary ms-2" />)}
                                                                                    {doc.document_extension == 'xlsx' && (<RiFileExcel2Line
                                                                                        className="text-success ms-2" />)}
                                                                                    {doc.document_extension == 'pptx' && (<BsFilePpt className="text-danger ms-2" />)}
                                                                                    {doc.document_size != 0 ?
                                                                                        <span className='ms-1'>{'(' + doc.document_size + ')'}</span>
                                                                                        : null
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                            : <li key={`${i}-${ii}`}>
                                                                                <Link to={doc.document_file} target="_blank">{doc.document_title}
                                                                                    {doc.document_extension == 'pdf' && (<BsFileEarmarkPdf className="text-danger ms-2" />)}
                                                                                    {doc.document_extension == 'docx' && (<BsFillFileEarmarkWordFill
                                                                                        className="text-primary ms-2" />)}
                                                                                    {doc.document_extension == 'xlsx' && (<RiFileExcel2Line
                                                                                        className="text-success ms-2" />)}
                                                                                    {doc.document_extension == 'pptx' && (<BsFilePpt className="text-danger ms-2" />)}
                                                                                    {doc.document_size != 0 ?
                                                                                        <span className='ms-1'>{'(' + doc.document_size + ')'}</span>
                                                                                        : null
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                    })
                                                                }
                                                            </ul>
                                                        ) : (
                                                            <div className="text-center">No documents found.</div>
                                                        )
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                    })
                                ) : null
                            }
                        </Accordion>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
};

export default InformationDocuments;
