import React, { useState, useEffect, useRef } from "react";
import { init, initselect, multijsoninit, inputEventChecker } from "../../assets/js/formbuilder";
import { Link } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';
import FdDiscomHistory from "./FdDiscomHistory";
import FdDiscomLogHistory from "./FdDiscomLogHistory";
import { useStateMounted } from '../../Core/Hooks';
import { Alert } from 'react-bootstrap';
import { Http } from "../../Core/Services/HttpService";
import FieldComponent from "../../FieldComponent";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import PropTypes from 'prop-types';
import { BsFileEarmarkExcel, BsFileEarmarkPdf } from 'react-icons/bs';
import QueryString from 'qs';
import { Storage } from "../../Core/Services/StorageService";
import FdDiscomName from "./FdDiscomName";

export const LoadingSpinner = () => {
	return (
		<div className="spinner-container">
			<div className="spinner-outer">
				<div className="loading-spinner"></div>
			</div>
		</div>
	);
}

type MultiChildProps = {
	field: any;
	pIndex: number,
	jIndex: number,
	index?: string | number,
	totalFields: any,
	view: any,
	frmId: any
};

const multiChildPropTypes = {
	field: PropTypes.array.isRequired,
	index: PropTypes.any,
	pIndex: PropTypes.number.isRequired,
	jIndex: PropTypes.number.isRequired,
	totalFields: PropTypes.any.isRequired
};

function MultiChild({ field, index, pIndex, totalFields, jIndex, view, frmId }: MultiChildProps) {

	let srNo = 1;
	const idRef = useRef(Math.random().toString(36).substring(2, 15));
	const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
	const [fields, setFields] = useStateMounted<any>([]);
	const [newFields, setNewFields] = useStateMounted<any>();

	useEffect(() => {
		if (field) {
			setHasFieldDelete(false);
			setFields([]);
			setNewFields(field.addnew);
			const fieldsMap = field.childrens.map((items: any) => {
				return {
					fields: items,
					delete: false
				}
			})
			setFields(fieldsMap);
		}
	}, [field]);

	useEffect(() => {
		if (fields.length) {
			const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
			setHasFieldDelete(hasDelete);
			srNo = 1;
			setTimeout(() => {
				// initselect(`#${frmId} .mcField-${field.key} .form-control`);
				// multijsoninit(`#${frmId} .mcField-${field.key} .form-control`);
			}, 1500);
		}
	}, [fields]);

	const addNewField = () => {
		setFields((prev: any[]) => {
			return [...prev, { fields: newFields, delete: false }];
		});
		setTimeout(() => {
			initselect(`#${frmId} .form-control`);
			multijsoninit(`#${frmId} .form-control`);
		}, 1500);
	}

	const removeField = (index: number) => {
		setFields((prev: any[]) => {
			const newFields = prev.map((item, i) => {
				if (i === index) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
		setTimeout(() => {
			initselect(`#${frmId} .form-control`);
			multijsoninit(`#${frmId} .form-control`);
		}, 1500);
	}
	const extTD = <td colSpan={6} ></td>;

	return (
		<table className={`table-responsive RevenueDetailsTable PqTable mcField-${field.key}`}>
			<tbody>
				{
					fields.map((item: any, i: number) => {
						return (item.delete === false)
							? (
								<>
									<tr key={`${idRef.current}-${index}`}>
										{
											item.fields.map((field: any, j: number) => {
												return ([100].includes(j))
													? null
													: <td style={{ minWidth: 100, width: 100 }} key={`${idRef.current}-${index}-${field.key}`}>
														<FieldComponent
															options={
																{ showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
															}
															fieldName={field.key}
															field={field} module="disbur_" />
													</td>
											})
										}

										{view != 'Y' ?
											<>
												{(i != 0 && hasFieldDelete)
													? <>
														<td className="td13 text-end">
															<Link className='text-danger' to="#" onClick={() => removeField(i)}>
																<FiTrash2 className="p-0 mb-0 h5" />
															</Link>
														</td>
													</>
													: null
												}

												{i == 0 ?
													<td className="td13 text-end">
														<Link to="#" className='p-0 text-success' onClick={addNewField}>
															<FiPlusCircle className="h5 p-0 mb-0" />
														</Link>
													</td>
													: null
												}
											</>
											: null
										}
									</tr>
								</>
							)
							: null;
					})
				}
				{
					totalFields && (
						<ChildInput frmId={frmId} fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
					)
				}
			</tbody>
		</table>
	);
}

MultiChild.propTypes = multiChildPropTypes;

// ChildInput
type ChildInputProps = {
	fields: any[];
	index?: string | number,
	showTotal?: any,
	frmId: any
};

const childInputPropTypes = {
	fields: PropTypes.array.isRequired,
	index: PropTypes.any,
	showTotal: PropTypes.any,
};

function ChildInput({ fields, index, showTotal, frmId }: ChildInputProps) {

	const [_fields, _setFields] = useStateMounted<string[]>([]);

	useEffect(() => {
		if (fields.length) {
			_setFields(fields);
		}
	}, [fields]);

	useEffect(() => {
		if (_fields.length) {
			// setTimeout(() => {
			// 	initselect('#' + frmId + ' .form-control');
			// 	multijsoninit('#' + frmId + ' .form-control');
			// }, 1500);
		}
	}, [_fields]);

	return (
		<>
			<tr aria-hidden={(showTotal) ? "false" : "true"}>
				<td colSpan={1}>&nbsp;</td>
				{
					(_fields.length)
						? _fields.map((field: any, i: number) => (
							<>
								<td key={index + '-' + i + '-' + field.key}>
									<FieldComponent
										options={{ showTitle: false, extraId: '_' + index + '_' + i }}
										fieldName={field.key}
										field={field} module="disbur_" />
								</td>
								{
									([0, 1].includes(i)) && (
										<>
											<td colSpan={1}>&nbsp;</td>
										</>
									)
								}
							</>
						))
						: null
				}
			</tr>
		</>
	);
}

ChildInput.propTypes = childInputPropTypes;

// Multijson
type MultijsonProps = {
	field: any;
	index: number,
	totalField: any,
	moduleName: any,
	view: any,
	frmId: any
};

function Multijson({ field, index, totalField, moduleName, view, frmId }: MultijsonProps) {

	let srNo = 1;
	const idRef = useRef(Math.random().toString(36).substring(2, 15));
	const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
	const [fields, setFields] = useStateMounted<any>([]);
	const [newFields, setNewFields] = useStateMounted<any>();

	useEffect(() => {
		if (field) {
			setHasFieldDelete(false);
			setFields([]);
			setNewFields(field.addnew);
			const fieldsMap = field.childrens.map((items: any) => {
				return {
					fields: items,
					delete: false
				}
			})
			setFields(fieldsMap);
		}
	}, [field]);

	useEffect(() => {
		if (fields.length) {
			const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
			setHasFieldDelete(hasDelete);
			srNo = 1;
			setTimeout(() => {
				// initselect(`#${frmId} .mjField-${moduleName} .form-control`);
				// multijsoninit(`#${frmId} .mjField-${moduleName} .form-control`);
				adjustwidth();
			}, 1500);
		}
	}, [fields]);

	const addNewField = () => {
		setFields((prev: any[]) => {
			return [...prev, { fields: newFields, delete: false }];
		});
	}

	const removeField = (index: number) => {
		setFields((prev: any[]) => {
			const newFields = prev.map((item, i) => {
				if (i === index) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
	}

	const adjustwidth = () => {
		let awards: any = document.querySelectorAll('.award-td');
		for (let i = 0; i < awards.length; i++) {
			let awardid = awards[i].getAttribute('id');
			if (awardid) {
				let allcss: any = document.querySelectorAll('.' + awardid);
				if (allcss.length) {
					if (awardid == 'td1') {
						for (let j = 0; j < allcss.length; j++) {
							allcss[j].width = awards[i].offsetWidth;
						}
					}
					else
						awards[i].width = allcss[0].offsetWidth;
				}
			}
			let footid = document.getElementById('tdf' + i);
			if (footid)
				footid.style.width = awards[i].offsetWidth + 'px';
		}
	}

	return (
		<>
			{moduleName == 'smart_metering'
				?
				<thead>
					<tr>
						<th style={{ minWidth: 50, width: 50 }}id="td1" className="award-td"><span className="text-center">S.No.</span></th>
						<th id="td2" className="award-td"><span className="text-center">Name of the District/Project</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">Grant No.</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">Tranche</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">GoI Grant Disbursement   (INR)</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">Date of GoI   Disb.</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">CP  Fund  Disbursement   (INR)</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">Date of CP   Disb.</span></th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">Own Fund   Infused</span>   (INR)</th>
						<th style={{ minWidth: 130, width: 130, maxWidth:130 }} className="award-td"><span className="text-center">Date of release   of own fund</span></th>
						{/* <th id="td11" className="award-td text-end action-td"></th> */}
					</tr>
				</thead>
				: null
			}
			<tbody className={`mjField-${moduleName}`}>
				{moduleName == 'smart_metering' ?
					<tr className='caption text-center'>
						<td colSpan={11}>Smart Metering</td>
					</tr>
					: null
				}

				{moduleName == 'lose_reduction' ?
					<tr className='caption text-center'>
						<td colSpan={11}>Loss Reduction</td>
					</tr>
					: null
				}

				{moduleName == 'modernisation' ?
					<tr className='caption text-center'>
						<td colSpan={11}>MODERNISATION</td>
					</tr>
					: null
				}

				{
					fields.map((item: any, i: number) => {
						return (item.delete === false)
							? (
								<>
									<tr className="align-top">
										<td className="td1"><span className="text-center">{srNo++}</span></td>
										{
											item.fields.map((field: any, j: number) => {
												let extraId = '_' + i + '_' + j;
												return (field.type !== 'multijson' && !field.childrens)
													? (
														<td className={'td' + (j + 2)} key={`${idRef.current}-${index}-${field.key}`}>
															<FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module="disbur_" />
														</td>
													) : null;
											})
										}
										<td colSpan={7} className="multiple-award-items customTableWidth">
											{
												item.fields.map((field: any, j: number) => {
													const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
													const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
													return field.type === 'multijson' && (
														<MultiChild frmId={frmId} field={field} pIndex={i} jIndex={10} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} view={view} />
													)
												})
											}
										</td>
									</tr>
									{/* {
                                        item.fields.map((field: any, j: number) => (
                                            (field.type === 'json' && field.childrens) && (
                                                <ChildInput fields={field.childrens} index={index + '_' + i + '_' + j} />
                                            )
                                        ))
                                    } */}
								</>
							)
							: null;
					})
				}
				{
					(totalField) && (
						<tr>
							<td colSpan={3}><span className="text-center">Total</span></td>
							<td colSpan={7}>
								<table className="table-responsive RevenueDetailsTable PqTable award-footer-table">
									<tbody>
										<tr>
											<td colSpan={1} style={{ minWidth: 125, width: 125 }}>&nbsp;</td>
											<td colSpan={1} style={{ minWidth: 130, width: 130 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[0].key} field={totalField[0]} module="disbur_" /></td>
											{view == 'Y' ?
												<td colSpan={1} style={{ minWidth: 150, width: 150 }}>&nbsp;</td>
												:
												<td colSpan={1} style={{ minWidth: 150, width: 150 }}>&nbsp;</td>
											}
											<td colSpan={1} style={{ minWidth: 130, width: 130 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[1].key} field={totalField[1]} module="disbur_" /></td>
											{view == 'Y' ?
												<td colSpan={1} style={{ minWidth: 130, width: 130 }}>&nbsp;</td>
												:
												<td colSpan={1} style={{ minWidth: 130, width: 130 }}>&nbsp;</td>
											}
											<td colSpan={1} style={{ minWidth: 130, width: 130 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[2].key} field={totalField[2]} module="disbur_" /></td>
											<td colSpan={1} style={{ minWidth: 100, width: 100 }}></td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					)
				}
			</tbody>
		</>
	);
}

export const DisbursementDetails = (props?: any) => {

	const idRef = useRef(Math.random().toString(36).substring(2, 15));
	const formData: any = useRef(null);
	const [roleid, setRoleid] = useState<any>(0);
	const [discomid, setDiscomid] = useState<any>(0);
	const [discomuserid, setDiscomUserid] = useState<any>(0);
	const [error, setError] = useStateMounted<string>();
	const [refresh, setRefresh] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [submittedHistory, setSubmittedHistory] = useState<any[]>([]);
	const [submittedLogHistory, setSubmittedLogHistory] = useState<any[]>([]);
	const [fields, setFields] = useState<any[]>([]);
	const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
	const [isButtonStatus, setButtonStatus] = useState<any>(null);
	const [documentClass, setDocumentClass] = useState<any>(null);
	const [view, setView] = useStateMounted<string>();
	const [excelLink, setExcelLink] = useStateMounted('#');
	const [pdfLink, setPdfLink] = useStateMounted('#');
	const [year, setYear] = useState<any>('2022');
    const [dropDownYearValue, setDropDownYearValue] = useState<any>('2021-2022');

	const exportPdfLink = () => {
		const params = {
			roleid: roleid,
			discom_id: discomid,
			form_id: 231,
			discom_user_id: discomuserid,
			year: year,
			export_type: 'pdf'
		};
		let plink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/fddisburexport'].join('/');
		plink += '?' + QueryString.stringify(params);
		setPdfLink(plink);
	}

	const exportExcelLink = () => {
		const params = {
			roleid: roleid,
			discom_id: discomid,
			form_id: 231,
			discom_user_id: discomuserid,
			year: year,
			export_type: 'excel'
		};
		let elink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/fddisburexport-excel'].join('/');
		elink += '?' + QueryString.stringify(params);
		setExcelLink(elink);
	}

	const selectYearValue = (evt?: any) => {
		if (evt.target.value != 0 || evt.target.value != '') {
			const year_array = evt.target.value.split('-');
			setYear(year_array[1]);
			setDropDownYearValue(evt.target.value);
		}
	};

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
		setDiscomid(Storage.get('discom_id'));
		setDiscomUserid(Storage.get('discom_user_id'));

		let currentYearValue = new Date().getFullYear();
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setYear(currentYearValue);
        let currentYear = (currentYearValue-1) + '-' + currentYearValue;
        setDropDownYearValue(currentYear);

	}, [roleid, discomid]);

	useEffect(() => {
		if (roleid == 2 || roleid == 3 || roleid == 4) {
			if (view == 'Y') {
				setDocumentClass('col-md-3');
			} else {
				setDocumentClass('col-md-6');
			}
		} else {
			setDocumentClass('col-md-3');
		}
	}, [roleid, view]);

	const getFields = () => {
		(async () => {
			setIsLoading(true);
			setError('');
			setFields([]);

			console.log(year);
			if (roleid && discomid && year) {
				const params = {
					roleid: roleid,
					discom_id: discomid,
					form_id: 231,
					discom_user_id: discomuserid,
					year: year,
				};
				let footerspinner: any = document.getElementById('footer-spinner');
				if (footerspinner)
					footerspinner.classList.remove('d-none');
				await Http.get(`/apiv1/fddisburfields`, { params })
					.then((res) => {
						if (res.resultflag && res.fields) {
							if (res.fields && Array.isArray(res.fields)) {
								if (!res.fields.length) setError("No data found.");
								setFields(res.fields);
								// setSubmittedStatus(res.submittedStatus);
								// setButtonStatus(res.buttonStatus);
								setSubmittedLogHistory(res.logHistory);
								setView(res.view);
								setIsLoading(false);
								setRefresh(false);
								// setTimeout(() => {
								// 	init();
								// 	multijsoninit();
								// }, 1000);
							} else {
								setError("Invalid data type.");
							}
						} else if (!res.resultflag) {
							setError(res.message);
						} else {
							setError('No data found.');
						}
					})
					.catch((error) => {
						setError('System error occurred!! please try again.');
					}).finally(() => {
						setIsLoading(false);
						footerspinner.classList.add('d-none');
					});
			}
		})();
	};

	useEffect(() => {
		(async () => {
			getFields();
		})();
	}, [roleid, discomid, year]);

	useEffect(() => {
		if (fields.length) {
			setTimeout(() => {
				const selector = `#frm-${idRef.current} .form-control`;
				inputEventChecker.removeGroupEvent(selector);
				init(selector);
				multijsoninit(selector);
			}, 2500);
		}
	}, [fields]);

	const saveDraft = () => {
		setIsLoading(true);
		setRefresh(false);
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		let myFormData = new FormData(formData.current);
		myFormData.append('discom_user_id', discomuserid);
		myFormData.append('roleid', roleid);
		myFormData.append('discom_id', discomid);
		myFormData.append("year", year);

		Http.post('/apiv1/save-disbursement-details', myFormData, config)
			.then((result) => {
				if (result.resultflag == 0) {
					setIsLoading(false);
					setRefresh(true);
					swal("Warning", result.message, "info");
				} else {
					setIsLoading(false);
					setRefresh(true);
					swal("Fund Disbursement Form", result.message, "success");
					setTimeout(() => {
						getFields();
					}, 2000);
				}
			});
	}

	const submitToForword = () => {
		setIsLoading(true);
		setRefresh(false);
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		let myFormData = new FormData(formData.current);
		myFormData.append('discom_user_id', discomuserid);
		myFormData.append('roleid', roleid);
		myFormData.append('discom_id', discomid);
		myFormData.append("year", year);

		swal({
			title: "Are you sure to submit this form?",
			text: "After submit, You will not able to edit this form.",
			icon: "warning",
			buttons: [
				'No, cancel it!',
				'Yes, I am sure!'
			],
			dangerMode: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				swal({
					title: 'Fund Disbursement Form!',
					text: 'Your data has been submitted.',
					icon: 'success'
				}).then(function () {
					Http.post('/apiv1/fund-disbursement-forward', myFormData, config)
						.then((result) => {
							swal("Fund Disbursal Form", "Data has been forwarded", "success");
							setIsLoading(false);
							setRefresh(true);
							setTimeout(() => {
								getFields();
							}, 2000);
						});
				});
			} else {
				setIsLoading(false);
			}
		});
	}

	const submitToBackward = () => {
		setIsLoading(true);
		setRefresh(false);
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		let myFormData = new FormData(formData.current);
		myFormData.append('discom_user_id', discomuserid);
		myFormData.append('roleid', roleid);
		myFormData.append('discom_id', discomid);
		myFormData.append("year", year);

		swal({
			title: "Are your sure want to return this form?",
			text: "",
			icon: "warning",
			buttons: [
				'No, cancel it!',
				'Yes, I am sure!'
			],
			dangerMode: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				Http.post('/apiv1/fund-disbursement-backward', myFormData, config)
					.then((result) => {
						if (result.resultflag == 0) {
							setIsLoading(false);
							swal("Warning", result.message, "info");
						} else {
							swal("Fund Disbursement Form", "The form has been returned for correction", "success");
							setIsLoading(false);
							setRefresh(true);
							setTimeout(() => {
								getFields();
							}, 2000);
						}
					});
			} else {
				setIsLoading(false);
			}
		});
	}

	return (
		<>
			<section className="financialForm">
				<div className="financialMainFrom">
					<div className="tab-content border-0 p-0">
						<div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
							<div className='fYearMonth setPosition'>
								<div className='row justify-content-end align-items-center gx-2'>
									{
										((!error) && fields?.length > 0) && (
											<>
												<div className='col-md-6 text-end'>
													<label>Select Year (FY)</label>
												</div>
												<div className='col-md-4'>
                                        			<select className="form-select selectYear" onChange={selectYearValue} aria-label="select year" value={dropDownYearValue}>
														<option value={"2021-2022"}>2021-2022</option>
														<option value={"2022-2023"}>2022-2023</option>
														<option value={"2023-2024"}>2023-2024</option>
														<option value={"2024-2025"}>2024-2025</option>
														<option value={"2025-2026"}>2025-2026</option>
														<option value={"2026-2027"}>2026-2027</option>
													</select>
												</div>
											</>
										)
									}
								</div>
							</div>
							<div className='row p-2 align-items-end'>
								<div className='col-md-10'>
								</div>
								<div className='col-md-2'>
									<Link title="Export in Excel" to={excelLink} onClick={() => exportExcelLink()} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
									<Link title="Export in PDF" to={pdfLink} onClick={() => exportPdfLink()} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
								</div>
							</div>
							<form noValidate ref={formData} id={`frm-${idRef.current}`}>
								<>
									{error && (
										<>
											<Alert className='mt-3 mb-3' variant="info">{error}</Alert>
										</>
									)}

									{
										((!error) && fields?.length > 0) && (
											<>
												<div className="award-table pe-3">
													<table className="table-responsive RevenueDetailsTable PqTable w-100">
														{
															fields.map((field: any, index: number) => {
																if ([
																	'smart_metering_fields',
																	'lose_reduction_fields',
																	'modernisation_fields'
																].includes(field.key) === false) return null;

																const smartTotal = (fields[1] && fields[1].childrens) ? fields[1].childrens : null;
																const loseTotal = (fields[3] && fields[3].childrens) ? fields[3].childrens : null;
																const moderTotal = (fields[5] && fields[5].childrens) ? fields[5].childrens : null;
																return (field.type === 'multijson' && field.key === 'smart_metering_fields')
																	? <Multijson frmId={`frm-${idRef.current}`} key={index} field={field} index={index} totalField={smartTotal} moduleName="smart_metering" view={view} />
																	: ((field.type === 'multijson' && field.key === 'lose_reduction_fields'))
																		? <Multijson frmId={`frm-${idRef.current}`} key={index} field={field} index={index} totalField={loseTotal} moduleName="lose_reduction" view={view} />
																		: <Multijson frmId={`frm-${idRef.current}`} key={index} field={field} index={index} totalField={moderTotal} moduleName="modernisation" view={view} />
															})
														}

														{/* lose Reduction */}
														{/*
															fields.map((field: any, index: number) => {
																const totalField = (fields.length && fields[3].childrens) ? fields[3].childrens : null;
																return field.type === 'multijson' && field.key === 'lose_reduction_fields' && (
																	<Multijson field={field} index={index} totalField={totalField} moduleName="lose_reduction" view={view} />
																)
															})*/
														}

														{/* Modernisation */}
														{/*
															fields.map((field: any, index: number) => {
																const totalField = (fields.length && fields[5].childrens) ? fields[5].childrens : null;
																return field.type === 'multijson' && field.key === 'modernisation_fields' && (
																	<Multijson field={field} index={index} totalField={totalField} moduleName="modernisation" view={view} />
																)
															})*/
														}

														<tr>
															<td colSpan={3}><span className="text-center">Grand Total</span></td>
															<td colSpan={7}>
																<table className="table-responsive RevenueDetailsTable PqTable award-footer-table">
																	<tbody>
																		<tr>
																			<td style={{ minWidth: 125, width: 125 }}></td>
																			<td style={{ minWidth: 130, width: 130 }}><FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} module="disbur_" /></td>
																			{view == 'Y' ?
																				<td style={{ minWidth: 150, width: 150 }}></td>
																				:
																				<td style={{ minWidth: 150, width: 150 }}></td>
																			}
																			<td style={{ minWidth: 130, width: 130 }}><FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} module="disbur_" /></td>
																			{view == 'Y' ?
																				<td style={{ minWidth: 130, width: 130 }}></td>
																				:
																				<td style={{ minWidth: 130, width: 130 }}></td>
																			}
																			<td style={{ minWidth: 130, width: 130 }}><FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} module="disbur_" /></td>
																			<td style={{ minWidth: 100, width: 100 }}></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</table>
												</div>
												<div className='row my-3 uploadUcss align-items-center'>
													<div className='col-md-2'>
														<label>Sanction Letter</label>
													</div>
													<div className={documentClass}>
														<div className="uploadSelect disbDocument sancLetter">
															<FieldComponent options={{ showTitle: false }} fieldName={fields[9] && fields[9].key ? fields[9].key : ''} field={fields[9] && fields[9] ? fields[9] : ''} />
														</div>
													</div>
												</div>
											</>
										)
									}
								</>

								{/* {isButtonStatus == "1" && isSubmittedStatus == "1" && roleid != '2' ?
									<>
										<div>
											<div className="form-group mb-4">
												<label className="d-block" htmlFor="remarks">Remarks</label>
												<textarea className="w-100" id="remarks" name="remarks"></textarea>
											</div>
										</div>
									</>
									: null
								} */}
								{/* <FdDiscomHistory FdHistoryBlock={submittedHistory} /> */}
								<FdDiscomLogHistory FdLogHistoryBlock={submittedLogHistory} />

								<div className="formBtnSection justify-content-end">
									{(roleid == 2 || roleid == 3 || roleid == 4) ?
										<Link to="#" className="btnDraft" onClick={() => saveDraft()} >Save</Link>
										: null
									}
								</div>

								{/* <div className="formBtnSection justify-content-between">
                                    {isSubmittedStatus == "1" && (roleid == 2 || roleid == 3 || roleid == 4) ?
                                        <Link to="#" className="btnDraft" onClick={() => saveDraft()} >Save Draft</Link>
                                            :null
                                    }

                                    {isButtonStatus == "1" && isSubmittedStatus == "1"
                                        ?
                                        <>
                                            {roleid != 2 ?
                                                <>
                                                <Link to="#" className="btnCancle" onClick={() => submitToBackward()} >Return</Link>
                                                </>
                                                : null
                                            }

                                            <Link to="#" className="btnCancle" onClick={() => submitToForword()} >
                                                {roleid == 2 ? "Submit to Reviewer" : null}
                                                {roleid == 3 ? "Submit to Approver" : null}
                                                {roleid == 4 ? "Submit to Nodal Agency" : null}
                                                {roleid == 5 ? "Submit to Approver" : null}
                                                {roleid == 6 ? "Approve" : null}
                                            </Link>
                                        </>
                                        : null
                                    }
                                </div> */}
							</form>
						</div>
					</div>
				</div>
			</section>
			<>
				{isLoading ? <LoadingSpinner /> : null}
			</>
		</>
	);
};

export default DisbursementDetails;