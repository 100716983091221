import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { BsFillInfoCircleFill } from "react-icons/bs";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from "react-bootstrap/esm/types";
import './tooltip.scss';

export interface TooltipProps {
  icon?: any;
  defaultShow?: boolean;
  children: any;
  placement: Placement
}

const propTypes = {
  icon: PropTypes.any,
  defaultShow: PropTypes.bool,
  children: PropTypes.any.isRequired,
  placement: PropTypes.string
};

const MyTooltip = ({ icon, defaultShow, children, placement }: TooltipProps) => {

  const target = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (defaultShow) {
      setShow(true);
    }
  }, [defaultShow]);

  const onHide = (e: any) => {
    setShow(false);
  }


  return <>
    <button type="button" className="btn border-0 py-0" ref={target} onClick={() => setShow(!show)}>
      {(icon) ? icon : <BsFillInfoCircleFill />}
    </button>
    <Overlay target={target.current} show={show} placement={placement} onHide={onHide} rootClose={true}>
      {(props) => (
        <Tooltip className={'my-tooltip'} id="UploadTool" {...props}>
          {children}
        </Tooltip>
      )}
    </Overlay>
  </>
}

MyTooltip.defaultProps = {
  placement: 'left'
};

MyTooltip.propTypes = propTypes;

export default MyTooltip;