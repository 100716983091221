import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';

/* Route */
const routes: RouteProps[] = [
  {
    path: '',
    element: lazy(() => import('../Pages/Main')),
    index: true,
    private: true
  },
  {
    path: 'input-form-discom/:discomId?',
    element: lazy(() => import('../Pages/InputForm/Discom')),
    private: true
  },
  {
    path: 'input-form-state/:stateId?',
    element: lazy(() => import('../Pages/InputForm/State')),
    private: true
  },
  {
    path: 'outcomes-discom-state/:discomId?',
    element: lazy(() => import('../Pages/Outcomes/DiscomState')),
    private: true
  },
  {
    path: 'outcomes-nodal/:stateId?',
    element: lazy(() => import('../Pages/Outcomes/Nodal')),
    private: true
  }
];

export default routes;