import React, { Component } from 'react'
import CapacityBuilding from "../../assets/ImageCompo/CapacityBuilding";
import Evaluation from "../../assets/ImageCompo/Evaluation";
import SchemeClosure from "../../assets/ImageCompo/SchemeClosureNew";
import InputForm from "../../assets/ImageCompo/InputForm";
import Monitor from "../../assets/ImageCompo/Monitor";
import FundDisbursal from "../../assets/ImageCompo/SchemeClosure";
import { Link } from "react-router-dom";

export default class ActionCenter extends Component {

  render() {
    return (
        <>
        <div className="ActionCenterSection">
          <div className="row gx-0">
            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
              <Link to="/input-form" className="d-block w-100 text-decoration-none h-100">
                <div className="tabPanelInner">
                  <InputForm />
                  <h5>Input Forms</h5>
                  <div className="hoverContent">
                    <div><InputForm />
                    <h5>Input Forms</h5>
                    <p>To enter details of Financial, PQ SOP, Energy, REF, Sanction letter and Award details</p></div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
            <Link to="/monitoring" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <Monitor />
                <h5>Monitoring</h5>
                <div className="hoverContent">
                  <div><Monitor />
                  <h5>Monitoring</h5>
                  <p>To track physical progress of projects</p></div>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
              <Link to="/evaluation-form" className="d-block w-100 text-decoration-none h-100 h-100">
                <div className="tabPanelInner">
                  <Evaluation />
                  <h5>Evaluation</h5>
                  <div className="hoverContent">
                  <div><Evaluation />
                    <h5>Evaluation</h5>
                    <p>To view evaluation of PQ, SOP and REF</p></div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
              <Link to="/fund-disbursal" className="d-block w-100 text-decoration-none h-100 h-100">
                <div className="tabPanelInner">
                  <FundDisbursal />
                  <h5>Fund Disbursal</h5>
                  <div className="hoverContent">
                  <div> <FundDisbursal />
                    <h5>Fund Disbursal</h5>
                    <p>To request for fund release</p>
                  </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
              <Link to="/rdss/quality-monitoring" className="d-block w-100 text-decoration-none h-100 h-100">
                <div className="tabPanelInner">
                  <CapacityBuilding />
                  <h5>Quality Monitoring</h5>
                  <div className="hoverContent">
                  <div> <CapacityBuilding />
                    <h5>Quality Monitoring</h5>
                    <p>Major Material Quality Inspection, Power Transformer Inspection, Work Quality Inspection</p>
                  </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
              <div className="tabPanelInner">
                <SchemeClosure />
                <h5>Scheme Closure</h5>
              </div>
            </div>
          </div>
        </div>
        </>
    )
  }
}
