import React, { useEffect, useRef, useState } from 'react'
import { Tabs, Tab, ProgressBar } from 'react-bootstrap'
import DocumentCentre from '../RdssTabs/DocumentCentre'
import { Http } from '../../Core/Services/HttpService';
import { GrUpload } from 'react-icons/gr';
import { FiUpload } from 'react-icons/fi';
import { BsLink45Deg } from 'react-icons/bs';
import { Visibility } from '../../Core/Components';
import { Storage } from '../../Core/Services/StorageService';

export default function UploadModal(props: any) {

  const [modelId, setModelId] = useState('UploadModal');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectFiles, setSelectFiles] = useState<any>([]);
  const [discomid, setDiscomid] = useState<any>(0);
  const [tabKey, setTabKey] = useState('link');
  const [uploadResponse, setUploadResponse] = useState<any>();
  const frmRef = useRef<any>(null);
  const [refreshList, setRefreshList] = useState(false);
  const [currentLevel, setCurrentLevel] = useState<{ label: string, path: string, lastLevelId: string | number }>({ label: '', path: '', lastLevelId: '' });
  const [selectedFile, setSelectedFile] = useState('');
  const [savedFilesData, setSavedFilesData] = useState<any>([]);
  const [level, setLevel] = useState<number>(0);

  useEffect(() => {
    setDiscomid(Storage.get('discom_id'));
  }, [discomid]);

  const handleFileChange = (event: any) => {
    if (event.target.files[0]) {
      setSelectedFile(event.target.files[0].name);
    }
    setUploadResponse('');
  };

  useEffect(() => {
    if (props.modelId && props.modelId != modelId)
      setModelId(props.modelId);
  }, [props.modelId]);

  useEffect(() => {
    // console.log(savedFilesData);
    if (props.savedFilesData && props.savedFilesData != selectFiles) {
      props.setUploadData(props.savedFilesData);
      setSavedFilesData(props.savedFilesData);
    }
  }, [props.savedFilesData, savedFilesData]);

  const handleFileUpload = async () => {
    setUploadResponse('');
    const formData = new FormData(frmRef.current);
    let fileVar: any = formData.get('files');

    if (fileVar.name == "" || formData.get('file_name') == "") {
      if (formData.get('file_name') == "") {
        setUploadResponse('<span class="text-danger">Please enter file name</span>');
      }
      return;
    }
    formData.append('table_id', props.fieldId);
    formData.append('table_key', props.fieldKey);
    formData.append('discom_id', discomid);

    let searchParams = new URLSearchParams(props.apiurl);
    let formID: any = searchParams.get('form_id');
    formData.append("form_id", formID);
    setUploadResponse('<span class="loader"></span>');

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // onUploadProgress: (progressEvent: any) => {
      //     const percentCompleted = Math.round(
      //         (progressEvent.loaded * 100) / progressEvent.total
      //     );
      //     setUploadProgress(percentCompleted);
      // },
    };
    await Http.post("/apiv1/upload-dms-file", formData, config)
      .then((res) => {
        if (res._resultflag == 1) {
          setUploadResponse('<span class="text-success">' + res.message + '</span>');
          setTimeout(() => {
            setRefreshList(true);
            setUploadProgress(0);
            setUploadResponse('');
            setSelectedFile('');
            frmRef.current.reset();
            tabChange('link');
          }, 2000);
        } else {
          setUploadResponse('<span class="text-danger">' + res.message + '</span>');
          frmRef.current.reset();
          setUploadProgress(0);
        }
      });
  };

  const tabChange = (key: any) => {
    setTabKey(key);
  };

  const closeModal = (id: any) => {
    if (props.closeModal)
      props.closeModal(id);
  };

  const onUpload = () => {
    props.setUploadData(selectFiles);
    closeModal(modelId);
  };

  const onSelectFile = (files: any) => {
    setSelectFiles(files);
  }

  const getLevels = (levels: any) => {
    setLevel(levels.length);
  }

  return (
    <div className="modal fade" id={modelId} aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="row gx-0">
            <div className="modal-header">
              <h6 className="modal-title">Forms Uploading / Linking a file</h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => closeModal(modelId)}></button>
            </div>
            <div className="modal-body">
              <div className='rdssGuideline'>
                { (level == 0) && (
                  <div style={{"position": "absolute", "top": "15px", "right": "10px"}} className="float-end me-2 text-danger mt-2">Please select folder to upload file</div>
                )}
                <Tabs defaultActiveKey="link" id="uncontrolled-tab-example" activeKey={tabKey} onSelect={(k) => tabChange(k)}>
                  <Tab eventKey="link" title="Link to an existing file?">
                    <Visibility options={{ threshold: 0.5 }}>
                      {(ref: any, isVisible: boolean) => (
                        <div
                          ref={ref} className='field-wise-dms'>
                          {(isVisible) ? (
                            <DocumentCentre getLevels={getLevels} onSelectFile={onSelectFile} setRefreshList={setRefreshList} refreshList={refreshList} getCurrentLevel={setCurrentLevel} selectedFileList={savedFilesData} isModelDirLoad={true} />
                          ) : null}
                        </div>
                      )}
                    </Visibility>
                    {/* <DocumentCentre onSelectFile={onSelectFile} setRefreshList={setRefreshList} refreshList={refreshList} getCurrentLevel={setCurrentLevel} selectedFileList={savedFilesData} isModelDirLoad={true} /> */}
                    <hr />
                    <div className="">
                      <button type="button" onClick={onUpload} className="btn-success" data-bs-dismiss="modal"><BsLink45Deg /> Link</button>
                    </div>
                  </Tab>
                  { (level > 0) && (
                  <Tab eventKey="upload" title="Upload a new file?">
                    <form name="upload" ref={frmRef}>
                      <div className="row">
                        <div className="col-3 me-3">
                          <div className="fileUpload position-relative text-center UploadModalCustomUpload mt-3">
                            <div className="downEqule">
                              <input name='files' type="file" className="form-control" onChange={handleFileChange} />
                              <div className="customUpload align-items-center flex-wrap">
                                <span className='w-100'> <GrUpload /> </span>
                                <p className='w-100'><b>Choose a file</b> or Drag it</p>
                              </div>
                            </div>
                          </div>
                          <div className='mt-3 progressWidth'>
                            <ProgressBar animated striped variant="success" now={uploadProgress} label={`${uploadProgress}%`} />
                          </div>
                          <div className='mt-2'>{selectedFile}</div>
                          <div className='mt-3' dangerouslySetInnerHTML={{ __html: uploadResponse }}></div>
                        </div>
                        <div className='col-6 mt-3 text-start'>
                          <div className="mb-3">
                            <label for-html="file_name" className="form-label">File Name</label>
                            <input tabIndex={-1} type="text" className="form-control text-start" name="file_name" id="file_name" placeholder="File Name" required />
                          </div>
                          <div className="row">
                            <div className="col mb-3">
                              <label for-html="fileDescription" className="form-label">File Description</label>
                              <textarea tabIndex={-1} className="form-control" name="description" id="fileDescription" rows-html="3"></textarea>
                            </div>
                            <div className="col mb-3">
                              <label for-html="fileTags" className="form-label">Tags</label>
                              <textarea tabIndex={-1} name='tags' className="form-control" id="fileTags" rows-html="3"></textarea>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label for-html="fileLocationLabel" className="form-label">File Location</label>
                            <input tabIndex={-1} type='text' name='fileLocationLabel' className="form-control text-start" id="fileLocationLabel" placeholder="File Location" defaultValue={currentLevel.label} disabled />
                            <input tabIndex={-1} type='hidden' name='fileLocation' id="fileLocation" defaultValue={currentLevel.lastLevelId} />
                          </div>
                        </div>
                      </div>
                    </form>
                    <hr />
                    <div className="">
                      <button type="button" onClick={handleFileUpload} className="btn-success"><FiUpload /> Upload</button>
                    </div>
                  </Tab>)}
                </Tabs>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer">
              <button type="button" onClick={onUpload} className="btn-success">Upload</button>
          </div> */}
        </div>
      </div>
    </div>
  )
}
