import React, { useCallback, useEffect, useRef, useState } from "react";
import { GrDownload, GrUpload } from "react-icons/gr";
import UploadModal from "./Components/InputForms/UploadModal";
import Modal from 'bootstrap/js/dist/modal';
import Select from "react-select";

const functions: any = {
  changetitle: (e: any) => {
    console.log(e.target.value);
  }
}

interface FieldPropsType {
  key: string;
  col: string;
  type: 'text' | 'input' | 'select' | any;
  properties: any;
  caption: string;
  comments?: string;
  source: any | any[];
  value: any;
  table_key?: string;
  field_formula?: string;
  required?: string;
  parent?: string | any[];
  parent_type?: string | any[];
  view?: string;
  tab_index?: any;
  errors?: any;
  isMultiFile?: boolean;
  frmDataKey?: string;
  class1?: string;
  class2?: string;
}

type FieldComponentPropType = {
  fieldName: string;
  field: FieldPropsType;
  options?: { showTitle?: boolean, extraId?: any, parentIndex?: any[] }
  module?: string;
} & typeof defaultProps;

const defaultProps = {
  options: { showTitle: true },
  module: ''
};

function toFixed(amt: any, decimal: number = 0, noComma: boolean = false) {
  amt = (amt + "").replace(/[^\-.0-9]+/g, '');
  if (amt == '') amt = decimal == 0 ? 0 : "0.00";
  amt = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(amt);
  if (noComma) {
    amt = amt.replace(/,/g, '');
  }
  return amt;
}

function FieldComponent(props: FieldComponentPropType) {

  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const inputRef = useRef<any>(null);
  const [fileDownload, setFileDownload] = useState<any>(0);
  const [uploadData, setUploadData] = useState<any>([]);
  const [filesSelected, setFilesSelected] = useState<any>([]);
  const [multiSelectVal, setMultiSelectVal] = useState<any>([]);
  const [multiSource, setMultiSource] = useState<any>([]);
  const [selectSearch, setSelectSearch] = useState<any>([]);

  const myModal = useRef<any>({});

  useEffect(() => {
    if (props.field && props.field.type == 'file') {
      setFilesSelected([]);
      // if(props.field.value != '' && !Array.isArray(props.field.value)) {
      //   props.field.value = JSON.parse(props.field.value);
      // }
      if (props.field.view == 'Y' && (props.field.value && Array.isArray(props.field.value) && props.field.value.length)) {
        setUploadData(props.field.value);
      }
    }

    if (props.field && props.field.type == 'multiselect') {
      const source = Object.keys(props.field.source).map((key, i) => {
        return { value: key.trim(), label: props.field.source[key] };
      });
      setMultiSource(source);
      if (props.field.value) {
        const fval = JSON.parse(props.field.value);
        if (fval && Array.isArray(fval) && fval.length) {
          const msval = fval.map((item: any) => {
            return { value: item.trim(), label: props.field.source[item.trim()] };
          });
          setMultiSelectVal(msval);
        }
      }
    }
    if (props.field && props.field.type == 'selectsearch') {
      if (props.field.value) {
        const sVal = props.field.source.filter((item: any) => item.value == props.field.value)[0] || null;
        setSelectSearch(sVal);
      }
    }
  }, [props.field]);

  useEffect(() => {
    if (uploadData && Array.isArray(uploadData) && uploadData.length) {
      setFilesSelected(uploadData.filter((value: any, index: any, array: any) => {
        return array.indexOf(value) === index;
      }));
    } else {
      setFilesSelected([]);
    }
  }, [uploadData]);

  const onFileDownload = (event: any) => {
    if (event.target.value) {
      setFileDownload(event.target.value);
    }
  };

  const getMultiSelectVal = useCallback(() => {
    if (multiSelectVal && multiSelectVal.length) {
      return JSON.stringify(multiSelectVal.map((item: any) => item.value));
    } else {
      return JSON.stringify([]);
    }
  }, [multiSelectVal]);

  const getSelectSearch = useCallback(() => {
    if (selectSearch) {
      return selectSearch.value;
    } else {
      return '';
    }
  }, [selectSearch]);

  const getFiles = useCallback(() => {
    if (filesSelected && Array.isArray(filesSelected) && filesSelected.length) {
      return JSON.stringify(filesSelected);
    } else {
      return '';
    }
  }, [filesSelected]);

  let className = 'form-field';
  if (props.options.showTitle)
    className = ' form-floating';

  function loadField(fieldName: string, field: FieldPropsType) {
    let fileClassName = 'downEqule';
    if (field.value != '' && field.value != undefined)
      fileClassName = 'dowupEqule';

    let dataprefix = field.table_key;
    if (props.module)
      dataprefix = props.module + dataprefix;

    let inputName = 'formdata';

    if (field.frmDataKey !== undefined && field.frmDataKey !== '') {
      inputName = field.frmDataKey;
    }

    let fieldid = fieldName;
    if (field.table_key !== undefined) {
      inputName += '[' + field.table_key + ']';
      fieldid = dataprefix + '_' + fieldid;
      if (props.options.extraId)
        fieldid = fieldid + '' + props.options.extraId;
    }
    if (field.parent !== undefined && field.parent !== '') {
      if (Array.isArray(field.parent)) {
        field.parent.forEach((pkey, i) => {
          inputName += '[' + pkey + ']';
        });
      } else {
        inputName += '[' + field.parent + ']';
      }
    }

    inputName += '[' + fieldName + ']';
    let hasParentMultijson = false;
    if (field.parent_type !== undefined && field.parent_type !== '') {
      if (Array.isArray(field.parent_type)) {
        let ppMultiCount = field.parent_type.filter((ptype: any) => ptype === 'multijson').length;
        hasParentMultijson = (ppMultiCount) ? true : false;
        ppMultiCount = ppMultiCount - 1;
        if (ppMultiCount) {
          let pmCount = field.parent_type.length;
          let pICount = 0;
          const parentIndex = props.options.parentIndex;
          field.parent_type.forEach((ptype, i) => {
            if (ppMultiCount && (pmCount <= ppMultiCount)) {
              const pindex = (parentIndex && parentIndex[pICount] !== undefined) ? parentIndex[pICount] : '';
              inputName += '[' + pindex + ']';
              pICount++;
            }
            pmCount--;
          });
        }
      } else if (field.parent_type === 'multijson') {
        hasParentMultijson = true;
      }
    }
    if (hasParentMultijson) {
      inputName += '[]';
    }

    let readonly = false;
    let className = 'form-control';
    if (field.field_formula !== '' && field.field_formula !== null) {
      readonly = true;
      className += ' data-formula';
    }
    let errorMessage = '';
    if (field.errors) {
      className += ' is-invalid';
      if (field.errors.error_message) {
        errorMessage = field.errors.error_message;
      } else {
        for (let key in field.errors) {
          errorMessage = field.errors[key];
          break;
        }
      }
    }
    if (field.type == 'amount' || field.type == 'number')
      className += ' text-end';
    let extraClass = '';
    if (field.value == 'Compliant') {
      className += ' green';
      extraClass = ' green';
    }
    if (field.value == 'Non-compliant') {
      className += ' red';
      extraClass = ' red';
    }

    if (hasParentMultijson)
      className += ' ' + fieldName;

    let readonlyprop = false;
    let blankAllowed = false;
    if (field.properties) {
      if (field.properties['data-conditional'] != undefined && field.properties['data-conditional'] !== '') {
        className += ' data-conditional';
      }
      if (field.properties.readonly) {
        readonlyprop = true;
        delete field.properties.readonly;
        field.properties.readOnly = true;
      }
      if (field.properties['data-blank-allowed'] != undefined && field.properties['data-blank-allowed'] == 'Y') {
        blankAllowed = true;
      }
    }

    if (readonlyprop)
      field.properties['data-org-readonly'] = 'Y';

    const openModal = (id: string) => {
      myModal.current[id] = new Modal(document.getElementById(id) as HTMLElement, {
        backdrop: 'static'
      });
      myModal.current[id].show();
    };

    const closeModal = (id: string) => {
      myModal.current[id].hide();
    };

    // Multi Select - Start
    const multiSelectProps = {
      className: 'p-0 border-0 field-comp-multiselect ' + className,
      isSearchable: true,
      menuPortalTarget: document.body,
      styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) },
      isMulti: true,
    };
    // Multi Select - End
    // Select Search - Start
    const selectProps = {
      className: 'p-0 border-0 field-comp-select-search ' + className,
      isSearchable: true,
      menuPortalTarget: document.body,
      styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) },
      isMulti: false,
    };
    // Select Search - End
    // Field File Type
    if (field.type == 'file') {
      if (field.isMultiFile == undefined || (field.isMultiFile != undefined && field.isMultiFile == true)) {
        inputName += '[]';
      }
    }

    switch (field.type) {
      case 'hidden':
        return (
          <React.Fragment key={`${idRef.current}-hidden`}>
            {field.view == 'Y' ?
              <div className={"view-field text-start" + extraClass}>{field.value}</div>
              :
              <>
                <input data-prefix={dataprefix} className={className} {...field.properties} type={field.type} name={inputName} id={fieldid} defaultValue={field.value} />
              </>
            }
          </React.Fragment>
        );
        break;
      case 'text':
        return (
          <React.Fragment key={`${idRef.current}-text`}>
            {field.view == 'Y' ?
              <div className={"view-field text-start" + extraClass}>{field.value}</div>
              :
              <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <input readOnly={readonly} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} type={field.type} name={inputName} id={fieldid} defaultValue={field.value} />
                  </a>
                  :
                  <input readOnly={readonly} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} type={field.type} name={inputName} id={fieldid} defaultValue={field.value} />
                }

              </>
            }
          </React.Fragment>
        );
        break;
      case 'textarea':
        return (
          <React.Fragment key={`${idRef.current}-textarea`}>
            {field.view == 'Y' ?
              <div className={"view-field text-start" + extraClass}>{field.value}</div>
              : <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <textarea readOnly={readonly} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} type={field.type} name={inputName} id={fieldid} defaultValue={field.value}></textarea>
                  </a>
                  :
                  <textarea readOnly={readonly} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} type={field.type} name={inputName} id={fieldid} defaultValue={field.value}></textarea>
                }
              </>
            }
          </React.Fragment>
        );
        break;
      case 'number':
        let nNoComma = (field.properties && field.properties['data-no-comma']);
        const numberFieldVal = (blankAllowed && !field.value) ? field.value : toFixed(field.value, 0, nNoComma);
        return (
          <React.Fragment key={`${idRef.current}-number`}>
            {field.view == 'Y' ?
              <div className="view-field text-end">{numberFieldVal}</div>
              :
              <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <input tabIndex={field.tab_index} data-number="Y" maxLength={14} data-prefix={dataprefix} autoComplete="off" readOnly={readonly} data-required={field.required} className={className} {...field.properties} type="text" name={inputName} id={fieldid} defaultValue={numberFieldVal} />
                  </a>
                  :
                  <input tabIndex={field.tab_index} data-number="Y" maxLength={14} data-prefix={dataprefix} autoComplete="off" readOnly={readonly} data-required={field.required} className={className} {...field.properties} type="text" name={inputName} id={fieldid} defaultValue={numberFieldVal} />
                }
              </>
            }
          </React.Fragment>
        );
        break;
      case 'amount':
        let aNoComma = (field.properties && field.properties['data-no-comma']);
        const dataDecimal = (field.properties && field.properties['data-decimal']) ? field.properties['data-decimal'] * 1 : 2;
        const amountFieldVal = (blankAllowed && !field.value) ? field.value : toFixed(field.value, dataDecimal, aNoComma);
        return (
          <React.Fragment key={`${idRef.current}-amount`}>
            {field.view == 'Y' ?
              <div className="view-field text-end">{amountFieldVal}</div>
              : <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <input tabIndex={field.tab_index} data-amount="Y" maxLength={17} data-prefix={dataprefix} autoComplete="off" readOnly={readonly} data-required={field.required} className={className} {...field.properties} type="text" name={inputName} id={fieldid} defaultValue={amountFieldVal} />
                  </a>
                  :
                  <input tabIndex={field.tab_index} data-amount="Y" maxLength={17} data-prefix={dataprefix} autoComplete="off" readOnly={readonly} data-required={field.required} className={className} {...field.properties} type="text" name={inputName} id={fieldid} defaultValue={amountFieldVal} />
                }
              </>
            }
          </React.Fragment>
        );
        break;
      case 'date':
        return (
          <React.Fragment key={`${idRef.current}-date`}>
            {field.view == 'Y' ?
              <div className="view-field text-start">{field.value}</div>
              :
              <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <input tabIndex={field.tab_index} readOnly={readonly} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} type="date" name={inputName} id={fieldid} defaultValue={field.value} />
                  </a>
                  :
                  <input tabIndex={field.tab_index} readOnly={readonly} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} type="date" name={inputName} id={fieldid} defaultValue={field.value} />
                }
              </>
            }
          </React.Fragment>
        );
        break;
      case 'select':
        return (
          <React.Fragment key={`${idRef.current}-select`}>
            {field.view == 'Y' || readonlyprop ?
              <>
                <div className="view-field text-center">{field.value ? field.source[field.value] : null}</div>
                {
                  readonlyprop ?
                    <input type="hidden" defaultValue={field.value} {...field.properties} name={inputName} id={fieldid} />
                    : null
                }
              </>
              :
              <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <select tabIndex={field.tab_index} defaultValue={field.value} readOnly={readonly} data-prefix={dataprefix} data-required={field.required} className={'form-control text-start form-select ' + className + ' ' + readonly} {...field.properties} name={inputName} id={fieldid}>
                      {
                        Object.keys(field.source).map((key, i) => {
                          return <option key={i} value={key.trim()}>{field.source[key]}</option>;
                        })
                      }
                    </select>
                  </a>
                  :
                  <select tabIndex={field.tab_index} defaultValue={field.value} readOnly={readonly} data-prefix={dataprefix} data-required={field.required} className={'form-control text-start form-select ' + className + ' ' + readonly} {...field.properties} name={inputName} id={fieldid}>
                    {
                      Object.keys(field.source).map((key, i) => {
                        return <option key={i} value={key.trim()}>{field.source[key]}</option>;
                      })
                    }
                  </select>
                }
              </>
            }
          </React.Fragment>
        );
        break;
      case 'selectarr':
        return (
          <React.Fragment key={`${idRef.current}-selectarr`}>
            {field.view == 'Y' || readonlyprop ?
              <>
                <div className="view-field text-center">
                  {field.value ? (field.source.filter((item: any) => item.value == field.value)[0].label || null) : null}
                </div>
                {
                  readonlyprop ?
                    <input type="hidden" defaultValue={field.value} {...field.properties} name={inputName} id={fieldid} />
                    : null
                }
              </>
              :
              <>
                {errorMessage != "" ?
                  <a title={errorMessage} className="error-tag">
                    <select tabIndex={field.tab_index} defaultValue={field.value} readOnly={readonly} data-prefix={dataprefix} data-required={field.required} className={'form-control text-start form-select ' + className + ' ' + readonly} {...field.properties} name={inputName} id={fieldid}>
                      {
                        field.source.map((item: any, i: number) => {
                          return <option key={i} value={item.value}>{item.label}</option>;
                        })
                      }
                    </select>
                  </a>
                  :
                  <select tabIndex={field.tab_index} defaultValue={field.value} readOnly={readonly} data-prefix={dataprefix} data-required={field.required} className={'form-control text-start form-select ' + className + ' ' + readonly} {...field.properties} name={inputName} id={fieldid}>
                    {
                      field.source.map((item: any, i: number) => {
                        return <option key={i} value={item.value}>{item.label}</option>;
                      })
                    }
                  </select>
                }
              </>
            }
          </React.Fragment>
        );
        break;
      case 'selectsearch':
        return (
          <React.Fragment key={`${idRef.current}-selectsearch`}>
            {field.view == 'Y' || readonly ?
              <>
                <div className="view-field text-center">
                  {(selectSearch) ? selectSearch.label : null}
                </div>
                {
                  readonly ?
                    <input type="hidden" defaultValue={field.value} {...field.properties} name={inputName} id={fieldid} />
                    : null
                }
              </>
              : (errorMessage != "")
                ? <>
                  <a title={errorMessage} className="error-tag">
                    <input className={"d-none " + className} data-prefix={dataprefix} data-required={field.required} {...field.properties} type="hidden" name={inputName} id={fieldid} value={getSelectSearch()} />
                    <Select {...selectProps} options={field.source} onChange={(e) => setSelectSearch(e)} value={selectSearch} />
                  </a>
                </>
                : <>
                  <input className={"d-none " + className} data-prefix={dataprefix} data-required={field.required} {...field.properties} type="hidden" name={inputName} id={fieldid} value={getSelectSearch()} />
                  <Select {...selectProps} options={field.source} onChange={(e) => setSelectSearch(e)} value={selectSearch} />
                </>
            }
          </React.Fragment>
        );
        break;
      case 'multiselect':
        return (
          <React.Fragment key={`${idRef.current}-multiselect`}>
            {(field.view == 'Y')
              ? <>
                <div className="view-field text-start">
                  {(multiSelectVal && multiSelectVal.length) ? multiSelectVal.map((item: any) => item.label).join(', ') : null}
                </div>
              </>
              : (errorMessage != "")
                ? <>
                  <a title={errorMessage} className="error-tag">
                    <input className="d-none" data-prefix={dataprefix} type='hidden' name={inputName} id={fieldid} value={getMultiSelectVal()} />
                    <Select {...multiSelectProps} options={multiSource} onChange={(e) => setMultiSelectVal(e)} value={multiSelectVal} />
                  </a>
                </>
                : <>
                  <input className="d-none" data-prefix={dataprefix} type='hidden' name={inputName} id={fieldid} value={getMultiSelectVal()} />
                  <Select {...multiSelectProps} options={multiSource} onChange={(e) => setMultiSelectVal(e)} value={multiSelectVal} />
                </>
            }
          </React.Fragment>
        );
        break;
      case 'checkbox':
        return (
          <React.Fragment key={`${idRef.current}-checkbox`}>
            <div className="form-check">
              <input type="checkbox" data-prefix={dataprefix} readOnly={readonly} autoComplete="off" data-required={field.required} className={'form-check-input' + className} {...field.properties} name={inputName} id={fieldid} value={field.value} />
            </div>
          </React.Fragment>
        );
        break;
      case 'file':
        return (
          <React.Fragment key={`${idRef.current}-file`}>
            {/* <div className="fileUpload position-relative">
              <input type="file" data-prefix={dataprefix} readOnly={readonly} autoComplete="off" data-required={field.required} className={className} {...field.properties} name={inputName} id={fieldid} value={field.value} />
              <div className="customUpload">
                <span>Upload</span>
                <span> <BiUpload /> </span>
              </div>
            </div> */}
            {field.view == 'Y' ?
              <>
                <div className="view-form-field text-center">
                  {/* <UploadModal modelId={fieldid + '_popup'} fieldKey={field.table_key} fieldId={fieldName} setUploadData={setUploadData} id={fieldid + '_file'} savedFilesData={field.value} /> */}
                  <select onChange={onFileDownload} tabIndex={0} data-prefix={dataprefix} className={'form-control text-start form-select form-control false'}>
                    <option value={0}>Select</option>
                    {
                      filesSelected && Array.isArray(filesSelected) ?
                        filesSelected.map((item: any, index: number) => {
                          const attr = { value: item.file };
                          return <option key={index} {...attr}>{item.name}</option>;
                        })
                        : null
                    }
                  </select>
                </div>
                {fileDownload != 0 ?
                  <a href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/downloaddoc/?q=' + encodeURIComponent(fileDownload)} title={fileDownload} id={'viewupload_' + fieldid} className="viewupload" target="_blank"><GrDownload /></a>
                  : null
                }
              </>
              :
              <div className="fileUpload position-relative text-center align-items-end">
                <div className={fileClassName}>
                  <input tabIndex={field.tab_index} type="file" data-prefix={dataprefix} readOnly={readonly} autoComplete="off" className={'pe-none'} {...field.properties} id={fieldid + '_file'} />
                  <UploadModal closeModal={closeModal} modelId={fieldid + '_popup'} fieldKey={field.table_key} fieldId={fieldName} setUploadData={setUploadData} id={fieldid + '_file'} savedFilesData={field.value} />
                  <div className="customUpload justify-content-center flex-wrap" onClick={() => openModal(fieldid + '_popup')}>
                    <span> <GrUpload /> </span>
                  </div>
                  <div style={{ display: 'none' }}>
                    {/* <input type="text"
                      data-prefix={dataprefix} className={'d-none form-control false ' + className}
                      name={inputName} id={fieldid} data-required={field.required}
                      {...field.properties}
                      // value={(filesSelected && Array.isArray(filesSelected) && filesSelected.length) ? JSON.stringify(filesSelected) : ''}
                      value={getFiles()}
                    /> */}
                    <select multiple data-prefix={dataprefix} className={'form-control text-start form-select false ' + className} name={inputName} id={fieldid} data-required={field.required}  {...field.properties}>
                      {
                        filesSelected && Array.isArray(filesSelected) ?
                          filesSelected.map((item: any, index: number) => {
                            const attr = { value: JSON.stringify(item), selected: true };
                            return <option key={index} {...attr}>{item.name}</option>;
                          })
                          : null
                      }
                    </select>
                  </div>
                  <select onChange={onFileDownload} tabIndex={0} data-prefix={dataprefix} className={'form-control text-start form-select form-control false'}>
                    <option value={0}>Select</option>
                    {
                      filesSelected && Array.isArray(filesSelected) ?
                        filesSelected.map((item: any, index: number) => {
                          const attr = { value: item.file };
                          return <option key={index} {...attr}>{item.name}</option>;
                        })
                        : null
                    }
                  </select>
                </div>
                {fileDownload != 0 ?
                  <a href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/downloaddoc/?q=' + encodeURIComponent(fileDownload)} title={fileDownload} id={'viewupload_' + fieldid} className="viewupload" target="_blank"><GrDownload /></a>
                  : null
                }
              </div>
            }
          </React.Fragment>
        );
        break;
      case 'ynradio':
        return (
          <React.Fragment key={`${idRef.current}-ynradio`}>
            {field.view == 'Y' ?
              <div className="view-field text-start">{field.value}</div>
              :
              <div className="d-flex align-items-center">
                <div className="form-check me-3">
                  <input type="radio" data-prefix={dataprefix} data-required={field.required} className={`form-control form-check-input radio-${idRef.current}`}  {...field.properties} name={inputName} id={fieldid + '_yes'} value='Yes' defaultChecked={field.value == 'Yes'} />
                  <label className="form-check-label" htmlFor={fieldid + '_yes'}>Yes</label>
                </div>
                <div className="form-check">
                  <input type="radio" data-prefix={dataprefix} data-required={field.required} className={`form-control form-check-input radio-${idRef.current}`} {...field.properties} name={inputName} id={fieldid + '_no'} value='No' defaultChecked={field.value == 'No'} />
                  <label className="form-check-label" htmlFor={fieldid + '_no'}>No</label>
                </div>
              </div>
            }
          </React.Fragment>
        );
        break;
      case 'yesno':
        const radioInputs = document.querySelectorAll(`.radio-${idRef.current}`);
        radioInputs.forEach((e: any) => {
          if (field.value === e.value) {
            e.checked = true;
          }
        });
        return (
          <React.Fragment key={`${idRef.current}-yesno`}>
            {field.view == 'Y' ?
              <div className="view-field text-start">{field.value}</div>
              :
              <div className="d-flex align-items-center">
                <div className="form-check me-3">
                  <input type="radio" data-prefix={dataprefix} data-required={field.required} className={`form-control form-check-input radio-${idRef.current}`}  {...field.properties} name={inputName} id={fieldid + '_yes'} value='Yes' defaultChecked={field.value == 'Yes'} />
                  <label className="form-check-label" htmlFor={fieldid + '_yes'}>Yes</label>
                </div>
                <div className="form-check">
                  <input type="radio" data-prefix={dataprefix} data-required={field.required} className={`form-control form-check-input radio-${idRef.current}`} {...field.properties} name={inputName} id={fieldid + '_no'} value='No' defaultChecked={field.value == 'No'} />
                  <label className="form-check-label" htmlFor={fieldid + '_no'}>No</label>
                </div>
              </div>
            }
          </React.Fragment>
        );
        break;
      case 'uploadfile':
        return (
            <React.Fragment key={`${idRef.current}-file`}>
              {field.view == 'Y' ?
                  <>
                  </>
                  :
                  <div className="position-relative text-center align-items-end">
                    <input type="file" tabIndex={field.tab_index} data-prefix={dataprefix} autoComplete="off" data-required={field.required} className={className} {...field.properties} name={inputName} id={fieldid + '_file'}/>
                  </div>
              }
            </React.Fragment>
        );
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className={'col-md-' + props.field.col}>
        <div className={"form-group " + props.field.type}>
          <div className={className}>
            {loadField(props.fieldName, props.field)}
            {(props.options.showTitle) ? <label className="form-label" htmlFor={props.fieldName}>{props.field.caption}</label> : null}
          </div>
        </div>
      </div>
    </>
  );

}

FieldComponent.defaultProps = defaultProps;

export default FieldComponent;