import React from "react";
import { Doughnut } from "react-chartjs-2";
import {Chart, ArcElement} from 'chart.js'
import { BiRupee } from "react-icons/bi";
Chart.register(ArcElement);

class DoughnutChart extends React.Component {
  state = {
    dataDoughnut: {
      // labels: ["Red", "Green",],
      datasets: [
        {
          data: [27, 73],
          backgroundColor: [
            "rgba(82, 217, 194, 1)",
            "rgba(88, 118, 203, 1)"
          ],
          hoverBackgroundColor: [
            "rgba(0, 161, 135, 1)",
            "rgba(18, 41, 110, 1)"
          ]
        }
      ]
    }
  };

  render() {
    return (
      <>
      <div className="row">
        <div className="col-md-7">
            <div className="doughnutChart">
                <Doughnut
                data={this.state.dataDoughnut}
                options={{ responsive: true }}
                />
            </div>
        </div>
        <div className="col-md-5">
            <div className="doughnutChartFund">
                <div className="FundsSanctioned">
                    <h4><BiRupee/> 5,12,60,950</h4>
                    <p>Funds Sanctioned</p>
                </div>
                <div className="FundsUtilized">
                    <h4><BiRupee/> 1,34,33,400</h4>
                    <p>Funds Utilized</p>
                </div>
                <div className="FundsPending">
                    <h4><BiRupee/> 3,78,27,550</h4>
                    <p>Funds Pending</p>
                </div>
            </div>
        </div>
      </div>        
      </>
    );
  }
}

export default DoughnutChart;
