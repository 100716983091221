import React, { useEffect, useRef, useState } from "react";
import "../../RdssForms/rdssforms.scss";
import { Storage } from '../../Core/Services/StorageService';
import { Http } from "../../Core/Services/HttpService";
import { useStateMounted } from '../../Core/Hooks';

export const FdDiscomName = () => {

  const [discomid, setDiscomId] = useState<any>(0);
  const [roleid, setRoleid] = useState<any>(0);
  const [discomName, setDiscomName] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useStateMounted<string>();

   useEffect(() => {
      setDiscomId(Storage.get('discom_id'));
      setRoleid(Storage.get('roleid'));
  }, [discomid, roleid]);

  const getDiscomName = () => {
    (async () => {
        setIsLoading(true);
        setError('');
        if (discomid) {
            const params = {
                discom_id: discomid
            };

            await Http.get(`/apiv1/fd-discom-name`, { params })
                .then((res) => {
                    if (res._resultflag && res.discom_name) {
                        if (res.discom_name) {
                            if (!res.discom_name.length) setError("No data found.");
                            setDiscomName(res.discom_name);
                        } else {
                            setError("Invalid data type.");
                        }
                    } else if (!res._resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError('System error occurred!! please try again.');
                })
                .finally(() => {
                    setIsLoading(false);
            });
        }
    })();
};

useEffect(() => {
  (async () => {
    getDiscomName();
  })();
}, [discomid]);

  return (
    <>
      {(discomName.length != '') ?
       <>
       ( {discomName} )
       </>
        : null
      }
    </>
  );
}
export default FdDiscomName;

