import React from "react";

class Icon extends React.PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73.651"
        height="66.05"
        viewBox="0 0 100 100"
      >
        <g
          id="Layer_1"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.25"
          transform="translate(.125 .125)"
        >
          <path className="st0" d="M67,24.2c-2.5,0-4.5-2-4.5-4.5V4.9l19.4,19.4H67z"/>
            <path d="M20.7,100c-4.1,0-7.4-3.3-7.4-7.4V7.4c0-4.1,3.3-7.4,7.4-7.4h40.4c0.1,0,0.2,0,0.2,0l0.1,0c0.1,0,0.1,0,0.2,0
                c0.1,0,0.1,0,0.1,0.1l0.1,0.1L62,0.2l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1l24,24c0,0,0.1,0.1,0.1,0.1l0.1,0.1l0.1,0.1
                c0,0.1,0,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1l-0.1,0.2l0.1,0c0,0.1,0,0.1,0,0.2v67c0,4.1-3.3,7.4-7.4,7.4H20.7z
                M20.7,3.1c-2.4,0-4.3,1.9-4.3,4.3v85.2c0,2.4,1.9,4.3,4.3,4.3h58.6c2.4,0,4.3-1.9,4.3-4.3V27.1H67c-2.8,0-5.5-1.7-6.7-4.2H25.8
                c-1.9,0-3.5-1.6-3.5-3.5v-7.1c0-1.9,1.6-3.5,3.5-3.5h33.8V3.1H20.7z M62.7,19.7c0,2.4,1.9,4.3,4.3,4.3h14.4L62.7,5.3V19.7z
                M25.8,11.9c-0.2,0-0.4,0.2-0.4,0.4v7.1c0,0.2,0.2,0.4,0.4,0.4h33.8l0-7.9H25.8z"/>
            <path d="M25.2,46.1c-1.6,0-2.9-1.3-2.9-2.9V32.6c0-1.6,1.3-2.9,2.9-2.9h10.5c1.6,0,2.9,1.3,2.9,2.9v10.5c0,1.6-1.3,2.9-2.9,2.9
                H25.2z M35.6,42.9V32.8H25.4v10.1H35.6z"/>
            <path d="M25.2,68.1c-1.6,0-2.9-1.3-2.9-2.9V54.6c0-1.6,1.3-2.9,2.9-2.9h10.5c1.6,0,2.9,1.3,2.9,2.9v10.5c0,1.6-1.3,2.9-2.9,2.9
                H25.2z M35.6,65V54.8H25.4V65H35.6z"/>
            <path d="M25.2,90.1c-1.6,0-2.9-1.3-2.9-2.9V76.7c0-1.6,1.3-2.9,2.9-2.9h10.5c1.6,0,2.9,1.3,2.9,2.9v10.5c0,1.6-1.3,2.9-2.9,2.9
                H25.2z M35.6,87V76.9H25.4V87H35.6z"/>
            <path d="M45.9,32.8c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6H45.9z"/>
            <path d="M45.9,38.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6H45.9z"/>
            <path d="M45.9,54.8c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H45.9z"/>
            <path d="M45.9,60.7c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H45.9z"/>
            <path d="M45.9,76.9c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H45.9z"/>
            <path d="M45.9,82.7c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H45.9z"/>
        </g>
      </svg>
      
    );
  }
}

export default Icon;
