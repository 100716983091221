/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import React, { useEffect } from 'react';
import { useVisibility } from '../Hooks';

function Visibility(props: any) {
    const { children, options } = props;
    const [ref, isVisible, setVisible] = useVisibility(options);
    return children(ref, isVisible, setVisible);
}
export default Visibility;
