import React from "react";

class Icon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="g1788"
        width="73.732"
        height="73.732"
        viewBox="0 0 73.732 73.732"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              id="path1798"
              fill="none"
              stroke="#fff"
              strokeWidth="2.5"
              d="M0 0h73.732v73.732H0z"
            ></path>
          </clipPath>
        </defs>
        <g id="g1790" transform="translate(26.623 18.332)">
          <path
            id="path1792"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M0 0h20.485"
          ></path>
        </g>
        <g id="g1794">
          <g id="g1796" clipPath="url(#clip-path)">
            <g id="g1802" transform="translate(20.737 27.493)">
              <path
                id="path1804"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M0 1.322v2.81a1.322 1.322 0 001.322 1.322h29.614a1.322 1.322 0 001.322-1.322v-2.81A1.322 1.322 0 0030.936 0H1.322A1.322 1.322 0 000 1.322z"
              ></path>
            </g>
            <g id="g1806" transform="translate(20.737 39.718)">
              <path
                id="path1808"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M0 1.322v2.81a1.322 1.322 0 001.322 1.322h29.614a1.322 1.322 0 001.322-1.322v-2.81A1.322 1.322 0 0030.936 0H1.322A1.322 1.322 0 000 1.322z"
              ></path>
            </g>
            <g id="g1810" transform="translate(31.73 52.72)">
              <path
                id="path1812"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M7.648 0H2.624A2.624 2.624 0 000 2.624v.147a2.624 2.624 0 002.624 2.624h5.024a2.624 2.624 0 002.624-2.624v-.147A2.624 2.624 0 007.648 0z"
              ></path>
            </g>
            <g id="g1814" transform="translate(11.124 1.08)">
              <path
                id="path1816"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M48.394 0h-45.3a3.09 3.09 0 00-3.09 3.09v7.827h51.484V3.09A3.09 3.09 0 0048.394 0z"
              ></path>
            </g>
            <g id="g1818" transform="translate(11.124 20.492)">
              <path
                id="path1820"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M0 0v43.661a3.09 3.09 0 003.09 3.09h27.788"
              ></path>
            </g>
            <g id="g1822" transform="translate(11.124 1.08)">
              <path
                id="path1824"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M49.6 65.92a3.091 3.091 0 001.887-2.847V3.091a3.09 3.09 0 00-3.09-3.09h-45.3a3.09 3.09 0 00-3.09 3.09v12"
              ></path>
            </g>
            <g id="g1826" transform="translate(15.755 4.315)">
              <path
                id="path1828"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M2.224 0A2.224 2.224 0 000 2.224a2.224 2.224 0 002.224 2.224 2.224 2.224 0 002.224-2.224A2.224 2.224 0 002.224 0z"
              ></path>
            </g>
            <g id="g1830" transform="translate(24.399 4.315)">
              <path
                id="path1832"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M2.224 0A2.224 2.224 0 000 2.224a2.224 2.224 0 002.224 2.224 2.224 2.224 0 002.224-2.224A2.224 2.224 0 002.224 0z"
              ></path>
            </g>
            <g id="g1834" transform="translate(33.043 4.315)">
              <path
                id="path1836"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M2.224 0A2.224 2.224 0 000 2.224a2.224 2.224 0 002.224 2.224 2.224 2.224 0 002.224-2.224A2.224 2.224 0 002.224 0z"
              ></path>
            </g>
            <g id="g1838" transform="translate(46.386 55.417)">
              <path
                id="path1840"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M12.348 6.573L1.142.103A.761.761 0 000 .762v12.939a.761.761 0 001.142.659l2.887-1.666a.888.888 0 011.213.325l2.187 3.789a.853.853 0 001.165.312l2.417-1.4a.853.853 0 00.315-1.16l-2.187-3.789a.888.888 0 01.325-1.213l2.886-1.667a.762.762 0 00-.002-1.318z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default Icon;
