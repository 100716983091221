/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import Cache from './CacheService';
import { Http } from './HttpService';

/**
 * Api Remove Cache In Store
 * @param key string.
 * @param group string.
 * @returns func() Promise\<T>
 */

export function ModelCacheRemove(key?: string, group?: string) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    return {
      value: function (...args: any[]) {
        let _key = key;
        if (_key && args.length) {
          for (let i = 0; i < args.length; i++) {
            let val = (args[i] instanceof Array || args[i] instanceof Object) ? JSON.stringify(args[i]) : args[i];
            val = val.replaceAll('.', '-');
            _key = _key.replaceAll('{' + i + '}', val);
          }
        }
        const next = () => {
          const call = descriptor.value.apply(this, args);
          return call.then((res: any) => {
            return res;
          });
        };
        if (_key) {
          return Cache.remove(_key, group).then(() => next());
        } else if (group) {
          return Cache.removeAll(group).then(() => next());
        } else {
          return next();
        }
      }
    };
  }
}

/**
 * Api Response Store In Cache
 * @param key string.
 * @param group string.
 * @returns func() Promise\<T>
 */
export function ModelCache(key: string, group?: string) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    return {
      value: function (...args: any[]) {
        let _key = key;
        if (args.length) {
          for (let i = 0; i < args.length; i++) {
            let val = (args[i] instanceof Array || args[i] instanceof Object) ? JSON.stringify(args[i]) : args[i];
            val = val.replaceAll('.', '-');
            _key = _key.replaceAll('{' + i + '}', val);
          }
        }
        const next = () => {
          const call = descriptor.value.apply(this, args);
          return call.then((res: any) => {
            Cache.set(_key, res, group);
            return res;
          });
        };
        return Cache.get(_key, group)
          .then((res) => {
            if (res) {
              return res;
            } else {
              return next();
            }
          });
      }
    };
  }
}

export enum ModelErrorType {
  CLIENT = 'client',
  SERVER = 'server'
}

/**
 * Model Error Entity
 * @export
 * @class MyModelError
 */
export class MyModelError {
  type: ModelErrorType;
  message: any;

  constructor(type: ModelErrorType, error: any) {
    Object.assign(this, error);
    this.type = type;
  }
}

/**
 * Model Entity
 * @export
 * @class MyModelEntity
 */
export class MyModelEntity {

  id!: string | number;

  constructor(data?: Partial<any>) {
    if (data) {
      this.id = data._id || 0;
    }
  }

  protected objectAssign<T extends Object>(target: T, data: any) {
    const properties = Object.keys(data) as Array<keyof T>;
    properties.forEach((property) => {
      const descriptor =
        Object.getOwnPropertyDescriptor(target, property)
        || Object.getOwnPropertyDescriptor(Object.getPrototypeOf(target), property)
        || {};
      const isWritable = descriptor ? descriptor.writable : true;
      // const isConfigurable = descriptor ? descriptor.configurable : true;
      if (isWritable && data[property] !== undefined) {
        target[property] = data[property];
      }
    });
  }
}

/**
 * Model Service
 * @export
 * @class MyModelService
 * @template T
 */
export class MyModelService<T> {

  private endpoint!: string;

  get url(): string {
    return this.endpoint;
  }

  setUrl(endpoint: string) {
    this.endpoint = endpoint;
  }

  find<T extends string | number>(id: T, option?: any): Promise<T> {
    return Http.get(this.url + '/' + id, option);
  };

}