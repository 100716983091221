import React from "react";
import { Link } from "react-router-dom";
import notFoundImg from "../images/404-not-found.jpg";

const NotFound = () => {  
    return(
        <>
            <div className="notFound">
                <img src={notFoundImg} className="img-fluid"/>
                <Link to="/" className="text-center d-block text-decoration-none mx-auto mt-5 backBtn">Back to Home</Link>
            </div>
        </>
    );
}

export default NotFound;