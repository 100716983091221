import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';

/* Route */
const routes: RouteProps[] = [
  {
    path: '',
    element: lazy(() => import('../Pages/Main')),
    index: true,
    private: true
  },
  {
    path: ':year/:quarter',
    element: lazy(() => import('../Pages/Main')),
    index: true,
    private: true
  },
  {
    path: 'dashboard',
    element: lazy(() => import('../Pages/Dashboard')),
    private: true
  },
  {
    path: 'input-form/:form/:year/:quarter',
    element: lazy(() => import('../Pages/InputForm')),
    private: true
  },
  {
    path: 'input-form/:form/:year/:quarter/:discomId',
    element: lazy(() => import('../Pages/InputForm')),
    private: true
  },
  /*{
    path: 'infrastructure/:year/:quarter',
    element: lazy(() => import('../Pages/Infrastructure')),
    private: true
  },
  {
    path: 'division-wise-loss/:year/:quarter',
    element: lazy(() => import('../Pages/DivisionWiseLosses')),
    private: true
  },
  {
    path: 'input-energy/:year/:quarter',
    element: lazy(() => import('../Pages/InputEnergy')),
    private: true
  },
  {
    path: 'received-source/:year/:quarter',
    element: lazy(() => import('../Pages/ReceivedSources')),
    private: true
  },
  {
    path: 'consumers-consumption/:year/:quarter',
    element: lazy(() => import('../Pages/ConsumersConsumption')),
    private: true
  },
  {
    path: 'feeder-levels/:year/:quarter',
    element: lazy(() => import('../Pages/DivisionFeederLosses')),
    private: true
  },
  {
    path: 'feeder-levels/:year/:quarter',
    element: lazy(() => import('../Pages/DivisionFeederLosses')),
    private: true
  },
  {
    path: 'dt-wise-loss/:year/:quarter',
    element: lazy(() => import('../Pages/DtWiseLoss')),
    private: true
  },
  {
    path: 'subsidy/:year/:quarter',
    element: lazy(() => import('../Pages/Subsidy')),
    private: true
  },
  {
    path: 'summary/:year/:quarter',
    element: lazy(() => import('../Pages/Summary')),
    private: true
  }*/
];

export default routes;