import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FieldComponent from "../../../FieldComponent";
import "./modalbox.scss";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import { multijsoninit, multijsonsop32init } from "../../../assets/js/formbuilder";
import MyTooltip from "./../../InputForms/Section1/MyTooltip";

const Modalbox = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);
    const [year, setYear] = useState<any>(props.year);

    useEffect(() => {
        setYear(props.year);
    },[props.year]);


    useEffect(() => {
        if (props.block === '4' && props.field2) {
            setNewBlock2fields(props.field2.addnew);
            const fields2 = props.field2.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
        }

        if (props.block === '7' && props.field1) {
            setNewBlock2fields(props.field1.addnew);
            const fields1 = props.field1.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields1);
        }
    }, [props.field2 , props.field1]);

    useEffect(() => {
        if(block2Fields.length) {
            const hasDelete = (block2Fields.filter((item:any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
    },[block2Fields]);

    const addItem = (block?: any) => {
        if (block === '4') {
            setBlock2fields((prev: any[]) => {
                return [...prev, { fields: newBlock2Fields, delete: false }];
            });
        }

        if (block === '7') {
            setBlock2fields((prev: any[]) => {
                return [...prev, { fields: newBlock2Fields, delete: false }];
            });
        }
        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        if (block === '4') {
            setBlock2fields((prev: any[]) => {
                const newFields = prev.map((item, index) => {
                    if (index === rindex) {
                        item.delete = true;
                    }
                    return item;
                })
                return newFields;
            });
        }

        if (block === '7') {
            setBlock2fields((prev: any[]) => {
                const newFields = prev.map((item, index) => {
                    if (index === rindex) {
                        item.delete = true;
                    }
                    return item;
                })
                return newFields;
            });
        }
        setTimeout(() => {
            multijsoninit();
            multijsonsop32init();
        }, 500);
    }

    const resetsubsidy = () => {
        // let v1 = document.querySelector('#pqsop_details_subsidy_fy_baseline');
        // console.log(v1);
        // if ( v1 ) v1.value = '';
    }
    
    switch (props.block) {
        case '1':
            return (
                <>
                    {/* Regulatory Assets */}
                    <div className="modal fade" id="RegulatoryAssets" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="col-lg-6 leftSection">
                                        <div className="modal-header">
                                            <h2 className="modal-title">Approved Regulatory Assets (INR Cr)</h2>
                                        </div>
                                        <div className="modal-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <tr>
                                                        <td style={{maxWidth:20}}>A</td>
                                                        <td>Opening balance of approved regulatory assets for FY-{year} (INR Cr)</td>
                                                        <td style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{maxWidth:20}}>B</td>
                                                        <td>Regulatory Assets approved by the regulator in tariff order for FY-{year} (INR Cr)</td>
                                                        <td style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{maxWidth:20}}>C</td>
                                                        <td>Past regulatory assets (if any) liquidated by the regulator in tariff order for FY-{year} (INR Cr)</td>
                                                        <td style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{maxWidth:20}}>D</td>
                                                        <td>Closing Balance of approved regulatory assets for FY-{year} ( A + B – C) (INR Cr)</td>
                                                        <td style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="modal-header">
                                            <h2 className="modal-title">Approved Uncovered Gap (INR Cr)</h2>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <tr>
                                                        <td style={{maxWidth:20}}>A</td>
                                                        <td>Opening balance of approved Uncovered gap for FY-{year} (INR Cr)</td>
                                                        <td  style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{maxWidth:20}}>B</td>
                                                        <td>Uncovered Gap approved by the regulator in tariff order for FY-{year} (INR Cr)</td>
                                                        <td  style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{maxWidth:20}}>C</td>
                                                        <td>Past uncovered gap (if any) liquidated by the regulator in tariff order for FY-{year}  (INR Cr)</td>
                                                        <td  style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{maxWidth:20}}>D</td>
                                                        <td>Closing Balance of approved uncovered gap for FY-{year} (Formula: A + B – C)  (INR Cr)</td>
                                                        <td  style={{maxWidth:130}}>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="formBtnSection">
                                        <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
            break;
        case '2':
            return (
                <>
                    {/* Subsidy Dues */}
                    <div className="modal fade" id="SubsidyDues" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Subsidy Dues</h2>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <th>Particulars</th>
                                                    <th>Amount (INR Cr)</th>
                                                    {/* <th>FY {year}</th> */}
                                                </thead>
                                                <tr>
                                                    <td>Total legacy subsidy dues as on 31 March 2021</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                                                    {/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Target for liquidation in FY {year} as per approved REF </td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                                                    {/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} /></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Actual liquidation in FY {year}</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                                                    {/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} /></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} /></td>
                                                    {/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} /></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Remarks</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                                                    {/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} /></td> */}
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <div className="formBtnSection text-start">
                                            <Link to="#" className="btn btnSubmit" onClick={() => resetsubsidy()} aria-label="Reset">Reset</Link>
                                        </div> */}
                                        <div className="formBtnSection text-end">
                                            <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        case '3':
            return (
                <>
                    {/* Metering Status of Subsidized Categories */}
                    <div className="modal fade" id="Meteringstatusofsubsidisedcatagories" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Metering Status of Subsidized Categories</h2>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <th>Subsidised Category Name<br />(As per Govt Order/ Tariff Order)</th>
                                                    <th>Total Consumers</th>
                                                    <th>No. of Metered Consumer</th>
                                                    <th>No. of Unmetered Consumers</th>
                                                </thead>
                                                <tr>
                                                    <td>Agricultural</td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Commercial</td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Domestic</td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[8].key} field={props.field1.childrens[8]} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Industrial</td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[9].key} field={props.field1.childrens[9]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[10].key} field={props.field1.childrens[10]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[11].key} field={props.field1.childrens[11]} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Others</td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[12].key} field={props.field1.childrens[12]} />
                                                    </td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[13].key} field={props.field1.childrens[13]} />
                                                    </td>
                                                    <td>

                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[14].key} field={props.field1.childrens[14]} />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="formBtnSection">
                                            <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )
        case '4':
            return (
                <>
                    {/* Subsidy billing and collection */}
                    <div className="modal fade" id="Subsidybillingandcollection" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Subsidy Billing and Collection (Quarterly Submission)</h2>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* <div className="row mb-3">
                                            <div className="col-md-3">
                                                <div className="form-control border-0 p-0">
                                                    <label className="mb-2">State/DISCOM :</label>
                                                    <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-control border-0 p-0">
                                                    <label className="mb-2">Reporting Quarter</label>
                                                    <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-control border-0 p-0">
                                                    <label className="mb-2">Date of subsidy bill</label>
                                                    <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-control border-0 p-0">
                                                    <label className="mb-2">Copy of Subsidy bill</label>
                                                    <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead className="align-middle text-center">
                                                    <th colSpan={3}>Particulars</th>
                                                    <th>Full cost tariff determination by SERC/JERC (on annual basis)</th>
                                                    <th>Per unit Subsidy declared by the State Government</th>
                                                    <th>Total energy forecasted for the category</th>
                                                    <th>Total advance subsidy amount raised to the State Govt.</th>
                                                    <th>Date of subsidy bill</th>
                                                    <th>Actual electricity supplied based on measurement through meters</th>
                                                    <th>Actual subsidy required from the State Govt.</th>
                                                    <th>Difference in advance and actual subsidy</th>
                                                    <th>Reconciled subsidy bill (Advance-Actual) bill raised to the State Govt.</th>
                                                    <th>Copy of Subsidy bill</th>
                                                    <th>&nbsp;</th>
                                                </thead>
                                                <tr className="text-center">
                                                    <td>Sl No.</td>
                                                    <td>Reporting Quarter</td>
                                                    <td>Consumer Categories</td>
                                                    <td>INR/kWh</td>
                                                    <td>INR/kWh</td>
                                                    <td>MUs</td>
                                                    <td>INR Cr</td>
                                                    <td>Date</td>
                                                    <td>MUs</td>
                                                    <td>INR Cr</td>
                                                    <td>INR Cr</td>
                                                    <td>INR Cr</td>
                                                    <td>Document</td>
                                                    <td>
                                                        <Link to="#" className="text-success" onClick={() => addItem(props.block)}><GrAddCircle className="gr-icon" /></Link>
                                                    </td>
                                                </tr>
                                                {
                                                    block2Fields.map((item: any, key: number) => {
                                                        return (item.delete === false) ? <>
                                                            <tr key={key}>
                                                                {/* <td>{(block2SrNo++) + 1}</td> */}
                                                                {
                                                                    item.fields.map((field: any, fkey: number) => {
                                                                        return <>
                                                                            <td key={key + '_' + fkey}>
                                                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                                        </>;
                                                                    })
                                                                }
                                                                <td>{(hasBlock2FieldsDelete) ? <Link to="#" onClick={() => removeItem(key, props.block)}><GrTrash className="gr-icon" /></Link> : null}</td>
                                                            </tr>
                                                        </>
                                                            : null;
                                                    })
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="formBtnSection">
                                        <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        case '5':
            return (
                <>
                    {/* Subsidy disbursal plan */}
                    <div className="modal fade" id="Subsidydisbursalplan" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="modal-header">
                                        <h2 className="modal-title">
                                            Subsidy Disbursal Plan
                                        </h2>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <table>
                                                <thead className="align-middle text-center">
                                                    <tr>
                                                        <th colSpan={7}>DISCOMs to assess consumer category-wise tariff subsidy in advance</th>
                                                        <th>DISCOM to share the quarterly subsidy disbursal plan with the State Government</th>
                                                        <th colSpan={2}>State Governments to release the assessed subsidy amount in advance to DISCOM(s) for every quarter within the first 15 days of the quarter</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={7}>Category wise subsidy assessed (INR Cr.)</th>
                                                        <th>Date of plan share</th>
                                                        <th colSpan={2}>Date and amount of advance subsidy release</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center">
                                                        <td>Qtr.</td>
                                                        <td>Ag</td>
                                                        <td>Dom</td>
                                                        <td>Com</td>
                                                        <td>Ind</td>
                                                        <td>Others</td>
                                                        <td>Total</td>
                                                        <td>Date</td>
                                                        <td>Date of release</td>
                                                        <td>Amount released <br /> (INR Cr.)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q1</td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[8].key} field={props.field1.childrens[8]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q2</td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[9].key} field={props.field1.childrens[9]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[10].key} field={props.field1.childrens[10]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[11].key} field={props.field1.childrens[11]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[12].key} field={props.field1.childrens[12]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[13].key} field={props.field1.childrens[13]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[14].key} field={props.field1.childrens[14]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[15].key} field={props.field1.childrens[15]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[16].key} field={props.field1.childrens[16]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[17].key} field={props.field1.childrens[17]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q3</td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[18].key} field={props.field1.childrens[18]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[19].key} field={props.field1.childrens[19]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[20].key} field={props.field1.childrens[20]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[21].key} field={props.field1.childrens[21]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[22].key} field={props.field1.childrens[22]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[23].key} field={props.field1.childrens[23]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[24].key} field={props.field1.childrens[24]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[25].key} field={props.field1.childrens[25]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[26].key} field={props.field1.childrens[26]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q4</td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[27].key} field={props.field1.childrens[27]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[28].key} field={props.field1.childrens[28]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[29].key} field={props.field1.childrens[29]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[30].key} field={props.field1.childrens[30]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[31].key} field={props.field1.childrens[31]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[32].key} field={props.field1.childrens[32]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[33].key} field={props.field1.childrens[33]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[34].key} field={props.field1.childrens[34]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[35].key} field={props.field1.childrens[35]} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[36].key} field={props.field1.childrens[36]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[37].key} field={props.field1.childrens[37]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[38].key} field={props.field1.childrens[38]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[39].key} field={props.field1.childrens[39]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[40].key} field={props.field1.childrens[40]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[41].key} field={props.field1.childrens[41]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[42].key} field={props.field1.childrens[42]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[43].key} field={props.field1.childrens[43]} />
                                                        </td>
                                                        <td>
                                                            <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[44].key} field={props.field1.childrens[44]} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={3}>DISCOM(s) to raise actual bill of subsidy dues to Finance Department of State Government on quarterly basis net of advanced subsidy paid for the quarter with a copy to the concerned SERC/JERC and Nodal Agencies within 60 days from end of quarter</th>
                                                        <th colSpan={2}>Finance department to ensure the balance payment of reconciled subsidy of the last quarter within 15 days of receipt of the subsidy bill from the DISCOM for the last quarter</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={3}>Date and amount of actual subsidy claim</th>
                                                        <th colSpan={2}>Status of quarterly reconcilliation</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center">
                                                        <td>Qtr.</td>
                                                        <td>Date of bills raised</td>
                                                        <td>Amount raised (INR Cr.)</td>
                                                        <td>Date</td>
                                                        <td>Amount (INR Cr)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q1</td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[3].key} field={props.field2.childrens[3]} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q2</td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[4].key} field={props.field2.childrens[4]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[5].key} field={props.field2.childrens[5]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[6].key} field={props.field2.childrens[6]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[7].key} field={props.field2.childrens[7]} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q3</td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[8].key} field={props.field2.childrens[8]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[9].key} field={props.field2.childrens[9]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[10].key} field={props.field2.childrens[10]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[11].key} field={props.field2.childrens[11]} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Q4</td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[12].key} field={props.field2.childrens[12]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[13].key} field={props.field2.childrens[13]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[14].key} field={props.field2.childrens[14]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[15].key} field={props.field2.childrens[15]} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[16].key} field={props.field2.childrens[16]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[17].key} field={props.field2.childrens[17]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[18].key} field={props.field2.childrens[18]} /></td>
                                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[19].key} field={props.field2.childrens[19]} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <div className="table-responsive">
                                            <table className="w-100">
                                                <thead>
                                                    <tr><th>An annual reconciliation activity shall be carried out at the end of financial year by the DISCOMs and the State Finance Department for all the claims during the year</th></tr>
                                                    <tr><th>Status of annual reconcilliation</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr><td>Date <FieldComponent options={{ showTitle: false }} fieldName={props.field3.childrens[0].key} field={props.field3.childrens[0]} /></td></tr>
                                                    <tr><td>Amount (INR Cr.) <FieldComponent options={{ showTitle: false }} fieldName={props.field3.childrens[1].key} field={props.field3.childrens[1]} /></td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="formBtnSection">
                                            <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )
        case '6':
            return (
                <>
                    {/* Billing and Collection details of state govt depts */}
                    <div className="modal fade" id="Undertakingregarding" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Billing and Collection details of state govt depts</h2>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <th>Type of Office</th>
                                                    <th>Amount Billed (INR Cr.)</th>
                                                    <th className="with-tooltip">Amount Received (INR Cr.)
                                                    <MyTooltip placement="left">
                                                        <p>Payment Received in the previous and current FY, for the bill raised, to be entered</p>
                                                    </MyTooltip>
                                                    </th>
                                                </thead>
                                                <tr>
                                                    <td>Govt. Dept</td>
                                                    <td>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} />
                                                    </td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[9].key} field={props.field1.childrens[9]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Attached Offices</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[10].key} field={props.field1.childrens[10]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Urban Local Bodies</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[11].key} field={props.field1.childrens[11]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Rural Local Bodies</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[12].key} field={props.field1.childrens[12]} /></td>
                                                </tr>

                                                <tr>
                                                    <td>Autonomous Bodies</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[13].key} field={props.field1.childrens[13]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Boards</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[14].key} field={props.field1.childrens[14]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Corporations</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[15].key} field={props.field1.childrens[15]} /></td>
                                                </tr>

                                                <tr>
                                                    <td>Others</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[16].key} field={props.field1.childrens[16]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[8].key} field={props.field1.childrens[8]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[17].key} field={props.field1.childrens[17]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Balance</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[18].key} field={props.field1.childrens[18]} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="formBtnSection">
                                            <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        case '7':
            return (
                <>
                    {/* Payment of Dues */}
                    <div className="modal fade" id="PaymentofDues" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="row gx-0">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Payment of dues</h2>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Details of DISCOM's payments of dues to a generating company or transmission company or a trading company as per LPS rules 2022</p>
                                        <div className="table-responsive paymentModalTable">
                                            <table className="table table-striped">
                                                    <tr className="text-center">
                                                        <td>GENCO Dues</td>
                                                        <td>Current bills payment (INR Cr.)</td>
                                                        <td>EMI amount, if any (INR Cr.)</td>
                                                        <td>Overdues as on date (INR Cr.)</td>
                                                        <td style={{maxWidth:"30px"}}>
                                                            <Link to="#" onClick={() => addItem(props.block)} className="text-success"><GrAddCircle className="gr-icon" /></Link>
                                                        </td>
                                                    </tr>
                                               
                                                    {
                                                        block2Fields.map((item: any, key: number) => {
                                                            return (item.delete === false) ? <>
                                                                <tr key={key}>
                                                                    {
                                                                        item.fields.map((field: any, fkey: number) => {
                                                                            return <>
                                                                                <td key={key + '_' + fkey}>
                                                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                                            </>;
                                                                        })
                                                                    }
                                                                    <td>{(hasBlock2FieldsDelete) ? <Link to="#" onClick={() => removeItem(key, props.block)}><GrTrash className="gr-icon" /></Link> : null}</td>
                                                                </tr>
                                                            </>
                                                                : null;
                                                        })
                                                    }
                                                
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} /></td>
                                                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="formBtnSection">
                                            <Link to="#" className="btn btnSubmit" data-bs-dismiss="modal" aria-label="Close">Save</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        default:
            return (
                <></>
            )
            break;
    }
}

export default Modalbox;