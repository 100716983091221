import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Sop34 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    const [year, setYear] = useState<any>(props.year);
    const [roleid, setRoleid] = useState<any>(0);

    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);

    return (
        <>
        {/* SoP-3.4 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara SoP">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">SoP-3.4</p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[223] && fields[223].value ? fields[223].value : ''} name="formdata[pqsop_details][sop34_nodal]" id="pqsop_details_sop34_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][sop34_remarks_nodal]" id="pqsop_details_sop34_remarks_nodal" defaultValue={fields[238] && fields[238].value ? fields[238].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][sop34_document_nodal]" id="pqsop_details_sop34_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[253].value != '' ?
                                            <a href={fields[253].value} title={fields[253].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>
                                    {fields[223].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[223] && fields[223].value ? fields[223].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[238].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[238] && fields[238].value ? fields[238].value : ''}</div>
                                    {/* : null
                                               } */}

                                    {fields[253].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[253] && fields[253].value ? fields[253].value : ''} title={fields[253] && fields[253].value ? fields[253].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }
                                    </>
                                }
                                </> : null
                            }

                        </div>
                        {(props.tooltip !== undefined && props.tooltip === false)
                            ? null
                            : <MyTooltip placement="left">
                                <ul>
                                    <li>Relevant Extract from Tariff order and True up order(s)</li>
                                    <li>Liquidation plan wherever applicable</li>
                                </ul>
                            </MyTooltip>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td>Outstanding regulatory assets/ uncovered revenue gap as on 31st March {year}</td>
                <td style={{ width: props.width + 'px' }}>
                    <Modalbox field1={fields[192]} block="1" year={year} />
                    <Link to="" className="btn-warning btn d-block mx-auto w-100" data-bs-toggle="modal" data-bs-target="#RegulatoryAssets">Regulatory Assets</Link>
                </td>
                <td style={{ width: props.width + 'px' }}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[46].key} field={fields[46]} />
                </td>
                <td style={{ width: props.width + 'px' }}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[45].key} field={fields[45]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>In case of any existing regulatory assets/uncovered revenue gap, whether a plan for liquidation of same in seven years (Max)  is in place?</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[48].key} field={fields[48]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[50].key} field={fields[50]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[53].key} field={fields[53]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Whether the liquidation plan has been incorporated in Results Evaluation Framework with adequate weightage?</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[52].key} field={fields[52]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[54].key} field={fields[54]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[55].key} field={fields[55]} />
                </td>
                <td></td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[56].key} field={fields[56]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Sop34;