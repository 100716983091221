import React, { useState, useEffect, useRef } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

export interface TooltipProps {
  icon?: any;
  defaultShow?: boolean;
  children: any;
}

const MyTooltip = (props: TooltipProps) => {

  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState<any>();
  const [childContent, setChildContent] = useState<any>();

  useEffect(() => {
    setIcon(props.icon);
  }, [props.icon]);

  useEffect(() => {
    if (props.defaultShow) {
      setShow(true);
    }
  }, [props.defaultShow]);

  useEffect(() => {
    setChildContent(props.children);
  }, [props.children]);

  return <>
    <button type="button" className="btn border-0 py-0" ref={target} onClick={() => setShow(!show)}>
      {(icon) ? icon : <BsFillInfoCircleFill />}
    </button>
    <Overlay target={target.current} show={show} placement="left" rootClose={true}>
      {(props) => (
        <Tooltip id="UploadTool" {...props}>
          {childContent}
        </Tooltip>
      )}
    </Overlay>
  </>
}

export default MyTooltip;