import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect } from "../../assets/js/formbuilder";
import { Http } from '../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';
import InnerTemplate from "../../Components/InnerTemplate/InnerTemplate";
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { useStateMounted } from '../../Core/Hooks';
import { Alert} from 'react-bootstrap';
import FieldComponent from "../../FieldComponent";
import { Storage } from "../../Core/Services/StorageService";
import FdDiscomName from "./FdDiscomName";

const Block1 = (props: any) => {

    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {

        if (block1Fields.length < 100) {
            setBlock1fields((prev: any[]) => {
                return [...prev, { fields: newBlock1Fields, delete: false }];
            });
        }

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    setTimeout(() => {
        initselect();
        multijsoninit();
    }, 500);


    return (
        <>
            <table className="table caption-top mt-4">
                <caption className="blankPara">List of Contractors</caption>
                <thead>
                    <th style={{ width: "50px" }}>S.No</th>
                    <th style={{ width: "550px" }}>Package No. - Agency </th>
                    <th style={{ width: "550px" }}>LOA Date</th>
                    {props.view != 'Y' ?
                        <th className='text-end pe-1' style={{ width: "50px" }}>
                            <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        </th>
                        : null
                    }
                </thead>
                <tbody>
                    {
                        block1Fields.map((item: any, key: number) => {
                            return (item.delete === false) ? <>
                                <tr key={key}>
                                    <td>{(block1SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                            </>;
                                        })
                                    }

                                    {props.view != 'Y' ?
                                        <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                        : null
                                    }
                                </tr>
                            </>
                                : null;
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

const Block2 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    return (
        <>
            <table className="table caption-top mt-4">
                <caption className="blankPara">Bank Mandate Details</caption>
                <thead>
                    <th>Name of Discom</th>
                    <th>TSA No.</th>
                    <th>Name of Account</th>
                    <th>IFSC Code</th>
                    {/* {props.view != 'Y' ?
                    <th className='text-end pe-1' style={{ width: "50px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                 :null
                } */}
                </thead>
                <tbody>
                    {
                        block2Fields.map((item: any, key: number) => {
                            return (item.delete === false) ? <>
                                <tr key={key}>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} /></td>
                                            </>;
                                        })
                                    }

                                    {/* {props.view != 'Y' ?
                                <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                :null
                            } */}
                                </tr>
                            </>
                                : null;
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

const RequestForFundForm = () => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const formData: any = useRef(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getreleasefundid, setReleaseFundId] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
    const [isDgSignatureAccepted, setIsDgSignatureAccepted] = useState<any>(0);
    const [view, setView] = useState<any[]>([]);
    const layout = useLayout();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        (async () => {
            if (location.state) {
                getDocumentation(location.state.data);
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setReleaseFundId(location.state.id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
            }
            else {
                navigate('/fund-disbursal');
            }
        })();
    }, [getcomponentid, gettrancheid]);

    const navtab = () => {
        setPageLink('/fund-disbursal-link');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
            }
        })();
    }, [getpagelink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (roleid == 2 || roleid == 3 || roleid == 4) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let myFormData = new FormData(formData.current);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", getreleasefundid);
            myFormData.append('tranche_id', gettrancheid);
            myFormData.append('component_id', getcomponentid);
            myFormData.append("aplication_tracker_id", getaplicationtrackerid);
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                        navigate('/fund-disbursal-link', { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
                    }
                });
        }
    }

    const getFields = () => {
        (async () => {
            setIsLoaded(true);
            setError('');
            setFields([]);
            if (getaplicationtrackerid) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 18,
                    discom_user_id: discomuserid,
                    application_id: getaplicationtrackerid,
                    component_id: getcomponentid,
                    tranche_id: gettrancheid
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(true);
                                setFields(res.fields);
                                setSubmittedStatus(res.submittedStatus);
                                setIsDgSignatureAccepted(res.isDgSignatureAccepted);
                                setView(res.view);
                                layout.set({ fieldsresult: res });
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [getaplicationtrackerid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Request for Fund Release</Link></li>
                            <li>Request letter for fund release form</li>
                            <FdDiscomName />
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <>
                                        {error && (
                                            <>
                                                <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
                                            </>
                                        )}

                                        <form noValidate onSubmit={onSubmit} ref={formData}>
                                            {
                                                ((!error) && fields?.length > 0) && (
                                                    <>
                                                        <div className="RevenueDetailsTable mt-4 pfForm overflow-hidden">
                                                            <div className="caption-top">
                                                                <caption className="blankPara w-100 d-block mb-3">Proforma Request letter for release of funds</caption>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">                                                                    
                                                                        <label>Grant No./ Scheme Code</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Name of Utility</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[14].key} field={fields[14]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">                                                                    
                                                                        <label>State</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[15].key} field={fields[15]} />
                                                                    </div>
                                                                    <div className="form-group">                                                                    
                                                                        <label>Work Type</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group"> 
                                                                        <label>Tranche of work</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                                                                    </div>
                                                                    <div className="form-group">                                                                    
                                                                        <label>Fund percentage Requested</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">      
                                                                        <label>Fund Amount Requested (INR)</label>
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pfForm RevenueDetailsTable overflow-hidden mt-4">
                                                            <div className="caption-top">
                                                                <caption className="blankPara w-100 d-block mb-3">
                                                                    <div className='row align-items-center'>
                                                                        <div className='col-md-6'>Referred Sanction Letter Details</div>
                                                                        <div className='col-md-4'><label>Supporting Documents</label></div>
                                                                        <div className='col-md-2'><FieldComponent options={{ showTitle: false }} fieldName={fields[16].key} field={fields[16]} /></div>
                                                                    </div>
                                                                </caption>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Sanction Letter No.</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Sanction Letter Date</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Sanctioned Total Amount (INR)</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Sanctioned Work</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Sanctioned amount for {getcomponentname} (INR)</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Sanction meeting no.</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Sanction meeting date</label>
                                                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive RevenueDetailsTable">
                                                            <Block1 field={fields[11]} view={view} />
                                                        </div>
                                                        <div className="table-responsive RevenueDetailsTable">
                                                            <Block2 field={fields[12]} view={view} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="formBtnSection justify-content-between">
                                                <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab()}>Back</Link>
                                                {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4) && isDgSignatureAccepted != 1) ?
                                                    <button className="btnDraft" type="submit">Save & Back</button>
                                                    : null
                                                }
                                            </div>
                                        </form>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    )
}
export default RequestForFundForm;