import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import { init, validatefrm, multijsoninit, initselect } from "../../assets/js/formbuilder";
import { Http } from '../../Core/Services/HttpService';

// Images
import Arrow from "../../../images/right-arrow.svg";
import FieldComponent from "../../FieldComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { BiUpload } from 'react-icons/bi';
import { Storage } from "../../Core/Services/StorageService";

const ChildInput = (props: { fields: any, pIndex?: number }) => {

    const [fields, setFields] = useState<string[]>([]);

    useEffect(() => {
        if (props.fields.length) {
            setFields(props.fields);
        }
    }, [props.fields]);

    return (
        <>
            {
                (fields.length)
                    ? fields.map((field: any, index) => (
                        <td key={props.pIndex + '-' + index + '-' + field.key}>
                            <FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} />
                        </td>
                    ))
                    : null
            }
        </>
    );
}

type AccordianBlockProps = {
    title: string;
    apiurl: string;
    accordianId: string;
    class1: string;
    class2: string;
    discription?: any;
    tableHead?: any;
    refresh?: boolean;
};

const AccordianBlock = (props: AccordianBlockProps) => {
    const layout = useLayout();
    const [error, setError] = useState(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [tableHead, setTableHead] = useState<React.ReactNode>(null);
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Revenue deferred by SERC as per tariff order for the relevant FY
        </Tooltip>
    );
    const getFields = () => {
        (async () => {
            setFields([]);
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    // layout.set({ api: props.apiurl, 'test': 'finac' });
                    layout.set({ submittedStatus: result });
                    setTimeout(() => {
                        init();
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    };

    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("file_to_upload", e.target.files[0]);
        formData.append("roleid", roleid);
        formData.append("discom_id", discomid);

        let searchParams = new URLSearchParams(props.apiurl);
        let formID:any = searchParams.get('form_id');
        formData.append("form_id", formID);
        
        setFields([]);
        Http.post('/apiv1/finincialupload', formData, config)
            .then((result) => {
                setFields(result.fields);
                setTimeout(() => {
                    initselect();
                    multijsoninit();
                }, 2000);
            });
    }

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        if (props.tableHead) {
            setTableHead(props.tableHead);
        }
    }, [props.tableHead])

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            fields ?
                <>
                    <div className="accordion-item">
                        
                        <h3 className="accordion-header" id="RevenueDetails">
                            <button className={'accordion-button ' + props.class1} type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.accordianId} aria-expanded="true" aria-controls={props.accordianId}>
                                {props.title}
                            </button>
                        </h3>
                        <div id={props.accordianId} className={'accordion-collapse collapse ' + props.class2} aria-labelledby="RevenueDetails" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {props.discription}
                                <div className="table-responsive RevenueDetailsTable">
                                    <table className="table">
                                        {tableHead}
                                        <tbody>
                                            {
                                                (fields.length)
                                                    ? fields.map((field: any, index) => (
                                                        <tr key={index + '-' + field.key} className="activeRow">
                                                            <td className={field.class1}>{field.key}</td>
                                                            <td className={field.class2}>{field.caption}
                                                                {field.key == 'C1' ?
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderTooltip}
                                                                    >
                                                                        <Link to="" className="text-decoration-none text-black">*</Link>
                                                                    </OverlayTrigger>
                                                                    : null}
                                                            </td>
                                                            {
                                                                (field.childrens)
                                                                    ? <ChildInput pIndex={index} fields={field.childrens} />
                                                                    : null
                                                            }
                                                        </tr>
                                                    ))
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null
        );
    }
}

const DivisionWiseLossesForm = (props?: any) => {
    const layout = useLayout();
    const [year, setYear] = useState<any>(props.year);
    const [refresh, setRefresh] = useState(false);
    const [roleid, setRoleid] = useState<any>(0);
    const [act, setAct] = useState<string>(props.act);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState<any[]>([]);

    useEffect(() => {
        layout.set({ pdfParams: { form_ids: '1,2,3,4,5' } });
        props.tblkey('fpl_revenue_details');
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    useEffect(() => {
        setAct(props.act);
    }, [props.act]);

    const tableHead = <>
        <thead>
            <tr>
                <th scope="col" style={{ width: 50 }}>Sl.No.</th>
                <th scope="col" style={{ width: 250 }}>Perameters</th>
                <th scope="col" className="text-center">Total</th>
                <th scope="col" className="text-center">Covered during in audit</th>
                <th scope="col" className="text-center">Verified by Auditor in sample check</th>
                <th scope="col" className="text-center">Remarks</th>
            </tr>
        </thead>
    </>;

    const balanceSheetTableHead = <>
        <thead>
            <tr>
                <th scope="col" style={{ width: 50 }}>Sl.No.</th>
                <th scope="col" style={{ width: 250 }}>Total</th>
                <th scope="col" className="text-center">66kV and above</th>
                <th scope="col" className="text-center">33KV</th>
                <th scope="col" className="text-center">11/22kV</th>
                <th scope="col" className="text-center">LT</th>
            </tr>
        </thead>
    </>;

    return (
        <>
            <div className="mainWrap">
                <section className="financialForm">
                    <div className="financialMainFrom">
                        {/* <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
                            <li className="nav-item col-md-4" role="presentation">
                                <button className="nav-link active" onClick={() => props.tblkey('fpl_revenue_details')} id="ProfitLossTab" data-bs-toggle="tab" data-bs-target="#ProfitLoss" type="button" role="tab" aria-controls="ProfitLoss" aria-selected="true">Profit and Loss</button>
                            </li>
                            <li className="nav-item col-md-4" role="presentation">
                                <button className="nav-link" id="BalanceSheetTab" onClick={() => props.tblkey('fbs_total_assets')} data-bs-toggle="tab" data-bs-target="#BalanceSheet" type="button" role="tab" aria-controls="BalanceSheet" aria-selected="false">Balance Sheet</button>
                            </li>
                            <li className="nav-item col-md-4" role="presentation">
                                <button className="nav-link" id="KeyPerformanceTab" onClick={() => props.tblkey('fpl_key_parameters')} data-bs-toggle="tab" data-bs-target="#KeyPerformance" type="button" role="tab" aria-controls="KeyPerformance" aria-selected="false">Key Performance Indicators</button>
                            </li>
                        </ul> */}
                        {/* <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="ProfitLoss" role="tabpanel" aria-labelledby="ProfitLossTab"> */}
                                <div className="accordion" id="accordionExample">
                                    {
                                        roleid == 0 ? null :
                                            <>
                                                <AccordianBlock tableHead={tableHead} apiurl={"/apiv1/formfields/?&form_id=52&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="" class1="" class2="show" accordianId="revenueDetails" />
                                                <AccordianBlock tableHead={balanceSheetTableHead} apiurl={"/apiv1/formfields/?&form_id=53&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="" class1="" class2="show" accordianId="expenditureDetails" />
                                            </>
                                    }
                                </div>
                            {/* </div>   
                        </div> */}
                    </div>
                </section>

            </div>
        </>
    );
};

export default DivisionWiseLossesForm;