import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../RdssForms/rdssforms.scss";
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';

import qs from 'qs';

// Images
import Arrow from "../../../images/right-arrow.svg";
import FieldComponent from "../../../FieldComponent";
import { Alert } from "react-bootstrap";
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import { BiDownload, BiUpload } from 'react-icons/bi';
import { useStateMounted } from "../../../Core/Hooks";
import { Storage } from "../../../Core/Services/StorageService";
import MyTooltip from "../../MyTooltip";

const ChildInput = (props: { fields: any, pIndex?: number }) => {
    const [fields, setFields] = useState<string[]>([]);
    useEffect(() => {
        if (props.fields.length) {
            setFields(props.fields);
        }
    }, [props.fields]);
    return (
        <>
            {
                (fields.length)
                    ? fields.map((field: any, index) => (
                        <td key={props.pIndex + '-' + index + '-' + field.key}>
                            <FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} />
                        </td>
                    ))
                    : null
            }
        </>
    );
}

type AccordianBlockProps = {
    title: string;
    apiurl: string;
    accordianId: string;
    class1: string;
    class2: string;
    discription?: any;
    tableHead?: any;
    refresh?: boolean;
    sampleLink?: string;
};

const AccordianBlock = (props: AccordianBlockProps) => {
    const layout = useLayout();
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [tableHead, setTableHead] = useState<React.ReactNode>(null);
    const [sampleFileLink, setSampleFileLink] = useStateMounted<any>(null);
    const [error, setError] = useStateMounted<string>();
    // const getFields = () => {
    //     (async () => {
    //         setFields([]);
    //         let footerspinner: any = document.getElementById('footer-spinner');
    //         if (footerspinner)
    //             footerspinner.classList.remove('d-none');
    //         await Http.get(props.apiurl)
    //             .then((result) => {
    //                 setIsLoaded(true);
    //                 setFields(result.fields);
    //                 // layout.set({ api: props.apiurl, 'test': 'finac' });
    //                 layout.set({ submittedStatus: result });
    //                 // setTimeout(() => {
    //                 //     init();
    //                 // }, 2000);
    //             })
    //             .catch((error) => {
    //                 setIsLoaded(true);
    //                 setError(error);
    //             }).finally(() => {
    //                 footerspinner.classList.add('d-none');
    //             })
    //     })();
    // };

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner)
                footerspinner.classList.remove('d-none');
            Http.get(props.apiurl)
                .then((res) => {
                    if (res.resultflag && res.fields) {
                        if (res.fields && Array.isArray(res.fields)) {
                            if (!res.fields.length) setError("No data found.");
                            setIsLoaded(true);
                            setFields(res.fields);
                            layout.set({ submittedStatus: res });

                        } else {
                            setError('System error occurred!! please try again.');
                            setIsLoaded(true);
                        }
                        setIsLoaded(true);
                    } else if (!res.resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');

                    }
                    setIsLoaded(true);
                }).catch((error) => {
                    setError('System error occurred!! please try again.');
                    setIsLoaded(true);

                })
                .finally(() => {
                    setIsLoaded(true);;
                    footerspinner.classList.add('d-none');
                });
        })();
    };
    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("file_to_upload", e.target.files[0]);
        formData.append("roleid", roleid);
        formData.append("discom_id", discomid);

        let searchParams = new URLSearchParams(props.apiurl);
        let formID: any = searchParams.get('form_id');
        formData.append("form_id", formID);

        setFields([]);
        Http.post('/apiv1/finincialupload', formData, config)
            .then((result) => {
                setFields(result.fields);
                // setTimeout(() => {
                //     initselect();
                //     multijsoninit();
                // }, 2000);
                console.log("File uploaded successfully");
            });
    }
    useEffect(() => {
        if (fields && fields.length) {
            setTimeout(() => {
                inputEventChecker.removeAllEvent();
                init();
                // initselect();
                // multijsoninit();
            }, 1500);
        }
    }, [fields]);

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        if (props.tableHead) {
            setTableHead(props.tableHead);
        }
    }, [props.tableHead])

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        let chkUrl = window.location.pathname;
        if (props.sampleLink && chkUrl == '/input-form') {
            setSampleFileLink(props.sampleLink);
        }
    }, [props.sampleLink]);

    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            fields ?
                <>
                    <div className="accordion-item">
                        {(sampleFileLink) && (
                            <div className='col-md-2 float-end'>
                                <div className="form-field">
                                    <div className="fileUpload position-relative text-center">
                                        <div className="downEqule ms-2">
                                            <div>
                                                <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                                            </div>
                                        </div>
                                        <div className="downEqule ms-2">
                                            <input type="file" onChange={handleFileInput} data-prefix="fpl_expenditure_details" className="form-control" />
                                            <div className="customUpload justify-content-center flex-wrap w-50">
                                                <span><BiUpload /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <h3 className="accordion-header" id="RevenueDetails">
                            <button className={'accordion-button ' + props.class1} type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.accordianId} aria-expanded="true" aria-controls={props.accordianId}>
                                {props.title}
                            </button>
                        </h3>
                        <div id={props.accordianId} className={'accordion-collapse collapse ' + props.class2} aria-labelledby="RevenueDetails" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {props.discription}
                                <div className="table-responsive RevenueDetailsTable">
                                    <table className="table">
                                        {tableHead}
                                        <tbody>
                                            {
                                                ((!error) && (fields.length))
                                                    ? fields.map((field: any, index) => (
                                                        <tr key={index + '-' + field.key} className="activeRow">
                                                            {
                                                                ['G_not', 'G1_not', 'N_not', 'N1_not'].includes(field.key) ? <td className={field.class1}></td> : <td className={field.class1}>{field.key}</td>
                                                            }
                                                            <td className={field.class2}>{field.caption} {(field.info) ? <MyTooltip placement="bottom">{field.info}</MyTooltip> : null}</td>
                                                            {
                                                                (field.childrens)
                                                                    ? <ChildInput pIndex={index} fields={field.childrens} />
                                                                    : null
                                                            }
                                                        </tr>
                                                    ))
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null
        );
    }
}

const FinancialForm = (props?: any) => {
    const layout = useLayout();
    const [year, setYear] = useState<any>(props.year);
    const [refresh, setRefresh] = useState(false);
    const [roleid, setRoleid] = useState<any>(0);
    const [act, setAct] = useState<string>(props.act);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState<any[]>([]);

    useEffect(() => {
        layout.set({ pdfParams: { form_ids: '1,2,3,4,5' } });
        props.tblkey('fpl_revenue_details');
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    useEffect(() => {
        setAct(props.act);
    }, [props.act]);

    const tableHead = <>
        <thead>
            <tr>
                <th scope="col" style={{ width: 50 }}>Sl.No.</th>
                <th scope="col">Items</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 1</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 2</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 3</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 4</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Cumulative</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Audited Annual</th>
                <th scope="col" className="text-center" style={{ minWidth: 160, maxWidth: 160 }}>Remarks</th>
            </tr>
        </thead>
    </>;

    const balanceSheetTableHead = <>
        <thead>
            <tr>
                <th scope="col" style={{ width: 50 }}>Sl.No.</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }}>Items</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 1</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 2</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 3</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Quarter 4</th>
                <th scope="col" style={{ minWidth: 160, maxWidth: 160 }} className="text-center">Audited Annual</th>
                <th scope="col" className="text-center" style={{ minWidth: 160, maxWidth: 160 }}>Remarks</th>
            </tr>
        </thead>
    </>;


    return (
        <>
            <div className="mainWrap">

                <section className="financialForm">
                    {/* <div className="year">
                        <label>Select Year</label>
                        <select className="form-select" aria-label="Default select example">
                            <option value="2022" selected>2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                        <div className="inputBtn">
                            <span><img src={Arrow} /></span>
                            <input type="submit" />
                        </div>
                    </div> */}
                    <div className="financialMainFrom">
                        <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
                            <li className="nav-item col-md-4" role="presentation">
                                <button className="nav-link active" onClick={() => props.tblkey('fpl_revenue_details')} id="ProfitLossTab" data-bs-toggle="tab" data-bs-target="#ProfitLoss" type="button" role="tab" aria-controls="ProfitLoss" aria-selected="true">Profit and Loss</button>
                            </li>
                            <li className="nav-item col-md-4" role="presentation">
                                <button className="nav-link" id="BalanceSheetTab" onClick={() => props.tblkey('fbs_total_assets')} data-bs-toggle="tab" data-bs-target="#BalanceSheet" type="button" role="tab" aria-controls="BalanceSheet" aria-selected="false">Balance Sheet</button>
                            </li>
                            <li className="nav-item col-md-4" role="presentation">
                                <button className="nav-link" id="KeyPerformanceTab" onClick={() => props.tblkey('fpl_key_parameters')} data-bs-toggle="tab" data-bs-target="#KeyPerformance" type="button" role="tab" aria-controls="KeyPerformance" aria-selected="false">Key Performance Indicators</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="ProfitLoss" role="tabpanel" aria-labelledby="ProfitLossTab">
                                <div className="accordion" id="accordionExample">
                                    {
                                        roleid == 0 ? null :
                                            <>
                                                <AccordianBlock tableHead={tableHead} apiurl={"/apiv1/formfields/?&form_id=1&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="Revenue Details" class1="" class2="show" accordianId="revenueDetails" sampleLink={process.env.REACT_APP_API_ENDPOINT + '/uploads/rdss-excel-sample/financial-revenue-details.xlsx'} />
                                                <AccordianBlock tableHead={tableHead} apiurl={"/apiv1/formfields/?&form_id=2&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="Expenditure Details" class1="" class2="show" accordianId="expenditureDetails" sampleLink={process.env.REACT_APP_API_ENDPOINT + '/uploads/rdss-excel-sample/financial-expenditure-details.xlsx'} />
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="BalanceSheet" role="tabpanel" aria-labelledby="BalanceSheetTab">
                                <div className="accordion" id="accordionExample">
                                    {
                                        roleid == 0 ? null :
                                            <>
                                                <AccordianBlock tableHead={balanceSheetTableHead} apiurl={"/apiv1/formfields/?&form_id=3&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="Total Assets" class1="" class2="show" accordianId="revenueDetails" sampleLink={process.env.REACT_APP_API_ENDPOINT + '/uploads/rdss-excel-sample/financial-total-assets.xlsx'} />
                                                <AccordianBlock tableHead={balanceSheetTableHead} apiurl={"/apiv1/formfields/?&form_id=4&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="Total Equity and Liabilities" class1="" class2="show" accordianId="expenditureDetails" sampleLink={process.env.REACT_APP_API_ENDPOINT + '/uploads/rdss-excel-sample/financial-total-equity-liabilities.xlsx'} />
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="KeyPerformance" role="tabpanel" aria-labelledby="KeyPerformanceTab">
                                <div className="accordion" id="accordionExample">
                                    {
                                        roleid == 0 ? null :
                                            <>
                                                <AccordianBlock tableHead={tableHead} apiurl={"/apiv1/formfields/?&form_id=5&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} title="Key Parameters" class1="" class2="show" accordianId="expenditureDetails" />
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
};

export default FinancialForm;