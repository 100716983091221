import React from 'react';
import { useAuth } from '../../../Core/Providers';
import { RouteMiddlewareProps } from '../../../Core/Components/Routes';
import { Navigate } from 'react-router-dom';

function DiscomirAccess({ children }: RouteMiddlewareProps) {

    const user: any = useAuth().user();

    if (!user.id) {
        return <Navigate to={'/'} />;
    }

    const roleids = [
        process.env.REACT_APP_DISCOMIR_DDE,
        process.env.REACT_APP_DISCOMIR_DDR,
        process.env.REACT_APP_DISCOMIR_DDA,
        process.env.REACT_APP_DISCOMIR_CONR,
        process.env.REACT_APP_DISCOMIR_NDA
    ];

    if (roleids.includes(String(user.role_id))) {
        return children;
    }

    return <Navigate to={'/'} />;
}

export default DiscomirAccess;