import React, { useState, useCallback, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, Navigate } from "react-router-dom";
import EnergyInnertemplate from "../InnerTemplate/EnergyInnertemplate";
import InfrastructureDetailsForm from "./InfrastructureDetailsForm";
import DivisionWiseLossesForm from "./DivisionWiseLossesForm";

import { Http } from "../../Core/Services/HttpService";
import Arrow from "../../images/right-arrow.svg";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import StepperFormat from "../StepperFormat";
import swal from 'sweetalert';
import { Storage } from "../../Core/Services/StorageService";

export const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-outer">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

const EnergyAccounting = (props: any) => {
  const syear: any = useRef(null);
  const [year, setYear] = useState("2022");
  const [sticky, setSticky] = useState("");
  const formData: any = useRef(null);
  const layout = useLayout();
  const [refresh, setRefresh] = useState(false);
  const [tblkey, setTblkey] = useState('');
  const [roleid, setRoleid] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [isSubmittedStatus, setSubmittedStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [discomhistory, setDiscomHistory] = useState('');
  const [steps, setSteps] = useState(['active', '', '', '']);

  let layoutDataSubscribe: any;

  const refreshform = () => {
    setYear(syear.current.value);
  }

  useEffect(() => {
    setDiscomUserid(Storage.get('discom_user_id'));
  }, [discomuserid]);

  useEffect(() => {
    setDiscomid(Storage.get('discom_id'));
  }, [discomid]);


  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    (async () => {
      layoutDataSubscribe = await layout.data.subscribe((res: any) => {
        setSubmittedStatus(res.submittedStatus.submittedStatus);
        setRemarks(res.submittedStatus.remarks);
        setDiscomHistory(res.submittedStatus.discom_history)
      });
    })();
    return () => {
      if (layoutDataSubscribe !== undefined) {
        layoutDataSubscribe.unsubscribe();
      }
    }
  }, []);

  useEffect(() => {
    setYear(props.year);
  }, [props.year]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('year', year);
    Http.post('/apiv1/save-form', myFormData, config)
      .then((res) => {
        setIsLoading(false);
        // window.location.reload();
        setRefresh(true);
        // popup
        // Data have been saved.
        // console.log(res);    
        swal("Input Form", "Your data has been saved.", "success");
      });
  }

  const next = (opt: number) => {
    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2)
      setSteps(['completed', 'completed', 'active', '']);
    if (opt == 3)
      setSteps(['completed', 'completed', 'completed', 'active']);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('year', year);
    Http.post('/apiv1/next-form', myFormData, config)
      .then((res) => {
        // console.log(res);
      });

    setGoSteps(opt);
  }

  const navtab = (opt: number) => {
    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2)
      setSteps(['completed', 'completed', 'active', '']);
    if (opt == 3)
      setSteps(['completed', 'completed', 'completed', 'active']);
    setGoSteps(opt);
  }

  const submittoreviewer = (form_step_no: any) => {
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);

    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('roleid', roleid);
    myFormData.append('year', year);
    myFormData.append('form_step_no', form_step_no);
    myFormData.append('discom_id', discomid);

    swal({
      title: "Are you sure to submit this form?",
      text: "After submit, You will not able to edit this form.",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: 'Input Form!',
          text: 'Your data has been submitted.',
          icon: 'success'
        }).then(function () {
          Http.post('/apiv1/forward', myFormData, config)
            .then((res) => {
              setIsLoading(false);
              setRefresh(true);
              // swal("Input Form", "Data has been forwarded", "success");
            });
        });
      } else {
        setIsLoading(false);
        // swal("Cancelled", "", "error");
      }
    });

  }

  const submittobackward = (form_step_no: any) => {
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('roleid', roleid);
    myFormData.append('year', year);
    myFormData.append('form_step_no', form_step_no);
    myFormData.append('discom_id', discomid);

    swal({
      title: "Are your sure want to return this form?",
      text: "",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        Http.post('/apiv1/backward', myFormData, config)
          .then((res) => {
            if (res._resultflag == 0) {
              setIsLoading(false);
              swal("Warning", res.message, "info");
            } else {
              setIsLoading(false);
              setRefresh(true);
              swal("Input Form", "The form has been returned for correction", "success");
            }
          });

      } else {
        setIsLoading(false);
        // swal("Cancelled", "", "error");
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 50 ? "is-sticky" : "";
    setSticky(stickyClass);
  };
  const classes = `formBtnSection justify-content-between is-sticky ${sticky}`;

  return (
    <>
      <div className="mainWrap">
        <Header />
        <EnergyInnertemplate PageTitle="Input Form" />
        <section className="financialForm">
          <div className="container">
            <div className="financialMainFrom">
              <div className="tab-content border-0 p-0">
                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                  <div className="radioYear">
                    <div className="row justify-content-center align-items-center year resYear p-3">
                      <div className="col-sm-4">
                        <div className="d-flex">
                          <label>Select Year (FY)</label>
                          <select defaultValue={year} ref={syear} className="form-select" aria-label="Default select example">
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="d-flex">
                          <label>Select Quarter</label>
                          <select defaultValue={year} ref={syear} className="form-select" aria-label="Default select example">
                            <option value="1">Quarter 1</option>
                            <option value="2">Quarter 2</option>
                            <option value="3">Quarter 3</option>
                            <option value="4">Quarter 4</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <Link to="#" onClick={() => refreshform()}>
                          <div className="inputBtn">
                            <span><img src={Arrow} /></span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='container'>
                      <div className='customStepper'>
                        <div className="StepperContainer-0-2-1">
                          <div className="StepContainer-0-2-2">
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Infrastructure Details</span></div>
                            </div>
                          </div>
                          <div className="StepContainer-0-2-2">
                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Division Wise Losses </span></div>
                            </div>
                          </div>
                          <div className="StepContainer-0-2-2">
                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d2-0-2-22"><span className="Connector-0-2-15 Connector-d3-0-2-23"></span></div>
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(2)} className={"StepButton-0-2-4 StepButton-d4-0-2-20 " + (steps[2] ? steps[2] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d5-0-2-21 " + (steps[2] ? steps[2] : '')}>3</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d4-0-2-24"><span className="Label-0-2-9 Label-d5-0-2-25">Form-Input energy</span></div>
                            </div>
                          </div>
                          <div className="StepContainer-0-2-2">
                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(3)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[3] ? steps[3] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[3] ? steps[3] : '')}>4</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Details of Received Sources</span></div>
                            </div>
                          </div>
                          <div className="StepContainer-0-2-2">
                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(4)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[4] ? steps[4] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[4] ? steps[4] : '')}>5</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Detail of Consumers & Consumption</span></div>
                            </div>
                          </div>
                          <div className="StepContainer-0-2-2">
                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(5)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[5] ? steps[5] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[5] ? steps[5] : '')}>6</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Details on Feeder Levels</span></div>
                            </div>
                          </div>
                          <div className="StepContainer-0-2-2">
                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                            <div className="StepMain-0-2-3">
                              <button onClick={() => navtab(6)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[6] ? steps[6] : '')}>
                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[6] ? steps[6] : '')}>7</span>
                              </button>
                              <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Details of DT wise loss</span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <form noValidate onSubmit={onSubmit} ref={formData}>
                      <input type="hidden" name="year" id="year" value={year} />
                      {goSteps === 0 && (
                        <div>
                          <div>
                            <InfrastructureDetailsForm year={year} refresh={refresh} tblkey={setTblkey} />
                          </div>

                          <div className={classes}>
                            <div></div>
                            <div>
                              <button className="btnDraft" type="submit">Save as Draft</button>
                            </div>
                            <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
                          </div>
                        </div>
                      )}
                      {goSteps === 1 && (
                        <div>
                          <div>
                            <DivisionWiseLossesForm year={year} refresh={refresh} tblkey={setTblkey} />
                          </div>

                          <div className={classes}>
                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(0)}>Previous</Link>
                            <div>
                              <button className="btnDraft" type="submit">Save as Draft</button>
                            </div>
                            <Link to="#" className="btn btnSubmit" onClick={() => next(2)}>Next</Link>
                          </div>
                        </div>
                      )}
                      {goSteps === 2 && (
                        <div>
                          <div>
                            {/* <PqSopForm year={year} refresh={refresh} /> */}
                          </div>


                          <div className={classes}>
                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(1)}>Previous</Link>
                            <div>
                              <button className="btnDraft" type="submit">Save as Draft</button>

                            </div>
                            <Link to="#" className="btn btnSubmit" onClick={() => next(3)}>Next</Link>
                          </div>
                        </div>
                      )}
                      {goSteps === 3 && (
                        <div>
                          <div>
                            {/* <RefForm year={year} refresh={refresh} tblkey={setTblkey} /> */}
                          </div>

                          <div className={classes}>
                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(2)}>Previous</Link>
                            <div>
                              <button className="btnDraft" type="submit">Save as Draft</button>
                            </div>
                            {/* <button className="btnDraft" type="submit">Submit</button> */}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                  {isLoading ? <LoadingSpinner /> : null}

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Container>
                <Row>
                    <Col>
                        <MasterForm />
                    </Col>
                </Row>
            </Container> */}
        <Footer />
      </div>

    </>
  )
}

export default EnergyAccounting