import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { useLocation, useNavigate } from "react-router-dom";
import GrantReleased from '../Module/LossReduction/GrantReleased';
import GrantLapsed from '../Module/LossReduction/GrantLapsed';

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

type PropsItems = {
    discomid: any;
};

const LossReduction = (props: any) => {
    const [selected, setSelected] = useState<any>(0);
    const [defaultDiscom, setDefaultDiscom] = useState<any>(0);
    const [defaultTab, setDefaultTab] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [discomid, setDiscomId] = useState<any>(0);

    const location = useLocation();
    let navigate = useNavigate();
    const changeLrTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        if (props.subDefaultTab == 'LrGrantLapsed') {
            setSelected("LrGrantLapsed");
        } else {
            setSelected("LrGrantReleased");
        }
    }, [props.subDefaultTab]);

    const changeTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            if (location.state) {
                setDiscomId(location.state.discomid)
                setDefaultDiscom(location.state.discomid);
                setDefaultTab(location.state.sub_module_type);
            }
        })();
    }, []);

    return (
        <>
            <section className="financialForm">
                <div className="financialMainFrom inputFromNew">
                    <div className="tab-content border-0 p-0">
                        <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                            <div className='radioTitleSection'>
                                <div className='row mx-0 gx-0 justify-content-between'>
                                    <div className='col-lg-12 col-xl-12 col-xxl-7'>
                                        <div className="radioYear">
                                            <div className="row justify-content-center align-items-center mx-0 gx-0">
                                                <div className="col-5 col-md-6">
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="LrGrantReleased" value="LrGrantReleased" id="LrGrantReleased" checked={selected === "LrGrantReleased"} onChange={changeLrTab} />
                                                        <label className="form-check-label" htmlFor="LrGrantReleased">Grant Released</label>
                                                    </div>
                                                </div>
                                                <div className="col-5 col-md-6">
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="LrGrantLapsed" value="LrGrantLapsed" id="LrGrantLapsed" checked={selected === "LrGrantLapsed"} onChange={changeLrTab} />
                                                        <label className="form-check-label" htmlFor="LrGrantLapsed">Grant Lapsed</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div aria-hidden={selected !== "LrGrantReleased" ? true : false}><GrantReleased discomid={discomid} /></div>
                            <div aria-hidden={selected !== "LrGrantLapsed" ? true : false}><GrantLapsed discomid={discomid} /></div>
                        </div>
                    </div>
                </div>
                {isLoading ? <LoadingSpinner /> : null}
            </section>
        </>
    );
};

export default LossReduction;