import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Pq7 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* PQ-7 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">PQ-7 : No. of days Payables to Creditors including Gencos <b>*</b></p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[219] && fields[219].value ? fields[219].value : ''} name="formdata[pqsop_details][pq7_nodal]" id="pqsop_details_pq7_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][pq7_remarks_nodal]" id="pqsop_details_pq7_remarks_nodal" defaultValue={fields[234] && fields[234].value ? fields[234].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][pq7_document_nodal]" id="pqsop_details_pq7_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[249].value != '' ?
                                            <a href={fields[249].value} title={fields[249].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>
                                    </>
                                    :
                                    <>
                                    {fields[219].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[219] && fields[219].value ? fields[219].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[234].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[234] && fields[234].value ? fields[234].value : ''}</div>
                                    {/* : null
                                            } */}

                                    {fields[249].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[249] && fields[249].value ? fields[249].value : ''} title={fields[249] && fields[249].value ? fields[249].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }

                        </div>
                        <div className={props.remove}>
                            <MyTooltip placement="left">
                                <ul>
                                    <li>No. of days Payables to Creditors including Gencos for the year under evaluation is equal to or less than the projected trajectory as per results evaluation framework</li>
                                    <li>Documentary Evidence: Undertaking by the DISCOM for compliance to LPS Rules</li>
                                </ul>
                            </MyTooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Has the discom opted for Electricity (Late Payment Surcharge and Related Matters) Rules, 2022 ("LPS Rules")?</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[147].key} field={fields[147]} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                {/* <td className="text-center">Status</td>
                    <td>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[149].key} field={fields[149]} />
                    </td> */}
            </tr>
            <tr>
                <td>Target for Payable Days as per REF</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[148].key} field={fields[148]} />
                </td>
                <td>Payable Days</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[150].key} field={fields[150]} />
                </td>
            </tr>
            <tr>
                <td>Status</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[149].key} field={fields[149]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[268].key} field={fields[268]} />
                </td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[151].key} field={fields[151]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Pq7;