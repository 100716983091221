import React, { Component } from 'react'
import Report from '../../assets/ImageCompo/Report'
import { Link } from "react-router-dom";
import { useAuth } from '../../Core/Providers';
import { Storage } from '../../Core/Services/StorageService';

function ActionCenter(props: any) {

    const user: any = useAuth().user();

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];
    let roleid: any = Storage.get('roleid') ? Storage.get('roleid') : 0;

    const qmRoleIds = [
        process.env.REACT_APP_DDE,
        process.env.REACT_APP_DDR,
        process.env.REACT_APP_DDA
    ];

    return (
        <>
            <div className="ActionCenterSection">
                <div className="row gx-0">
                    <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                        <Link to="/mis-awards" className="d-block w-100 text-decoration-none h-100">
                            <div className="tabPanelInner">
                                <Report />
                                <h5>Award Module MIS</h5>
                                <div className="hoverContent">
                                    <div>
                                        <Report />
                                        <h5>Award Module MIS</h5>
                                        <p>Generate reports for Award Module</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                        <Link to="/feeder-wise-reports" className="d-block w-100 text-decoration-none h-100">
                            <div className="tabPanelInner">
                                <Report />
                                <h5>Feeder Wise Progress MIS</h5>
                                <div className="hoverContent">
                                    <div>
                                        <Report />
                                        <h5>Feeder Wise Progress MIS</h5>
                                        <p>Generate reports for Feeder Wise Progress</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {roleids.includes(roleid.toString()) ?
                        <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                            <Link to="/mis-custom-reports" className="d-block w-100 text-decoration-none h-100">
                                <div className="tabPanelInner">
                                    <Report />
                                    <h5>Custom MIS Reports</h5>
                                    <div className="hoverContent">
                                        <div>
                                            <Report />
                                            <h5>Custom MIS Reports</h5>
                                            <p>Generate reports in Custom Formats</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        : null
                    }
                    {/* { roleids.includes(roleid.toString()) ? */}
                    <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                        <Link to="/pvtg-vvp-reports" className="d-block w-100 text-decoration-none h-100">
                            <div className="tabPanelInner">
                                <Report />
                                <h5>PVTG & VVP MIS</h5>
                                <div className="hoverContent">
                                    <div>
                                        <Report />
                                        <h5>PVTG & VVP MIS</h5>
                                        <p>Generate reports for PVTG & VVP</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {/* : null */}
                    {/* } */}
                    {!qmRoleIds.includes(String(user.role_id)) && (
                        <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                            <Link to="/rdss/quality-monitoring/reports" className="d-block w-100 text-decoration-none h-100">
                                <div className="tabPanelInner">
                                    <Report />
                                    <h5>Quality Monitoring MIS</h5>
                                    <div className="hoverContent">
                                        <div>
                                            <Report />
                                            <h5>Quality Monitoring MIS</h5>
                                            <p>Generate reports for Major Material, Power Transformer and Work Quality Inspection</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>)}
                </div>
            </div>
        </>
    )
}

export default ActionCenter;