import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./innertemplate.scss";
import { Storage } from "../../Core/Services/StorageService";

const InnerTemplate = (props: any) => {   
    const [roleid, setRoleid] = useState<number>(0);

    useEffect(() => {
        setRoleid(Number(Storage.get('roleid')));
    },[]);

    return (
        <>
        
            <section className="InnerBanner">
                <div className="container">
                    {/* <h2>{ props.PageTitle }</h2> */}
                    <ul className="BreadCumb list-unstyled mb-0">
                        <li><Link to="/" className="text-decoration-none">Home</Link></li>
                        {(props.sublink !== undefined && props.sublink === false)
                                    ? null
                                    : <li>
                                        {props.page && props.page == 'EA' ?
                                            <Link to={roleid === 5 || roleid === 6 ? "/nodal-view" : "/ea"} className="text-decoration-none">Energy Accounting</Link>
                                            :
                                            <Link to={roleid === 5 || roleid === 6 ? "/nodal-view" : "/rdss"} className="text-decoration-none">RDSS</Link>
                                        }
                                    </li>}
                        {/* <li><Link to="/" className="text-decoration-none">Input Form</Link></li> */}
                        <li>{ props.PageTitle }</li>
                    </ul>
                </div>
            </section>
           
        </>
    );
};

export default InnerTemplate;