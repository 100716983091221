import React from "react";

class Icon extends React.PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73.651"
        height="66.05"
        viewBox="0 0 100 100"
      >
        <g
          id="_x32_4_Bricks"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.25"
          transform="translate(.125 .125)"
        >
          <path d="M19.9,14.9L44,28.5c0.7,0.4,1.7,0.2,2.1-0.6L52.9,16c0.4-0.8,0.2-1.7-0.6-2.1L28.2,0.2C27.4-0.2,26.5,0,26,0.8l-6.8,11.9
			c-0.2,0.4-0.3,0.8-0.1,1.2C19.2,14.3,19.5,14.7,19.9,14.9L19.9,14.9z M28,3.7l21.5,12.1l-5.2,9.2L22.8,12.9L28,3.7z"/>
		    <path d="M91.7,28.3c-12.8,0-29.5,0-41.7,0c-0.9,0-1.6,0.7-1.6,1.6V42c-8.6,0-16,0-24.7,0V29.8c0-0.9-0.7-1.6-1.6-1.6H8.3
			c-0.9,0-1.6,0.7-1.6,1.6c0,63.7,0,44.7,0,68.6c0,0.9,0.7,1.6,1.6,1.6h83.3c0.9,0,1.6-0.7,1.6-1.6c0-1.5,0,5.9,0-17.3
			c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v2c-8.5,0-15.9,0-24.7,0V72.6h24.7v2c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
			c0,0.2,0-39.1,0-44.8C93.2,29,92.5,28.3,91.7,28.3L91.7,28.3z M37.7,83.2V72.6h24.7v10.6C53.7,83.2,46.3,83.2,37.7,83.2z
			 M9.9,83.2V72.6h24.7v10.6C25.9,83.2,18.5,83.2,9.9,83.2z M48.4,58.8v10.6c-8.6,0-16,0-24.7,0V58.8H48.4z M37.7,45.1h24.7v10.6
			c-8.6,0-16,0-24.7,0V45.1z M90.1,45.1v10.6c-8.6,0-16,0-24.7,0V45.1H90.1z M20.6,69.4H9.9V58.8h10.8L20.6,69.4L20.6,69.4z
			 M51.6,69.4V58.8h24.7v10.6C73.7,69.4,85.3,69.4,51.6,69.4z M79.3,69.4V58.8h10.8v10.6H79.3z M90.1,42H79.3V31.4h10.8V42z
			 M51.6,31.4h24.7V42c-8.6,0-16,0-24.7,0V31.4z M34.5,55.7c-8.6,0-16,0-24.7,0V45.1h24.7V55.7z M9.9,31.4h10.8V42H9.9L9.9,31.4z
			 M9.9,86.3h10.8v10.6H9.9L9.9,86.3z M23.8,86.3h24.7v10.6H23.8V86.3z M51.6,86.3c0.2,0,24.5,0,24.7,0v10.6H51.6V86.3z M79.3,96.9
			V86.3h10.8v10.6H79.3z"/>    
        </g>
      </svg>
      
    );
  }
}

export default Icon;
