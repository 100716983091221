import React from "react";

class Icon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70.394"
        height="70.394"
        viewBox="0 0 70.394 70.394"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              id="path2391"
              fill="#fff"
              d="M0-682.665h70.394v70.394H0z"
              transform="translate(0 682.665)"
            ></path>
          </clipPath>
        </defs>
        <g id="g2385">
          <g id="g2387">
            <g id="g2389" clipPath="url(#clip-path)">
              <g id="g2395" transform="translate(8.746 40.157)">
                <path
                  id="path2397"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M5.51 13.225h0A5.511 5.511 0 01-.001 7.714V3.306A3.306 3.306 0 013.305 0h7.715v7.715a5.511 5.511 0 01-5.51 5.51z"
                ></path>
              </g>
              <g id="g2399" transform="translate(12.052 53.382)">
                <path
                  id="path2401"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0v3.306"
                ></path>
              </g>
              <g id="g2403" transform="translate(16.461 53.382)">
                <path
                  id="path2405"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0v3.306"
                ></path>
              </g>
              <g id="g2407" transform="translate(50.627 40.157)">
                <path
                  id="path2409"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M5.51 12.123h0a5.511 5.511 0 005.511-5.511v-1.1A5.511 5.511 0 005.51.001h0A5.511 5.511 0 00-.001 5.512v1.1a5.511 5.511 0 005.511 5.511z"
                ></path>
              </g>
              <g id="g2411" transform="translate(6.542 56.688)">
                <path
                  id="path2413"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M15.434 9.919V4.408A4.409 4.409 0 0011.025 0H4.412A4.408 4.408 0 00.004 4.408v5.511"
                ></path>
              </g>
              <g id="g2415" transform="translate(9.297 48.973)">
                <path
                  id="path2417"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0h9.919"
                ></path>
              </g>
              <g id="g2419" transform="translate(28.584 56.688)">
                <path
                  id="path2421"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M13.225 5.102a5.1 5.1 0 00-5.1-5.1H5.103a5.1 5.1 0 00-5.1 5.1"
                ></path>
              </g>
              <g id="g2423" transform="translate(27.832 41.259)">
                <path
                  id="path2425"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M7.365 15.434a25.485 25.485 0 006.555-.706 1.1 1.1 0 00.729-1.473l-.749-1.872a14.327 14.327 0 01-1.025-5.321v-.547A5.511 5.511 0 007.364.004a5.511 5.511 0 00-5.511 5.511v.547a14.326 14.326 0 01-1.025 5.321l-.749 1.872a1.1 1.1 0 00.729 1.473 25.484 25.484 0 006.557.706z"
                ></path>
              </g>
              <g id="g2427" transform="translate(12.052 13.706)">
                <path
                  id="path2429"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M19.838 4.407a10.972 10.972 0 012.2 6.614 11.021 11.021 0 01-11.016 11.018A11.021 11.021 0 01.001 11.018 11.021 11.021 0 0111.022-.003a10.972 10.972 0 015.511 1.475"
                ></path>
              </g>
              <g id="g2431" transform="translate(16.06 17.713)">
                <path
                  id="path2433"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0l7.014 7.014h11.021"
                ></path>
              </g>
              <g id="g2435" transform="translate(23.074 13.706)">
                <path
                  id="path2437"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 11.021V0"
                ></path>
              </g>
              <g id="g2439" transform="translate(38.503 33.544)">
                <path
                  id="path2441"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0h22.042"
                ></path>
              </g>
              <g id="g2443" transform="translate(40.708 29.135)">
                <path
                  id="path2445"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 4.409V.001"
                ></path>
              </g>
              <g id="g2447" transform="translate(49.524 22.523)">
                <path
                  id="path2449"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 11.021V0"
                ></path>
              </g>
              <g id="g2451" transform="translate(58.341 15.91)">
                <path
                  id="path2453"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 17.634V0"
                ></path>
              </g>
              <g id="g2455" transform="translate(45.116 25.829)">
                <path
                  id="path2457"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 7.717V.002"
                ></path>
              </g>
              <g id="g2459" transform="translate(53.933 19.216)">
                <path
                  id="path2461"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 14.327V0"
                ></path>
              </g>
              <g id="g2463" transform="translate(27.482 61.097)">
                <path
                  id="path2465"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M15.434 5.51v-2.2A3.306 3.306 0 0012.128.004H3.311A3.307 3.307 0 00.005 3.31v2.2"
                ></path>
              </g>
              <g id="g2467" transform="translate(6.542 61.097)">
                <path
                  id="path2469"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M15.434 5.51v-2.2A3.306 3.306 0 0012.128.004H3.311A3.306 3.306 0 00.005 3.31v2.2"
                ></path>
              </g>
              <g id="g2471" transform="translate(53.933 52.28)">
                <path
                  id="path2473"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0v3.306"
                ></path>
              </g>
              <g id="g2475" transform="translate(58.341 52.28)">
                <path
                  id="path2477"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M0 0v3.306"
                ></path>
              </g>
              <g id="g2479" transform="translate(48.422 55.586)">
                <path
                  id="path2481"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M15.434 11.021V4.408A4.408 4.408 0 0011.026 0H4.413A4.408 4.408 0 00.004 4.408v6.613"
                ></path>
              </g>
              <g id="g2483" transform="translate(48.422 61.097)">
                <path
                  id="path2485"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M15.434 5.51v-2.2A3.306 3.306 0 0012.128.004H3.311A3.306 3.306 0 00.005 3.31v2.2"
                ></path>
              </g>
              <g id="g2487" transform="translate(1.031 3.787)">
                <path
                  id="path2489"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M59.515 4.409H1.103a1.1 1.1 0 01-1.1-1.1v-2.2a1.1 1.1 0 011.1-1.1H67.23a1.1 1.1 0 011.1 1.1v2.2a1.1 1.1 0 01-1.1 1.1h-3.306"
                ></path>
              </g>
              <g id="g2491" transform="translate(3.235 8.195)">
                <path
                  id="path2493"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M6.613 42.983H1.102a1.1 1.1 0 01-1.1-1.1V.002"
                ></path>
              </g>
              <g id="g2495" transform="translate(18.665 51.178)">
                <path
                  id="path2497"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M9.919 0H0"
                ></path>
              </g>
              <g id="g2499" transform="translate(41.81 51.178)">
                <path
                  id="path2501"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M11.022 0H0"
                ></path>
              </g>
              <g id="g2503" transform="translate(59.443 8.195)">
                <path
                  id="path2505"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.25"
                  d="M7.715 0v41.881a1.1 1.1 0 01-1.1 1.1H.002"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default Icon;
