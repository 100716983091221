import React, {useEffect, useRef, useState} from "react";
import { Http } from '../../../../Core/Services/HttpService';
import {init, initselect, inputEventChecker, validatefrm} from "../../../../assets/js/formbuilder";
import FieldComponent from "../../../../FieldComponent";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import {GrAddCircle, GrTrash, GrUpload} from "react-icons/gr";
import { multijsoninit } from "../../../../assets/js/formbuilder";
import { BiUpload } from "react-icons/bi";
import {AiOutlinePlus} from "react-icons/ai";
import swal from "sweetalert";
import {useStateMounted} from "../../../../Core/Hooks";
import QueryString from "qs";
import {BsFileEarmarkExcel, BsFileEarmarkPdf} from "react-icons/bs";
import PropTypes from "prop-types";
import {useAuth} from "../../../../Core/Providers";
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

const AwardModuleSmDetails = (props: any) => {
    const [show, setShow] = useState(false);
    const [date, setDate] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);

     const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    setTimeout(() => {
                        init();
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                }).finally(() => {
                    footerspinner.classList.add('d-none');
                })
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
            <>
                <div className="formBox mt-3">
                    <div className="row mx-0 g-2">
                        <div className="col-md-2 mt-0">
                            <label htmlFor="Type of Work">Type of Work</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                        </div>
                        <div className="col-md-2 mt-0">
                            <label htmlFor="No. of Work Packages">No. of Work Packages</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                        </div>
                        <div className="col-md-2 mt-0">
                            <label htmlFor="Sanctioned Cost">Sanctioned Cost</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                        </div>
                        <div className="col-md-2 mt-0">
                            <label htmlFor="Date of Sanction">Date of Sanction</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                        </div>
                        <div className="col-md-2 mt-0">
                            <label htmlFor="Name of PMA">Name of PMA</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                        </div>
                    </div>
                </div>
                
                <div className="table-responsive RevenueDetailsTable PqTable">
                    <Block1 field={fields[5]} />
                </div>

                <div className="row text-center"><h5>RFP Status (Smart Metering)</h5></div>
                <div className="table-responsive RevenueDetailsTable PqTable">
                    <Block2 field={fields[6]} />
                </div>
                <div className="formBox mt-3">
                    <div className="row mx-0 g-2">
                        <div className="col-md-3 mt-0">
                            <label htmlFor="Remarks">Remarks</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} />
                        </div>
                    </div>
                </div>


                <div className="preAstatus">
                    {/* Package Details */}
                     <div className="radioYear h-auto">
                        <div className="row mx-0">
                            <div className="col-md-12"><h6 className="mb-0">1. Package Details</h6></div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="fixUploader">
                            <tr>
                                <td style={{width:250}}>Name or No. of Work Package (Unique Package Identifier)</td>
                                <td style={{width:200}}><input type="text"/></td>
                                <td style={{width:50}}></td>
                                <td style={{width:220}}>Mode of implementation (TOTEX/ OPEX)</td>
                                <td style={{width:150}}>
                                    <select className="form-select text-start">
                                        <option selected>Mode - 1</option>
                                        <option>Mode - 2</option>
                                        <option>Mode - 3</option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                        </table>
                    </div>

                    {/* Quantity Specified in RFP */}
                     <div className="radioYear h-auto mt-0">
                        <div className="row mx-0">
                            <div className="col-md-12"><h6 className="mb-0">2. Quantity Specified in RFP</h6></div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="fixUploader">
                            <tr>
                                <td className="p-0">
                                    <table className="fixUploader">
                                        <tr>
                                            <td>Consumer Meters Ph-1</td>
                                            <td><input type="text" style={{width:100}}/></td>
                                            <td>Consumer Meters Ph-2</td>
                                            <td><input type="text" style={{width:100}}/></td>
                                            <td>DT Meters Ph-1</td>
                                            <td><input type="text" style={{width:100}}/></td>
                                            <td>DT Meters Ph-2</td>
                                            <td><input type="text" style={{width:100}}/></td>
                                            <td>Feeder Meters</td>
                                            <td><input type="text" style={{width:100}}/></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className="pt-3 px-0">
                                    <table className="fixUploader">
                                        <td style={{width:300}}>Other works, if any (Service Cable, Meter Panel etc.) - Please Specify</td>
                                        <td><input type="text"/></td>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                    {/* Provision of Upfront Payment against CAPEX in RFP */}
                     <div className="row mx-0">
                        <div className="col-md-6 ps-0">
                            <div className="radioYear h-auto mt-0 ps-2"><h6 className="mb-0">3. Provision of Upfront Payment against CAPEX in RFP</h6></div>
                            <div className="table-responsive px-1">
                                <table className="fixUploader">
                                    <tr>
                                        <td style={{width:80}}>Yes/No</td>
                                        <td>
                                            <select className="form-select text-start"  style={{width:120}}>
                                                <option selected>Yes/No</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td className="text-end pe-3">If Yes, Amount (in Rs/meter)</td>
                                        <td><input type="text" style={{width:150}}/></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="radioYear h-auto mt-0 ps-2"><h6 className="mb-0">4. Payment Security Mechanism</h6></div>
                            <div className="table-responsive">
                                <table className="fixUploader">
                                    <tr>
                                        <td>
                                            <select className="form-select text-start"  style={{width:200}}>
                                                <option selected>Select</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-3 pe-0">
                            <div className="radioYear h-auto mt-0 ps-2"><h6 className="mb-0">5. No. of Total Meter months in RFP</h6></div>
                            <div className="table-responsive">
                                <table className="fixUploader">
                                    <tr>
                                        <td><input type="text" style={{width:200}}/></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* BID PROCESS DETAILS  */}
                     <div className="radioYear h-auto mt-0">
                        <div className="row mx-0">
                            <div className="col-md-12"><h6 className="mb-0">6. Bid Process Details </h6></div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="rowWidth">
                            <tr>
                                <td style={{width:120}}>Present Status</td>
                                <td><input type="text" style={{width:170}}/></td>
                                <td>Expected RFP Issue Date</td>
                                <td><input type="text" style={{width:170}}/></td>
                                <td>Actual RFP Issue Date</td>
                                <td style={{width:100}}><input type="text"/></td>
                                <td>
                                    <div className="fileUpload position-relative text-center UploadModalCustomUpload">
                                        <div className="downEqule">
                                            <input name='files' type="file" className="form-control" />
                                            <div className="customUpload align-items-center flex-wrap">
                                                <span className='w-100'> <GrUpload /> </span>
                                                <p className='w-100'><b>Choose a file</b> or Drag it</p>
                                            </div>
                                        </div>
                                        <select className="form-select">
                                            <option selected>Select</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>No. of Bids Received</td>
                                <td><input type="text" style={{width:170}}/></td>
                                <td>Expected Date of Technical Bid Opening</td>
                                <td><input type="text" style={{width:170}}/></td>
                                <td>Actual Date of Technical Bid Opening</td>
                                <td><input type="text"/></td>
                                <td>
                                    <div className="fileUpload position-relative text-center UploadModalCustomUpload">
                                        <div className="downEqule">
                                            <input name='files' type="file" className="form-control" />
                                            <div className="customUpload align-items-center flex-wrap">
                                                <span className='w-100'> <GrUpload /> </span>
                                                <p className='w-100'><b>Choose a file</b> or Drag it</p>
                                            </div>
                                        </div>
                                        <select className="form-select">
                                            <option selected>Select</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Name of participants</td>
                                <td><input type="text" style={{width:170}}/></td>
                                <td>Actual Date of Financial Bid Opening </td>
                                <td><input type="text" style={{width:170}}/></td>
                                <td>
                                    <div className="fileUpload position-relative text-center UploadModalCustomUpload">
                                        <div className="downEqule">
                                            <input name='files' type="file" className="form-control" />
                                            <div className="customUpload align-items-center flex-wrap">
                                                <span className='w-100'> <GrUpload /> </span>
                                                <p className='w-100'><b>Choose a file</b> or Drag it</p>
                                            </div>
                                        </div>
                                        <select className="form-select">
                                            <option selected>Select</option>
                                        </select>
                                    </div>
                                </td>
                                <td>Name of successful bidder</td>
                                <td><input type="text" style={{width:160}}/></td>
                            </tr>
                        </table>
                    </div>

                    {/* AWARD DETAILS  */}
                     <div className="radioYear h-auto mt-0">
                        <div className="row mx-0">
                            <div className="col-md-12"><h6 className="mb-0">7. Award Details </h6></div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="fixUploader">
                            <tr>
                                <td style={{width:180}}>Expected Date of LOA</td>
                                <td><input type="date"/></td>
                                <td style={{width:150}}>Actual Date of LOA</td>
                                <td><input type="date"/></td>
                                <td style={{width:250}}>Upload (evidence document for Actual date of LOA)</td>
                                <td>
                                    <div className="fileUpload position-relative text-center UploadModalCustomUpload">
                                        <div className="downEqule">
                                            <input name='files' type="file" className="form-control" />
                                            <div className="customUpload align-items-center flex-wrap">
                                                <span className='w-100'> <GrUpload /> </span>
                                                <p className='w-100'><b>Choose a file</b> or Drag it</p>
                                            </div>
                                        </div>
                                        <select className="form-select">
                                            <option selected>Select</option>
                                        </select>
                                    </div>
                                </td>
                                <td style={{width:250}}></td>
                            </tr>
                        </table>
                    </div>

                    <Link to="#" className="addMoreBtn">Add More <AiOutlinePlus/></Link>
                </div>

            </>
            : null
        );
    }
}

const Block1OLD = (props: any) => {
    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {

        if(block1Fields.length < 100){
            setBlock1fields((prev: any[]) => {
                return [...prev, { fields: newBlock1Fields, delete: false }];
            });
        }
    
        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }
    return (
        <>
            <table className="table fixUploader">
                <thead className="text-center">
                    <th style={{ minWidth: "40px" }}>S. No.</th>
                    <th>Work Package No.</th>
                    <th>RFP No</th>
                    <th>Mode of implementation</th>
                    <th>Consumer Meters under Phase 1 (Nos.)</th>
                    <th>Consumer Meters under Phase 2 (Nos.)</th>
                    <th>Total Consumer Meters (Phase 1 + Phase 2) (Nos.)</th>
                    <th>DT Meters under Phase 1 (Nos.)</th>
                    <th>DT Meters under Phase 2 (Nos.)</th>
                    <th>Total DT Meters (Phase 1 + Phase 2) (Nos.)</th>
                    <th>Feeder Meters (Nos.)</th>
                    <th>Other works, if any Please Specify</th>
                    <th>Yes/ No</th>
                    <th>If Yes, Amount (in Rs/ meter)</th>
                    <th>Yes/ No</th>
                    <th>If Yes, Whether the DDF provisions are as per SBD</th>
                    <th>If No, Payment Security Mechanism in RFP</th>
                    <th>No. of Total Meter months in RFP</th>
                    <th>Actual date of RFP issuance</th>
                    <th>No. of Bids Received</th>
                    <th>Actual/ Expected Date of Technical Bid Opening</th>
                    <th>Actual/ Expected Date of Financial Bid Opening</th>
                    <th>Present Status</th>
                    <th>Name of participants</th>
                    <th>Name of successful bidder</th>
                    <th>Actual/ Expected Date of LOA</th>
                    <th>Actual/ Expected Date of Agreement</th>
                    <th>Consumer Meters under Phase 1 (Nos.)</th>
                    <th>Consumer Meters under Phase 2 (Nos.)</th>
                    <th>Total Consumer Meters (Phase 1 + Phase 2) (Nos.)</th>
                    <th>DT Meters under Phase 1 (Nos.)</th>
                    <th>DT Meters under Phase 2 (Nos.)</th>
                    <th>Total DT Meters (Phase 1 + Phase 2) (Nos.)</th>
                    <th>Feeder Meters (Nos.)</th>
                    <th>Other works, if any Please Specify</th>
                    <th>For Consumer Meters - Upfront payment (In Rs/ meter)</th>
                    <th>For Consumer Meters - Remaining TOTEX (In Rs/ meter/ month)</th>
                    <th>For DT Meters - Upfront payment (In Rs/ meter)</th>
                    <th>For DT Meters - Remaining TOTEX (In Rs/ meter/ month)</th>
                    <th>For Feeder Meters - Upfront payment (In Rs/ meter)</th>
                    <th>For Feeder Meters - Remaining TOTEX (In Rs/ meter/ month)</th>
                    <th>Consumer Meters under Phase 1 (Nos.)</th>
                    <th>Consumer Meters under Phase 2 (Nos.)</th>
                    <th>Total Consumer Meters (Phase 1 + Phase 2)(Nos.)</th>
                    <th>DT Meters under Phase 1(Nos.)</th>
                    <th>DT Meters under Phase 2(Nos.)</th>
                    <th>Total DT Meters (Phase 1 + Phase 2)(Nos.)</th>
                    <th>Feeder Meters (Nos.)</th>
                    <th>Expenditure Incurred on installation of smart meters</th>
                    <th>Other works, if any Please Specify</th>
                    <th>Remarks</th>

                    <th className='text-end pe-1' style={{ width: "30px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                </thead>
                <tbody>
                    <>
                        {
                            block1Fields.map((item: any, key: number) => {
                                return (item.delete === false) ? <>
                                    <tr key={key}>
                                        <td>{(block1SrNo++) + 1}</td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                </>;
                                            })
                                        }
                                        <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    </tr>
                                </>
                                    : null;
                            })
                        }
                    </>
                </tbody>
            </table>
        </>
    );
}

const Block2OLD = (props: any) => {
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 2);
            setHasBlock2FieldsDeletes(hasDelete);
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {

        if(block2Fields.length < 100){
            setBlock2fields((prev: any[]) => {
                return [...prev, { fields: newBlock2Fields, delete: false }];
            });
        }
    
        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }
    return (
        <>
            <table className="table fixUploader">
                <thead className="text-center">
                    <th style={{ width: "100px" }}>RFP issued (No. of Work Packages)</th>
                    <th style={{ minWidth: "100px" }}>RFP  issued (INR Cr)</th>
                    <th style={{ minWidth: "100px" }}>RFP under evaluation (No. of Work Packages)</th>
                    <th style={{ minWidth: "100px" }}>RFP under evaluation (INR Cr)</th>
                    <th style={{ minWidth: "100px" }}>Work Awarded  (No. of Work Packages)</th>
                    <th style={{ minWidth: "100px" }}>Work Awarded (INR Cr)</th>
                    <th style={{ minWidth: "100px" }}>RFP yet to be issued  (No. of Work Packages)</th>
                    <th style={{ minWidth: "100px" }}>RFP yet to be issued (INR Cr)</th>
                    <th className='text-end pe-1' style={{ width: "30px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                </thead>
                <tbody>
                    <>
                        {
                            block2Fields.map((item: any, key: number) => {
                                return (item.delete === false) ? <>
                                    <tr key={key}>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                </>;
                                            })
                                        }
                                        <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    </tr>
                                </>
                                    : null;
                            })
                        }
                    </>
                </tbody>
            </table>
        </>
    );
}

const PreAwardStatus = (props: any) => {
    const user = useAuth().user();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [pdfLink, setPdfLink] = useState('#');
    const [excelLink, setExcelLink] = useState('#');
    const [prevfields, setPrevFields] = useStateMounted<any[]>([]);
    const [userData, setUserData] = useState<any>();

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];

    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    });

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    setPrevFields(result.fields);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                }).finally(() => {
                    footerspinner.classList.add('d-none');
                })
        })();
    };

    useEffect(() => {
        const params = {
            form_id: 35,
            form_key: 'sm_award_module',
            discom_user_id: props.discomuserid,
            discom_id: props.discomid,
            form_type: 'pre',
            form_no: 0,
            discom_shortname:userData?.short_form,
        };
        let link = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/frmPdf', 35].join('/');
        link += '?' + QueryString.stringify(params);
        setPdfLink(link);

        let excellink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/excelDownload', 35].join('/');
        excellink += '?' + QueryString.stringify(params);
        setExcelLink(excellink);
    }, [props.discomid,props.discomuserid,userData]);

    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        const imprtParams: any = {
            file_to_upload: e.target.files[0],
            role_id: props.roleid,
            discom_id: props.discomid,
            table_key: 'sm_award_module',
            form_name: e.target.dataset.prefix,
            form_id: 35
        };
        for (let key in imprtParams) {
            formData.append(key, imprtParams[key]);
        }

        setFields([]);
        Http.post('/apiv1/dprfields/preawardupload35', formData, config)
            .then((res) => {
                swal(res.message, "", (res.resultflag ? "success" : "error"));
                if (res.resultflag && res.fields) {
                    if (res.fields && Array.isArray(res.fields)) {
                        setFields(res.fields);
                    } else {
                        setFields(prevfields);
                    }
                } else {
                    setFields(prevfields);
                }
            })
            .catch((err) => {
                swal("System error occurred!! please try again.", "", "error");
                setFields(prevfields);
            })
            .finally(() => {
                setIsLoaded(true);
            });
        e.target.value = null;
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        props.btnLoadState(false);
        if(fields && fields.length) {
            setTimeout(() => {
                init(props.frmid+' .form-control');
                initselect(props.frmid+' .form-control');
                props.btnLoadState(true);
            }, 1500);
        }
    }, [fields]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>
                    <div className='row justify-content-end'>
                        {
                            (!roleids.includes(userData?.role_id)) &&
                            <div className="mb-3 form-field w-3">
                                <div className="fileUpload position-relative text-center">
                                    <div className="downEqule">
                                        <input type="file" onChange={handleFileInput} data-prefix="pre_award_status" className="form-control" />
                                        <div className="customUpload justify-content-center flex-wrap">
                                            <span><BiUpload /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="w-2">
                            <Link title="Download Sample Format" to={excelLink} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                        </div>
                        <div className="w-2">
                            <Link to={pdfLink} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                        </div>
                    </div>
                    <div className="table-responsive RevenueDetailsTable PqTable">
                        <Block1 field={fields[0]} frmid={props.frmid}/>
                    </div>
                </>
                : null
        );
    }
};

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const user = useAuth().user();
    const [userData, setUserData] = useState<any>();
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];

    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    },[]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
            setTimeout(() => {
                initselect(props.frmid+' .form-control');
                multijsoninit(props.frmid+' .form-control');
            }, 2000);
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        if(block1Fields.length < 100){
            setBlock1fields((prev: any[]) => {
                return [...prev, { fields: newBlock1Fields, delete: false }];
            });
        }
    };

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            });
            return newFields;
        });
    };

    const isSubmitted = (fields:  any) => {
        return (fields[4].childrens[0].value === '6' && fields[13].value === 'submitted');
    };

    const isAssigned = (fields:  any) => {
        return (fields[14]) ? (fields[14].value === 0) : true;
    };

    return (
        <>
            <div className="preAstatus">
                {
                    block1Fields.map((item: any, key: number) => {
                        let assignBtnStyle = {
                            padding:"10px 20px !important",
                            width:"100px",
                            display:"block",
                            margin:"auto"
                        };
                        if(item.delete === false) block1SrNo++;
                        return (item.delete === false) ?
                            <>
                                <div style={isSubmitted(item.fields) ? {display:"none"} : {display:"block"}}>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return (field.type === 'group') ?
                                                <>
                                                    {/* Package Details */}
                                                    <div className="radioYear h-auto">
                                                        <div className="row mx-0">
                                                            <div className="col-md-12"><h6 className="mb-0">{field.caption}</h6></div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="fixUploader">
                                                            <thead>
                                                            {
                                                                field['childrens'].map((gfield: any, gkey: number) => {
                                                                    return <>
                                                                        <th style={{minWidth:200}}>{gfield.caption}</th>
                                                                    </>
                                                                })
                                                            }
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                {
                                                                    field['childrens'].map((gfield: any, gkey: number) => {
                                                                        const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey + '_' + gkey };
                                                                        if (gfield.type == 'file') {
                                                                            options.parentIndex = [(block1SrNo - 1)];
                                                                            gfield.parent_type = ['multijson', 'multijson'];
                                                                            gfield.isMultiFile = false;
                                                                        }
                                                                        return <>
                                                                            <td>
                                                                                <FieldComponent options={options} fieldName={gfield.key} field={gfield} />
                                                                            </td>
                                                                        </>
                                                                    })
                                                                }
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                : (['sm_contract_agreement_date','sm_contract_agreement_upload','sm_whether_psm_executed','sm_date_of_execution','sm_date_of_execution_upload','sm_engineering_approval','sm_area_covered','sm_submit_status'].includes(field.key)) ?
                                                    <span style={{display:"none"}}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey}} fieldName={field.key} field={field} />
                                                    </span>
                                                : null
                                        })
                                    }
                                    {/*{
                                        (hasBlock1FieldsDelete && isAssigned(item.fields)) ?
                                            <Link className='bg-danger addMoreBtn' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link>
                                            : <span className="badge bg-info text-dark" style={assignBtnStyle}>Assigned</span>
                                    }*/}
                                    <React.Fragment key={key}>
                                        {
                                            (hasBlock1FieldsDelete && isAssigned(item.fields) && !roleids.includes(userData?.role_id)) ?
                                                <Link className='bg-danger addMoreBtn' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link>
                                                : (!isAssigned(item.fields)) ?
                                                <span className="badge bg-info text-dark" style={assignBtnStyle}>Assigned</span>
                                                : null
                                        }
                                    </React.Fragment>
                                </div>
                                {
                                    (isSubmitted(item.fields))
                                        ? <div className={"submitted" + key}>
                                            {
                                                item.fields.map((field: any, fkey: number) => {
                                                    let cloneField = JSON.parse(JSON.stringify(field));
                                                    cloneField.view = 'Y'; // Forcefully view Y
                                                    return (cloneField.type === 'group') ?
                                                        <>
                                                            {/* Package Details */}
                                                            <div className="radioYear h-auto">
                                                                <div className="row mx-0">
                                                                    <div className="col-md-12"><h6 className="mb-0">{cloneField.caption}</h6></div>
                                                                </div>
                                                            </div>
                                                            <div className="table-responsive">
                                                                <table className="fixUploader">
                                                                    <thead>
                                                                    {
                                                                        cloneField['childrens'].map((gfield: any, gkey: number) => {
                                                                            return <>
                                                                                <th style={{minWidth:200}}>{gfield.caption}</th>
                                                                            </>
                                                                        })
                                                                    }
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                        {
                                                                            cloneField['childrens'].map((gfield: any, gkey: number) => {
                                                                                let cloneFieldC = JSON.parse(JSON.stringify(gfield));
                                                                                cloneFieldC.view = 'Y'; // Forcefully view Y
                                                                                const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey + '_' + gkey };
                                                                                if (cloneFieldC.type == 'file') {
                                                                                    options.parentIndex = [(block1SrNo - 1)];
                                                                                    cloneFieldC.parent_type = ['multijson', 'multijson'];
                                                                                    cloneFieldC.isMultiFile = false;
                                                                                }
                                                                                return <>
                                                                                    <td>
                                                                                        <FieldComponent options={options} fieldName={cloneFieldC.key} field={cloneFieldC} />
                                                                                    </td>
                                                                                </>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </>
                                                        : (['sm_contract_agreement_date','sm_contract_agreement_upload','sm_whether_psm_executed','sm_date_of_execution','sm_date_of_execution_upload','sm_engineering_approval','sm_area_covered','sm_submit_status'].includes(cloneField.key)) ?
                                                            <span style={{display:"none"}}>
                                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey}} fieldName={cloneField.key} field={cloneField} />
                                                            </span>
                                                        : null
                                                })
                                            }
                                            {/*{
                                                (hasBlock1FieldsDelete) ? <Link className='bg-danger addMoreBtn' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null
                                            }*/}
                                        </div> : null
                                }
                            </>
                            : null
                    })
                }
                {
                    (!roleids.includes(userData?.role_id)) &&
                    <Link to="#" className='addMoreBtn' onClick={() => addItem()}>Add More <FiPlusCircle className="h5 p-0 mb-0"/></Link>
                }
            </div>
        </>
    );
};

const PostAwardStatus = (props: any) => {
    const user = useAuth().user();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [pdfLink, setPdfLink] = useState('#');
    const [excelLink, setExcelLink] = useState('#');
    const [prevfields, setPrevFields] = useStateMounted<any[]>([]);
    const [userData, setUserData] = useState<any>();

    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    });

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                }).finally(() => {
                    footerspinner.classList.add('d-none');
                })
        })();
    };

    useEffect(() => {
        const params = {
            form_id: 35,
            form_key: 'sm_award_module',
            discom_user_id: props.discomuserid,
            discom_id: props.discomid,
            form_type: 'post',
            form_no: 1,
            discom_shortname:userData?.short_form,
        };
        let link = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/frmPdf', 35].join('/');
        link += '?' + QueryString.stringify(params);
        setPdfLink(link);

        let excellink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/excelDownload', 35].join('/');
        excellink += '?' + QueryString.stringify(params);
        setExcelLink(excellink);
    }, [props.discomid,props.discomuserid,userData]);

    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        const imprtParams: any = {
            file_to_upload: e.target.files[0],
            role_id: props.roleid,
            discom_id: props.discomid,
            table_key: 'sm_award_module',
            form_name: e.target.dataset.prefix,
            form_id: 35
        };
        for (let key in imprtParams) {
            formData.append(key, imprtParams[key]);
        }

        setFields([]);
        Http.post('/apiv1/dprfields/preawardupload', formData, config)
            .then((res) => {
                swal(res.message, "", (res.resultflag ? "success" : "error"));
                if (res.resultflag && res.fields) {
                    if (res.fields && Array.isArray(res.fields)) {
                        setFields(res.fields);
                    } else {
                        setFields(prevfields);
                    }
                } else {
                    setFields(prevfields);
                }
            })
            .catch((err) => {
                swal("System error occurred!! please try again.", "", "error");
                setFields(prevfields);
            })
            .finally(() => {
                setIsLoaded(true);
            });
        e.target.value = null;
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        if(fields && fields.length) {
            setTimeout(() => {
                init(props.frmid+' .form-control');
                initselect(props.frmid+' .form-control');
            }, 3000);
        }
    }, [fields]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>
                    <div className='row justify-content-end'>
                        {/*<div className="mb-3 form-field w-3">
                            <div className="fileUpload position-relative text-center">
                                <div className="downEqule">
                                    <input type="file" onChange={handleFileInput} data-prefix="pre_award_status" className="form-control" />
                                    <div className="customUpload justify-content-center flex-wrap">
                                        <span><BiUpload /></span>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        <div className="w-2">
                            <Link title="Download Sample Format" to={excelLink} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                        </div>
                        <div className="w-2">
                            <Link to={pdfLink} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                        </div>
                    </div>
                    <div className="table-responsive RevenueDetailsTable PqTable">
                        <Block2 field={fields[0]} frmid={props.frmid} />
                    </div>
                </>
                : null
        );
    }
};

const Block2 = (props: any) => {
    let block2SrNo = 0;
    let block2SrNoFile = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            });
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect(props.frmid+' .form-control');
                multijsoninit(props.frmid+' .form-control');
            }, 3500);
        }
    }, [block2Fields]);

    const isSubmitted = (fields:  any) => {
        return (fields[3].childrens[0].value === '6' && fields[13].value === 'submitted');
    };

    return (
        <>
            <table className="table fixUploader">
                <thead className="text-center">
                    <tr>
                        <th style={{ width: "40px" }} rowSpan={2}>S. No.</th>
                        <th style={{ width: "100px" }} rowSpan={2}>Name or No. of Work Package (Unique Package Identifier)</th>
                        <th style={{ minWidth: "100px" }} rowSpan={2}>Date of LOA</th>
                        <th style={{ minWidth: "100px" }} rowSpan={2}>Date of Contract Agreement</th>
                        <th style={{ minWidth: "100px" }} rowSpan={2}>Upload (Evidence document for Date of Contract Agreement)</th>
                        <th style={{ minWidth: "100px" }} colSpan={4}>Payment Security Mechanism executed</th>
                        <th style={{ minWidth: "100px" }} rowSpan={2}>Engineering Approval(%)</th>
                        <th style={{ minWidth: "100px" }} rowSpan={2}>Area covered</th>
                    </tr>
                    <tr>
                        <th style={{ minWidth: "100px" }}>Whether PSM executed (Yes/No)</th>
                        <th style={{ minWidth: "100px" }}>Type of Payment Security Mechanism (DDF/LC / Escrow / Other)</th>
                        <th style={{ minWidth: "100px" }}>Date of Execution</th>
                        <th style={{ minWidth: "100px" }}>Upload (Evidence document for Date of Execution)</th>
                    </tr>
                </thead>
                <tbody>
                <>
                    {
                        block2Fields.map((item: any, key: number) => {
                            /*let areaHiddenProp = false;
                            // Check Present Status "Awarded" or not
                            if(item.fields[3].childrens[0].hidden_row){
                                areaHiddenProp = true;
                            }*/
                            return (item.delete === false) ? <>
                                    <tr key={key} aria-hidden={isSubmitted(item.fields) ? "false" : "true"}>
                                        {/*<td>{isSubmitted(item.fields) ? (block2SrNo++) + 1 : 0 }</td>*/}
                                        <td>{(block2SrNo++) + 1}</td>
                                        <span style={{display:"none"}}>{(block2SrNoFile++) + 1}</span>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                                if (field.type == 'file') {
                                                    options.parentIndex = [(block2SrNoFile - 1)];
                                                    field.parent_type = ['multijson', 'multijson'];
                                                    field.isMultiFile = false;
                                                }
                                                return (field.type === 'group') ?
                                                    (
                                                        (field.childrens) ?
                                                            field.childrens.map((cfield: any, ckey: number) => {
                                                                const cOptions: any = { showTitle: false, extraId: '_' + key + '_' + fkey + '_' + ckey };
                                                                if (cfield.type == 'file') {
                                                                    cOptions.parentIndex = [(block2SrNoFile - 1)];
                                                                    cfield.parent_type = ['multijson', 'multijson'];
                                                                    cfield.isMultiFile = false;
                                                                }
                                                                return (['sm_work_package_name_no','sm_actual_date_of_loa','sm_type_payment_security_mechanism_in_rfp'].includes(cfield.key)) ?
                                                                    <td key={key + '_' + fkey+ '_' + ckey}>
                                                                        <FieldComponent options={cOptions} fieldName={cfield.key} field={cfield} />
                                                                    </td>
                                                                    : <span style={{display:"none"}}>
                                                                          <FieldComponent options={cOptions} fieldName={cfield.key} field={cfield} />
                                                                      </span>
                                                            })
                                                            : null
                                                    )
                                                    : <>
                                                        <td key={key + '_' + fkey}>
                                                            <FieldComponent options={options} fieldName={field.key} field={field}/>
                                                        </td>
                                                    </>
                                            })
                                        }
                                    </tr>
                                </>
                                : null;
                        })
                    }
                </>
                </tbody>
            </table>
        </>
    );
};

const AwardModuleSm = (props?: any) => {
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const formData: any = useRef(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [goSteps, setGoSteps] = useState(0);
    const [steps, setSteps] = useState(['active', '']);
    const [isDomLoaded, setIsDomLoaded] = useState<boolean>(false);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomid, discomuserid]);

    // Nav Steps
    const navtab = (opt: number) => {
        if (opt == 0){
            setSteps(['active', '']);
        }
        if (opt == 1){
            setSteps(['completed', 'active']);
        }
        setGoSteps(opt);
    };

    const saveasdraft = (form_tab_no: any) => {
        /*const isValid = validatefrm(`#frm-sm_${idRef.current} .form-control`);
        if (!isValid) {
            return false;
        }*/
        setRefresh(false);
        setIsLoading(true);
        setIsDomLoaded(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('tbl_key', 'sm_award_module');
        myFormData.append('form_tab_no', form_tab_no);
        myFormData.append('action', 'draft');

        let frmAction = '/apiv1/dprfields/saveAwardModuleSmPrePostAward';
        Http.post(frmAction, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setRefresh(true);
                    setIsLoading(false);
                }
            });
    };

    const submit = (form_tab_no: any) => {
        const isValid = validatefrm(`#frm-sm_${idRef.current} .form-control`);
        if (!isValid) {
            return false;
        }
        setRefresh(false);
        setIsLoading(true);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('tbl_key', 'sm_award_module');
        myFormData.append('form_tab_no', form_tab_no);
        myFormData.append('action', 'submit');

        let frmAction = '/apiv1/dprfields/saveAwardModuleSmPrePostAward';
        Http.post(frmAction, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setRefresh(true);
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    const btnLoadState = (val: boolean) => {
        setIsDomLoaded(val);
    };

    return (
        <>
            {roleid == 0 ? null :
                /*<AwardModuleSmDetails apiurl={"/apiv1/formfields/?form_id=35&roleid=" + roleid+"&discom_id=" + discomid} refresh={refresh} />*/
                <>
                    <div className='container'>
                        <div className='customStepper'>
                            <div className="StepperContainer-0-2-1">
                                <div className="StepContainer-0-2-2">
                                    <div className="StepMain-0-2-3">
                                        <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                                        </button>
                                        <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Pre-Award Status</span></div>
                                    </div>
                                </div>
                                <div className="StepContainer-0-2-2">
                                    <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                                    <div className="StepMain-0-2-3">
                                        <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                                        </button>
                                        <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Post-Award Status</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form id={'frm-sm_' + idRef.current} ref={formData} noValidate>
                        {goSteps === 0 && (
                            <div id="smPreFrm">

                                <PreAwardStatus apiurl={"/apiv1/dprfields/form35/?form_id=35&roleid=" + roleid+"&discom_id=" + discomid} refresh={refresh} discomid={discomid} discomuserid={discomuserid} roleid={roleid} frmid={`#frm-sm_${idRef.current}`}  btnLoadState={btnLoadState}/>

                                <div className="formBtnSection justify-content-between is-sticky">
                                    {roleid == 2 || roleid == 3 || roleid == 4 ?
                                        <>
                                            {
                                                (isDomLoaded) &&
                                                <>
                                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)}> Save as Draft</Link>
                                                    <Link to="#" className="btnDraft" onClick={() => submit(1)}>Submit</Link>
                                                </>
                                            }
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        )}
                        {goSteps === 1 && (
                            <div>

                                <PostAwardStatus apiurl={"/apiv1/dprfields/form35/?form_id=35&form_type=post&roleid=" + roleid+"&discom_id=" + discomid} refresh={refresh} discomid={discomid} discomuserid={discomuserid} roleid={roleid} frmid={`#frm-sm_${idRef.current}`}/>

                                <div className="formBtnSection justify-content-between is-sticky">
                                    {roleid == 2 || roleid == 3 || roleid == 4 ?
                                        <>
                                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(2)} >
                                                Save as Draft
                                            </Link>
                                            <Link to="#" className="btnDraft" onClick={() => submit(2)} >
                                                Submit
                                            </Link>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        )}
                    </form>
                    {isLoading ? <LoadingSpinner /> : null}
                </>
            }
        </>
    )
}

export default AwardModuleSm;
