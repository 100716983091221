import React from "react";

class Icon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="76.379"
        height="64.997"
        viewBox="0 0 76.379 64.997"
      >
        <g id="complete" fill="#fff">
          <path
            id="Path_45180"
            d="M69.577 96.514h-.871a1.117 1.117 0 100 2.233h.871a4.574 4.574 0 014.569 4.569v42.714a4.574 4.574 0 01-4.569 4.569H6.8a4.574 4.574 0 01-4.569-4.569v-34.245a1.117 1.117 0 10-2.233 0v34.245a6.81 6.81 0 006.8 6.8h62.778a6.81 6.81 0 006.8-6.8v-42.713a6.81 6.81 0 00-6.799-6.803z"
            data-name="Path 45180"
            transform="translate(0 -87.835)"
          ></path>
          <path
            id="Path_45181"
            d="M1.117 58.532a1.116 1.116 0 001.117-1.117V45.026A4.574 4.574 0 016.8 40.457h21.888a4.578 4.578 0 012.236.584L43.8 48.266a6.817 6.817 0 003.328.87H52.3a1.117 1.117 0 100-2.233h-5.169a4.579 4.579 0 01-2.236-.584l-12.879-7.225a6.818 6.818 0 00-3.328-.87H6.8a6.81 6.81 0 00-6.8 6.8v12.391a1.117 1.117 0 001.117 1.117z"
            data-name="Path 45181"
            transform="translate(0 -38.224)"
          ></path>
          <path
            id="Path_45182"
            d="M199.754 140.732a18.428 18.428 0 0114.492.1 1.117 1.117 0 00.9-2.046 20.663 20.663 0 00-16.255-.114 1.117 1.117 0 10.867 2.058z"
            data-name="Path 45182"
            transform="translate(-168.693 -122.349)"
          ></path>
          <path
            id="Path_45183"
            d="M177.548 245.488a20.62 20.62 0 0030.51-24.779 1.117 1.117 0 00-2.078.819 18.383 18.383 0 01-27.2 22.095 1.117 1.117 0 10-1.229 1.865z"
            data-name="Path 45183"
            transform="translate(-150.686 -192.937)"
          ></path>
          <path
            id="Path_45184"
            d="M123.5 235.747a1.117 1.117 0 101.643-1.512 18.389 18.389 0 01-3.116-20.245 1.117 1.117 0 00-2.021-.95 20.622 20.622 0 003.494 22.707z"
            data-name="Path 45184"
            transform="translate(-100.478 -186.466)"
          ></path>
          <path
            id="Path_45185"
            d="M164.2 199.357a16.149 16.149 0 0015.663-20.07 1.117 1.117 0 10-2.166.543 13.912 13.912 0 11-9.533-9.956 1.117 1.117 0 10.637-2.141 16.146 16.146 0 10-4.6 31.623z"
            data-name="Path 45185"
            transform="translate(-126.013 -147.882)"
          ></path>
          <path
            id="Path_45186"
            d="M215.826 143.665l-15.055 14.762-2.019-2.159a1.732 1.732 0 00-2.445-.082l-3.455 3.23a1.73 1.73 0 00-.082 2.445l4.882 5.222a4.1 4.1 0 00.8.66 1.116 1.116 0 00.6.173h3.185a1.118 1.118 0 00.6-.174 4.115 4.115 0 00.674-.532l6.235-6.113a1.117 1.117 0 10-1.564-1.595l-6.235 6.113c-.023.023-.048.045-.073.067h-2.458a1.669 1.669 0 01-.131-.126l-4.538-4.854 2.719-2.542 2.027 2.168a1.73 1.73 0 002.475.054l15.064-14.77 2.606 2.658-8.044 7.887a1.117 1.117 0 101.564 1.595l8.4-8.239a1.73 1.73 0 00.024-2.446l-3.311-3.377a1.73 1.73 0 00-2.447-.024z"
            data-name="Path 45186"
            transform="translate(-163.673 -127.546)"
          ></path>
          <path
            id="Path_45187"
            d="M125.891 145.484a1.76 1.76 0 001.5.934h.067a1.773 1.773 0 001.508-.831 7.4 7.4 0 012.382-2.318 1.777 1.777 0 00.84-1.553 1.756 1.756 0 00-.89-1.5 6.191 6.191 0 01-2.373-2.269 1.759 1.759 0 00-1.527-.882h-.009a1.758 1.758 0 00-1.526.866 6.329 6.329 0 01-2.236 2.217 1.8 1.8 0 00-.866 1.578 1.746 1.746 0 00.924 1.512 5.343 5.343 0 012.207 2.248zm1.891-6.412zm-.4.6a8.442 8.442 0 002.187 2.1 9.68 9.68 0 00-2.086 2.031 7.55 7.55 0 00-2.1-2.143 8.576 8.576 0 002.003-1.984z"
            data-name="Path 45187"
            transform="translate(-104.482 -122.348)"
          ></path>
          <path
            id="Path_45188"
            d="M394.51 360.03a6.74 6.74 0 012.786 2.835 1.9 1.9 0 001.621 1.01h.073a1.918 1.918 0 001.632-.9 9.242 9.242 0 012.976-2.9 1.922 1.922 0 00.909-1.68 1.9 1.9 0 00-.962-1.622 7.777 7.777 0 01-2.979-2.852 1.9 1.9 0 00-1.65-.952h-.01a1.9 1.9 0 00-1.649.935 8.048 8.048 0 01-2.806 2.783 1.945 1.945 0 00-.938 1.707 1.886 1.886 0 00.997 1.636zm4.393-4.549a10.056 10.056 0 003.1 2.973 11.566 11.566 0 00-2.989 2.913 8.979 8.979 0 00-2.98-3.036 10.223 10.223 0 002.866-2.85z"
            data-name="Path 45188"
            transform="translate(-334.923 -306.11)"
          ></path>
          <path
            id="Path_45189"
            d="M370.268 57.778a6.741 6.741 0 012.786 2.836 1.9 1.9 0 001.621 1.01h.073a1.919 1.919 0 001.632-.9 9.243 9.243 0 012.975-2.9 1.922 1.922 0 00.909-1.68 1.9 1.9 0 00-.962-1.622 7.777 7.777 0 01-2.979-2.852 1.922 1.922 0 00-3.309-.017 8.048 8.048 0 01-2.806 2.783 1.945 1.945 0 00-.938 1.707 1.887 1.887 0 00.998 1.635zm4.393-4.549a10.058 10.058 0 003.1 2.973 11.565 11.565 0 00-2.989 2.913 8.982 8.982 0 00-2.98-3.036 10.222 10.222 0 002.869-2.85z"
            data-name="Path 45189"
            transform="translate(-314.29 -48.859)"
          ></path>
        </g>
      </svg>
    );
  }
}

export default Icon;
