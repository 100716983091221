import { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';

/* Route */
const routes: RouteProps[] = [
  {
    path: '/about',
    element: lazy(() => import('../Pages/About')),
    index: true,
    private: true
  },
  {
    path: '/tm',
    element: lazy(() => import('../Pages/TrainingManagement')),
    index: true,
    private: true
  },
  {
    path: '/tm/tc',
    element: lazy(() => import('../Pages/TrainingManagement/TrainingCalender')),
    index: true,
    private: true
  },
  {
    path: '/tm/tc/input-form/:id?',
    element: lazy(() => import('../Pages/TrainingManagement/TrainingCalender/InputForm')),
    index: true,
    private: true
  },
  {
    path: '/tm/nc',
    element: lazy(() => import('../Pages/TrainingManagement/NominationCentre')),
    index: true,
    private: true
  },
  {
    path: '/knowledge-repository',
    element: lazy(() => import('../Pages/KnowledgeRepository')),
    index: true,
    private: true
  },
  {
    path: '/knowledge-repository/input-form/:id?',
    element: lazy(() => import('../Pages/KnowledgeRepository/InputForm')),
    index: true,
    private: true
  },
  {
    path: '/knowledge-repository/requests',
    element: lazy(() => import('../Pages/KnowledgeRepository/EditRequests')),
    index: true,
    private: true
  }
];

export default routes;