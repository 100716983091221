import React, { useState, useEffect } from "react";
import AccordionPanel from "./AccordionPanel";
import { AccordionProps } from "./accordion.d";
import { Link } from "react-router-dom";

export default function Accordion({
  asTabs,
  onlyOneOpenAccordion = true,
  panels
}: AccordionProps) {
  const canClose = !asTabs;
  const onlyOneOpen = asTabs || onlyOneOpenAccordion;

  const [openPanel, setOpenPanel] = useState(-1);


  useEffect(() => {
    if (panels.length) {
      setOpenPanel(panels.findIndex(({ open }) => open));
    }
  }, [panels]);

  const handleToggle = (index: number) => (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    const wasOpen = index === openPanel;
    setOpenPanel(canClose && wasOpen ? -1 : index);
  };

  const isIndexOpen = (index: number) => {
    return openPanel !== -1 && openPanel === index;
  }

  return (
    <>
      {asTabs && (
        <div className="TabButtons">
          <ul role="tablist" className="TabButtons-list list-unstyled">
            {panels.map(({ heading, id, link }, i) => (
              (link) ?
                <li key={i} className="TabButtons-listItem">
                  <Link
                    aria-controls={`panel-${id}`}
                    aria-selected={isIndexOpen(i)}
                    className="TabButtons-button text-decoration-none"
                    to={link}
                    id={`tab-${id}`}
                    role="tab"
                  >
                    {heading}
                  </Link>
                </li>
                :
                <li key={i} className="TabButtons-listItem">
                  <Link
                    aria-controls={`panel-${id}`}
                    aria-selected={isIndexOpen(i)}
                    className="TabButtons-button text-decoration-none"
                    to={`#${id}`}
                    id={`tab-${id}`}
                    onClick={handleToggle(i)}
                    role="tab"
                  >
                    {heading}
                  </Link>
                </li>
            ))}
          </ul>
        </div>
      )}
      {panels.map(({ content, heading, id, open }, i) => (
        <AccordionPanel
          asTab={asTabs}
          handleToggle={onlyOneOpen ? handleToggle : undefined}
          heading={heading}
          id={id}
          index={i}
          key={i}
          open={onlyOneOpen ? isIndexOpen(i) : open}
        >
          {content}
        </AccordionPanel>
      ))}
    </>
  );
}
