import React from 'react'
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import './chart.scss';

export default class PPCRevenueChart extends React.Component<{}, any> {
    constructor(props: any) {
        super(props);

        this.state = {

            series: [
                {
                    name: "Revenue",
                    data: [0, 18, 36, 60, 40, 38]
                },
                {
                    name: "Power Performance Cost",
                    data: [5, 11, 60, 55, 45, 35]
                }
            ],
            legend: {
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
            },
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#848484','#237FCF'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 2,
                },
                title: {
                    text: 'PPC/Revenue (INR Cr)',
                    align: 'center',
                    style:{
                        fontWeight:  '600',
                        fontSize: '22'
                    },
                },
                grid: {
                    borderColor: '#fff',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0
                    },
                },
                xaxis: {
                    categories: [2017, 2018, 2019, 2020, 2021, 2022],
                },
                yaxis: {
                    show: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                      shadeIntensity: 1,
                      opacityFrom: 0,
                      opacityTo: 0,
                      stops: [0, 90, 100]
                    }
                },
            },
        };
    }
    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={255} />
            </div>
        )
    }
}
