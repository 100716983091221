import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';

/* Route */
const routes: RouteProps[] = [
  {
    path: '',
    element: lazy(() => import('../Pages/Main')),
    index: true
  },
  {
    path: 'detailed-view',
    element: lazy(() => import('../Pages/DetailsPage')),
    index: true
  },
  {
    path: 'discom-view',
    element: lazy(() => import('../Pages/DiscomPage')),
    index: true
  },
];

export default routes;