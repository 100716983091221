import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LossReduction from './LossReduction';
import SmartMetering from './SmartMetering';
import InnerTemplate from "../../../Components/InnerTemplate/InnerTemplate";
import Header from "../../../Layout/Default/Element/Header";
import Footer from "../../../Layout/Default/Element/Footer";

const QuarterlyTargetsDetails = () => {

  const [selected, setSelected] = useState("SmartMetering");
  useEffect(() => {
    setSelected("SmartMetering");
  }, []);
  const changeHandler = (e: any) => {
    setSelected(e.target.value);
  };
  const sanctionLetterMainForm = () => {
    document.body.classList.add('test');
  }
  return (
    <>
      <Header />
      <div className="mainWrap">
        {/* <InnerTemplate PageTitle="Monthly Targets" /> */}
        <section className="InnerBanner">
          <div className="container">
            <ul className="BreadCumb list-unstyled mb-0">
              <li><Link to="/" className="text-decoration-none">Home</Link></li>
              <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
              <li><Link to="/monitoring" className="text-decoration-none">Monitoring</Link></li>
              <li>Monthly Targets</li>
            </ul>
          </div>
        </section>

        <section className="financialForm">
          <div className="container">
            <div className="financialMainFrom inputFromNew">
              <div className="tab-content border-0 p-0">
                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                  <div className='radioTitleSection'>
                    <div className='row mx-0 gx-0 justify-content-between'>
                      <div className='col-lg-12 col-xl-6 col-xxl-5'>
                        <div className="radioYear">
                          <div className="row justify-content-center align-items-center mx-0 gx-0">
                            <div className="col-5 col-md-4">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="SanctionLetter" value="SmartMetering" id="SmartMetering" checked={selected === "SmartMetering"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="SmartMetering">
                                  Smart Metering
                                </label>
                              </div>
                            </div>
                            <div className="col-5 col-md-4">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="SanctionLetter" value="Loss-Reduction" id="Loss-Reduction" checked={selected === "Loss-Reduction"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="Loss-Reduction">Loss Reduction</label>
                              </div>
                            </div>
                            <div className="col-5 col-md-4">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="SanctionLetter" value="Modernization" id="Modernization" checked={selected === "Modernization"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="Modernization">Modernization</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div aria-hidden={selected !== "SmartMetering" ? true : false}><SmartMetering /></div>
                  <div aria-hidden={selected !== "Loss-Reduction" ? true : false}><LossReduction /></div>
                  <div aria-hidden={selected !== "Modernization" ? true : false}>Coming Soon.</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div >
      <Footer />
    </>
  );
}

export default QuarterlyTargetsDetails;