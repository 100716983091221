import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import { FiCheck, FiX, FiExternalLink } from 'react-icons/fi'
import swal from 'sweetalert';
import FdDiscomHistory from "./FdDiscomHistory";
import { Http } from "../../Core/Services/HttpService";
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineAntDesign } from "react-icons/ai";
import FieldComponent from "../../FieldComponent";
import { useStateMounted } from '../../Core/Hooks';
import { Storage } from "../../Core/Services/StorageService";
import FdDiscomName from "./FdDiscomName";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

export const FundDisbursalLink = () => {
    const formData: any = useRef(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);;
    const [error, setError] = useStateMounted<string>();
    const [fromList, setFormList] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [getpagebacklink, setPageBackLink] = useState<any>(null);
    const [getpagelinkid, setPageLinkId] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [type_of_entity, setTypeOfEntity] = useState<any>(0);
    const [getdocumentationids, setDocumentationIds] = useState<any>(null);
    const [getButtonStatus, setButtonStatus] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    const [getsubmittedstatus, setSubmittedStatus] = useState<any>(0);
    const [getfddiscomhistory, setSubmittedHistory] = useState<any[]>([]);
    const [fields, setFields] = useState<any[]>([]);
    const [getsavedraft, setSaveDraft] = useState<any>(0);
    const [issDgSignatureAccepted, setIsDgSignatureAccepted] = useState<any>(0);
    const [isDgSignature, setIsDgSignature] = useState<any>(null);
    const [aplicationRefid, setAplicationRefId] = useState<any>(0);
    const location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (location.state) {
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
            } else {
                if (getsavedraft != 1) {
                    navigate('/fund-disbursal');
                }
            }
        })();
    }, [fromList, getcomponentname, gettranchename, getcomponentid, gettrancheid, getaplicationtrackerid]);

    const gottopage = (gottopage: any, id: any) => {
        setPageLink(gottopage);
        setPageLinkId(id);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: gettrackerid, id: getpagelinkid, data: fromList, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
            }
        })();
    }, [getpagelink]);

    const navtab = () => {
        setPageBackLink('/fund-disbursal');
    }

    useEffect(() => {
        (async () => {
            if (getpagebacklink) {
                navigate(getpagebacklink, { state: { component_id: getcomponentid, tranche_id: gettrancheid } });
            }
        })();
    }, [getpagebacklink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setTypeOfEntity(Storage.get('type_of_entity'));
    }, [roleid]);

    const getFields = () => {
        (async () => {
            setError('');
            setFields([]);
            if (discomid && gettrancheid && discomuserid && getaplicationtrackerid) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 1000,
                    tranche_id: gettrancheid,
                    discom_user_id: discomuserid,
                    application_id: getaplicationtrackerid,
                    component_id: getcomponentid
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                setIsLoading(true);
                await Http.get(`/apiv1/fdfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoading(false);
                                setFields(res.fields);
                                setSubmittedStatus(res.submittedStatus);

                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoading(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [roleid, discomid, getaplicationtrackerid, getcomponentid]);

    const getRequestforFundRelease = () => {
        (async () => {
            setIsLoading(true);
            setError('');
            setFormList([]);
            if (getaplicationtrackerid) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    component_id: getcomponentid,
                    tranche_id: gettrancheid,
                    discom_user_id: discomuserid,
                    application_id: getaplicationtrackerid
                };
                await Http.get(`/apiv1/getfddiscomsubmitedapplication`, { params })
                    .then((res) => {
                        if (res.data) {
                            if (res.data && Array.isArray(res.data)) {
                                if (!res.data.length) setError("No data found.");
                                setFormList(res.data);
                                setComponentname(res.component_name);
                                setTranchename(res.tranche_name);
                                setComponentId(res.component_id);
                                setTrancheId(res.tranche_id);
                                setAplicationTrackerId(res.aplication_tracker_id);
                                setDocumentationIds(res.documentation_ids);
                                setButtonStatus(res.button_status);
                                setSubmittedStatus(res.submitted_status);
                                setSubmittedHistory(res.funddisbursal_history);
                                setIsDgSignature(res.is_dg_signature);
                                setIsDgSignatureAccepted(res.is_dg_signature_accepted);
                                setAplicationRefId(res.aplication_ref_id);
                                setIsLoading(false);
                            } else {
                                setError("Invalid data type.");
                                setIsLoading(false);
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoading(false);
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getRequestforFundRelease();
        })();
    }, [getaplicationtrackerid]);

    const submitToForward = () => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);

        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('discom_id', discomid);
        myFormData.append('component_id', getcomponentid);
        myFormData.append("tranche_id", gettrancheid);
        myFormData.append("aplication_tracker_id", getaplicationtrackerid);
        myFormData.append("ref_id", gettrackerid);

        swal({
            title: "Are you sure to submit this form?",
            text: "After submit, You will not able to edit this form.",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {

                Http.post('/apiv1/fund-disbursal-forward', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        setIsLoading(false);
                        swal("Warning", res.message, "info");
                    } else {
                        setIsLoading(false);
                        setRefresh(true);
                        swal("Fund Disbursal Form", "Your data has been submitted.", "success");
                        navigate('/fund-disbursal');
                    }
                });
            } else {
                setIsLoading(false);
                // swal("Cancelled", "", "error");
            }
        });

    }

    const submitToBackward = () => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('discom_id', discomid);
        myFormData.append('component_id', getcomponentid);
        myFormData.append("tranche_id", gettrancheid);
        myFormData.append("aplication_tracker_id", getaplicationtrackerid);
        myFormData.append("ref_id", gettrackerid);

        swal({
            title: "Are your sure want to return this form?",
            text: "",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/fund-disbursal-backward', myFormData, config)
                    .then((res) => {
                        if (res._resultflag == 0) {
                            setIsLoading(false);
                            swal("Warning", res.message, "info");
                        } else {
                            setIsLoading(false);
                            setRefresh(true);
                            swal("Fund Disbursal Form", "The form has been returned for correction", "success");
                            navigate('/fund-disbursal');
                        }
                    });

            } else {
                setIsLoading(false);
            }
        });
    }

    const saveDraft = () => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('discom_id', discomid);
        myFormData.append('component_id', getcomponentid);
        myFormData.append("tranche_id", gettrancheid);
        myFormData.append("aplication_tracker_id", getaplicationtrackerid);
        myFormData.append("ref_id", gettrackerid);

        Http.post('/apiv1/fund-release-file-upload', myFormData, config)
            .then((result) => {
                if (result._resultflag == 0) {
                    setIsLoading(false);
                    setRefresh(true);
                    swal("Warning", result.message, "info");
                } else {
                    setIsLoading(false);
                    setRefresh(true);
                    swal("Fund Disbursal Form", result.message, "success");
                    setSaveDraft(1);
                    setFormList(result.release_funds_documentation);
                    setButtonStatus(result.button_status);
                    setSubmittedStatus(result.submitted_status);
                }
            });
    }

    const saveDgAccepted = () => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append("aplication_ref_id", aplicationRefid);

        swal({
            title: "You will not be able to return this application once you digitally sign it.",
            text: "",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/fd-accepte-dg-signature', myFormData, config)
                    .then((res) => {
                        if (res._resultflag == 0) {
                            setIsLoading(false);
                            swal("Warning", res.message, "info");
                        } else {
                            setIsDgSignatureAccepted(res.isDgSignatureAccepted);
                            setIsLoading(false);
                            setRefresh(true);
                            swal("Fund Disbursal Form", "The form has been accepted.You can now digitally sign and submit.", "success");
                        }
                    });

            } else {
                setIsLoading(false);
            }
        });
    }

    const checkSignature = async (id: any) => {

        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) {
            footerspinner.remove();
        }
        const params = {
            roleid: roleid,
            discom_id: discomid,
            tranche_id: gettrancheid,
            discom_user_id: discomuserid,
            application_id: getaplicationtrackerid,
            component_id: getcomponentid,
            aplication_ref_id: id
        };
        await Http.get(`/apiv1/dg-signature-verification-status`, { params })
            .then((res) => {
                if (res.data && res._resultflag == 1) {
                    if (res.data && Array.isArray(res.data)) {
                        setFormList(res.data);
                        setComponentname(res.component_name);
                        setTranchename(res.tranche_name);
                        setComponentId(res.component_id);
                        setTrancheId(res.tranche_id);
                        setAplicationTrackerId(res.aplication_tracker_id);
                        setDocumentationIds(res.documentation_ids);
                        setButtonStatus(res.button_status);
                        setSubmittedStatus(res.submitted_status);
                        setSubmittedHistory(res.funddisbursal_history);
                        setIsDgSignature(res.is_dg_signature);
                        setIsDgSignatureAccepted(res.is_dg_signature_accepted);
                        setAplicationRefId(res.aplication_ref_id);
                    }
                } else {
                    setTimeout(() => { checkSignature(id); }, 5000);
                }
            })
            .catch((error) => {
                setTimeout(() => { checkSignature(id); }, 5000);
            });
    }

    return (
        <>
            <Header />
            <div className="mainWrap">
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li>Request for Fund Release</li>
                            <FdDiscomName />
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className="radioYear px-3">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="RevenueDetailsTable">
                                                <div className="caption-top">
                                                    <caption className="p-0 d-block bg-transparent">Request for Fund Release</caption>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row pt-3'>
                                        <div className='col-md-4'>
                                            <label className="mb-1" >Component </label>
                                            <input type="text" className='form-control' defaultValue={getcomponentname} readOnly={true} />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="mb-1">Tranche/Installment </label>
                                            <input type="text" className='form-control' defaultValue={gettranchename} readOnly={true} />
                                        </div>
                                    </div>
                                    <form noValidate ref={formData} >
                                        <div className="table-responsive RevenueDetailsTable mt-2">
                                            <div className='radioYear d-flex align-items-center'>
                                                <div className='col-md-6 text-end'>
                                                    <div className="caption-top">
                                                        <caption className="py-0 d-block bg-transparent">Documentation required for release of funds</caption>
                                                    </div>                                                    
                                                </div>
                                                {(roleid == 4) ?
                                                    <div className='col-md-5 text-end pe-3'>
                                                        <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/uploads/Fund-Disbursal/documents/How_to_digital_sign_the_documents.pdf'}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" />How to digital sign</a>
                                                    </div>
                                                 :null
                                                }
                                                {(roleid == 4) ?
                                                <>
                                                <div className='col-md-1 text-end'>
                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-multi-forms/?page_link_id=' + getdocumentationids + '&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                </div>
                                                </>
                                                :
                                                <>
                                                <div className='col-md-6 text-end pe-3'>
                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-multi-forms/?page_link_id=' + getdocumentationids + '&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                </div>
                                                </>
                                                }

                                                   
                                            </div>
                                            <table className="table caption-top fdLinkTable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "4%" }}>S. No.</th>
                                                        <th style={{ width: "50%" }}>Form Title</th>
                                                        <th style={{ width: "3%" }}>Status</th>
                                                        <th style={{ width: "3%" }}>Link</th>
                                                        <th style={{ width: "10%" }}>Supporting documents</th>
                                                        {(roleid == 4 && issDgSignatureAccepted == 1) ?
                                                            <th style={{ width: "10%" }}>Digital Signature</th>
                                                            : null
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(fromList.length > 0) ?
                                                        <>
                                                            {
                                                                fromList.map((item: any, key: number) => {
                                                                    return <>
                                                                        <tr key={key}>
                                                                            <td>{key + 1}</td>
                                                                            <td>{item.title}</td>
                                                                            <td className="text-cenetr">
                                                                                {item.submitted_status == "1" ?
                                                                                    <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                    :
                                                                                    <>
                                                                                    <FiX className="p-0 mb-0 h5 text-danger" />
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                            <td className="text-cenetr">
                                                                                {item.link != "" ?
                                                                                    <Link className='text-primary' to="" onClick={() => gottopage(item.link, item.id)}><FiExternalLink className="p-0 mb-0 h5 text-primary" /></Link>
                                                                                    : null
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {item.link == "" ?
                                                                                    <>
                                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[item.id] && fields[item.id].key ? fields[item.id].key : ''} field={fields[item.id] && fields[item.id] ? fields[item.id] : ''} />
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                                {
                                                                                    [1, 4, 7, 10, 2, 3, 11].includes(item.id) && item.submitted_status == "1" ?
                                                                                        item.id == 1 ?
                                                                                            <>
                                                                                                {item.is_dg_signature == 1 ?
                                                                                                    <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    :
                                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=17&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                        item.id == 2 ?
                                                                                            <>
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                        :
                                                                                                        <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=21&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&fd_link_pdf=1'}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    }
                                                                                                </>
                                                                                                <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pp-export-excel/?form_id=21&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&module=request_for_fund_release'}><AiOutlineFileExcel className="pl-3 mb-0 h5 ms-4 text-primary" /></a>
                                                                                            </>
                                                                                            :
                                                                                        item.id == 3 ?
                                                                                            <>
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                        :
                                                                                                        <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=39&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    }
                                                                                                </>
                                                                                                <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-fp-export-excel/?form_id=39&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&module=request_for_fund_release'}><AiOutlineFileExcel className="pl-3 mb-0 h5 ms-4 text-primary" /></a>
                                                                                            </>
                                                                                            :
                                                                                        item.id == 4 ?
                                                                                            <>
                                                                                                {item.is_dg_signature == 1 ?
                                                                                                    <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    :
                                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=18&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                        item.id == 7 && type_of_entity == "2" ?
                                                                                            <>
                                                                                                {item.is_dg_signature == 1 ?
                                                                                                    <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    :
                                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=23&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                        item.id == 7 && type_of_entity != "2" ?
                                                                                            <>
                                                                                                {item.is_dg_signature == 1 ?
                                                                                                    <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    :
                                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=24&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                        item.id == 10 ?
                                                                                            <>
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                        :
                                                                                                        <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=19&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&claim_download_link=all'}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    }
                                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-cf-export-excel/?form_id=19&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&module=request_for_fund_release'}><AiOutlineFileExcel className="pl-3 mb-0 h5 ms-4 text-primary" /></a>
                                                                                                </>
                                                                                            </>
                                                                                            :
                                                                                        item.id == 11 ?
                                                                                            <>
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <a target="_blank" href={item.supporting_document}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                        :
                                                                                                        <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?form_id=20&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&claim_download_link=all'}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                    }
                                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-cf-export-excel/?form_id=20&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&module=request_for_fund_release'}><AiOutlineFileExcel className="pl-3 mb-0 h5 ms-4 text-primary" /></a>
                                                                                                </>
                                                                                            </>
                                                                                            : null
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </td>
                                                                            {(roleid == 4 && issDgSignatureAccepted == 1) ?
                                                                                <td>
                                                                                    {
                                                                                        [1, 4, 7, 10, 2, 3, 11].includes(item.id) && item.submitted_status == "1" ?
                                                                                            item.id == 1 ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=17&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 2 ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=21&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&fd_link_pdf=1'}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 3 ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=39&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 4 ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=18&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 7 && type_of_entity == "2" ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=23&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 7 && type_of_entity != "2" ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=24&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 10 ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=19&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&claim_download_link=all'}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                            item.id == 11 ?
                                                                                                <>
                                                                                                    {item.is_dg_signature == 1 ?
                                                                                                        <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                                        :
                                                                                                        <>
                                                                                                            {getsubmittedstatus == "1" ?
                                                                                                                <a target="_blank" onClick={() => checkSignature(item.id)} href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-digital-signature/?form_id=20&roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&type=export&application_id=' + getaplicationtrackerid + '&discomuserid=' + discomuserid + '&claim_download_link=all'}><AiOutlineAntDesign className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                                                : null
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                : null
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </td>
                                                                                : null
                                                                            }
                                                                        </tr>
                                                                    </>
                                                                })
                                                            }
                                                        </>
                                                        : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {(getButtonStatus == "active" && getsubmittedstatus == 1) ?
                                            <>
                                                <div>
                                                    <div className="form-group mb-4">
                                                        <label className="d-block" htmlFor="remarks">Remarks</label>
                                                        <textarea className="w-100" id="remarks" name="remarks"></textarea>
                                                    </div>
                                                </div>

                                            </>
                                            : null
                                        }

                                        <>
                                            <div className="row align-items-center fundinlinecss other-document-popup mb-4">
                                                <div className="col-md-8 other_documents">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <label>Other Document</label>
                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[0] && fields[0].key ? fields[0].key : ''} field={fields[0] && fields[0] ? fields[0] : ''} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        <FdDiscomHistory FdHistoryBlock={getfddiscomhistory} />
                                        <div className="formBtnSection justify-content-between">
                                            <Link to="#" className="btnCancle" onClick={() => navtab()}>Back</Link>
                                            {(getsubmittedstatus == 1 && (roleid == 2 || roleid == 3 || roleid == 4) && issDgSignatureAccepted != 1) ?
                                                <Link to="#" className="btnDraft" onClick={() => saveDraft()} >Save Draft</Link>
                                                : null
                                            }

                                            {getButtonStatus == "active" && getsubmittedstatus == "1"
                                                ?
                                                <>
                                                    {roleid != 2 ?
                                                        <>
                                                            <Link to="#" className="btnCancle" onClick={() => submitToBackward()} >Return</Link>
                                                        </>
                                                        : null
                                                    }

                                                    {(roleid == 4 && issDgSignatureAccepted != 1) ?
                                                        <Link to="#" className="btnDraft" onClick={() => saveDgAccepted()} >Accept And Digital Sign</Link>
                                                        : null
                                                    }

                                                    {(roleid == 4 && isDgSignature != 'in-active') ?
                                                        <Link to="#" className="btn btnSubmit" onClick={() => submitToForward()} >
                                                            {roleid == 2 ? "Submit to Reviewer" : null}
                                                            {roleid == 3 ? "Submit to Approver" : null}
                                                            {roleid == 4 ? "Submit to Nodal Agency" : null}
                                                            {roleid == 5 ? "Submit to Approver" : null}
                                                            {roleid == 6 ? "Approve" : null}
                                                        </Link>
                                                        : null
                                                    }

                                                    {roleid != 4 ?
                                                        <Link to="#" className="btn btnSubmit" onClick={() => submitToForward()} >
                                                            {roleid == 2 ? "Submit to Reviewer" : null}
                                                            {roleid == 3 ? "Submit to Approver" : null}
                                                            {roleid == 4 ? "Submit to Nodal Agency" : null}
                                                            {roleid == 5 ? "Submit to Approver" : null}
                                                            {roleid == 6 ? "Approve" : null}
                                                        </Link>
                                                        : null
                                                    }
                                                </>
                                                : null
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <>
                    {isLoading ? <LoadingSpinner /> : null}
                </>
            </div >
            <Footer />
        </>
    );
};
export default FundDisbursalLink;