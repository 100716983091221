import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiUpload } from 'react-icons/bi';
import swal from 'sweetalert';

// Images
import FieldComponent from "../../../FieldComponent";
import { Storage } from "../../../Core/Services/StorageService";

type SmartMeteringProps = {
    apiurl: string;
};

const Block1 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);
    const [years, setYears] = useState<number[]>([]);
    const [getcollspan, setCollspan] = useState<any>(0);
    const [months, setMonths] = useState("Apr")
    const [getmonths, setMonthsSmall] = useState("apr")
    const [getpagelink, setPageLink] = useState<any>(null);
    const [district, setDistricts] = useState<any>(null);
    
    const yearRange = (start: any, stop: any, step: number) => {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
    };

    useEffect(() => {
        if (props.field3) {
            if(props.field3.value != '')
            {
                setHasBlock1FieldsDeletes(false);
                setBlock1fields([]);
                setNewBlock1fields(props.field3.addnew);
                setTimeout(() => {
                    const fields2 = props.field3.childrens.map((items: any) => {
                        return {
                            fields: items,
                            delete: false
                        }
                    })
                    setBlock1fields(fields2);
                    initselect();
                    multijsoninit();
                }, 500);
            }else{
                setBlock1fields([]);
            }
        }else{
            setBlock1fields([]);
        }
    }, [props.field3]);

    useEffect(() => {
        if (props.year) {
            setYears(props.year);
        }
    }, [props.year]);

    useEffect(() => {
        if (props.month) {
            const monthstr = props.month.charAt(0).toUpperCase() + props.month.slice(1);
            setMonths(monthstr);
            setMonthsSmall(props.month);
        }
    }, [props.month]);

    useEffect(() => {
        if (props.district) {
            setDistricts(props.district);
        }
    }, [props.district]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [block2Fields]);

    return (
        <>
        <table className="table caption-top newTableUcss">
        <thead className='text-center'>
            <tr>
                <th colSpan={8}></th>
                <th colSpan={5} className="border-end noPadding"><span>Progress</span></th>
                <th colSpan={3} className="border-end noPadding"><span>Cumulative Achievement</span></th>
                <th colSpan={3}></th>
            </tr>
            <tr>
                <th rowSpan={2}>Sl No.</th>
                <th rowSpan={2}>Major Components</th>
                <th rowSpan={2}>Sub Component</th>
                <th rowSpan={2}>Item Specification</th>
                <th rowSpan={2}>Unit</th>
                <th colSpan={3} className="border-end noPadding"><span>Sanctioned</span></th>
                <th colSpan={3} className="border-end noPadding"><span>Award</span></th>
                <th colSpan={2} className="border-end noPadding"><span>Survey</span></th>
                <th colSpan={1} className="border-end noPadding"><span>Material Supplied Quantity</span></th>
                <th colSpan={1} className="border-end noPadding"><span>Erected Quantity</span></th>
                <th colSpan={1} className="border-end noPadding"><span>Charged Quantity</span></th>
                <th rowSpan={2}>Physical progress (in units)</th>
                <th rowSpan={2}>Physical Progress (%)</th>
                <th rowSpan={2}>Financial Progress linked with physical progress</th>
            </tr>
            <tr className="text-center">
                <th className="fw-normal">Sanctioned Unit Rate  (INR/unit)</th>
                <th className="fw-normal">Sanctioned Qnty</th>
                <th className="fw-normal">Sanctioned Cost (INR Lakh)</th>
                <th className="fw-normal">Award Rate (INR/unit)</th>
                <th className="fw-normal">Award Qnty</th>
                <th className="fw-normal">Award Cost (INR Lakh)</th>
                <th className="fw-normal">Surveyed Qnty</th>
                <th className="fw-normal">Surveyed Cost (INR Lakh)</th>
                <th className="fw-normal">W(m)-40%</th>
                <th className="fw-normal">W(e)-40%</th>
                <th className="fw-normal">W(c)-20%</th>
            </tr>
        </thead>
        <tbody>
            {
                (block2Fields.length)
                    ? block2Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                            <tr key={key} className={'cat-'+item.fields[0].value}  >
                                <td className="text-center" >{(block2SrNo++) + 1}</td>
                                {
                                    item.fields.map((field: any, fkey: number) => {
                                        return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} />
                                                </td>
                                                 
                                        </>;
                                    })
                                }
                            </tr>
                            </>
                            : null;
                    })
                    : null
            }
            <>
                {
                    (block2Fields.length && props.field4)
                        ? <>
                            <tr>
                                <td colSpan={7} className="text-center"><h5>Total</h5></td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[2].key} field={props.field4.childrens[2]} /></td>
                                <td colSpan={2}>&nbsp;</td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[4].key} field={props.field4.childrens[4]} /></td>
                                <td colSpan={1}>&nbsp;</td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[5].key} field={props.field4.childrens[5]} /></td>
                                <td colSpan={3}></td> 
                                <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[6].key} field={props.field4.childrens[6]} /></td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[7].key} field={props.field4.childrens[7]}  /></td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[8].key} field={props.field4.childrens[8]}  /></td>                                
                            </tr>
                        </>
                    : null
                }
            </> 
        </tbody>
    </table>
        </>
    );
}

const General = (props?: any) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [apiurl, setApiurl] = useState<any>(null);
    const [districts, setDistricts] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState("2023");
    const formData: any = useRef(null);
    const [month, setMonth] = useState("apr");
    const [fieldsvalues, setFieldsValues] =  useState<any>(0);

    useEffect(() => {
        (async () => {
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    setYear(result.year);
                    setDistricts(result.district_id);
                    setMonth(result.month);
                    setFieldsValues(result.fields[0].value);
                    setTimeout(() => {
                        inputEventChecker.removeAllEvent();
                        // init();
                        multijsoninit();
                    }, 1000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    }, [props.apiurl]);
    
    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
            <>  
                <div className="table-responsive RevenueDetailsTable mt-4">
                    <div className="row justify-content-center align-items-center mt-2 mx-0">
                      
                        <div className="col-sm-5">
                            <div className="form-check ps-0">
                                <div className='caption'>Percentage achievement of the project</div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-check ps-0">
                                <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            {/* <div className="form-check ps-0">
                                <div className='caption'>(Sum of financial progress for each line item / Total Award Cost)</div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="table-responsive RevenueDetailsTable PqTable">
                    <Block1 field3={fields[0]} field4={fields[3]} year={year} month={month} district={districts} />
                </div>  
            </>
            : null
        );
    }
};

const FieldWorksQualityInspectionForm = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [districts, setDistricts] = useState<any>(0);
    const [year, setYear] = useState<any>(0);
    const [month, setMonth] = useState("apr");

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    useEffect(() => {
        if (props.districts) 
        {
            setDistricts(props.districts);
        }
    }, [props.districts]);

    useEffect(() => {
        if (props.year) 
        {
            setYear(props.year);
        }
    }, [props.year]);

    useEffect(() => {
        if (props.month) 
        {
            setMonth(props.month);
        }
    }, [props.month]);

    return (
        <>
        {roleid == 0 ? null :
            <General apiurl={"/apiv1/qmfields/?form_id=33&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=field_works_qualit_inspection&district_id=" + districts + "&year=" + year+ "&month=" + month} refresh={refresh}/>
        }
        </>
    )
}
export default FieldWorksQualityInspectionForm;