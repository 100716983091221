import React from 'react'
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import './chart.scss';

export default class AtcLossChart extends React.Component<{}, any> {
    constructor(props: any) {
        super(props);

        this.state = {

            series: [
                {
                    name: "Billing Efficiency",
                    data: [85, 70, 92]
                },
                {
                    name: "Collection Efficiency",
                    data: [72, 60, 82]
                },
                {
                    name: "AT&C Loss (%)",
                    data: [40, 30, 20]
                }
            ],
            legend: {
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
            },
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#848484', '#237FCF', '#F86933'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 2,
                },
                title: {
                    text: 'AT&C Loss (%)',
                    align: 'center',
                    style:{
                        fontWeight:  '600',
                        fontSize: '22'
                    },
                },
                grid: {
                    borderColor: '#fff',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0
                    },
                },
                yaxis: {
                    show: false,
                },
                xaxis: {
                    type: 'category',
                    categories: [
                      "2010",
                      "2015",
                      "2020",
                    ]
                },
                fill: {
                    type: "gradient",
                    gradient: {
                      shadeIntensity: 1,
                      opacityFrom: 0,
                      opacityTo: 0,
                      stops: [0, 90, 100]
                    }
                },
            },
        };
    }
    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={255} />
            </div>
        )
    }
}
