import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';
import DiscomirAccess from '../Middlewares/DiscomirAccess';
import ReportAccess from '../Middlewares/ReportAccess';

/* Route */
const routes: RouteProps[] = [
  {
    path: '',
    element: lazy(() => import('../Pages/Main')),
    index: true,
    private: true,
    middleware: DiscomirAccess
  },
  {
    path: 'input-form',
    element: lazy(() => import('../Pages/InputForm')),
    private: true,
    middleware: DiscomirAccess
  },
  {
    path: 'input-form/:discomId/:year?',
    element: lazy(() => import('../Pages/InputForm')),
    private: true,
    middleware: DiscomirAccess
  },
  {
    path: 'report/:type?',
    element: lazy(() => import('../Pages/Report')),
    private: true,
    middleware: ReportAccess
  },
];

export default routes;