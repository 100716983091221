import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import { Http } from '../../Core/Services/HttpService';
import ExcelIcn from '../../images/excel.svg';
import PdfIcn from '../../images/pdf.svg';
import Select from "react-select";
import { RiFileExcel2Line } from "react-icons/ri";
import { BsSearch, BsFillFileEarmarkPdfFill, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { constant, set } from "lodash";
import { Link } from "react-router-dom";
import { sub } from "date-fns";
import { Accordion } from "react-bootstrap";

const SmMisReports = (props: any) => {
    let currentYearValue = new Date().getFullYear();
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const d = new Date();
    if (d.getMonth() > 2) {
        currentYearValue++;
    }
    let currentMonthValue = months[d.getMonth()];

    const [discomList, setDiscomList] = useState([{ 'id': 0, 'discom_name': 'All' }]);
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [selectedMultiDiscom, setSelectedMultiDiscom] = useState<any>();
    const [districtList, setDistrictList] = useState([{ 'id': 0, 'district_name': 'All' }]);
    const [selectedMajorComponent, setSelectedMajorComponent] = useState<any>(0);
    const [selectedMultiMajorComponent, setSelectedMultiMajorComponent] = useState<any>();
    const [selectedSubComponent, setSelectedSubComponent] = useState<any>(0);
    const [subComponentList, setSubComponentList] = useState([{ 'id': 0, 'title': 'All' }]);
    const [itemSpecificationList, setItemSpecificationList] = useState([{ 'id': 0, 'title': 'All' }]);
    const [smMajorComponents, setSmMajorComponents] = useState([{ 'id': 0, 'title': 'All' }]);
    const [sanctionLetterData, setSanctionLetterData] = useState<any>();
    const [selectedDistrict, setSelectedDistrict] = useState<any>(0);
    const [selectedState, setSelectedState] = useState<any>(0);
    const [selectedItemSpecification, setSelectedItemSpecification] = useState<any>(0);
    const [reportType, setReportType] = useState<any>('Allindia');
    const [misReportDataCount, setMisReportDataCount] = useState<any>(0);
    const [searchText, setSearchText] = useState<any>();
    const [statesList, setStatesList] = useState([{ 'id': 0, 'title': 'All' }]);
    const [getstate, setState] = useState<any>(0);
    const [district, setDistrict] = useState<any>(0);
    const [subcomponent, setSubcomponent] = useState<any>(0);
    const [selectedSummaryReport, setSelectedSummaryReport] = useState<any>(0);
    const [selectedMonitoringParameters, setSelectedMonitoringParameters] = useState<any>();
    const [selectedMultiMonitoringParameters, setSelectedMultiMonitoringParameters] = useState<any>();
    const [getstatesapiurl, setStatesApiurl] = useState<any>(null);
    const [reportCols, setReportCols] = useState<any>(null);
    const [month, setMonths] = useState(currentMonthValue);
    const [year, setYear] = useState(currentYearValue);
    const [drildown, setDrildown] = useState(false);
    const [option, setOption] = useState('C');
    const [subtotal, setSubtotal] = useState('N');
    const [agency, setAgency] = useState('0');
    const [currencyUnit, setCurrencyUnit] = useState(0);

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const searchParams = new URLSearchParams(window.location.search);
    const statefilter = searchParams.get('statefilter');

    const handleYearSelect = (evt?: any) => {
        setCurrentPage(0);
        setYear(evt.target.value);
    };

    const handleMonthSelect = (evt?: any) => {
        setCurrentPage(0);
        setMonths(evt.target.value);
    };

    const handleDiscomSelect = (selectedDiscom: any) => {
        setCurrentPage(0);
        let multiSelectedDiscom = selectedDiscom.map((item: any) => item.value).join(",");
        setSelectedMultiDiscom(multiSelectedDiscom);
        setSelectedDiscom(selectedDiscom);
    };

    const handleDistrictSelect = (selectedDistrict: any) => {
        setCurrentPage(0);
        setSelectedDistrict(selectedDistrict);
        setDistrict(selectedDistrict.map((item: any) => item.value).join(","));
    };

    const handleStateSelect = (SelectedState: any) => {
        setCurrentPage(0);
        if (reportType == 'DisStates')
            setState(SelectedState.map((item: any) => item.value).join(","));
        else
            setState(SelectedState.value);
        setSelectedState(SelectedState);
    };

    const handleMajorComponentSelect = (selectedMajorComponent: any) => {
        setCurrentPage(0);
        let multiSelectedMajorComponent = selectedMajorComponent.map((item: any) => item.value).join(",");
        setSelectedMultiMajorComponent(multiSelectedMajorComponent);
        setSelectedMajorComponent(selectedMajorComponent);
    };

    const handleSubComponentSelect = (selectedSubComponent: any) => {
        setCurrentPage(0);
        setSelectedSubComponent(selectedSubComponent);
        setSubcomponent(selectedSubComponent.map((item: any) => item.value).join(","));
    };

    const handleItemSpecificationSelect = (selectedItemSpecification: any) => {
        setCurrentPage(0);
        setSelectedItemSpecification(selectedItemSpecification);
    };

    const handleSummaryReportSelect = (selectedSummaryReport: any) => {
        setCurrentPage(0);
        setSelectedSummaryReport(selectedSummaryReport);
    };

    const handleMonitoringParametersSelect = (selectedMonitoringParameters: any) => {
        setCurrentPage(0);
        let multiMonitoringParameters = selectedMonitoringParameters.map((item: any) => item.value).join(",");
        setSelectedMultiMonitoringParameters(multiMonitoringParameters);
        setSelectedMonitoringParameters(selectedMonitoringParameters);
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value);
    }

    const handleSearchEnter = (event: any) => {
        if (event.key === 'Enter') {
            fetchSanctionLetterData(searchText);
        }
    }

    // useEffect(() => {
    //     if (misReportDataCount > 0) {
    //         let footerspinner:any = document.getElementById('footer-spinner');
    //         if ( footerspinner ) footerspinner.classList.add('d-none');
    //     }
    // }, [misReportDataCount]);

    const fetchSanctionLetterData = async (search: any, optParams?: {}) => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        let params = {
            searchText: searchText,
            reportType: reportType,
            stateid: getstate,
            districtid: district,
            discomid: selectedMultiDiscom,
            majorcomponentid: selectedMultiMajorComponent,
            subcomp: subcomponent,
            itemSpec: selectedItemSpecification.value,
            summaryReport: selectedSummaryReport.value,
            monitoringPar: selectedMultiMonitoringParameters,
            year: year,
            month: month,
            module: 'sm',
            page: curPage,
            pageSize: pageSize,
            consolidated: option,
            subtotal: subtotal,
            agency: agency,
            currencyUnit: currencyUnit,
        };
        params = Object.assign(params, optParams);
        if (curPage >= 0) {
            setSanctionLetterData([]);
            setTotalItems(0);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner ) footerspinner.classList.remove('d-none');    
            await Http.get('/apiv1/getSummaryReportData', { params })
            .then((res) => {
                setReportCols(res.cols);
                setMisReportDataCount(res.totalRecord);
                setSanctionLetterData(res.rows);
                setTotalItems(res.totalRecord);
                setPageCount(Math.ceil(res.totalRecord / pageSize));
                setTotalPages(Math.ceil(res.totalRecord / pageSize));

                let cPage = (!currentPage) ? 1 : currentPage;
                setFirst((cPage - 1) * pageSize + 1);
                setLast(Math.min(res.totalRecord, cPage * pageSize));
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                footerspinner.classList.add('d-none');
                setDrildown(false);
            });
        }
    }

    useEffect(() => {
        if (currentPage != 0) {
            fetchSanctionLetterData(searchText);
        }
        if (props.reptype == 'smart-metering') {
            fetchSanctionLetterData('');
        }
    }, [currentPage]);

    useEffect(() => {
        if (statefilter) {
            fetchSanctionLetterData(searchText);
        }
    }, [statefilter]);

    const drilDown = (col: any) => {
        setDrildown(true);
        setCurrentPage(0);
        if (reportType == 'DisStates') {
            setReportType('Discom');
            setState(col.id);
            let SelectedState = { value: col.id, label: col.title1 };
            setSelectedState(SelectedState);
            const optParams = {
                reportType: 'Discom',
                stateid: col.id,
                page: 0,
            };
            fetchSanctionLetterData(searchText, optParams);
        }
        if (reportType == 'Discom') {
            setReportType('Districts');

            setState(col.state_id);
            let SelectedState = { value: col.state_id, label: col.state_title };
            setSelectedState(SelectedState);

            setTimeout(() => {
                let SelectedDiscom = { value: col.id, label: col.title2 };
                setSelectedMultiDiscom(col.id);
                setSelectedDiscom(SelectedDiscom);
            }, 1000);

            const optParams = {
                reportType: 'Districts',
                stateid: col.state_id,
                discomid: col.id,
                page: 0,
            };
            fetchSanctionLetterData(searchText, optParams);
        }
        // console.log(reportType, col);
    }

    const fetchReportData = async () => {
        let footerspinner:any = document.getElementById('footer-spinner');
        if ( footerspinner ) footerspinner.classList.remove('d-none');
        await Http.get('/apiv1/mis-discom?state_id=' + selectedState.value + '&module=sm')
        .then((res) => {
            setDiscomList(res.discoms);
            setDistrictList([]);
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            if ( !drildown )
                footerspinner.classList.add('d-none');
        });
    }

    useEffect(() => {
        fetchReportData();
    }, [selectedState.value])

    const handleSubTotalChange = (e: any) => {
        setSubtotal(e.target.value);
    }

    const handleAgencyChange = (e: any) => {
        setAgency(e.target.value);
    };

    const handleCurrencyUnitChange = (e: any) => {
        setCurrencyUnit(e.target.value);
    };

    const handleOptionChange = (e: any) => {
        let opts = [
            { value: "0", label: 'All' },
            { value: "1", label: "Sanctioned Items" },
            { value: "2", label: "Sanctioned Cost" },
            { value: "3", label: "Award Items" },
            { value: "4", label: "Award Rate" },
            { value: "5", label: "Award Cost" },
            { value: "6", label: "Surveyed Items" },
            { value: "7", label: "Surveyed Cost" },
            { value: "8", label: "Annual Target" },
            { value: "9", label: "Delivered" },
            { value: "10", label: "Installed" },
            { value: "11", label: "Charged" },
            { value: "12", label: "Communicating Meters" },
            { value: "13", label: "Prepaid Mode" },
            // { value: "14", label: "Physical Progress" },
        ];
        setOption(e.target.value);
        let obj1 = document.getElementById('divmajorcomponent');
        let obj2 = document.getElementById('divsubcomponent');
        obj1?.classList.add('d-none');
        obj2?.classList.add('d-none');
        if ( e.target.value == 'M' )
            obj1?.classList.remove('d-none');
        if ( e.target.value == 'D' || e.target.value == 'P' )
        {
            obj1?.classList.remove('d-none');
            obj2?.classList.remove('d-none');
        }
        if ( e.target.value == 'P' )
        {
            opts = [
                { value: "0", label: 'All' },
                { value: "1", label: "Sanctioned Items" },
                { value: "2", label: "Sanctioned Cost" },
                { value: "3", label: "Award Items" },
                { value: "4", label: "Rate per Unit including all taxes and duties other than GST (INR/ month/ meter)" },
                { value: "5", label: "AMISP Service Charge (INR/ month/ meter)" },
                { value: "6", label: "O&M Period (No. of Months)" },
                { value: "7", label: "Lumpsum payment per Unit (in INR/ meter)" },
                { value: "8", label: "Award Rate" },
                { value: "9", label: "Award Cost" },
                { value: "10", label: "Surveyed Items" },
                { value: "11", label: "Surveyed Cost" },
                { value: "12", label: "Annual Target" },
                { value: "13", label: "Delivered" },
                { value: "14", label: "Installed" },
                { value: "15", label: "Charged" },
                { value: "16", label: "Communicating Meters" },
                { value: "17", label: "Prepaid Mode" },
                // { value: "18", label: "Physical Progress" },
            ];    
        }
        //console.log(opts);
        clearparams(); 
        setMonitoringParametersList(opts);
    }

    const handleRadioChange = (e: any) => {
        let value = e.target.value;
        let obj1 = document.getElementById('smwithpackage');
        let obj2 = document.getElementById('smwithoutpackage');
        obj1?.classList.add('d-none');
        obj2?.classList.add('d-none');
        if ( value == 'Districts' )
            obj1?.classList.remove('d-none');
        else
            obj2?.classList.remove('d-none');
        setReportType(e.target.value);
        clearparams();
    };

    const clearparams = () => {
        setSearchText('');
        setState(0);
        setSelectedState(0);
        setSelectedDiscom(0);
        setSelectedMultiDiscom('');
        setDistrict('');
        setSelectedDistrict([]);
        setSelectedMajorComponent(0);
        setSelectedMultiMajorComponent('');
        setSubcomponent('');
        setSelectedSubComponent(0);
        setSelectedItemSpecification('');
        setSelectedItemSpecification(0);
        setSelectedMultiMonitoringParameters('');
        setSelectedMonitoringParameters(0);
        setCurrentPage(0);
        setTotalItems(0);
        setPageCount(0);
        setMisReportDataCount(0);
        setReportCols([]);
        setSanctionLetterData([]);
    }

    const fetchDistrict = async () => {
        let footerspinner:any = document.getElementById('footer-spinner');
        if ( footerspinner ) footerspinner.classList.remove('d-none');
        await Http.get('/apiv1/mis-district?stateid='+selectedState.value+'&discomid=' + selectedMultiDiscom)
        .then((res) => {
            setDistrictList(res.districtsList);
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            if ( !drildown )
                footerspinner.classList.add('d-none');
        });
    }

    useEffect(() => {
        fetchDistrict();
    }, [selectedMultiDiscom]);


    const clearSelect = [
        { value: "0", label: 'All' }
    ];


    const getStates = () => {
        (async () => {
            if (getstatesapiurl) {
                let footerspinner:any = document.getElementById('footer-spinner');
                if ( footerspinner ) footerspinner.classList.remove('d-none');
                await Http.get(getstatesapiurl)
                    .then((res) => {
                        setStatesList(res.statesList);
                        setSelectedDiscom([]);
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        if ( !drildown )
                            footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        if (reportType == 'Discom' || reportType == 'DisStates' || reportType == 'Districts') {
            setStatesApiurl("/apiv1/mis-states");
        }
    }, [getstatesapiurl, reportType]);

    useEffect(() => {
        (async () => {
            getStates();
        })();
    }, [getstatesapiurl, reportType]);

    const fetchSubComponent = async () => {
        // if (selectedMultiMajorComponent) {
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner ) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/mis-lr-subcomponents?sm_major_components_id=' + selectedMultiMajorComponent + '&module=sm')
            .then((res) => {
                setSubComponentList(res.lssubcomponents);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if ( !drildown )
                    footerspinner.classList.add('d-none');
            });
        // } else {
            // setSubComponentList([]);
            // setSelectedSubComponent(0);
        // }
    }

    useEffect(() => {
        fetchSubComponent();
    }, [selectedMultiMajorComponent]);

    //
    const [getcomponentsapiurl, setComponentsApiurl] = useState<any>(null);

    const getMajorComponents = () => {
        (async () => {
            if (getcomponentsapiurl) {
                let footerspinner:any = document.getElementById('footer-spinner');
                if ( footerspinner ) footerspinner.classList.remove('d-none');
                await Http.get(getcomponentsapiurl)
                .then((res) => {
                    setSmMajorComponents(res.lrmajorcomponent);
                    setItemSpecificationList([]);
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    if ( !drildown )
                        footerspinner.classList.add('d-none');
                });
            }
        })();
    };

    useEffect(() => {
        setComponentsApiurl('/apiv1/mis-major-components?module=sm');
    }, [getcomponentsapiurl, reportType]);

    useEffect(() => {
        (async () => {
            getMajorComponents();
        })();
    }, [getcomponentsapiurl, reportType]);

    const fetchItemSpecification = async () => {
        if (selectedSubComponent.value > 0) {
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner ) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/getSummaryReportData?sm_major_components_id=' + selectedSubComponent.value)
            .then((res) => {
                setItemSpecificationList(res.lssubcomponents);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if ( !drildown )
                    footerspinner.classList.add('d-none');
            });
        } else {
            setItemSpecificationList([]);
            setSelectedItemSpecification(0);
        }
    }
    
    useEffect(() => {
        fetchItemSpecification();
    }, [selectedSubComponent]);

    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt + "").replace(/[^\-.0-9]+/g, '');
        if (amt == '') amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>" + amt + "</mark>";
        }
        return amt;
    }

    const opts = [
        { value: "0", label: 'All' },
        { value: "1", label: "Sanctioned Items" },
        { value: "2", label: "Sanctioned Cost" },
        { value: "3", label: "Award Items" },
        { value: "4", label: "Award Rate" },
        { value: "5", label: "Award Cost" },
        { value: "6", label: "Surveyed Items" },
        { value: "7", label: "Surveyed Cost" },
        { value: "8", label: "Annual Target" },
        { value: "9", label: "Delivered" },
        { value: "10", label: "Installed" },
        { value: "11", label: "Charged" },
        { value: "12", label: "Communicating Meters" },
        { value: "13", label: "Prepaid Mode" },
        // { value: "14", label: "Physical Progress" },
        // { value: "10", label: "Progress"},
        // { value: "11", label: "Approved Project Cost"},
        // { value: "12", label: "GBS"},
        // { value: "13", label: "PMA Charges"},
        // { value: "14", label: "Fund Released" },
        // { value: "15", label: "Overall Progress" }
    ];
    const [monitoringParametersList, setMonitoringParametersList] = useState<any>(opts);

    useEffect(() => {
        if (reportType == 'Discom') {
            setDiscomList([]);
        }
        if (reportType == 'Districts') {
            setDistrictList([]);
            setDiscomList([]);
        }
    }, [reportType]);

    return (
        <>
            <section className="financialForm">
                <div className="fYearMonth">
                    <div className="row mx-0 align-items-center gx-3">
                        {/*<div className="col-md-auto ps-2">
                            <select name="subtotal" id="subtotal" defaultValue={agency} className="form-select" onChange={handleAgencyChange} >
                                <option value="0">Agency</option>
                                <option value="1">PFC</option>
                                <option value="2">REC</option>
                            </select>
                        </div>*/}
                        <div className="col-md-auto text-end pe-0">
                            <label htmlFor="subtotal">Sub Total</label>
                        </div>
                        <div className="col-md-auto ps-2">
                            <select name="subtotal" id="subtotal" defaultValue={subtotal} className="form-select" onChange={handleSubTotalChange} >
                                <option value="N">No</option>
                                <option value="Y">Yes</option>
                            </select>
                        </div>
                        <div className='col-md-auto text-end pe-0'>
                            <label>FY</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="year" defaultValue={year} id="year" className="form-select" onChange={handleYearSelect} >
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                            </select>
                        </div>
                        <div className='col-md-auto text-end pe-0'>
                            <label>Month</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="month" id="month" defaultValue={month} className="form-select" onChange={handleMonthSelect} >
                                <option value="apr">Apr</option>
                                <option value="may">May</option>
                                <option value="jun">Jun</option>
                                <option value="jul">Jul</option>
                                <option value="aug">Aug</option>
                                <option value="sep">Sep</option>
                                <option value="oct">Oct</option>
                                <option value="nov">Nov</option>
                                <option value="dec">Dec</option>
                                <option value="jan">Jan</option>
                                <option value="feb">Feb</option>
                                <option value="mar">Mar</option>
                            </select>
                        </div>
                    </div>
                </div>
                {/* <Accordion defaultActiveKey="0"> */}
                    {/* <Accordion.Item eventKey="0"> */}
                        {/* <Accordion.Header> */}
                            {/* <div className="d-flex align-items-center justify-content-between w-100 pe-3"> */}
                                {/* Form Header Collapse */}
                                {/* <div className="dIcnBtn mt-0 d-flex align-items-center gap-3">
                                    <a className="text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/missummaryreportdata?reportType=' + reportType + '&stateid=' + getstate + '&districtid=' + district + '&discomid=' + selectedMultiDiscom + '&majorcomponentid=' + selectedMultiMajorComponent + '&subcomp=' + subcomponent + '&itemSpec=' + selectedItemSpecification.value + '&summaryReport=' + selectedSummaryReport.value + '&monitoringPar=' + selectedMultiMonitoringParameters + '&year=' + year + '&month=' + month + '&consolidated=' + option + '&subtotal=' + subtotal + '&agency=' + agency + '&currencyUnit=' + currencyUnit + '&task=excel'}><img src={ExcelIcn} width={22} /></a>
                                    <a className="text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/missummaryreportdata?reportType=' + reportType + '&stateid=' + getstate + '&districtid=' + district + '&discomid=' + selectedMultiDiscom + '&majorcomponentid=' + selectedMultiMajorComponent + '&subcomp=' + subcomponent + '&itemSpec=' + selectedItemSpecification.value + '&summaryReport=' + selectedSummaryReport.value + '&monitoringPar=' + selectedMultiMonitoringParameters + '&year=' + year + '&month=' + month + '&consolidated=' + option + '&subtotal=' + subtotal + '&agency=' + agency + '&currencyUnit=' + currencyUnit + '&task=pdf'}><img src={PdfIcn} width={15} /></a>
                                </div> */}
                            {/* </div> */}
                        {/* </Accordion.Header> */}
                        {/* <Accordion.Body> */}
                            <div className="radioYear">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-8">
                                        <div className="row align-items-center">
                                            <div className="col-md-auto">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="ReoptionSm" value="Allindia" id="Allindia" checked={reportType === 'Allindia'} onChange={handleRadioChange} />
                                                    <label className="form-check-label" htmlFor="Allindia">All India </label>
                                                </div>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="ReoptionSm" value="DisStates" id="States" checked={reportType === 'DisStates'} onChange={handleRadioChange} />
                                                    <label className="form-check-label" htmlFor="States">State </label>
                                                </div>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="ReoptionSm" value="Discom" id="Discom" checked={reportType === 'Discom'} onChange={handleRadioChange} />
                                                    <label className="form-check-label" htmlFor="Discom">Discom</label>
                                                </div>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="ReoptionSm" value="Districts" id="Districts" checked={reportType === 'Districts'} onChange={handleRadioChange} />
                                                    <label className="form-check-label" htmlFor="Districts">Districts / Projects</label>
                                                </div>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="ReoptionSm" value="Constituency" id="Constituency" checked={reportType === 'Constituency'} onChange={handleRadioChange} />
                                                    <label className="form-check-label" htmlFor="Constituency">Constituency</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row align-items-center justify-content-end">
                                            {/*<div className="col-md-4">
                                                <select name="currency_unit" id="currency_unit" defaultValue={currencyUnit} className="form-select" onChange={handleCurrencyUnitChange} >
                                                    <option value="0">INR Lakh</option>
                                                    <option value="1">INR</option>
                                                    <option value="2">INR Cr</option>
                                                </select>
                                            </div>*/}
                                            <div id="smwithpackage" className='col-md-auto d-none'>
                                                <select name="optsm" id="optsm" defaultValue={option} className="form-select" onChange={handleOptionChange} >
                                                    <option value="C">Consolidated</option>
                                                    <option value="M">Major Components</option>
                                                    <option value="D">Detailed</option>
                                                    <option value="P">Packagewise</option>
                                                </select>
                                            </div>
                                            <div id="smwithoutpackage" className='col-md-auto'>
                                                <select name="optsm" id="optsm" defaultValue={option} className="form-select" onChange={handleOptionChange} >
                                                    <option value="C">Consolidated</option>
                                                    <option value="M">Major Components</option>
                                                    <option value="D">Detailed</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center w-100 mt-3">
                                {reportType != 'Allindia' ?
                                    <>
                                        {reportType == 'Discom' || reportType == 'DisStates' || reportType == 'Districts'
                                            ?
                                            <div className="col-md-3">
                                                <div className="misSelectReportPosition">
                                                    <label htmlFor="states" className="form-label">States</label>
                                                    {
                                                        reportType == 'DisStates' ?
                                                            <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} isMulti />
                                                            :
                                                            <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} />
                                                    }
                                                </div>
                                            </div>
                                            : null
                                        }

                                        {reportType == 'Discom' || reportType == 'Districts'
                                            ?
                                            <div className="col-md-3">
                                                <div className="misSelectReportPosition">
                                                    <label htmlFor="discom" className="form-label">Discom</label>
                                                    <Select id="discom" className="misReportSelect" aria-label="Discom" onChange={handleDiscomSelect} isSearchable={true} options={discomList} value={selectedDiscom} isMulti />
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        {reportType == 'Districts'
                                            ?
                                            <div className="col-md-3">
                                                <div className="misSelectReportPosition">
                                                    <label htmlFor="districts" className="form-label">Districts / Projects</label>
                                                    <Select id="districts" className="misReportSelect" aria-label="Districts" onChange={handleDistrictSelect} isSearchable={true} options={districtList} value={selectedDistrict} isMulti />
                                                </div>
                                            </div>
                                            : null
                                        }

                                    </>
                                    : null
                                }

                                {reportType != 'Constituency'
                                    ?
                                    <>
                                        <div id="divmajorcomponent" className="col-md-3 d-none">
                                            <div className="misSelectReportPosition">
                                                <label htmlFor="majorcomponent" className="form-label">Major Components</label>
                                                <Select id="majorcomponent" className="misReportSelect" aria-label="Major Components" onChange={handleMajorComponentSelect} isSearchable={true} options={smMajorComponents} value={selectedMajorComponent} isMulti />
                                            </div>
                                        </div>

                                        <div id="divsubcomponent" className="col-md-3 d-none">
                                            <div className="">
                                                <label htmlFor="subcomponent" className="form-label">Sub Components</label>
                                                <Select id="subcomponent" className="misReportSelect" aria-label="Sub Components" onChange={handleSubComponentSelect} isSearchable={true} options={subComponentList} value={selectedSubComponent} isMulti />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="">
                                                <label htmlFor="monitoringparameters" className="form-label">Select Column</label>
                                                <Select id="monitoringparameters" className="misReportSelect" aria-label="Select Column" onChange={handleMonitoringParametersSelect} isSearchable={true} options={monitoringParametersList} value={selectedMonitoringParameters} isMulti />
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                                <div className="col-md-12 text-center">
                                    <div className="row justify-content-between align-items-center misBottomBtn">
                                        <div className="col-md-3">
                                            <input type="text" className="form-control" onChange={handleSearchTextChange} value={searchText} placeholder="Search" onKeyDown={handleSearchEnter} style={{ height: 38 }} />
                                        </div>
                                        <div className="col-md-2 text-start">
                                            <button className="bsSearchBtn mt-0" onClick={() => { setPageInput(1); setCurrentPage(1); fetchSanctionLetterData(searchText); }}>Search</button>
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-3 text-end">
                                            <div className="dIcnBtn">
                                                <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/missummaryreportdata?reportType=' + reportType + '&stateid=' + getstate + '&districtid=' + district + '&discomid=' + selectedMultiDiscom + '&majorcomponentid=' + selectedMultiMajorComponent + '&subcomp=' + subcomponent + '&itemSpec=' + selectedItemSpecification.value + '&summaryReport=' + selectedSummaryReport.value + '&monitoringPar=' + selectedMultiMonitoringParameters + '&year=' + year + '&month=' + month + '&consolidated=' + option + '&subtotal=' + subtotal + '&agency=' + agency + '&currencyUnit=' + currencyUnit + '&task=excel'}><img src={ExcelIcn} width={22} /></a>
                                                <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/missummaryreportdata?reportType=' + reportType + '&stateid=' + getstate + '&districtid=' + district + '&discomid=' + selectedMultiDiscom + '&majorcomponentid=' + selectedMultiMajorComponent + '&subcomp=' + subcomponent + '&itemSpec=' + selectedItemSpecification.value + '&summaryReport=' + selectedSummaryReport.value + '&monitoringPar=' + selectedMultiMonitoringParameters + '&year=' + year + '&month=' + month + '&consolidated=' + option + '&subtotal=' + subtotal + '&agency=' + agency + '&currencyUnit=' + currencyUnit + '&task=pdf'}><img src={PdfIcn} width={15} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </Accordion.Body> */}
                    {/* </Accordion.Item> */}
                {/* </Accordion> */}
                {/* <div className="input-group mb-3 mt-3">
                            <input type="text" className="form-control" onChange={handleSearchTextChange} value={searchText} placeholder="Search" />
                            <button className="btn btn-warning text-white" type="button" onClick={()=>fetchSanctionLetterData(searchText)}>Search</button>
                        </div> */}

                <div className="">
                    {
                        totalItems ?
                            <div>Displaying {first} to {last} of {totalItems}</div>
                            :
                            null
                    }
                    <div className="mt-2 financialMainFrom misReportTable">
                        <div className="tab-content p-0 border-0">
                            <div id="data-table" className="table-responsive RevenueDetailsTable misreportTable">
                                <table className="table">
                                    <thead>
                                        <tr className="text-center">
                                            <th scope="col" className="text-start">Sr. No</th>
                                            {reportCols ?
                                                <>
                                                    {
                                                        reportCols.map((reportcol: any, index: number) =>
                                                            (reportcol.display && reportcol.display == 'N') ? null : <th scope="col">{reportcol['title']}</th>
                                                        )
                                                    }
                                                </>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sanctionLetterData ?
                                            sanctionLetterData.map((reportData: any, index: number) => {
                                                return <tr>
                                                    <td>{(reportData.srNo)}</td>
                                                    {
                                                        reportCols.map((reportcol: any) => (
                                                            (reportcol.display && reportcol.display == 'N') ? null
                                                                : (
                                                                    (reportcol.drildown && reportcol.drildown == 'Y' && reportData[reportcol.key] != 'Sub Total.') ? <>
                                                                        <td className={"mis" + reportcol.key}>
                                                                            <Link to="#" onClick={() => drilDown(reportData)}>{
                                                                                (reportcol.formatted == 'Y' && reportData[reportcol.key] != '')
                                                                                    ? <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], (reportcol.decimal ? reportcol.decimal : 2 ) ) }}></div>
                                                                                    : <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                            }</Link>
                                                                        </td>
                                                                    </>
                                                                        : <>
                                                                            <td className={"mis" + reportcol.key}>
                                                                                {
                                                                                    (reportcol.formatted == 'Y' && reportData[reportcol.key] != '')
                                                                                        ? <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], (reportcol.decimal ? reportcol.decimal : 2 ) ) }}></div>
                                                                                        : <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                                }
                                                                            </td>
                                                                        </>
                                                                )
                                                        ))
                                                    }
                                                </tr>
                                            })
                                            :
                                            <tr>
                                                <td colSpan={10} className="text-center my-5">
                                                    <h5>No Record Found</h5>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {totalItems != 0 ?
                                <div className="mt-3">
                                    <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item">
                                                <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                    <BsChevronDoubleLeft />
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                    <BsChevronCompactLeft />
                                                </button>
                                            </li>
                                            <li className="page-item col-1">
                                                <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                    <BsChevronCompactRight />
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                    <BsChevronDoubleRight />
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="page-item text-center">
                                        <span>
                                            Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SmMisReports;