import React, { useEffect, useRef, useState } from 'react';
import { Http } from '../../Core/Services/HttpService';
import { AiFillCloseCircle, AiFillFileAdd, AiFillHome, AiFillPlusCircle, AiOutlineEye } from 'react-icons/ai';
import { FcOpenedFolder } from "react-icons/fc";
import { BiArrowBack, BiEdit } from 'react-icons/bi';
import { Alert, Button, Modal } from 'react-bootstrap';
import { MdCreateNewFolder } from 'react-icons/md';
import { RiDeleteBinLine, RiDownload2Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { TbListDetails } from 'react-icons/tb';
import { useStateMounted } from '../../Core/Hooks';
import { BsSearch, BsXCircle } from 'react-icons/bs';
import { debounce } from 'lodash';
import { Storage } from '../../Core/Services/StorageService';


const FileManager = (props: any) => {
  const [dmsList, setDmsList] = useState<any>([]);
  const [levels, setLevels] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentLevel, setCurrentLevel] = useState<{ label: string, path: string, lastLevelId: any | number } | null>();
  const [isDirectory, setIsDirectory] = useState<boolean>(((props.onSelectFile && typeof props.onSelectFile === 'function') ? true : false));
  const [discomid, setDiscomid] = useState<any>(0);
  const [isModelDirLoad, setIsModelDirLoad] = useState<any>(false);
  // Search files
  const [isSearch, setIsSearch] = useStateMounted<boolean>(false);
  const [searchLoading, setSearchLoading] = useStateMounted<boolean>(false);
  const [searchData, setSearchData] = useStateMounted<any>([]);
  const [searchError, setSearchError] = useStateMounted<string>();
  const [search, setSearch] = useStateMounted<string>();
  const [uploadResponse, setUploadResponse] = useState<any>('');
  const [selectedFile, setSelectedFile] = useState('');
  const [roleid, setRoleid] = useState<any>(0);
  const [stateid, setStateid] = useState<any>(0);

  const handleFileChange = (event: any) => {
    if (event.target.files[0]) {
      setSelectedFile(event.target.files[0].name);
    }
    setUploadResponse('');
  };

  useEffect(() => {
    setDiscomid(Storage.get('discom_id'));
    setRoleid(Storage.get('roleid'));
    setStateid(Storage.get('user_state_id'));
  }, [discomid]);

  useEffect(() => {
    let parent_id = currentLevel ? currentLevel.lastLevelId : 0
    if (parent_id == 0 || props.isModelDirLoad )
      setIsModelDirLoad(true);
    else
      setIsModelDirLoad(false);
  }, [currentLevel]);

  const setCurrentPathBaseLevel = (res: any) => {
    const path = (currentLevel?.path) ? currentLevel?.path.split('/') : [];
    const newLevels: any = [];
    let dmsData = res.dmsData;
    if (path.length) {
      for (let i = 0; i < path.length; i++) {
        const pathData = path[i].split('[');
        const id = parseInt(pathData[1].replace('[', '').replace(']', ''));
        dmsData.forEach((items: any) => {
          if (items.folders.length) {
            items.folders.forEach((item: any) => {
              if (item.id == id) {
                newLevels.push({ id: id, label: item.name, list: dmsData });
                dmsData = [item];
              }
            });
          }
        });
      }
      setLevels(newLevels);
      setDmsList(dmsData);
    }
  };
  const fetchDirectoryList = async (data: any) => {
    await Http.get('/apiv1/get-doc-list?searchPath=&discomid=' + discomid+'&roleId='+roleid+'&state_id='+stateid)
      .then((res) => {
        if (levels.length) {
          setCurrentPathBaseLevel(res);
        } else {
          setDmsList(res.dmsData);
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        if (props.setRefreshList)
          props.setRefreshList(false);
      });
  }

  // Start Search files
  const onSearchFiles = async (search: string) => {
    setSearchLoading(true);
    setSearchError('');
    setSearchData([]);
    const params = {
      search: search,
      discomid: discomid
    }
    await Http.get('/apiv1/get-doc-list', { params: params })
      .then((res) => {
        if (res.dmsData && res.dmsData.length) {
          setSearchData(res.dmsData);
        } else {
          setSearchError('No file found.');
        }
      }).catch((e) => {
        setSearchError('System error occurred!! please try again.');
      }).finally(() => {
        setSearchLoading(false);
      });
  }

  useEffect(() => {
    if (isSearch && search && search.length > 2) {
      onSearchFiles(search);
    }
  }, [isSearch, search]);

  const onSearchChange = (e: any) => {
    setSearch(e.target.value.trim());
  }

  const onHandelSearchChange = debounce(onSearchChange, 500);

  const onSearchCancel = () => {
    setIsSearch(false);
    setSearch('');
    setSearchData([]);
  }
  // End Search files

  useEffect(() => {
    if (discomid)
      fetchDirectoryList('');
  }, [discomid]);

  useEffect(() => {
    if (props.refreshList)
      fetchDirectoryList('');
  }, [props.refreshList]);

  useEffect(() => {
    if (props.getCurrentLevel && currentLevel)
      props.getCurrentLevel(currentLevel);
  }, [currentLevel]);

  useEffect(() => {
    if (props.onSelectFile && typeof props.onSelectFile === 'function') {
      props.onSelectFile(selectedFiles);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (props.selectedFileList) {
      setSelectedFiles(props.selectedFileList);
    }
  }, [props.selectedFileList]);

  useEffect(() => {
    if (levels.length) {
      const label = ['Home'];
      const path = [];
      for (let i = 0; i < levels.length; i++) {
        label.push(levels[i].label);
        path.push(i + '[' + levels[i].id + ']');
      }
      setCurrentLevel({ label: label.join(' > '), path: path.join('/'), lastLevelId: levels[levels.length - 1].id });
    } else {
      setCurrentLevel(null);
    }
    if(props.getLevels) {
      props.getLevels(levels);
    }
  }, [levels]);

  const handleFolderClick = (folder: any, cLevel: any) => {
    setDmsList(folder);
    const addLevel = {
      id: folder[0].id,
      label: folder[0].name,
      list: cLevel,
    }
    setLevels((prev: any) => [...prev, addLevel]);
  };

  const onBack = () => {
    if (levels.length) {
      const tmpLevels = JSON.parse(JSON.stringify(levels));
      const backTo = tmpLevels[tmpLevels.length - 1];
      tmpLevels.pop();
      setLevels(tmpLevels);
      setDmsList(backTo.list);
    }
  };

  const onBackIndex = (index: number) => {
    if (index === 0) {
      onSearchCancel();
    }
    if (levels.length && index < levels.length && levels.length != index) {
      const tmpLevels = JSON.parse(JSON.stringify(levels));
      const backTo = tmpLevels[index];
      tmpLevels.splice(index, tmpLevels.length - index);
      setLevels(tmpLevels);
      setDmsList(backTo.list);
    }
  };

  const onSelectFile = (file: any) => {
    if (isSelected(file))
      setSelectedFiles((prev: any) => prev.filter((item: any) => item.id != file.id));
    else
      setSelectedFiles((prev: any) => [...prev, file]);
  }

  const isSelected = (file: any) => {
    let selected = false;
    selectedFiles.forEach((item: any) => {
      if (item.id == file.id)
        selected = true;
    });
    return selected;
  }

  // For create folder functionality
  const [folderModalShow, setFolderModalShow] = useState(false);
  const [fileModalShow, setFileModalShow] = useState(false);
  const handleClose = () => setFolderModalShow(false);
  const handleShow = () => setFolderModalShow(true);

  const handleFileModelClose = () => setFileModalShow(false);
  const handleFileShow = () => setFileModalShow(true);
  const [folderName, setFolderName] = useState('');
  const frmCreateDir = useRef<any>(null);
  const frmCreateFile = useRef<any>(null);


  const handleFolderCreateSubmit = async (event: any) => {
    const formData = new FormData(frmCreateDir.current);
    formData.append('discom_id', discomid);
    console.log(currentLevel);
    if (currentLevel?.lastLevelId) {
      formData.append('parent_id', currentLevel ? currentLevel.lastLevelId : 0);
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
    await Http.post("/apiv1/create-dms-folder", formData, config)
      .then((res) => {
        if (res._resultflag == 1) {
          frmCreateDir.current.reset();
          setFolderName('');
          fetchDirectoryList('');
          handleClose();
        } else {
          // setUploadResponse('<span class="text-danger">' + res.message + '</span>');
        }
      });
  };

  const handleFileCreateSubmit = async (event: any) => {
    setUploadResponse('');
    const file_name = frmCreateFile.current.file_name.value;
    const description = frmCreateFile.current.description.value;
    const tags = frmCreateFile.current.tags.value;
    const files = frmCreateFile.current.files.value;
    if (!file_name) {
      setUploadResponse('<span class="text-danger">Please enter file name</span>');
      return;
    }
    if (!files) {
      setUploadResponse('<span class="text-danger">Please select file to upload</span>');
      return;
    }
    const formData = new FormData(frmCreateFile.current);
    formData.append('discom_id', discomid);
    formData.append('file_name', file_name);
    formData.append('description', description);
    formData.append('tags', tags);

    formData.append('parent_id', '0');
    if (currentLevel?.lastLevelId) {
      formData.append('parent_id', currentLevel ? currentLevel.lastLevelId : 0);
    }
    setUploadResponse('<span class="loader"></span>');

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
    await Http.post("/apiv1/create-dms-file", formData, config)
      .then((res) => {
        if (res._resultflag == "1") {
          setUploadResponse('<span class="text-success">' + res.message + '</span>');
          frmCreateFile.current.reset();
          fetchDirectoryList('');
          handleFileModelClose();
          setTimeout(() => {
            setUploadResponse('');
          }, 2000);
        } else {
          setUploadResponse('<span class="text-danger">' + res.message + '</span>');
          frmCreateFile.current.reset();
          // setUploadResponse('<span class="text-danger">' + res.message + '</span>');
        }
      });
  };

  // For delete file
  const deleteFile = async (file: any) => {
    const delFile = window.confirm("Are you sure you want to delete?");

    if (delFile) {
      await Http.get("/apiv1/delete-dms-file?id=" + file.id + '&filePath=' + file.file)
        .then((res) => {
          if (res._resultflag == "1") {
            fetchDirectoryList('');
          } else {
            // setUploadResponse('<span class="text-danger">' + res.message + '</span>');
          }
        });
    }
  }

  // For delete folder
  const deleteFolder = async (folder: any) => {
    const delFolder = window.confirm("Are you sure you want to delete?");
    if (delFolder) {
      await Http.get("/apiv1/delete-dms-folder?id=" + folder.id + '&folderPath=' + folder.file)
        .then((res) => {
          if (res._resultflag == "1") {
            fetchDirectoryList('');
          } else {
            window.alert(res.message);
            // setUploadResponse('<span class="text-danger">' + res.message + '</span>');
          }
        });
    }
  }

  // For Details/Tiles view
  const [isDetailsView, setIsDetailsView] = useState(true);
  const toggleFilesView = () => {
    setIsDetailsView(!isDetailsView);
  };


  return (
    <div className='rdssGuideline dmsCenter'>
      <h4 className='mb-3 float-start'>Document Center</h4>
      {
        (!isSearch && levels && levels.length) ? (
          <button className="float-end btn btn-light" onClick={() => onBack()} type='button'><BiArrowBack /> Back</button>
        ) : null
      }
      {
        (!isModelDirLoad) ?
          <button className="float-end btn btn-light me-2 addFolderBtn" onClick={handleShow} type='button'><MdCreateNewFolder className="text-warning" /></button>
          :
          ( !props.isModelDirLoad ) ?
            <div className="float-end me-2 text-danger mt-2">Please select folder</div>
            : null
      }
      <Modal show={folderModalShow} size="sm" onHide={handleClose} className='docCenterFolderModalBox' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={frmCreateDir}>
            <input value={folderName} onChange={(event) => setFolderName(event.target.value)} className="form-control form-control-sm" type="text" name="folder_name" placeholder="Folder Name" required />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Create
          </Button> */}
          <Button variant="primary" type="button" onClick={handleFolderCreateSubmit}>
            <AiFillPlusCircle /> Create
          </Button>
        </Modal.Footer>
      </Modal>
      {
        (!isModelDirLoad) ?
          <button className="float-end btn btn-light me-2 addFolderBtn" onClick={handleFileShow} type='button'><AiFillFileAdd className="fileUploadBtn text-warning" /></button>
          : null
      }
      <Modal show={fileModalShow} size="sm" onHide={handleFileModelClose} className='docCenterFileModalBox' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={frmCreateFile}>
            <div className="mb-3">
              <label htmlFor="formFileMultiple" className="form-label">Select Files</label>
              <input onChange={handleFileChange} className="form-control" name="files" type="file" id="formFileMultiple" required></input>
            </div>
            <div className="mb-3">
              <label for-html="file_name" className="form-label">File Name</label>
              <input type="text" className="form-control text-start" name="file_name" id="file_name" placeholder="File Name" required />
            </div>
            <div className="row">
              <div className="col mb-3">
                <label for-html="fileDescription" className="form-label">File Description</label>
                <textarea className="form-control" name="description" id="fileDescription" rows-html="3"></textarea>
              </div>
              <div className="col mb-3">
                <label for-html="fileTags" className="form-label">Tags</label>
                <textarea name='tags' className="form-control" id="fileTags" rows-html="3"></textarea>
              </div>
            </div>
          </form>
          <div className='mt-3' dangerouslySetInnerHTML={{ __html: uploadResponse }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFileModelClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Create
          </Button> */}
          <Button variant="primary" type="button" onClick={handleFileCreateSubmit}>
            <AiFillPlusCircle /> Upload
          </Button>
        </Modal.Footer>
      </Modal>
      {
        (!isModelDirLoad) ?
          <button className="float-end btn btn-light me-2 addFolderBtn" onClick={toggleFilesView} type='button'><TbListDetails className="fileUploadBtn text-info" /></button>
          : null
      }
      {
        (!isSearch)
          ? <div className="float-end me-2">
            <div className="dms-search-box">
              <input defaultValue={search} onChange={onHandelSearchChange} className="dms-search-input" type="text" placeholder="Search file.." />
              <button onClick={() => setIsSearch(true)} className="dms-search-btn"><BsSearch /></button>
            </div>
          </div>
          : null
      }
      <div className="clearfix"></div>
      {
        (isSearch) ?
          <div className="row">
            <div className={isDirectory ? 'col-md-8' : 'col-md-12 row'}>
              <section className="InnerBanner mb-3 ">
                <ul className="BreadCumb mt-0 list-unstyled mb-0 text-start">
                  <li className='pe-cursor'><a className="text-decoration-none" onClick={() => onBackIndex(0)}><AiFillHome /></a></li>
                  <li className='pe-cursor'>Search Result: {search}</li>
                </ul>
              </section>
              <section className="InnerBanner mb-3">
                <div className="dms-search-box" style={{ width: '450px' }}>
                  <input defaultValue={search} onChange={onHandelSearchChange}
                    className="dms-search-input" style={{ padding: '12px 16px' }}
                    type="text" placeholder="Search file.." />
                </div>
                <button onClick={() => onSearchCancel()} className="dms-search-btn btn btn-danger ms-2"><BsXCircle /> Close</button>
              </section>
              {searchLoading && (
                <>
                  <div className="content-spinner spinner-container h-50vh">
                    <div className="spinner-outer">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                </>
              )}
              {searchError && (
                <Alert className='mt-3 mb-3' variant="danger">{searchError}</Alert>
              )}
              {((!searchLoading && !searchError) && searchData?.length > 0) && (
                isDetailsView ?
                  searchData.map((file: any, k: number) => (
                    file.name ?
                      <div key={k + '-search-file'} className='col-md-2 mt-2'>
                        <div className={'docCenter xc ' + ((isSelected(file) && isDirectory) ? 'selected' : '')}>
                          <img className="p-1 cp" src={file.type} onClick={() => onSelectFile(file)}></img>
                          <h6 className='mb-0 pb-1'>{file.name} <span className='d-block'>({file.size} KB)</span></h6>
                          <Link to={process.env.REACT_APP_API_ENDPOINT + '/apiv1/downloaddoc/?q=' + encodeURIComponent(file.file)} className='rename d-inline-block me-1' ><RiDownload2Line title="Download" className="fs-6 text-success editFolderIcn" /></Link>
                          <Link to="javascript:void(0);" className='rename d-inline-block me-1' ><RiDeleteBinLine title="Delete" className="fs-6 text-danger editFolderIcn" onClick={() => deleteFile(file)} /></Link>
                        </div>
                      </div>
                      : null
                  ))
                  : <div className='row'>
                    <div className='col-12 table-responsive'>
                      <table className="table table-striped table-hover docCenter">
                        <thead className='table-warning'>
                          <tr>
                            <td>Sr No.</td>
                            <td>File Name</td>
                            <td>File Description</td>
                            <td>Tags</td>
                            <td>Uploaded On</td>
                            <td>Type</td>
                            <td>Size</td>
                            <td>Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            searchData.map((file: any, k: number) => (
                              file.name ?
                                <tr key={k + '-search-file-list'}>
                                  <td>{++k}</td>
                                  <td>{file.name}</td>
                                  <td>{file.description}</td>
                                  <td>{file.tags}</td>
                                  <td>{file.updated_at}</td>
                                  <td><img className="p-1 cp" src={file.type}></img></td>
                                  <td>{file.size} KB</td>
                                  <td>
                                    <Link to={process.env.REACT_APP_API_ENDPOINT + '/apiv1/downloaddoc/?q=' + encodeURIComponent(file.file)} className='rename d-inline-block me-1' ><RiDownload2Line title="Download" className="fs-6 text-success editFolderIcn" /></Link>
                                    <Link to="javascript:void(0);" className='rename d-inline-block' ><RiDeleteBinLine title="Delete" className="fs-6 text-danger editFolderIcn" onClick={() => deleteFile(file)} /></Link>
                                  </td>
                                </tr>
                                : null
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
              )}
            </div>
            <div className={isDirectory ? 'col-md-4 border-start' : 'd-none'}>
              <h5 className='text-start'>Selected Files</h5>
              <ul className="fileUl">
                {(selectedFiles && Array.isArray(selectedFiles) && selectedFiles.length) ? (
                  selectedFiles.map((file: any, i: number) => (
                    <li key={i + '-select-list'} className="text-start">{file.file.split('/').slice(0, -1).join('/') + '/' + file.name} <AiFillCloseCircle className='text-danger deleteFile' onClick={() => onSelectFile(file)} /></li>
                  ))) : null
                }
              </ul>
            </div>
          </div>
          : <div className="row">
            <div className={isDirectory ? 'col-md-8' : 'col-md-12'}>
              <section className="InnerBanner mb-3 ">
                <ul className="BreadCumb mt-0 list-unstyled mb-0 text-start">
                  <li className='pe-cursor'><a className="text-decoration-none" onClick={() => onBackIndex(0)}><AiFillHome /></a></li>
                  {
                    (levels && levels.length) ? levels.map((level: any, i: number) => (
                      <li key={i + 'back-btn'} className='pe-cursor'><a className="text-decoration-none" onClick={() => onBackIndex(i + 1)}>{level.label}</a></li>
                    )) : null
                  }
                </ul>
              </section>
              {
                dmsList.map((items: any, i: number) => (
                  <div key={i + '-documents'} >
                    {items.folders.length > 0 ?
                      <div key={i + '-dir'} className="panel panel-default text-start">
                        <div className="panel-heading">Folders</div>
                        <div className="panel-body">
                          <div className="row">
                            {
                              items.folders.map((folder: any, j: number) => (
                                <div key={i + '-dir-' + j} className='col-md-2 mt-2'>
                                  <div className='docCenter'>
                                    <FcOpenedFolder className="fs-1 cp" onClick={() => handleFolderClick([folder], dmsList)} />
                                    <h6 className='mb-0 pb-1 folderBorder'>{folder.name} <span className='d-block'>({folder.fileCount ?? 0} Files)</span></h6>
                                    {/* <Link to="#" className='rename d-inline-block'><AiOutlineEye title="View" className="fs-6 editFolderIcn" /></Link> */}
                                    {/* <Link to="#" className='rename d-inline-block me-1'><BiEdit title="Rename" className="fs-6 text-primary editFolderIcn" /></Link> */}
                                    <Link to="javascript:void(0);" className='rename d-inline-block me-1'><RiDeleteBinLine title="Delete" className="fs-6 text-danger editFolderIcn" onClick={() => deleteFolder(folder)} /></Link>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      : null}
                    {items.files.length > 0 ?
                      <div key={i + '-files'} className="panel panel-default mt-4 text-start">
                        <div className="panel-heading">Files</div>
                        <div className="panel-body">
                          <div className="row">
                            {
                              isDetailsView ?
                                items.files.map((file: any, k: number) => (
                                  file.name ?
                                    <div key={i + '-files-' + k} className='col-md-2 mt-2'>
                                      <div className={'docCenter xc ' + ((isSelected(file) && isDirectory) ? 'selected' : '')}>
                                        <img className="p-1 cp" src={file.type} onClick={() => onSelectFile(file)}></img>
                                        <h6 className='mb-0 pb-1'>{file.name} <span className='d-block'>({file.size} KB)</span></h6>
                                        {/* <p className='mb-1 pt-1'>{file.size} KB</p> */}
                                        {/* <Link to="#" className='rename d-inline-block me-1'><AiOutlineEye title="View" className="fs-6 editFolderIcn" /></Link>
                                    <Link to="#" className='rename d-inline-block me-1'><BiEdit title="Rename" className="fs-6 text-primary editFolderIcn" /></Link> */}
                                        <Link to={process.env.REACT_APP_API_ENDPOINT + '/apiv1/downloaddoc/?q=' + encodeURIComponent(file.file)} className='rename d-inline-block me-1' ><RiDownload2Line title="Download" className="fs-6 text-success editFolderIcn" /></Link>
                                        <Link to="javascript:void(0);" className='rename d-inline-block me-1' ><RiDeleteBinLine title="Delete" className="fs-6 text-danger editFolderIcn" onClick={() => deleteFile(file)} /></Link>
                                      </div>
                                    </div>
                                    : null
                                ))
                                :
                                <div className='row'>
                                  <div className='col-12 table-responsive'>
                                    <table className="table table-striped table-hover docCenter">
                                      <thead className='table-warning'>
                                        <tr>
                                          <td>Sr No.</td>
                                          <td>File Name</td>
                                          <td>File Description</td>
                                          <td>Tags</td>
                                          <td>Uploaded On</td>
                                          <td>Type</td>
                                          <td>Size</td>
                                          <td>Action</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          items.files.map((file: any, k: number) => (
                                            file.name ?
                                              <tr key={i + '-file-list-' + k}>
                                                <td>{++k}</td>
                                                <td>{file.name}</td>
                                                <td>{file.description}</td>
                                                <td>{file.tags}</td>
                                                <td>{file.updated_at}</td>
                                                <td><img className="p-1 cp" src={file.type}></img></td>
                                                <td>{file.size} KB</td>
                                                <td>
                                                  {/* <Link to="#" className='rename d-inline-block me-1'><AiOutlineEye title="View" className="fs-6 me-1 editFolderIcn" /></Link>
                                              <Link to="#" className='rename d-inline-block me-2'><BiEdit title="Rename" className="fs-6 text-primary editFolderIcn" /></Link> */}
                                                  <Link to={process.env.REACT_APP_API_ENDPOINT + '/apiv1/downloaddoc/?q=' + encodeURIComponent(file.file)} className='rename d-inline-block me-1' ><RiDownload2Line title="Download" className="fs-6 text-success editFolderIcn" /></Link>
                                                  <Link to="javascript:void(0);" className='rename d-inline-block' ><RiDeleteBinLine title="Delete" className="fs-6 text-danger editFolderIcn" onClick={() => deleteFile(file)} /></Link>
                                                </td>
                                              </tr>
                                              : null
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                      : null}
                  </div>
                ))
              }
            </div>
            <div className={isDirectory ? 'col-md-4 border-start' : 'd-none'}>
              <h5 className='text-start'>Selected Files</h5>
              <ul className="fileUl">
                {(selectedFiles && Array.isArray(selectedFiles) && selectedFiles.length) ? (
                  selectedFiles.map((file: any, i: number) => (
                    <li key={i + '-select-list'} className="text-start">{file.file.split('/').slice(0, -1).join('/') + '/' + file.name} <AiFillCloseCircle className='text-danger deleteFile' onClick={() => onSelectFile(file)} /></li>
                  ))) : null
                }
              </ul>
            </div>
          </div>
      }
    </div>
  );
};

export default FileManager;
