import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Pq5 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* PQ-5 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">PQ-5 : Electricity dues for the year (under evaluation) by all Government Departments/ Attached Offices/ Local Bodies/ Autonomous Bodies/ Boards/Corporations <b>*</b></p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[217] && fields[217].value ? fields[217].value : ''} name="formdata[pqsop_details][pq5_nodal]" id="pqsop_details_pq5_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][pq5_remarks_nodal]" id="pqsop_details_pq5_remarks_nodal" defaultValue={fields[232] && fields[232].value ? fields[232].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][pq5_document_nodal]" id="pqsop_details_pq5_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[247].value != '' ?
                                            <a href={fields[247].value} title={fields[247].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>

                                    :
                                    <>

                                    {fields[217].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[217] && fields[217].value ? fields[217].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[232].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[232] && fields[232].value ? fields[232].value : ''}</div>
                                    {/* : null
                                                } */}

                                    {fields[247].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[247] && fields[247].value ? fields[247].value : ''} title={fields[247] && fields[247].value ? fields[247].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }

                        </div>
                        <div className={props.remove}>
                            <MyTooltip placement="left">
                                <ul>
                                    <li>All Government Departments/ Attached Offices/ Local Bodies/ Autonomous Bodies/ Boards/Corporations have made 100% payment of current electricity dues for the year under evaluation</li>
                                    <li>Documentary Evidence: Statement showing Govt dues billed and received</li>
                                </ul>
                            </MyTooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td rowSpan={3}>Details</td>
                <td>
                    <input type="text" value={"Amount billed for FY-" + props.year + " (INR Cr)"} disabled />
                </td>
                <td>
                    <input type="text" value="Payment Received (INR Cr)" disabled />
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[108].key} field={fields[108]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[109].key} field={fields[109]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[110].key} field={fields[110]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <Modalbox field1={fields[200]} block="6" />
                    <Link to="" className="btn-warning btn d-block mx-auto w-100" data-bs-toggle="modal" data-bs-target="#Undertakingregarding">Billing and Collection details of state govt depts</Link>
                </td>
                <td></td>
                <td></td>

            </tr>
            <tr>
                <td>Status</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[111].key} field={fields[111]} />
                </td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[113].key} field={fields[113]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Pq5;