import React from 'react';
import { useAuth } from '../../../Core/Providers';
import { RouteMiddlewareProps } from '../../../Core/Components/Routes';
import { Navigate } from 'react-router-dom';

function MisAccess({ children }: RouteMiddlewareProps) {

    const user: any = useAuth().user();

    if (!user.id) {
        return <Navigate to={'/'} />;
    }

    const isNdrUser = (process.env.REACT_APP_CSRD_NDR == String(user?.role_id) && 9 == user?.id) ? true : false;
    const isNdaUser = (process.env.REACT_APP_CSRD_NDA == String(user?.role_id)) ? true : false;

    if (isNdrUser || isNdaUser) {
        return children;
    }

    return <Navigate to={'/'} />;
}

export default MisAccess;