import React from 'react';
import { useAuth } from '../../../../Core/Providers';
import { RouteMiddlewareProps } from '../../../../Core/Components/Routes';
import { Navigate } from 'react-router-dom';

function MmqiAccess({ children }: RouteMiddlewareProps) {

    const user: any = useAuth().user();

    const roleids = [
        process.env.REACT_APP_DDE,
        process.env.REACT_APP_DDR,
        process.env.REACT_APP_DDA,
        process.env.REACT_APP_QMR,
        process.env.REACT_APP_QMA,
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];

    if (roleids.includes(String(user.role_id))) {
        return children;
    }

    return <Navigate to={'/'} />;
}

export default MmqiAccess;