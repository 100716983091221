import React from 'react';
// import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import Routes from '../../Core/Components/Routes';
import routes from './Config/Routes';
import Default from '../../Layout/Default';
import { useAuth } from '../../Core/Providers/AuthProvider';
import './rdss-qm.scss';

function Index(props: any) {

  const auth = useAuth();
  let location = useLocation();

  const Loading = () => (
    <div className='container mt-3'>
      <div className="content-spinner spinner-container h-50vh">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <Helmet>
        <title>Quality Monitoring</title>
      </Helmet> */}
      <div className="app-rdss-qm">
        <Routes
          loading={<Loading />}
          location={location}
          redirect="/sign-in"
          layout={<Default />}
          isAuthorized={auth.isAuth()}
          routes={routes || []}
          notFound="/404"
        />
      </div>
    </>
  );

}

export default Index;
