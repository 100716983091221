import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm } from "../../assets/js/formbuilder";
import { Http } from '../../Core/Services/HttpService'
import "../../RdssForms/rdssforms.scss";
import Sop34 from "../InputForms/Section1/Sop34";
import Sop311 from "../InputForms/Section1/Sop311";
import Sop312 from "../InputForms/Section1/Sop312";
import Sop313 from "../InputForms/Section1/Sop313";
import Sop35 from "../InputForms/Section1/Sop35";
import Sop32 from "../InputForms/Section1/Sop32";
import Sop33 from "../InputForms/Section1/Sop33";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { Storage } from "../../Core/Services/StorageService";

const SOPFormRender = (props: any) => {
    const layout = useLayout();
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [year, setYear] = useState<any>(props.year);
    const [submittedStatus, setSubmittedStatus] = useState<any>(0);
    const [iscompletedsubmittedstatus, setCompletedSubmittedStatus] = useState<any>(0);

    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    const getFields = () => {
        (async () => {
            setFields([]);
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    // layout.set({ api: props.apiurl, 'test': 'finac' });
                    layout.set({ submittedStatus: result });
                    setSubmittedStatus(result.submittedStatus);
                    setCompletedSubmittedStatus(result.isCompletedSubmittedStatus);
                    setTimeout(() => {
                        init();
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);


    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
            {roleid == 5 || roleid == 6 || iscompletedsubmittedstatus == 1 ?
                <>
                {submittedStatus == 1 ?
                    <>
                    <tr>
                        <td className="w-20"><p className="mb-0 ps-2 fw-medium pe-3">Overall Compliance</p></td>
                        <td className="w-25">
                            <select defaultValue={fields[261] && fields[261].value ? fields[261].value : ''} name="formdata[pqsop_details][pqsopcomman_nodal]" id="pqsop_details_pqsopcomman_nodal" className="form-select text-start w-150">
                                <option value="">Select</option>
                                <option value="Qualified">Qualified</option>
                                <option value="Non-Qualified">Non-Qualified</option>
                            </select>
                        </td>
                    </tr>
                    </>
                    :
                    <>
                    {fields[261].value != '' ?
                        <tr>
                            <td className="w-20"><p className="mb-0 ps-2 fw-medium pe-3">Overall Compliance</p></td>
                            <td className="w-25">
                                <div className="col-md-0">
                                    <div className="form-group select">
                                        <div className="form-field">
                                            <div className="view-field text-start">
                                                {fields[261] && fields[261].value ? fields[261].value : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        : null
                    }
                    </>
                }
                </>
                : null
            }

            {/* SoP-3.4 */}
            <Sop34 tooltip={false} fields={fields} width={300} year={year} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            {/* Sop-3.1.1 */}
            <Sop311 tooltip={false} fields={fields} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            {/* SoP-3.1.2 */}
            <Sop312 tooltip={false} fields={fields} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            {/* SoP-3.1.3 */}
            <Sop313 tooltip={false} fields={fields} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            {/* PQ-6 */}
            <Sop35 tooltip={false} fields={fields} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            {/* SoP-3.2 */}
            <Sop32 tooltip={false} fields={fields} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            {/* SoP-3.3 */}
            <Sop33 tooltip={false} fields={fields} submittedStatus={submittedStatus} iscompletedsubmittedstatus={iscompletedsubmittedstatus} />
            </>
        );
    }
};

const SopForm = (props: any) => {
    const [year, setYear] = useState<any>();
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
    }, [roleid]);

    useEffect(() => {
        props.tblkey('pq_details');
    }, []);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    return (
        <>
        <div className="mainWrap">
            <div className="table-responsive RevenueDetailsTable PqTable">
                <table className="table">
                    <tbody>
                        {roleid == 0 ? null :
                            <SOPFormRender apiurl={"/apiv1/formfields/?form_id=15&roleid=" + roleid + "&act=view&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} refresh={refresh} year={year} />
                        }
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}
export default SopForm;