import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import '../chart.scss';

class DTRsChart extends React.Component<{ infraData: any }, any> {
  constructor(props: any) {
    super(props);

    const sanDataPer = props.infraData['sanction_pern'] * 1;
    const awaDataPer = props.infraData['award_pern'] * 1;
    const instDataPer = props.infraData['surveyed_pern'] * 1;

    this.state = {
      series: [sanDataPer, awaDataPer, instDataPer],
      options: {
        chart: {
          height: 165,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '40%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: '8px',
                fontFamily: 'Poppins',
                offsetY: 0,
              },
              value: {
                show: true,
                fontSize: '8px',
                fontFamily: 'Poppins',
                offsetY: 0,
                formatter: function (val: any) {
                  return val + '%'
                }
              },
              total: {
                show: false,
                label: 'XX',
                formatter: function (w: any) {
                  return 0
                }
              }
            }
          }
        },
        colors: ['#feba3b', '#269ffa', '#62eeb6',],
        labels: ['Sanctioned', 'Awarded', 'Installed'],
      },


    };
  }
  componentDidUpdate(prevProps: any): void {
    if (prevProps != this.props) {
      // const sanDataPer = 100;
      // const awaDataPer = (parseInt(this.props.infraData['sanctioned']) < parseInt(this.props.infraData['awarded'])) ? 100 : (parseInt(this.props.infraData['awarded']) * 100 / parseInt(this.props.infraData['sanctioned'])).toFixed(2);
      // const instDataPer = (parseInt(this.props.infraData['sanctioned']) < parseInt(this.props.infraData['surveyed'])) ? 100 : (parseInt(this.props.infraData['surveyed']) * 100 / parseInt(this.props.infraData['sanctioned'])).toFixed(2);

      const sanDataPer = this.props.infraData['sanction_pern'] * 1;
      const awaDataPer = this.props.infraData['award_pern'] * 1;
      const instDataPer = this.props.infraData['surveyed_pern'] * 1;

      const seriesData = [sanDataPer, awaDataPer, instDataPer];
      this.setState({ series: seriesData });
    }
  }

  render() {
    return (
      <div id="chart" className="progressDetailsChart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={165} />
      </div>
    );
  }
}

export default DTRsChart;
