import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import { useStateMounted } from '../../../Core/Hooks';
import { Alert } from 'react-bootstrap';
import FieldComponent from "../../../FieldComponent";
import { Storage } from "../../../Core/Services/StorageService";
import FdDiscomName from "../FdDiscomName";

const Block1 = (props: any) => {

    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    return (
        <>
            <tr className='caption text-left'>
                <td colSpan={23}>Smart Metering</td>
                {/* <td className='text-end pe-1' style={{ width: "120px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
            </td> */}
            </tr>
            {
                block1Fields.map((item: any, key: number) => {
                    return (item.delete === false) ? <>
                        <tr key={key}>
                            <td className="text-center">{(block1SrNo++) + 1}</td>
                            {
                                item.fields.map((field: any, fkey: number) => {
                                    return <>
                                        <td key={key + '_' + fkey}>
                                            <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                    </>;
                                })
                            }
                            {/* <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                        </tr>
                    </>
                        : null;
                })
            }
            {
                (props.field1.childrens?.length > 0)
                    ? <>
                        <tr>
                            <td colSpan={3} className="text-center"><h6>Total</h6></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                            <td></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[8].key} field={props.field1.childrens[8]} /></td>
                            <td></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[9].key} field={props.field1.childrens[9]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[10].key} field={props.field1.childrens[10]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[11].key} field={props.field1.childrens[11]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[12].key} field={props.field1.childrens[12]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[13].key} field={props.field1.childrens[13]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[14].key} field={props.field1.childrens[14]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[15].key} field={props.field1.childrens[15]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[16].key} field={props.field1.childrens[16]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[17].key} field={props.field1.childrens[17]} /></td>
                            <td></td>
                        </tr>
                    </>
                    : null
            }
        </>
    );
}

const Block2 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    return (
        <>
            <tr className='caption text-left'>
                <td colSpan={23}>Loss Reduction</td>
                {/* <td className='text-end pe-1' style={{ width: "120px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
            </td> */}
            </tr>
            {
                block2Fields.map((item: any, key: number) => {
                    return (item.delete === false) ? <>
                        <tr key={key}>
                            <td className="text-center">{(block2SrNo++) + 1}</td>
                            {
                                item.fields.map((field: any, fkey: number) => {
                                    return <>
                                        <td key={key + '_' + fkey}>
                                            <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                    </>;
                                })
                            }
                            {/* <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                        </tr>
                    </>
                        : null;
                })
            }
            {
                (props.field1.childrens?.length > 0)
                    ? <>
                        <tr>
                            <td colSpan={3} className="text-center"><h6>Total</h6></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                            <td></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[8].key} field={props.field1.childrens[8]} /></td>
                            <td></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[9].key} field={props.field1.childrens[9]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[10].key} field={props.field1.childrens[10]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[11].key} field={props.field1.childrens[11]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[12].key} field={props.field1.childrens[12]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[13].key} field={props.field1.childrens[13]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[14].key} field={props.field1.childrens[14]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[15].key} field={props.field1.childrens[15]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[16].key} field={props.field1.childrens[16]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[17].key} field={props.field1.childrens[17]} /></td>
                            <td></td>
                        </tr>
                    </>
                    : null
            }
        </>
    );
}

const Block3 = (props: any) => {
    let block3SrNo = 0;
    const [hasBlock3FieldsDelete, setHasBlock3FieldsDeletes] = useState<boolean>(false);
    const [block3Fields, setBlock3fields] = useState<any>([]);
    const [newBlock3Fields, setNewBlock3fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock3fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock3fields(fields1);
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [props.field]);

    useEffect(() => {
        if (block3Fields.length) {
            const hasDelete = (block3Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock3FieldsDeletes(hasDelete);
            block3SrNo = 0;
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }, [block3Fields]);

    const addItem = (block?: any) => {

        if (block3Fields.length < 100) {
            setBlock3fields((prev: any[]) => {
                return [...prev, { fields: newBlock3Fields, delete: false }];
            });
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock3fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    return (
        <>
            <tr className='caption text-left'>
                <td colSpan={23}>MODERNISATION</td>
                {/* <td className='text-end pe-1' style={{ width: "120px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
            </td> */}
            </tr>
            {
                block3Fields.map((item: any, key: number) => {
                    return (item.delete === false) ? <>
                        <tr key={key}>
                            <td className="text-center">{(block3SrNo++) + 1}</td>
                            {
                                item.fields.map((field: any, fkey: number) => {
                                    return <>
                                        <td key={key + '_' + fkey}>
                                            <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                    </>;
                                })
                            }
                            {/* <td className="text-end">{(hasBlock3FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                        </tr>
                    </>
                        : null;
                })
            }
            {
                (props.field1.childrens?.length > 0)
                    ? <>
                        <tr>
                            <td colSpan={3} className="text-center"><h6>Total</h6></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                            <td></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[5].key} field={props.field1.childrens[5]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[6].key} field={props.field1.childrens[6]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[7].key} field={props.field1.childrens[7]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[8].key} field={props.field1.childrens[8]} /></td>
                            <td></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[9].key} field={props.field1.childrens[9]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[10].key} field={props.field1.childrens[10]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[11].key} field={props.field1.childrens[11]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[12].key} field={props.field1.childrens[12]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[13].key} field={props.field1.childrens[13]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[14].key} field={props.field1.childrens[14]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[15].key} field={props.field1.childrens[15]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[16].key} field={props.field1.childrens[16]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[17].key} field={props.field1.childrens[17]} /></td>
                            <td></td>
                        </tr>
                    </>
                    : null
            }
        </>
    );
}

const FinancialProgressPreviewForms = (props: any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerid] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
    const [view, setView] = useState<any[]>([]);
    const layout = useLayout();

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setAplicationTrackerid(props.getaplicationtrackerid);
    }, [roleid, discomid, props.getaplicationtrackerid]);

    const getFields = () => {
        (async () => {
            setIsLoaded(true);
            setError('');
            setFields([]);

            if (roleid && discomid && getaplicationtrackerid) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 39,
                    discom_user_id: discomuserid,
                    application_id: getaplicationtrackerid,
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(true);
                                setFields(res.fields);
                                setSubmittedStatus(res.submittedStatus);
                                layout.set({ fieldsresult: res });
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [getaplicationtrackerid]);

    return (
        <>
            {error && (
                <>
                    <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
                </>
            )}

            {
                ((!error) && fields?.length > 0) && (
                    <>
                        <div className="radioYear pe-md-4 py-3 h-auto">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-sm-4 col-md-3">
                                    <div className="form-check ps-0">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3 mt-3 mt-sm-0">
                                    <div className="form-check ps-0">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3 mt-3 mt-sm-0">
                                    <div className="form-check ps-0">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive RevenueDetailsTable mt-4">
                            <table className="table caption-top">
                                <thead className='text-center'>
                                    <tr>
                                        <th style={{ width: "50px" }}>S.No</th>
                                        <th style={{ minWidth: "150px" }}>Project</th>
                                        <th style={{ minWidth: "150px" }}>Sanction/ Grant No.</th>
                                        <th style={{ minWidth: "150px" }}>Sanctioned Cost (INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Award Cost (incl all taxes INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Lower of sanctioned cost or award cost(INR Lakh)</th>
                                        <th style={{ minWidth: "80px", width: "80px" }}>GST Rate(%)</th>
                                        <th style={{ minWidth: "150px" }}>Eligible Project Cost(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>GOI Grant Sanction (INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Eligible GoI Grant(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>GoI Grant Disb.(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>GOI Fund utilized(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Name of FI</th>
                                        <th style={{ minWidth: "150px" }}>Counterpart Fund Sanctioned(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Eligible counterpart loan(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Counterpart Fund released(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Counterpart Fund utilized(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Own Resources - infused(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Own Resources - utilized(INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Cumulative fund available (INR Lakh)</th>
                                        <th style={{ minWidth: "150px" }}>Cumulative Amount Utilized (GoI/CP/Own funds)(INR Lakh)</th>
                                        <th style={{ minWidth: "80px", width: "80px" }}>Financial Progress %</th>
                                        <th style={{ minWidth: "150px" }}>Remarks</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <Block1 field={fields[3]} field1={fields[6]} />
                                    <Block2 field={fields[4]} field1={fields[7]} />
                                    <Block3 field={fields[5]} field1={fields[8]} />
                                </tbody>
                                <tr>
                                    <td colSpan={3} className="text-center"><h6>Total <br /> Financial <br /> Progress Preview</h6></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[11].key} field={fields[11]} /></td>
                                    <td></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[14].key} field={fields[14]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[15].key} field={fields[15]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[16].key} field={fields[16]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[17].key} field={fields[17]} /></td>
                                    <td></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[18].key} field={fields[18]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[19].key} field={fields[19]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[20].key} field={fields[20]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[21].key} field={fields[21]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[22].key} field={fields[22]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[23].key} field={fields[23]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[24].key} field={fields[24]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[25].key} field={fields[25]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[26].key} field={fields[26]} /></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default FinancialProgressPreviewForms;