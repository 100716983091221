import React, { useEffect, useState } from "react"
import DashboardFooter from '../Components/Footer/DashboardFooter'
import DashboardHeader from '../Components/Header/DashboardHeader'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BsFileEarmarkPdf } from "react-icons/bs"
import './dashboard.scss';
import { useLayout } from "../Core/Providers"
import { RiFileExcel2Line } from "react-icons/ri"
import { Http } from "../Core/Services/HttpService"
import { utils, writeFile } from "xlsx";
import { Modal } from "react-bootstrap"
import Select from 'react-select';

const AllStatePhysicalProgressView = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const locationModal = location.state && location.state.modal;
    const [params, setParams] = useState<any>();
    const [progressData, setProgressData] = useState<any>([]);
    const [reportData, setReportData] = useState<any>([]);
    const [selected, setSelected] = useState("TableSection");
    const [chartLink, setChartLink] = useState<string>('');
    const [headingTitle, setHeadingTitle] = useState<string>('State wise Physical Progress IN(%) - Smart Metering');
    const [noDataMessage, setNoDataMessage] = useState<string>('Loading...');
    const [options, setOptions] = useState<any>([]);
    const [filterParams, setFilterParams] = useState<any>({
        sortBy: 'alphabetically',
        query: [],
    });

    const layout = useLayout();
    let srNo = 1;
    let reportType = 'DisStates';

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ awarditemstype: e.target.value });
    };

    const fetchProgressData = async (fParams: any = {}) => {
        setProgressData([]);
        setNoDataMessage('Loading...');

        if (fParams.chartfor == 'state') {
            fParams.stateid = fParams.id;
            reportType = 'Discom';
        }
        if (fParams.chartfor == 'discom') {
            fParams.stateid = fParams.state;
            fParams.discomid = fParams.id;
            reportType = 'Districts';
        }

        const fnlParams = {
            ...fParams,
            reportType: reportType,
            majorcomponentid: '1, 2, 3, 4',
            subcomp: '0',
            monitoringPar: '1, 3, 4, 6, 7, 9, 12',
            module: 'sm'
        };
        await Http.get('/apiv1/d-board/progressData', { params: fnlParams })
            .then((res) => {
                if (res._resultflag == "1") {
                    setProgressData(res.stateProgressData);
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };

    const exportToPDF = () => {
        let table = document.getElementById("progress-data")?.cloneNode(true) as any;
        table = document.createDocumentFragment().appendChild(table);
        // remove link of second td of each tr
        let trs = table.getElementsByTagName('tr');
        for (let i = 0; i < trs.length; i++) {
            if (trs[i].getElementsByTagName('td')[1]) {
                trs[i].getElementsByTagName('td')[1].innerHTML = trs[i].getElementsByTagName('td')[1].textContent;
            }
        }
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const frmData = new FormData();
        frmData.append('content', encodeURIComponent(table.outerHTML));
        frmData.append('filename', 'progressdata');
        frmData.append('download', 'no');
        Http.post('/apiv1/contentExportPdf', frmData, config)
            .then((res) => {
                if (res._resultflag == "1") {
                    window.open(res.file, "_blank");
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };

    const exportToExcel = () => {
        const table = document.getElementById('progress-data');
        const wb = utils.table_to_book(table);
        writeFile(wb, 'progressdata.xlsx');
    };

    const sortBy = (e: any) => {
        setFilterParams((prev: any) => { return { ...prev, sortBy: e.target.value } });
    };

    const filterQuery = (item: any) => {
        setFilterParams((prev: any) => { return { ...prev, query: item } });
    };

    useEffect(() => {
        if (params) {
            setChartLink('');
            fetchProgressData(params);
            switch (params.chartfor) {
                case 'discom':
                    setHeadingTitle('District wise Physical Progress IN(%) - Smart Metering');
                    setChartLink('');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
                case 'state':
                    setChartLink(`/all-state-physical-progress?chartfor=discom&type=${params.type}&state=${params.id}`);
                    setHeadingTitle('Discom wise Physical Progress IN(%) - Smart Metering');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
                case 'all':
                    setChartLink(`/all-state-physical-progress?chartfor=state&type=${params.type}`);
                    setHeadingTitle('State wise Physical Progress IN(%) - Smart Metering');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
            }
        }
    }, [params]);

    useEffect(() => {
        const sectionfor = new URLSearchParams(location.search).get('sectionfor');
        if (sectionfor == 'TableSection') { setSelected("TableSection"); }
        setParams({
            id: new URLSearchParams(location.search).get('id') || null,
            state: new URLSearchParams(location.search).get('state') || null,
            discom: new URLSearchParams(location.search).get('discom') || null,
            chartfor: new URLSearchParams(location.search).get('chartfor') || 'all',
            type: new URLSearchParams(location.search).get('type')
        });
    }, [location]);

    useEffect(() => {
        const opt = progressData.map((item: any) => {
            return { label: item.title, value: item.title };
        });
        setOptions(opt);
    }, [progressData]);

    useEffect(() => {
        (async () => {
            if (progressData.length > 0) {
                let filterData = JSON.parse(JSON.stringify(progressData));
                let lastrec = filterData.pop();
                if (filterParams.query.length > 0) {
                    filterData = await filterData.filter((element: any) => filterParams.query.some((item: any) => item.value === element.title));
                }
                filterData.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1);
                if (filterParams.sortBy == 'alphabetically') {
                    filterData.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1);
                }
                else if (filterParams.sortBy == 'asc') {
                    filterData.sort((a: any, b: any) => (a.progress > b.progress) ? 1 : -1);
                } else {
                    filterData.sort((a: any, b: any) => (a.progress < b.progress) ? 1 : -1);
                }
                setReportData(filterData);
            }
        })();
    }, [progressData, filterParams]);

    const Content = () => {
        const linkState = (locationModal && (params && params.chartfor !== 'discom'))
            ? { state: { modal: locationModal } }
            : {};

        return <div className="container-fluid">
            <div className="dashboardInner detailedDashboard">
                <div className="row gx-xxl-5">
                    <div className="col-xxl-12 col-xl-12">
                        <div className="discomSection">
                            <div className="discomRight">
                                <div className="dashChart">
                                    <div className="DiscomSummary">
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <select className="form-select" onChange={sortBy}>
                                                    <option value={"alphabetically"} selected={filterParams.sortBy === "alphabetically"}>A-Z</option>
                                                    <option value={"asc"} selected={filterParams.sortBy === "asc"}>Ascending</option>
                                                    <option value={"desc"} selected={filterParams.sortBy === "desc"}>Descending</option>
                                                </select>
                                            </div>
                                            <div className="col-md-7">
                                                <Select
                                                    isMulti={true}
                                                    options={options}
                                                    onChange={filterQuery}
                                                    value={filterParams.query}
                                                />
                                            </div>
                                        </div>
                                        <div aria-hidden={selected !== "TableSection" ? true : false}>
                                            <div className="d-flex justify-content-end mt-2">
                                                <a title="Export to PDF" onClick={() => exportToPDF()}><div className="pdfBox"><BsFileEarmarkPdf /></div></a>
                                                <a title="Export to XLS" onClick={() => exportToExcel()}><div className="excelBox bg-success"><RiFileExcel2Line /></div></a>
                                            </div>
                                            <div className="table-responsive progressDetailsTable">
                                                <table className="table-responsive RevenueDetailsTable PqTable mx-auto" id="progress-data">
                                                    <thead className="text-center">
                                                        <tr className="d-none show-pdf">
                                                            <th colSpan={6} className="text-start">
                                                                <h6 className="mb-2 text-start">
                                                                    {headingTitle}
                                                                </h6>
                                                                <p className="text-end"> {new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</p>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th className="text-start">
                                                                {params?.chartfor == 'all' ? 'State' : ''}
                                                                {params?.chartfor == 'state' ? 'Discom' : ''}
                                                                {params?.chartfor == 'discom' ? 'District' : ''}
                                                            </th>
                                                            <th>Physical Progress IN(%)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {
                                                            (reportData.length <= 0)
                                                                ? <tr><td colSpan={6} className="text-center">{noDataMessage}</td></tr>
                                                                : null
                                                        }
                                                        {
                                                            reportData.map((item: any, index: any) => (
                                                                <tr key={index}>
                                                                    <td>{srNo++}</td>
                                                                    {params?.chartfor == 'discom' ? <td className="text-start">{item.title}</td> : <td className="text-start"><Link to={chartLink + '&id=' + item.id + '&sectionfor=TableSection'} {...linkState}>{item.title}</Link></td>
                                                                    }
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.progress)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <h4 className="mb-0 text-end stickyBack"><a onClick={() => navigate(-1)} className="text-decoration-none text-dark"><em className="arrow"><BiArrowBack onClick={() => navigate(-1)} /></em> Go Back</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };

    return (
        <>
            {
                (locationModal)
                    ? (
                        <Modal show={true} onHide={() => navigate(-1)}>
                            <Modal.Header>
                                <Modal.Title>
                                    {headingTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Content />
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                    )
                    : (
                        <div className="mainWrap dashboard">
                            <DashboardHeader />
                            <Content />
                            <DashboardFooter remove='d-none' />
                        </div>
                    )
            }
        </>
    );
};

export default AllStatePhysicalProgressView;