import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Pq2 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* PQ-2 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">PQ-2 : Date of publishing annual audited account <b>*</b></p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[214] && fields[214].value ? fields[214].value : ''} name="formdata[pqsop_details][pq2_nodal]" id="pqsop_details_pq2_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][pq2_remarks_nodal]" id="pqsop_details_pq2_remarks_nodal" defaultValue={fields[229] && fields[229].value ? fields[229].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][pq2_document_nodal]" id="pqsop_details_pq2_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[244].value != '' ?
                                            <a href={fields[244].value} title={fields[244].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>

                                    {fields[214].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[214] && fields[214].value ? fields[214].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[229].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[229] && fields[229].value ? fields[229].value : ''}</div>
                                    {/* : null
                                        }  */}

                                    {fields[244].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[244] && fields[244].value ? fields[244].value : ''} title={fields[244] && fields[244].value ? fields[244].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }

                        </div>
                        <div className={props.remove}>
                            <MyTooltip placement="left">
                                <ul>
                                    <li>DISCOMs would publish audited annual accounts by end of December of the following year during first two years of operation of the scheme (i.e. for FY 2021-22 and FY 2022-23) and thereafter audited annual accounts by end of September of the following year from 3rd year onwards</li>
                                    <li>Documentary Evidence: Copy of audited accounts and letter sent by DISCOM for CAG audit</li>
                                </ul>
                            </MyTooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Target Date</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[20].key} field={fields[20]} />
                </td>
                <td className="text-center">Published Date</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[22].key} field={fields[22]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[23].key} field={fields[23]} />
                </td>
            </tr>
            <tr>
                <td>Status</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[24].key} field={fields[24]} />
                </td>
                <td colSpan={3}></td>
            </tr>
            <tr>
                <td colSpan={4}>
                    Please provide the current status and the dates for the following activities
                    </td>
            </tr>
            <tr>
                <td>Statutory Audit
                        <MyTooltip placement="right">
                        <ul>
                            <li>Date of approval of annual accounts by statutory auditor</li>
                        </ul>
                    </MyTooltip>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[25].key} field={fields[25]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[26].key} field={fields[26]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[27].key} field={fields[27]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Board Approval
                        <MyTooltip placement="right">
                        <ul>
                            <li>Date of Publishing of Audited annual accounts after board approval</li>
                        </ul>
                    </MyTooltip>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[28].key} field={fields[28]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[29].key} field={fields[29]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[30].key} field={fields[30]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>CAG
                        <MyTooltip placement="right">
                        <ul>
                            <li>Date of completion of CAG Audit and submission of report by CAG</li>
                        </ul>
                    </MyTooltip>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[31].key} field={fields[31]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[32].key} field={fields[32]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[33].key} field={fields[33]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>AGM
                        <MyTooltip placement="right">
                        <ul>
                            <li>Date of adoption by AGM</li>
                        </ul>
                    </MyTooltip>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[34].key} field={fields[34]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[35].key} field={fields[35]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[36].key} field={fields[36]} />
                </td>
                <td></td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[37].key} field={fields[37]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Pq2;