import React, { Component } from 'react'
import CapacityBuilding from "../../assets/ImageCompo/CapacityBuilding";
import Evaluation from "../../assets/ImageCompo/Evaluation";
import FundDisbursal from "../../assets/ImageCompo/FundDisbursal";
import InputForm from "../../assets/ImageCompo/InputForm";
import Monitor from "../../assets/ImageCompo/Monitor";
import SchemeClosure from "../../assets/ImageCompo/SchemeClosure";
import { Link } from "react-router-dom";

export default class EaActionCenter extends Component {

  render() {
    return (
        <>
        <div className='row'>
        <div className="col-lg-3 col-6 d-flex align-items-center justify-content-center">
              <div className="tabPanelInner border-0 py-2">
                <div className='my-3'>
                  <select className='form-select'>
                    <option selected>FY</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 d-flex align-items-center justify-content-center">
              <div className="tabPanelInner border-0 py-2">
                <div className='my-3'>
                  <select className='form-select'>
                    <option selected>Quarter</option>
                  </select>
                </div>
              </div>
            </div>
        </div>
        <div className="ActionCenterSection ActionCenterSectionEA">
          <div className="row gx-0">
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/general-information" className="d-block w-100 text-decoration-none">
                <div className="tabPanelInner">
                  <h5>General <br/> Information</h5>                  
                </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/infrastructure-details" className="d-block w-100 text-decoration-none">
              <div className="tabPanelInner">
                <h5>Infrastructure <br/> Details</h5>                
              </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/division-wise-losses" className="d-block w-100 text-decoration-none h-100">
                <div className="tabPanelInner">
                  <h5>Division <br/> Wise Losses</h5>
                </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/energy-infrastructure" className="d-block w-100 text-decoration-none h-100">
                <div className="tabPanelInner">
                  <h5>Input Energy <br/> & Infrastructure</h5>
                </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/details-received-sources" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <h5>Details of Received Sources</h5>
              </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/details-consumers-consumption" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <h5>Detail of Consumers & Consumption</h5>
              </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/details-feeder-levels" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <h5>Details on <br/> Feeder Levels</h5>
              </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/details-dtwise-loss" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <h5>Details of <br/> DT wise loss</h5>
              </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/subsidy-details" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <h5>Subsidy Details</h5>
              </div>
              </Link>
            </div>
            <div className="col-lg-25 col-6 d-flex align-items-center justify-content-center">
              <Link to="/summary" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <h5>Summary</h5>
              </div>
              </Link>
            </div>            
          </div>
        </div>
        </>
    )
  }
}
