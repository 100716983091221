import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import { Http } from "../../Core/Services/HttpService";
import { BsArrowRight } from "react-icons/bs";
import swal from 'sweetalert';
import { FiEdit, FiTrash2 } from 'react-icons/fi'
import { useStateMounted } from '../../Core/Hooks';
import { Storage } from "../../Core/Services/StorageService";
import FdDiscomName from "./FdDiscomName";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

export const FundDisbursal = (props: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [selectedTranche, setSelectedTranche] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useStateMounted<string>();
    const [gettranchesoption, getTranchesOption] = useState<any[]>([]);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [apiapplicationurl, setApiApplicationUrl] = useState<any>(null);
    const [getapplicationtracker, setApplicationTracker] = useState<any[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    const [getSelectedComponent, setSelectedComponent] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();

    const handleSelectedComponent = (evt?: any) => {
        setSelectedComponent(evt.target.value);
        setIsLoaded(true);
    };

    const handleSelectedTranche = (evt: any) => {
        setSelectedTranche(evt.target.value);
        setIsLoaded(true);
    };

    const getTranches = () => {
        (async () => {
            setIsLoading(true);
            setError('');
            if (getSelectedComponent) {
                const params = {
                    component_id: getSelectedComponent
                };

                await Http.get(`/apiv1/get-tranche-installment`, { params })
                    .then((res) => {
                        if (res._resultflag && res.trancheinstallment) {
                            if (res.trancheinstallment) {
                                if (!res.trancheinstallment.length) setError("No data found.");
                                getTranchesOption(res.trancheinstallment);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res._resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError('System error occurred!! please try again.');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getTranches();
        })();
    }, [getSelectedComponent]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
        setDiscomid(Storage.get('discom_id'));
        setRoleid(Storage.get('roleid'));
    }, [roleid, discomid]);

    const getReleaseFundsDocumentationData = async () => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData();
        myFormData.append('tranche_id', selectedTranche);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('component_id', getSelectedComponent);

        await Http.post('/apiv1/get-release-funds-documentation', myFormData, config)
            .then((res) => {
                if (res._resultflag == 1) {
                    navigate('/fund-disbursal-link', { state: { ref_id: res.id, data: res.release_funds_documentation, component_name: res.component_name, tranche_name: res.tranche_name, component_id: res.component_id, tranche_id: res.tranche_id, aplication_tracker_id: res.aplication_tracker_id } });
                } else {
                    swal("", res.message, "warning");
                }
            }).catch((e) => {
            });
    }

    useEffect(() => {
        (async () => {
            if (location.state) {
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
            } else {
                navigate('/fund-disbursal');
            }
        })();
    }, [getcomponentid, gettrancheid]);

    const getApplicationTracker = () => {
        (async () => {
            setIsLoading(true);
            setError('');
            if (discomid && discomuserid && roleid) {
                const params = {
                    discom_id: discomid,
                    discom_user_id: discomuserid,
                    roleid: roleid
                };

                await Http.get(`/apiv1/get-application-tracker`, { params })
                    .then((res) => {
                        if (res._resultflag && res.fund_disbursal_data) {
                            if (res.fund_disbursal_data) {
                                if (!res.fund_disbursal_data.length) setError("No data found.");
                                setApplicationTracker(res.fund_disbursal_data);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res._resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError('System error occurred!! please try again.');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getApplicationTracker();
        })();
    }, [discomid, roleid, discomuserid]);

    const gotToPage = (id: any, application_id: any, component_id: any, tranche_id: any, component_name: any, tranche_name: any) => {
        setComponentId(component_id);
        setTrancheId(tranche_id);
        setAplicationTrackerId(application_id);
        setComponentname(component_name);
        setTranchename(tranche_name);
        setTrackerId(id);
        setPageLink('fund-disbursal-link');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate('/fund-disbursal-link', { state: { ref_id: gettrackerid, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
            }
        })();
    }, [getpagelink]);

    const deleteApp = (id: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("id", id);
        formData.append("discom_id", discomid);
        formData.append('discom_user_id', discomuserid);
        formData.append('roleid', roleid);
        setIsLoading(true);
        swal({
            title: "Are you sure to delete this application?",
            text: "",
            icon: "warning",
            buttons: [
                'No',
                'Yes'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Application Tracker!',
                    text: 'Your application has been deleted.',
                    icon: 'success'
                }).then(function () {
                    Http.post('/apiv1/application-tracker-delete', formData, config)
                        .then((result) => {
                            if (result._resultflag == 1) {
                                setIsLoading(false);
                                setApplicationTracker(result.data);
                                getApplicationTracker();
                            }
                            setIsLoading(false);
                        });
                });
            } else {
                setIsLoading(false);
            }
        });
    }

    return (
        <>
            <section className="financialForm">
                <div className="financialMainFrom">
                    <div className="tab-content border-0 p-0">
                        <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                            {roleid == 2 ?
                                <>
                                    <div className='fYearMonth setPosition'>
                                        <div className='row justify-content-end align-items-center gx-2'>
                                            <div className='col-md-4 col-xxl-4'>
                                                <select className='form-control form-select w-100' onChange={handleSelectedComponent} name="component" id="component">
                                                    <option value={0}>Select Component</option>
                                                    <option value={1}>Smart Metering Works</option>
                                                    <option value={2}>Loss Reduction Works</option>
                                                    <option value={3}>Modernization Works</option>
                                                    <option value={4}>PMA for Smart Metering Works</option>
                                                    <option value={5}>PMA for Loss Reduction Works</option>
                                                    <option value={6}>PMA for Modernization Works</option>
                                                </select>
                                            </div>
                                            <div className='col-md-6 col-xxl-5'>
                                                <select className='form-control form-select w-100' name="tranche_installment" id="tranche_installment" onChange={handleSelectedTranche}>
                                                    <option value={0}>Select Tranche/Installment</option>
                                                    {(gettranchesoption.length > 0)
                                                        ? <>
                                                            {
                                                                gettranchesoption.map((tranches: any) => {
                                                                    // const attr = (tranches.id === gettrancheid) ? {value:tranches.id,selected:true} : {value:tranches.id}; 
                                                                    const attr = { value: tranches.id };
                                                                    return <><option {...attr}>{tranches.name}</option></>;
                                                                })
                                                            }
                                                        </> : null
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-md-1">
                                                <div className="d-flex justify-content-end">
                                                    <button className="inputBtn mt-0" onClick={getReleaseFundsDocumentationData}><BsArrowRight /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                            }

                            <div className="table-responsive RevenueDetailsTable mt-4">
                                <table className="table caption-top">
                                    <caption className='text-center'>Application Tracker</caption>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10px" }}>S.No</th>
                                            <th style={{ width: "10px" }}>Application ID</th>
                                            <th style={{ width: "100px" }}>Component</th>
                                            <th style={{ width: "400px" }}>Tranche</th>
                                            <th style={{ width: "10px" }}>Status</th>
                                            <th style={{ width: "100px" }}>Date</th>
                                            <th style={{ width: "10px" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(getapplicationtracker.length > 0) ?
                                            <>
                                                {
                                                    getapplicationtracker.map((item: any, key: number) => {
                                                        return <>
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{item.application_id}</td>
                                                                <td>{item.component_name}</td>
                                                                <td>{item.tranche_name}</td>
                                                                <td>{item.status}</td>
                                                                <td>{item.submission_date}</td>
                                                                <td>
                                                                    <Link className='text-primary text-decoration-none' to="" onClick={() => gotToPage(item.id, item.application_id, item.component_id, item.tranche_id, item.component_name, item.tranche_name)}> <FiEdit className="p-0 mb-0 h5 text-success" /></Link>
                                                                    {(roleid == 2 && item.submittedStatus == 1) ?
                                                                        <Link className='text-primary text-decoration-none' to="" onClick={() => deleteApp(item.id)}> <FiTrash2 className="p-0 mb-0 h5 text-danger" /></Link>
                                                                        : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </>
                                                    })
                                                }
                                            </>
                                            : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isLoading ? <LoadingSpinner /> : null}
        </>
    );
};

export default FundDisbursal;