import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options:any = {
    responsive: true,
    plugins: {      
      title: {
        display: true,
        text: 'Number of Meters',
        position: 'left',
      },
      legend: {
        display: false
      },  
    },
  };


const labels = ['','January', 'February', 'March', 'April', 'May', 'June', 'July', 'Auguest', 'September', 'October', 'November', 'December'];

export const data:any = {
  labels,
  datasets: [
    {
        label:false,
        fill: true,
        data: [0,6000, 5500, 7500, 7900, 8000, 8200, 8000, 7400, 6400, 7800, 9800, 6500],
        borderColor: 'rgba(245, 130, 22, 1)',
        backgroundColor: 'rgba(255, 204, 190, 0.5)',
        
    },
    
  ],
};

export function LineChart() {
  return <Line height={100} options={options} data={data}/>;
}

export default LineChart ;