import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Http } from "../../Core/Services/HttpService";
import { useStateMounted } from "../../Core/Hooks";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { Alert } from "react-bootstrap";
import swal from 'sweetalert';

import Footer from "../../Layout/Default/Element/Footer";
import InnerTemplate from "../../Components/InnerTemplate/InnerTemplate";
import Header from "../../Layout/Default/Element/Header";
import FieldComponent from "../../FieldComponent";
import './dms-checklist.scss';
import { useAuth } from "../../Core/Providers";

// Images
import Arrow from "../../images/right-arrow.svg";
import Select from "react-select";
import { Storage } from "../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
};

const NodalView = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);

    useEffect(() => {
        setFields(props.fields);
    }, [props.fields]);

    return (
        <>
            <div className="row text-center mt-3 dms-checklist">
                <div className="col-md-8">
                    <div className="table-responsive RevenueDetailsTable">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th className="text-start" scope="col">Document</th>
                                    <th scope="col">Upload</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ((fields.length))
                                        ?
                                        fields.map((field: any, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td className="text-start">{field.caption}</td>
                                                <td>
                                                    {
                                                        (field.value.length > 0) ? "Uploaded" : "Not Uploaded"
                                                    }
                                                    {/*<td>
                                                    <div className="inputBtn">
                                                        <FieldComponent options={{ "showTitle": false }} fieldName={field.key} field={{...field, view: 'Y'}}/>
                                                    </div>
                                                </td>*/}
                                                </td>
                                            </tr>
                                        ))
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};

const CheckList_OLD = (props: any) => {
    const formData: any = useRef(null);
    const layout = useLayout();
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [error, setError] = useStateMounted<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [roleId, setRoleId] = useState<any>("");
    const [discomUserId, setDiscomUserId] = useState<any>(0);
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [discomList, setDiscomList] = useState([{ 'value': 0, 'label': 'Select Discom' }]);

    useEffect(() => {
        setTimeout(() => {
            setDiscomid(Storage.get('discom_id'));
            setRoleId(Storage.get('roleid'));
            setDiscomUserId(Storage.get('discom_user_id'));
        }, 1000);
    }, []);

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];

    const handleDiscomSelect = (SelectedDiscom?: any) => {
        setDiscomid(SelectedDiscom.target.value);
        setSelectedDiscom(SelectedDiscom.target.value);
    };

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner)
                footerspinner.classList.remove('d-none');
            Http.get(props.apiurl + '?discomid=' + discomid + '&year=' + props.year + '&roleId=' + roleId)
                .then((res) => {
                    if (res.resultflag && res.fields) {
                        if (res.fields && Array.isArray(res.fields)) {
                            if (!res.fields.length && !roleids.includes(roleId)) setError("No data found.");
                            setFields(res.fields);
                            layout.set({ submittedStatus: res });
                            setIsLoaded(true);
                        } else {
                            setError('System error occurred!! please try again.');
                            setIsLoaded(true);
                        }
                        setIsLoaded(true);
                    } else if (!res.resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');

                    }
                    setIsLoaded(true);
                }).catch((error) => {
                    setError('System error occurred!! please try again2.');
                    setIsLoaded(true);

                })
                .finally(() => {
                    setIsLoaded(true);
                    footerspinner.classList.add('d-none');
                });
        })();
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discomid', discomid);
        myFormData.append('year', props.year);
        myFormData.append('discom_user_id', discomUserId);
        Http.post('/apiv1/save-checklist', myFormData, config)
            .then((res) => {
                setIsLoading(false);
                setRefresh(true);
                swal("Input Form", "Your data has been saved.", "success");
            });
    }

    const getDiscoms = () => {
        (async () => {
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/getDiscomByLoggedInUser?user_id=' + discomUserId + '&role_id=' + roleId)
                .then((res) => {
                    if (res.resultflag) {
                        setDiscomList(res.discoms);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    footerspinner.classList.add('d-none');
                });
        })();
    };

    useEffect(() => {
        if (roleids.includes(roleId)) {
            getDiscoms();
        } else {
            setDiscomList([]);
        }
    }, [roleId]);

    useEffect(() => {
        (async () => {
            if(roleId && discomid) {
                getFields();
            }
        })();
    }, [props.apiurl, roleId, discomid]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <>
                {
                    roleids.includes(roleId) ?
                        <div className="row text-center mt-3 discomsSelect">
                            <div className="col-md-8">
                                <label className="ms-3">Select Discom</label>
                                <select className="form-select selectDiscom" aria-label="Default select example" onChange={handleDiscomSelect} value={selectedDiscom}>
                                    <option value="0">Select Discom</option>
                                    {
                                        (discomList.length) &&
                                        discomList.map((item: any) => {
                                            return <option value={item.value}>{item.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        : null
                }
                {
                    (fields) ?
                        <>
                            <form noValidate onSubmit={onSubmit} ref={formData}>
                                <div className="row text-center mt-3 dms-checklist">
                                    <div className="col-md-8">
                                        <div className="table-responsive RevenueDetailsTable">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr. No.</th>
                                                        <th className="text-start" scope="col">Document</th>
                                                        <th style={{"width": "200px"}} scope="col">Upload</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ((!error) && (fields.length))
                                                            ?
                                                            fields.map((field: any, index) => {
                                                                field.view = roleids.includes(roleId) ? 'Y' : 'N';
                                                                if(field.view == 'Y' && field.value.length == 0) {
                                                                    field.type = 'text';
                                                                    field.value = 'Not Uploaded';
                                                                }
                                                                return <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td className="text-start">{field.caption}</td>
                                                                    <td>
                                                                        <div className="inputBtn">
                                                                            <FieldComponent options={{ "showTitle": false }} fieldName={field.key} field={field} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            })
                                                            : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className={'text-center'}>
                                    <button className="btnDraft" type="submit">Save</button>
                                </div>
                            </form>
                            {isLoading ? <LoadingSpinner /> : null}
                        </>
                        : null
                }
            </>
        )
    }
};

const CheckList = (props: any) => {
    const formData: any = useRef(null);
    const layout = useLayout();
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [error, setError] = useStateMounted<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [roleId, setRoleId] = useState<any>("");
    const [discomUserId, setDiscomUserId] = useState<any>(0);
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [discomList, setDiscomList] = useState([{ 'value': 0, 'label': 'Select Discom' }]);
    const [tableHeader, setTableHeader] = useState(['Discom Name','DRC and Cabinet Approval','DPR','Action Plan','District-wise Sanction Letter','DISCOM Sanction Letter','DEC Meetings','LoA and Agreement- Smart Meter (including DDF Agreement)','LoA and Agreement- Loss Reduction','LoA and Agreement- PMA',]);

    useEffect(() => {
        setTimeout(() => {
            setDiscomid(Storage.get('discom_id'));
            setRoleId(Storage.get('roleid'));
            setDiscomUserId(Storage.get('discom_user_id'));
        }, 1000);
    }, []);

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];

    const handleDiscomSelect = (SelectedDiscom?: any) => {
        setDiscomid(SelectedDiscom.target.value);
        setSelectedDiscom(SelectedDiscom.target.value);
    };

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner)
                footerspinner.classList.remove('d-none');

            let apiUrl = props.apiurl + '?discomid=' + discomid + '&year=' + props.year + '&roleId=' + roleId;
            if(roleids.includes(roleId)){
                apiUrl = props.apiurl + '-nodal?userId='+discomUserId+'&year=' + props.year + '&roleId=' + roleId;
            }
            Http.get(apiUrl)
                .then((res) => {
                    if (res.resultflag && res.fields) {
                        if (res.fields && Array.isArray(res.fields)) {
                            if (!res.fields.length && !roleids.includes(roleId)) setError("No data found.");
                            if(roleids.includes(roleId)) setTableHeader(res.table_header);
                            setFields(res.fields);
                            layout.set({ submittedStatus: res });
                            setIsLoaded(true);
                        } else {
                            setError('System error occurred!! please try again.');
                            setIsLoaded(true);
                        }
                        setIsLoaded(true);
                    } else if (!res.resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');

                    }
                    setIsLoaded(true);
                }).catch((error) => {
                setError('System error occurred!! please try again2.');
                setIsLoaded(true);

            })
                .finally(() => {
                    setIsLoaded(true);
                    footerspinner.classList.add('d-none');
                });
        })();
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discomid', discomid);
        myFormData.append('year', props.year);
        myFormData.append('discom_user_id', discomUserId);
        Http.post('/apiv1/save-checklist', myFormData, config)
            .then((res) => {
                setIsLoading(false);
                setRefresh(true);
                swal("Input Form", "Your data has been saved.", "success");
            });
    }

    const getDiscoms = () => {
        (async () => {
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/getDiscomByLoggedInUser?user_id=' + discomUserId + '&role_id=' + roleId)
                .then((res) => {
                    if (res.resultflag) {
                        setDiscomList(res.discoms);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    footerspinner.classList.add('d-none');
                });
        })();
    };

    /*useEffect(() => {
        if (roleids.includes(roleId)) {
            getDiscoms();
        } else {
            setDiscomList([]);
        }
    }, [roleId]);*/

    useEffect(() => {
        (async () => {
            if(roleId && discomid) {
                getFields();
            }
        })();
    }, [props.apiurl, roleId, discomid]);

    useEffect(() => {
        if(roleids.includes(roleId)) {
            getFields();
        }
    }, [props.apiurl, roleId]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <>
                {
                    (fields) ?
                        <>
                            {
                                (roleids.includes(roleId)) ? (
                                    <div className="row text-center mt-3 dms-checklist">
                                        <div className="col-md-12">
                                            <div className="table-responsive RevenueDetailsTable">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <>
                                                            <th scope="col">Sr. No.</th>
                                                            {
                                                                ((!error) && (tableHeader))
                                                                    ?
                                                                    tableHeader.map((heading: any) => {
                                                                        return <th scope="col">{heading}</th>
                                                                    })
                                                                    : null
                                                            }
                                                        </>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        ((!error) && (fields.length))
                                                            ?
                                                            fields.map((field: any, index) => {
                                                                return <tr>
                                                                    <td>{index + 1}</td>
                                                                    {
                                                                        field.map((jfield: any) => {
                                                                            let style = {};
                                                                            if(jfield.key != 'discom_name' && jfield.value.length > 0) style = {'background':'#0080003b'};
                                                                            if(jfield.value.length == 0) {
                                                                                jfield.type = 'text';
                                                                                jfield.value = 'Not Uploaded';
                                                                            }
                                                                            return (
                                                                                <>
                                                                                    <td style={style}>
                                                                                        <div className="inputBtn">
                                                                                            <FieldComponent options={{ "showTitle": false }} fieldName={jfield.key} field={jfield} />
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            })
                                                            : null
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <form noValidate onSubmit={onSubmit} ref={formData}>
                                        <div className="row text-center mt-3 dms-checklist">
                                            <div className="col-md-8">
                                                <div className="table-responsive RevenueDetailsTable">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th className="text-start" scope="col">Document</th>
                                                            <th style={{"width": "200px"}} scope="col">Upload</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            ((!error) && (fields.length))
                                                                ?
                                                                fields.map((field: any, index) => {
                                                                    field.view = roleids.includes(roleId) ? 'Y' : 'N';
                                                                    if(field.view == 'Y' && field.value.length == 0) {
                                                                        field.type = 'text';
                                                                        field.value = 'Not Uploaded';
                                                                    }
                                                                    return <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td className="text-start">{field.caption}</td>
                                                                        <td>
                                                                            <div className="inputBtn">
                                                                                <FieldComponent options={{ "showTitle": false }} fieldName={field.key} field={field} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                                : null
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-center'}>
                                            <button className="btnDraft" type="submit">Save</button>
                                        </div>
                                    </form>
                                )
                            }
                            {isLoading ? <LoadingSpinner /> : null}
                        </>
                        : null
                }
            </>
        )
    }
};

const DMSChecklist = (props: any) => {
    const syear: any = useRef(null);
    const [year, setYear] = useState("2022");
    const [refresh, setRefresh] = useState(false);

    const refreshform = () => {
        setYear(syear.current.value);
        setRefresh(true);
    };

    useEffect(() => {
        setRefresh(false);
    }, [refresh]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <InnerTemplate PageTitle="DMS Checklist" />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            <div className="col-lg-12 col-xl-12 col-xxl-12 text-end">
                                                <div className="fYearMonth">
                                                    <div className="row gx-0 justify-content-end mt-3 mt-xl-0">
                                                        <div className="col-auto col-md-auto col-lg-auto xl-4 sanctionLetterYearNone">
                                                            <div className="year resYear">
                                                                <label>Select Year (FY)</label>
                                                                <select defaultValue={year} ref={syear} className="form-select selectYear" aria-label="Default select example">
                                                                    <option value="2022">2022</option>
                                                                    <option value="2023">2023</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                </select>

                                                                <Link to="#" onClick={() => refreshform()}>
                                                                    <div className="inputBtn">
                                                                        <span><img src={Arrow} /></span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div><CheckList year={year} apiurl={"/apiv1/checklist"} refresh={refresh} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    );
};

export default DMSChecklist;