import React, {useEffect, useRef, useState} from "react";
import { Http } from '../../../../Core/Services/HttpService';
import { init, validatefrm } from "../../../../assets/js/formbuilder";
import FieldComponent from "../../../../FieldComponent";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import { multijsoninit } from "../../../../assets/js/formbuilder";
import { BiUpload } from "react-icons/bi";
import swal from "sweetalert";
import { Storage } from "../../../../Core/Services/StorageService";

const AwardModuleLrPmaDetails = (props: any) => {
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const formData: any = useRef(null);
    const [show, setShow] = useState(false);
    const [date, setDate] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomid, discomuserid]);

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    setTimeout(() => {
                        init();
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                }).finally(() => {
                    footerspinner.classList.add('d-none');
                })
        })();
    };

    const saveasdraft = (form_tab_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        console.log(formData);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('tbl_key', 'pma_lr_award_module');
        myFormData.append('form_tab_no', form_tab_no);
        myFormData.append('award_items_tab', 'SMAWARD');
        myFormData.append('surveyed_items_tab', 'SMSURVEYED');

        let frmAction = '/apiv1/save-awardmodule-data';

        Http.post(frmAction, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        if(refresh) getFields();
    }, [refresh]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
            <>
                <form id={'lrpma_' + idRef.current} ref={formData} noValidate>
                    <div className="formBox mt-3">
                        <div className="row mx-0 g-2">
                            <div className="col-md-3 mt-0">
                                <label htmlFor="Type of Work">Type of Work</label>
                                <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                            </div>
                            <div className="col-md-3 mt-0">
                                <label htmlFor="SanctionLetterNo">Date of Sanction</label>
                                <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive RevenueDetailsTable PqTable">
                        <Block1 field={fields[2]} />
                    </div>
                    <div className="formBtnSection justify-content-between is-sticky">
                        {roleid == 2 || roleid == 3 || roleid == 4 ?
                            <>
                                <Link to="#" className="btnDraft" onClick={() => saveasdraft(2)} >
                                    Save & Submit
                                </Link>
                            </>
                            : null
                        }
                    </div>
                </form>
            </>
            : null
        );
    }
}

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {

        if(block1Fields.length < 100){
            setBlock1fields((prev: any[]) => {
                return [...prev, { fields: newBlock1Fields, delete: false }];
            });
        }
    
        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const isSubmitted = (fields:  any) => {
        return (fields[6].value === '6');
    };

    return (
        <>
            <table className="table fixUploader">
                <thead className="text-center">
                    <th style={{ minWidth: "50px" }}>S. No.</th>
                    <th style={{ minWidth: "120px" }}>RFP No (Please ensure there is no duplication in RFP No)</th>
                    <th style={{ minWidth: "100px" }}>Scope</th>
                    <th style={{ minWidth: "100px" }}>Tender Value</th>
                    <th style={{ minWidth: "120px" }}>No. of Bids Received</th>
                    <th>Actual/ Expected Date of Technical Bid Opening</th>
                    <th>Actual/ Expected Date of Financial Bid Opening</th>
                    <th style={{ minWidth: "120px" }}>Present Status</th>
                    <th>Name of participants</th>
                    <th style={{ minWidth: "150px" }}>Name of successful bidder</th>
                    <th>Actual/ Expected Date of LOA</th>
                    <th>Actual/ Expected Date of Agreement</th>
                    <th style={{ minWidth: "100px" }}>Items covered</th>
                    <th style={{ minWidth: "100px" }}>Award Cost (INR)</th>
                    <th style={{ minWidth: "100px" }}>Upload Evidence of LOA</th>
                    <th style={{ minWidth: "100px" }}>Remarks</th>
                    <th className='text-end pe-1' style={{ width: "30px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                </thead>
                <tbody>
                    <>
                        {
                            block1Fields.map((item: any, key: number) => {
                                return (item.delete === false) ? <>
                                    <tr key={key} aria-hidden={isSubmitted(item.fields) ? "true" : "false"}>
                                        <td>{!isSubmitted(item.fields) ? (block1SrNo++) + 1 : null}</td>
                                        {/*<td>{(block1SrNo++) + 1}</td>*/}
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                                const newField: any = field;
                                                if (field.type == 'file') {
                                                    options.parentIndex = [(block1SrNo - 1)];
                                                    newField.parent_type = ['multijson', 'multijson'];
                                                    newField.isMultiFile = false;
                                                }
                                                return <>
                                                    {
                                                        (field.type != 'hidden') ?
                                                            <td key={key + '_' + fkey}>
                                                                <FieldComponent options={options} fieldName={field.key} field={newField}/>
                                                            </td>
                                                            : <span style={{'display':'none'}}>
																<FieldComponent options={options} fieldName={field.key} field={newField}/>
															</span>
                                                    }
                                                </>;
                                            })
                                        }
                                        <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    </tr>
                                    {
                                        (isSubmitted(item.fields)) ?
                                            <tr key={'submitted-' + key}>
                                                <td>{(block1SrNo++) + 1}</td>
                                                {
                                                    item.fields.map((field: any, fkey: number) => {
                                                        const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                                        const newField: any = JSON.parse(JSON.stringify(field));
                                                        if (field.type == 'file') {
                                                            options.parentIndex = [(block1SrNo - 1)];
                                                            newField.parent_type = ['multijson', 'multijson'];
                                                            newField.isMultiFile = false;
                                                        }
                                                        newField.required = 'N'; // Forcefully required N
                                                        newField.view = 'Y'; // Forcefully view Y
                                                        return <>
                                                            {
                                                                (field.type != 'hidden') ?
                                                                    <td key={key + '_' + fkey}>
                                                                        <FieldComponent options={options} fieldName={field.key} field={newField}/>
                                                                    </td>
                                                                    : <span style={{'display': 'none'}}>
                                                                        <FieldComponent options={options} fieldName={field.key} field={newField}/>
                                                                    </span>
                                                            }
                                                        </>;
                                                    })
                                                }
                                                {/*<td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>*/}
                                            </tr>
                                            : null
                                    }
                                </>
                                    : null;
                            })
                        }
                    </>
                </tbody>
            </table>
        </>
    );
}

const AwardModuleLrPma = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    return (
        <>
            {roleid == 0 ? null :
                <AwardModuleLrPmaDetails apiurl={"/apiv1/formfields/?form_id=36&roleid=" + roleid+"&discom_id=" + discomid} refresh={refresh} />
            }
        </>
    )
}

export default AwardModuleLrPma;
