/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import _ from "lodash";
import qs from 'qs';
import { Auth } from "./AuthService";
import { Storage } from "./StorageService";
import EventService from "./EventService";

const HttpService = (function () {

  const checkRedirect = (status: any) => {
    const loginLink = (process.env.REACT_APP_AUTH_LOGIN_LINK) ? process.env.REACT_APP_AUTH_LOGIN_LINK : '';
    if (Auth.isAuthorized() && loginLink) {
      if (status === "401" || status === 401) {
        Auth.logout()
          .then(() => {
            Storage.delete('roleid');
            Storage.delete('username');
            window.location.replace(loginLink);
          });
      }
    }
  };

  const setHeaders = (headers: any) => {
    // Default Headers
    const _HEADERS: any = {
      'Content-Type': 'application/json'
    };

    // Timestamp
    const timestamp = new Date().toUTCString();
    _HEADERS['X-MoP-Timestamp'] = encodeURI(timestamp);

    // Add Authorization
    const user = Auth.getUser();
    if (Auth.isAuthorized()) {
      _HEADERS['X-MoP-Token'] = user.Token;
    }

    // Add Headers
    _.each(_HEADERS, (val, key) => {
      if (headers[key] === undefined) {
        headers[key] = val;
      }
    });
    return headers;
  }

  const transformRequest = (data: any, headers: any) => {
    headers = setHeaders(headers);
    const contentType = headers['Content-Type'];
    if (data !== undefined && contentType === 'application/json') {
      data = JSON.stringify(data);
    } else if (data !== undefined && contentType === 'application/x-www-form-urlencoded') {
      data = qs.stringify(data);
    } else if (data !== undefined && (!(data instanceof FormData) && contentType === 'multipart/form-data')) {
      let frmData = new FormData();
      for (let key in data) {
        if (data[key] instanceof Array && data[key].length) {
          for (let k in data[key]) {
            frmData.append(key, data[key][k]);
          }
        } else {
          frmData.append(key, data[key]);
        }
      }
      data = frmData;
    }
    return data;
  }

  const transformResponse = (data: any, headers: any) => {
    return JSON.parse(data);
  }

  const handleResponse = (response: any) => {
    if (response.headers && response.headers['x-mop-token-timestamp']) {
      const tokenTimestamp = response.headers['x-mop-token-timestamp'].split('|');
      EventService.dispatch('APP_TOKEN_TIMESTEMP', {
        currentTime: parseInt(tokenTimestamp[0]),
        tokenExpiryTime: parseInt(tokenTimestamp[1]),
        tokenRenewTime: parseInt(tokenTimestamp[2])
      });
    }
    checkRedirect(response.status);
    return response.data;
    // return response;
  }

  const handleError = (errors: any) => {
    checkRedirect(errors.response.status);
    throw errors;
  }

  let _CONFIG: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_ENDPOINT || '',
    timeout: Number(process.env.REACT_APP_API_TIMEOUT) || 0,
    transformRequest: transformRequest,
    // transformResponse: transformResponse,
  }

  class HttpService {

    private config: AxiosRequestConfig = _CONFIG;

    constructor(config = {}) {
      this.config = _.merge(_CONFIG, config);
    }

    get instance(): AxiosInstance {
      return axios.create(this.config);
    }

    get(url: string, config = {}): Promise<any> {
      return this.instance.get(url, config)
        .then(handleResponse)
        .catch(handleError);
    }

    post(url: string, body = {}, config = {}): Promise<any> {
      return this.instance.post(url, body, config)
        .then(handleResponse)
        .catch(handleError);
    }

    put(url: string, body = {}, config = {}): Promise<any> {
      return this.instance.put(url, body, config)
        .then(handleResponse)
        .catch(handleError);
    }

    delete(url: string, config = {}): Promise<any> {
      return this.instance.delete(url, config)
        .then(handleResponse)
        .catch(handleError);
    }

  }

  return HttpService;
})();

export const Http = new HttpService();

export default HttpService;
