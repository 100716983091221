import React, { useEffect, useState } from 'react'
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import "./feederwisereport.scss";
import InnerTemplate from '../../Components/InnerTemplate/InnerTemplate';
import AssetWiseReports from './FeederWiseReports';

const FeederReports = (props:any) => {
    return (
        <>
            <Header />
            <div className="mainWrap">
                <InnerTemplate PageTitle="Feeder Wise Progress MIS Reports" />
                <section className="financialForm misNew">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div><AssetWiseReports /></div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    );
}

export default FeederReports;