import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect } from "../../../../assets/js/formbuilder";
import { Http } from '../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye,FiEdit } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { useStateMounted } from "../../../../Core/Hooks";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsFileEarmarkExcel, BsFileEarmarkPdf } from 'react-icons/bs';
import QueryString from 'qs';

// Images
import FieldComponent from "../../../../FieldComponent";
import { Storage } from "../../../../Core/Services/StorageService";


export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

type ViewSubmittedProps = {
    districts:any;
    refresh:any;
    getData:any;
    months:any;
};

const ViewSubmittedForms = (props: ViewSubmittedProps) => {
    const [refresh, setRefresh] = useState(false); 
    const [getData, setData] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any[]>([]);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const [imfo, setInfo] = useStateMounted<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getpagelinkid, setPageLinkId] = useState<any>(0);
    const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
    const [pdfLink, setPdfLink] = useStateMounted('#');
    const [excelLink, setExcelLink] = useStateMounted('#');
    const [act, setAct] = useState<any>(null);
    let navigate = useNavigate();

    const exportPdfLink = (id: any) => {
        const params = {
            id: id,
            form_id: 176,
            form_key: 'qa_at_site_fields',
            filename: 'QA at Site',
            export_type: 'pdf'
          };
        let plink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/qmexport'].join('/');
        plink += '?' + QueryString.stringify(params);
        setPdfLink(plink);
    }

    const exportExcelLink = (id: any) => {
        const params = {
            id: id,
            form_id: 176,
            form_key: 'qa_at_site_fields',
            filename: 'QA at Site',
            export_type: 'excel'
          };
        let elink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/qmexport'].join('/');
        elink += '?' + QueryString.stringify(params);
        setExcelLink(elink);
    }

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid,discomid]);

    const edit = (id: any) => {
        setPageLink('/material-quality-inspection-qa-edit');
        setPageLinkId(id);
        setAct('')
    }

    const view = (id: any) => {
        setPageLink('/material-quality-inspection-qa-edit');
        setPageLinkId(id);
        setAct('view')
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: getpagelinkid,act:act,district_id:props.districts,months:props.months} });
            }
        })();
    }, [getpagelink]);
    
    useEffect(() => {
        if(props.getData.length)
        {
            setData(props.getData);
        }else{
            setData([]);
        }
    }, [props.getData]);

    if (error) {
        return <Alert className='mt-3 mb-3' variant="danger">{error}</Alert>;
    } else {
        return (
                <>
                <div className="table-responsive RevenueDetailsTable PqTable mt-3">
                <table className="table caption-top newTableUcss">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>District</th>
                            <th>Form Name</th>
                            <th>Date of submission</th>
                            <th>Action</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(getData.length > 0) ?
                            <>
                            {
                                getData.map((item: any, key: number) => {
                                    return <>
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{item.district}</td>
                                            <td>{item.application_id}</td>
                                            <td>
                                            {item.is_active == 1 ?
                                                <>
                                                {item.submission_date}
                                                </>
                                                :null
                                            }
                                            </td>
                                            <td>
                                                {(roleid == 2 && item.is_active != 1) ?
                                                    <Link title="Edit" className='text-primary' to="" onClick={() => edit(item.id)}> <FiEdit className="p-0 mb-0 h5 text-success" /></Link>
                                                :null
                                                }

                                                {(roleid != 2 && item.is_active == 1) ?
                                                    <Link title="View" className='text-primary' to="" onClick={() => view(item.id)}> <FiEye className="p-0 mb-0 h5 text-success" /></Link>
                                                :null
                                                }
                                            </td>
                                            <td>
                                                <Link title="Export in Excel" to={excelLink} onClick={() => exportExcelLink(item.id)}  target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                                                <Link title="Export in PDF"  to={pdfLink} onClick={() => exportPdfLink(item.id)} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                                            </td>
                                        </tr>
                                    </>
                                })
                            }
                            </>
                            : 
                            <>
                            <tr>
                                <td colSpan={6}>
                                    <div className="alert alert-info w-100 text-center" role="alert">
                                        Data Not Found
                                    </div>
                                </td>
                            </tr>
                            </>
                        }
                    </tbody>
                </table> 
                </div>
                </>
        );
    }
};

export default ViewSubmittedForms;