import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Sop35 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* SoP-3.5*/}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara SoP">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">SoP-3.5</p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[224] && fields[224].value ? fields[224].value : ''} name="formdata[pqsop_details][sop35_nodal]" id="pqsop_details_sop35_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][sop35_remarks_nodal]" id="pqsop_details_sop35_remarks_nodal" defaultValue={fields[239] && fields[239].value ? fields[239].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][sop35_document_nodal]" id="pqsop_details_sop35_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[254].value != '' ?
                                            <a href={fields[254].value} title={fields[254].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>

                                    {fields[224].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[224] && fields[224].value ? fields[224].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[239].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[239] && fields[239].value ? fields[239].value : ''}</div>
                                    {/* : null
                                                } */}

                                    {fields[254].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[254] && fields[254].value ? fields[254].value : ''} title={fields[254] && fields[254].value ? fields[254].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }

                        </div>
                        {(props.tooltip !== undefined && props.tooltip === false)
                            ? null
                            :
                            <MyTooltip placement="left">
                                <ul>
                                    <li>Declaration letter by MD</li>
                                    <li>Copy of Bills raised to finance department</li>
                                    <li>Copy of Payment receipts from State government</li>
                                    <li>Declaration letter by MD</li>
                                </ul>
                            </MyTooltip>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td rowSpan={2}>Whether electricity consumption for all ULBs/RLBs recorded as per the energy meters and periodic electricity bills are raised on time? Please provide details</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[126].key} field={fields[126]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[127].key} field={fields[127]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[132].key} field={fields[132]} />
                </td>
                <td></td>
            </tr>
            {/* <tr>
                    <td>Description</td>
                    <td colSpan={4}>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[128].key} field={fields[128]} />
                    </td>
                </tr> */}
            <tr>
                <td className="text-center">Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[130].key} field={fields[130]} />
                </td>
            </tr>
            <tr>
                <td rowSpan={2}>Whether Centralised system at State/ DISCOM level is established for payment of Govt. dept. Bills incl. ULBs/ RLBs until pre paid metering? Please provide details. </td>
                {/* <td>Details</td> */}
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[133].key} field={fields[133]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[134].key} field={fields[134]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[139].key} field={fields[139]} />
                </td>
                <td></td>
            </tr>
            {/* <tr>
                    <td>Description</td>
                    <td colSpan={3}>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[135].key} field={fields[135]} />
                    </td>
                </tr> */}
            <tr>
                <td className="text-center">Remarks</td>
                <td colSpan={3}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[137].key} field={fields[137]} />
                </td>
            </tr>
            <tr>
                <td rowSpan={2}>Whether DISCOM is raising the cumulative electricity dues of all ULBs and RLBs to the Finance Department of the State, in case of non-payment within 30 days of bills being raised by DISCOM? Please provide details.</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[140].key} field={fields[140]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[141].key} field={fields[141]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[146].key} field={fields[146]} />
                </td>
                <td></td>
            </tr>
            {/* <tr>
                    <td>Description</td>
                    <td colSpan={3}>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[142].key} field={fields[142]} />
                    </td>
                </tr> */}
            <tr className="borderThik">
                <td className="text-center">Remarks</td>
                <td colSpan={3}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[144].key} field={fields[144]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Sop35;