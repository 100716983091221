import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { initselect, multijsoninit, pqforcurrentyearcal, pqforadvancesubsidycal } from "../../../assets/js/formbuilder";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
            pqforcurrentyearcal();
        }, 500);
    }

    setTimeout(() => {
        multijsoninit();
    }, 2000);

    return (
        <>
        <table className="table">
            <thead className="text-center">
                {/* <th style={{ minWidth: "40px" }}>S. No.</th> */}
                <th>Subsidy Billed (INR Cr)</th>
                <th>Date of raising subsidy bill</th>
                <th>Subsidy Received (INR Cr)</th>
                <th>Date of payment</th>
                <th>Deficit (INR Cr)</th>
                <th>Document</th>
                <th className='text-end pe-1' style={{ width: "30px" }}>
                    {props.submittedStatus == 1 ?
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        : null
                    }
                </th>
            </thead>
            <tbody>
                <>
                {
                    block1Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                            <tr key={key}>
                                {/* <td>{(block1SrNo++) + 1}</td> */}
                                {
                                    item.fields.map((field: any, fkey: number) => {
                                        return <>
                                            <td key={key + '_' + fkey}>
                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                            </>;
                                    })
                                }
                                <td className="text-end mw-30">
                                    {(hasBlock1FieldsDelete) && props.submittedStatus == 1 ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                            </tr>
                            </>
                            : null;
                    })
                }
                </>
                <>
                {
                    (block1Fields.length && props.field2)
                        ? <>
                        <tr>
                            <td className="text-end">Status</td>
                            <td className="text-end">
                                <FieldComponent options={{ showTitle: false }} fieldName={props.statusfield.key} field={props.statusfield} />
                            </td>
                            <td colSpan={2} className="text-end">Total</td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                            <td colSpan={2}>&nbsp;</td>
                        </tr>
                        </>
                        : null
                }
                </>
            </tbody>
        </table>
        </>
    );
}

const Block2 = (props: any) => {
    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
            pqforadvancesubsidycal();
        }, 500);
    }

    setTimeout(() => {
        multijsoninit();
    }, 2000);

    return (
        <>
        <table className="table">
            <thead className="text-center">
                {/* <th style={{ minWidth: "40px" }}>S. No.</th> */}
                <th>Subsidy Billed (INR Cr)</th>
                <th>Date of raising subsidy bill</th>
                <th>Subsidy Received (INR Cr)</th>
                <th>Date of payment</th>
                <th>Deficit (INR Cr)</th>
                <th>Document</th>
                <th className='text-end pe-1' style={{ width: "30px" }}>
                    {props.submittedStatus == 1 ?
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        : null
                    }
                </th>
            </thead>
            <tbody>
                <>
                {
                    block2Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                            <tr key={key}>
                                {/* <td>{(block2SrNo++) + 1}</td> */}
                                {
                                    item.fields.map((field: any, fkey: number) => {
                                        return <>
                                            <td key={key + '_' + fkey}>
                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                            </>;
                                    })
                                }
                                <td className="text-end mw-30">{(hasBlock2FieldsDelete) && props.submittedStatus == 1 ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                            </tr>
                            </>
                            : null;
                    })
                }
                </>
                <>
                {
                    (block2Fields.length && props.field2)
                        ? <>
                        <tr>
                            <td className="text-end">Status</td>
                            <td className="text-end">
                                <FieldComponent options={{ showTitle: false }} fieldName={props.statusfield.key} field={props.statusfield} />
                            </td>
                            <td colSpan={2} className="text-end">Total</td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                            <td colSpan={2}>&nbsp;</td>
                        </tr>
                        </>
                        : null
                }
                </>
            </tbody>
        </table>
        </>
    );
}

const Pq4 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    const [year, setYear] = useState<any>(props.year);
    const [roleid, setRoleid] = useState<any>(0);

    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* PQ-4 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">PQ-4 : Subsidy payment by State Government <b>*</b></p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[216] && fields[216].value ? fields[216].value : ''} name="formdata[pqsop_details][pq4_nodal]" id="pqsop_details_pq4_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][pq4_remarks_nodal]" id="pqsop_details_pq4_remarks_nodal" defaultValue={fields[231] && fields[231].value ? fields[231].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][pq4_document_nodal]" id="pqsop_details_pq4_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[246].value != '' ?
                                            <a href={fields[246].value} title={fields[246].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>

                                    {fields[216].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[216] && fields[216].value ? fields[216].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[231].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[231] && fields[231].value ? fields[231].value : ''}</div>
                                    {/* : null
                                                } */}

                                    {fields[246].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[246] && fields[246].value ? fields[246].value : ''} title={fields[246] && fields[246].value ? fields[246].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }

                        </div>
                        <div className={props.remove}>
                            <MyTooltip placement="left">
                                <ul>
                                    <li>State Government to ensure 100% payment of subsidy for the previous year and advance payment of subsidy up to current period in line with section 65 of EA2003 and wipe out the remaining subsidy amount by the end of the project period</li>
                                    <li>Documentary Evidence: Subsidy demand letter, Govt. Order for release of subsidy amount</li>
                                </ul>
                            </MyTooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={5}>
                    <p>1. Current year (FY{year}) – Actual Subsidy (INR Cr)</p>
                    <div className="table-responsive RevenueDetailsTable PqTable p-0 tdInnerTable">
                        <Block1 field={fields[262]} field2={fields[263]} statusfield={fields[264]} submittedStatus={props.submittedStatus} />
                    </div>
                </td>
            </tr>
            {/* <tr>
                    <td>Status</td>
                    <td>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[264].key} field={fields[264]} />
                    </td>
                </tr> */}
            <tr>
                <td colSpan={5}>
                    <p>2. Advance Subsidy as on current quarter (INR Cr)</p>
                    <div className="table-responsive RevenueDetailsTable PqTable p-0 tdInnerTable">
                        <Block2 field={fields[265]} field2={fields[266]} statusfield={fields[267]} submittedStatus={props.submittedStatus} />
                    </div>
                </td>
            </tr>
            {/* <tr>
                    <td>Status</td>
                    <td>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[267].key} field={fields[267]} />
                    </td>
                </tr> */}

            {/* <tr>
                    <td>Unpaid subsidy cumulative for current and previous year (INR Cr)</td>
                    <td colSpan={3}>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[68].key} field={fields[68]} />
                    </td>
                    <td>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[69].key} field={fields[69]} />
                    </td>
                </tr>
                <tr>
                    <td>Legacy Subsidy Pending for payment (As on 31 March {year}) (INR Cr)</td>
                    <td colSpan={3}>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[70].key} field={fields[70]} />
                    </td>
                    <td>
                        <FieldComponent options={{ showTitle: false }} fieldName={fields[71].key} field={fields[71]} />
                    </td>
                </tr> */}

            <tr>
                <td>Details about the liquidation plan for legacy subsidy dues (INR Cr)</td>
                <td>
                    <Modalbox field1={fields[193]} block="2" year={year} />
                    <Link to="" className="btn-warning btn d-block mx-auto w-100" data-bs-toggle="modal" data-bs-target="#SubsidyDues">Subsidy Dues</Link>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[73].key} field={fields[73]} />
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Overall Status</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[74].key} field={fields[74]} />
                </td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[75].key} field={fields[75]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Pq4;