/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Observable, Subject } from 'rxjs';
import { Http } from '../Services/HttpService';

interface TranslationContextInterface {
  langCode: Function;
  changeLang: Function;
  clear: Function;
  translations: Observable<any>;
  init: Function;
  trans: Function;
}

const TranslationContext = React.createContext<TranslationContextInterface | null>(null);

class TranslationProvider extends React.Component<any, TranslationContextInterface> {

  subject = new Subject();

  langCode = 0;
  translations$ = {};

  fetchLangParams = async () => {
    return Http.get('/apiv1/langparams')
      .then((res) => {
        return res;
      }).catch((e) => {
        console.log(e);
      });
  };

  store$ = {
    langCode: () => { return this.langCode },
    init: async () => {
      this.subject.next({});
      await this.fetchLangParams()
        .then((res) => {
          if (res._resultflag == "1") {
            this.translations$ = res.langvars ?? {};
            this.subject.next(this.translations$);
          }
        }).catch((e) => { });
    },
    // subscribe: (setState: any) => this.subject.subscribe(setState),
    changeLang: (langCode?: any) => {
      this.langCode = langCode;
      this.subject.next(this.translations$);
    },
    clear: () => {
      this.translations$ = {};
      this.subject.next(this.translations$);
      return this.state;
    },
    translations: this.subject.asObservable(),
    callInit: () => {
      this.subject.next(this.translations$);
    },
    trans: () => { return this.translations$ }
  };

  constructor(props: any) {
    super(props)
    this.langCode = this.props.langCode ?? 0;
    this.state = {
      langCode: this.store$.langCode,
      init: this.store$.callInit,
      changeLang: this.store$.changeLang,
      clear: this.store$.clear,
      translations: this.store$.translations,
      trans: this.store$.trans
    };
    // this.store$.subscribe(this.translations$);
    this.store$.init();
  }

  render() {
    return (
      <TranslationContext.Provider value={this.state}>
        {this.props.children}
      </TranslationContext.Provider>
    )
  }
}

const TranslationConsumer = TranslationContext.Consumer

function useTranslation() {

  const translationContext = React.useContext(TranslationContext);
  if (!translationContext) {
    throw new Error('useTranslation must be used within a TranslationProvider.');
  }

  const translationSubscribe = useRef<any>(null);
  const [langCode, setLangCode] = useState<string>(translationContext.langCode());
  const [translations, setTranslations] = useState<Record<string, any>>({});

  const trans = useCallback(
    (key: string) => {
      if (langCode && translations[langCode]) {
        return translations[langCode][key] ?? key;
      }
      return key;
    },
    [langCode, translations]
  );

  // Sync translations from context
  useEffect(() => {
    const initTranslations = async () => {
      setTranslations(translationContext.trans());
      translationSubscribe.current = translationContext.translations.subscribe((res: any) => {
        setTranslations(res);
        setLangCode(translationContext.langCode());
      });
    };

    initTranslations();

    return () => {
      if (translationSubscribe.current) {
        translationSubscribe.current.unsubscribe();
      }
    };
  }, [translationContext]);

  // Sync langCode changes with context
  useEffect(() => {
    if (langCode && langCode !== translationContext.langCode()) {
      translationContext.changeLang(langCode);
    }
  }, [langCode, translationContext]);

  return [trans, setLangCode, langCode, translationContext] as const;
}


function withTranslation(Component: any) {
  return (props: any) => {
    const translations = useTranslation();
    return <Component translations={translations} {...props} />;
  };
}

export { TranslationProvider, TranslationConsumer, TranslationContext, useTranslation, withTranslation }
