import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect, inputEventChecker } from "../../../../assets/js/formbuilder";
import { Http } from '../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import { BiUpload } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { useStateMounted } from '../../../../Core/Hooks';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
// Images
import FieldComponent from "../../../../FieldComponent";
import { FiUploadCloud } from "react-icons/fi";
import { Storage } from "../../../../Core/Services/StorageService";

type BreakupBoQFormProps = {
	idRef: any;
	apiurl: string;
};

type MultiChildProps = {
	field: any;
	pIndex: number
	jIndex: number
	index?: string | number,
	totalFields: any,
	_idRef: any
};

const multiChildPropTypes = {
	field: PropTypes.array.isRequired,
	index: PropTypes.any,
	pIndex: PropTypes.number.isRequired,
	totalFields: PropTypes.any.isRequired,
	_idRef: PropTypes.any,
};

function MultiChild({ field, index, pIndex, totalFields, jIndex, _idRef }: MultiChildProps) {

	let srNo = 1;
	const idRef = useRef(Math.random().toString(36).substring(2, 15));
	const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
	const [fields, setFields] = useStateMounted<any>([]);
	const [newFields, setNewFields] = useStateMounted<any>();

	useEffect(() => {
		if (field) {
			setHasFieldDelete(false);
			setFields([]);
			setNewFields(field.addnew);
			const fieldsMap = field.childrens.map((items: any) => {
				return {
					fields: items,
					delete: false
				}
			})
			setFields(fieldsMap);
		}
	}, [field]);

	useEffect(() => {
		if (fields.length) {
			const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
			setHasFieldDelete(hasDelete);
			srNo = 1;
			setTimeout(() => {
				const selector = `#${_idRef} .form-control:not([readonly]:not([data-formula]))`;
				init(selector);
				multijsoninit(selector);
			}, 1500);
		}
	}, [fields]);

	const addNewField = () => {
		setFields((prev: any[]) => {
			return [...prev, { fields: newFields, delete: false }];
		});
		// setTimeout(() => {
		// 	init();
		// 	multijsoninit();
		// }, 1000);
	}

	const removeField = (index: number) => {
		setFields((prev: any[]) => {
			const newFields = prev.map((item, i) => {
				if (i === index) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
		// setTimeout(() => {
		// 	init();
		// 	multijsoninit();
		// }, 1000);
	}
	const extTD = <td colSpan={6} ></td>;

	return (
		<>

			{
				fields.map((item: any, i: number) => {
					return (item.delete === false)
						? (
							<>
								<tr key={`${idRef.current}-${index}`}>
									{
										item.fields.map((field: any, j: number) => {
											return ([4, 5].includes(j))
												? null
												: <td className={'td' + (jIndex + j)} key={`${idRef.current}-${index}-${field.key}`}>
													<FieldComponent
														options={
															{ showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
														}
														fieldName={field.key}
														field={field} />
												</td>
										})
									}

									{(i != 0 && hasFieldDelete)
										? <>
											<td className="td13 text-end">
												<Link className='text-danger' to="#" onClick={() => removeField(i)}>
													<FiTrash2 className="p-0 mb-0 h5" />
												</Link>
											</td>
										</>
										: null
									}

									{i == 0 ?
										<td className="td13 text-end">
											<Link to="#" className='p-0 text-success' onClick={addNewField}>
												<FiPlusCircle className="h5 p-0 mb-0" />
											</Link>
										</td>
										: null
									}

								</tr>
							</>
						)
						: null;
				})
			}

			{/* {
				totalFields && (
					<ChildInput fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
				)
			} */}

		</>
	);
}

MultiChild.propTypes = multiChildPropTypes;

// ChildInput
type ChildInputProps = {
	fields: any[];
	index?: string | number,
	showTotal?: any
};

const childInputPropTypes = {
	fields: PropTypes.array.isRequired,
	index: PropTypes.any,
	showTotal: PropTypes.any,
};

function ChildInput({ fields, index, showTotal }: ChildInputProps) {

	const [_fields, _setFields] = useStateMounted<string[]>([]);

	// useEffect(() => {
	// 	if (fields.length) {
	// 		inputEventChecker.removeAllEvent();
	// 		setTimeout(() => {
	// 			initselect();
	// 			multijsoninit();
	// 		}, 1000);
	// 		_setFields(fields);
	// 	}
	// }, [fields]);

	// const extTD = <td colSpan={2}><span className="text-center">Sub Total</span></td>;

	return (
		<>
			<tr key={index} aria-hidden={(showTotal) ? "false" : "true"}>
				{/* <td><span className="text-center">Sub Total</span></td> */}
				{
					(_fields.length && index !== 1)
						? _fields.map((field: any, i: number) => {
							return ([3, 4].includes(i))
								? <td key={index + '-' + i + '-' + field.key}></td>
								: <td key={index + '-' + i + '-' + field.key}>
									<FieldComponent
										options={{ showTitle: false, extraId: '_' + index + '_' + i }}
										fieldName={field.key}
										field={field} />
								</td>
						})
						: null
				}

			</tr>
		</>
	);
}

ChildInput.propTypes = childInputPropTypes;

// Multijson
type MultijsonProps = {
	_idRef: any;
	field: any;
	index: number,
	totalField: any
};

const multijsonPropTypes = {
	field: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	totalField: PropTypes.any.isRequired,
	_idRef: PropTypes.any
};

function Multijson({ field, index, totalField, _idRef }: MultijsonProps) {

	let srNo = 1;
	const idRef = useRef(Math.random().toString(36).substring(2, 15));
	const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
	const [fields, setFields] = useStateMounted<any>([]);
	const [newFields, setNewFields] = useStateMounted<any>();

	useEffect(() => {
		if (field) {
			setHasFieldDelete(false);
			setFields([]);
			setNewFields(field.addnew);
			const fieldsMap = field.childrens.map((items: any) => {
				return {
					fields: items,
					delete: false
				}
			})
			setFields(fieldsMap);
		}
	}, [field]);

	useEffect(() => {
		if (fields.length) {
			const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
			setHasFieldDelete(hasDelete);
			srNo = 1;
			inputEventChecker.removeAllEvent();
			setTimeout(() => {
				const selector = `#${_idRef} .form-control:not([readonly]:not([data-formula]))`;
				init(selector);
				multijsoninit(selector);
				adjustwidth();
			}, 1500);
		}
	}, [fields]);

	const addNewField = () => {
		setFields((prev: any[]) => {
			return [...prev, { fields: newFields, delete: false }];
		});
		// setTimeout(() => {
		// 	init();
		// 	multijsoninit();
		// }, 1000);
	}

	const removeField = (index: number) => {
		setFields((prev: any[]) => {
			const newFields = prev.map((item, i) => {
				if (i === index) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
		// setTimeout(() => {
		// 	init();
		// 	multijsoninit();
		// }, 1000);
	}

	const adjustwidth = () => {
		let awards: any = document.querySelectorAll('.award-td');
		for (let i = 0; i < awards.length; i++) {
			let awardid = awards[i].getAttribute('id');
			if (awardid) {
				let allcss: any = document.querySelectorAll('.' + awardid);
				if (allcss.length) {
					if (awardid == 'td1') {
						for (let j = 0; j < allcss.length; j++) {
							allcss[j].width = awards[i].offsetWidth;
						}
					}
					else
						awards[i].width = allcss[0].offsetWidth;
				}
			}
			let footid = document.getElementById('tdf' + i);
			if (footid)
				footid.style.width = awards[i].offsetWidth + 'px';
		}
	}

	return (
		<>
			<thead>
				<tr>
					<th id="td1" className="award-td"><span className="text-center">S.No.</span></th>
					<th id="td2" className="award-td"><span className="text-center">Major Components</span></th>
					<th id="td3" className="award-td"><span className="text-center">Sub Component</span></th>
					<th id="td4" className="award-td"><span className="text-center">Item Specification</span></th>
					<th id="td5" className="award-td"><span className="text-center">Remarks</span></th>
					<th id="td6" className="award-td"><span className="text-center">Unit</span></th>
					<th id="td7" className="award-td"><span className="text-center">Sanctioned Quantity</span></th>
					<th id="td8" className="award-td"><span className="text-center">Award Quantity</span></th>
					<th id="td9" className="award-td"><span className="text-center">Surveyed Quantity</span></th>
					<th id="td10" className="award-td"><span className="text-center">Feeder Name</span></th>
					<th id="td11" className="award-td"><span className="text-center">Quantity</span></th>
					<th id="td12" className="award-td"><span className="text-center">Lat/Long Remarks</span></th>
					<th id="td13" className="award-td text-end action-td"></th>
				</tr>
			</thead>
			<tbody>
				<tr className="boq-margin-top"><td colSpan={13}></td></tr>
				{
					fields.map((item: any, i: number) => {
						return (item.delete === false)
							? (
								<>
									<tr key={index + '_' + i + '_' + idRef.current} className="align-top">
										<td className="td1"><span className="text-center">{srNo++}</span></td>
										{
											item.fields.map((field: any, j: number) => {
												let extraId = '_' + i + '_' + j;
												let style: any = {};
												let hidefield = false;
												let module = '';
												if ([5, 7, 8, 10, 12].includes(j)) {
													module = 'boq_';
													extraId = '_' + i + '_' + i + '_' + j;
													style.display = 'none';
													hidefield = true;
												}
												return (field.type !== 'multijson' && !field.childrens)
													? (
														// !hidefield ?
														<td className={'td' + (j + 2)} key={`${idRef.current}-${index}-${field.key}`}>
															<FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
														</td>
														// : null
													) : null;
											})
										}
										<td colSpan={3} className="multiple-award-items">
											<table className="table-responsive RevenueDetailsTable PqTable">
												<tbody>
													{
														item.fields.map((field: any, j: number) => {
															const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
															const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
															return field.type === 'multijson' && (
																<MultiChild key={index + '_' + i + '_' + j} _idRef={_idRef} field={field} pIndex={i} jIndex={10} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} />
															)
														})
													}
												</tbody>
											</table>
										</td>
									</tr>
									{/* {
							item.fields.map((field: any, j: number) => (
								(field.type === 'json' && field.childrens) && (
									<ChildInput fields={field.childrens} index={index + '_' + i + '_' + j} />
								)
							))
						} */}
								</>
							)
							: null;
					})
				}
				{/* {
					(totalField) && (
						<tr>
							<td colSpan={8}><span className="text-center">Total</span></td>
							<td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[0].key} field={totalField[0]} /></td>
							<td colSpan={5}>
								<table className="table-responsive RevenueDetailsTable PqTable award-footer-table">
									<tbody>
										<tr>
											<td id="tdf10"></td>
											<td id="tdf11"></td>
											<td id="tdf12"><FieldComponent options={{ showTitle: false }} fieldName={totalField[2].key} field={totalField[2]} /></td>
											<td id="tdf13"></td>
											<td id="tdf14"></td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					)
				} */}
			</tbody>
		</>
	);
}

const BreakupBoQForm = (props: BreakupBoQFormProps) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [fields, setFields] = useState<any[]>([]);
	const [roleid, setRoleid] = useState<any>(0);
	const [apiurl, setApiurl] = useState<any>(null);
	const [discomid, setDiscomid] = useState<any>(0);
	const [refresh, setRefresh] = useState(false);
	const [districts, setDistricts] = useState<any[]>([]);
	const [getdistricts, setGetDistricts] = useState<any>(0)
	const [districtsresult, setDistrictsResult] = useState<any>(0);
	const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
	const [isLoading, setIsLoading] = useState(false);
	const [getawarditems, setGetAwardItems] = useState<any>();
	const [form_id, setFormId] = useState<any>(0);
	const [loading, setLoading] = useStateMounted<boolean>(true);
	const [error, setError] = useStateMounted<string>();

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
	}, [roleid]);

	const ChangeValue = (evt?: any) => {
		console.log(evt.target.value);
		setGetDistricts(evt.target.value);
		setIsLoaded(true);
	};

	const getDistricts = () => {
		(async () => {
			if (apiurl) {
				let footerspinner: any = document.getElementById('footer-spinner');
				if (footerspinner)
					footerspinner.classList.remove('d-none');
				await Http.get(apiurl)
					.then((result) => {
						setIsLoaded(true);
						setDistricts(result.district);
						// setTimeout(() => {
						// 	const selector = `#${props.idRef} .form-control:not([readonly]:not([data-formula]))`;
						// 	init(selector);
						// 	multijsoninit(selector);
						// }, 2000);
					})
					.catch((error) => {
						setIsLoaded(true);
						setError(error);
					}).finally(() => {
						footerspinner.classList.add('d-none');
					});
			}
		})();
	};

	useEffect(() => {
		if (discomid) {
			setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
		}
	}, [apiurl, discomid]);

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		(async () => {
			getDistricts();
		})();
	}, [apiurl]);

	// useEffect(() => {
	// 	(async () => {
	// 		setTimeout(() => {
	// 			const selector = `#${props.idRef} .form-control:not([readonly]:not([data-formula]))`;
	// 			init(selector);
	// 		}, 1000);
	// 	})();
	// }, [districtsresult]);

	const getFields = () => {
		(async () => {
			setLoading(true);
			setError('');
			setFields([]);

			if (roleid && discomid && getdistricts) {
				const params = {
					roleid: roleid,
					discom_id: discomid,
					form_id: 33,
					district_id: getdistricts,
					award_items_module: 1,
					module: 'boq'
				};
				let footerspinner: any = document.getElementById('footer-spinner');
				if (footerspinner)
					footerspinner.classList.remove('d-none');
				await Http.get(`/apiv1/dprfields`, { params })
					.then((res) => {
						if (res.resultflag && res.fields) {
							if (res.fields && Array.isArray(res.fields)) {
								if (!res.fields.length) setError("No data found.");
								// console.log(res.fields
								setFields(res.fields);
								setDistrictsResult(res.district_id);
								// setTimeout(() => {
								// 	const selector = `#${props.idRef} .form-control:not([readonly]:not([data-formula]))`;
								// 	init(selector);
								// 	multijsoninit(selector);
								// }, 1000);
							} else {
								setError("Invalid data type.");
							}
						} else if (!res.resultflag) {
							setError(res.message);
						} else {
							setError('No data found.');
						}
					})
					.catch((error) => {
						setError('System error occurred!! please try again.');
					}).finally(() => {
						setLoading(false);
						footerspinner.classList.add('d-none');
					});
			}
		})();
	};

	useEffect(() => {
		getFields();
	}, [discomid, roleid, form_id, getdistricts]);

	useEffect(() => {
		if (fields.length) {
			setTimeout(() => {
				const selector = `#${props.idRef} .form-control:not([readonly]:not([data-formula]))`;
				init(selector);
				multijsoninit(selector);
			}, 1000);
		}
	}, [fields]);

	return (
		<>
			<div className='row'>
				<div className='col-md-3'>
					<select className='form-control text-start form-select w-100' onChange={ChangeValue} defaultValue="" name="boq_award_district_id" id="boq_award_district_id">
						<option value={0}>Select District</option>
						{
							districts.map((district: any) => {
								const attr = (district.id === districtsdefaultvalue) ? { value: district.id, selected: true } : { value: district.id };
								return <><option {...attr}>{district.district_name}</option></>;
							})
						}
					</select>
				</div>
				<div className='col-md-9'>
					<div className="ligt-bg">
						<p className="text-center m-0">The form for Breakup of Sanctioned BoQ is not to be filled at present.</p>
					</div>
				</div>
			</div>
			{error && (
				<>
					<Alert className='mt-3 mb-3' variant="info">{error}</Alert>
				</>
			)}
			{
				((!loading && !error) && fields?.length > 0) && (
					<>
						{(getdistricts != 0) ?
							<div className="award-table pe-3">
								<table className="table-responsive RevenueDetailsTable PqTable">
									{
										fields.map((field: any, index: number) => {
											const totalField = (fields.length && fields[0].childrens) ? fields[0].childrens : null;
											return field.type === 'multijson' && (
												<Multijson key={index} _idRef={props.idRef} field={field} index={index} totalField={totalField} />
											)
										})
									}
								</table>
							</div>
							:
							null
						}
					</>
				)
			}
		</>
	);
};

const BreakupBoQ = (props?: any) => {
	const [roleid, setRoleid] = useState<any>(0);
	const [discomid, setDiscomid] = useState<any>(0);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
		setDiscomid(Storage.get('discom_id'));
	}, [roleid]);

	return (
		<>
			<div className="mainWrap">
				{roleid == 0 ? null :
					<BreakupBoQForm idRef={props.idRef} apiurl={"/apiv1/formfields/?roleid=" + roleid + "&discom_id=" + discomid + "&module=boq"} />
				}
			</div>
		</>
	)
}
export default BreakupBoQ;