import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect, inputEventChecker } from "../../../../../../assets/js/formbuilder";
import { Http } from '../../../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { BiUpload, BiDownload } from 'react-icons/bi';
import { useStateMounted } from "../../../../../../Core/Hooks";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Images
import FieldComponent from "../../../../../../FieldComponent";
import { useLayout } from "../../../../../../Core/Providers";
import { Storage } from "../../../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
	return (
		<div className="spinner-container">
			<div className="spinner-outer">
				<div className="loading-spinner"></div>
			</div>
		</div>
	);
}

type LossReductionProps = {
	apiurl: string;
	act: any;
	refresh: any;
	updateBtnState: any;
};

const Block2 = (props: any) => {

	let block2SrNo = 0;
	const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
	const [block2Fields, setBlock2fields] = useState<any>([]);
	const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);
	const [blockKey, setBlockKey] = useState<string>('');
	const [_remarkFields, setRemarkFields] = useState<any>({});

	useEffect(() => {
		if (props.field6) {
			setBlockKey(props.field6.key);
			setHasBlock2FieldsDeletes(false);
			setBlock2fields([]);
			setNewBlock2fields(props.field6.addnew);
			setTimeout(() => {
				const fields2 = props.field6.childrens.map((items: any) => {
					return {
						fields: items,
						delete: false
					}
				})
				setBlock2fields(fields2);
			}, 500);
		}
	}, [props.field6]);

	useEffect(() => {
		if (props.remarkFields) {
			setRemarkFields([]);
			setRemarkFields(props.remarkFields);
		}
	}, [props.remarkFields]);

	useEffect(() => {
		if (block2Fields.length) {
			const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
			setHasBlock2FieldsDeletes(hasDelete);
			block2SrNo = 0;
			inputEventChecker.removeAllEvent();
			setTimeout(() => {
				initselect();
				multijsoninit();
			}, 1000);
		}
	}, [block2Fields]);

	const addItem = (block?: any) => {
		setBlock2fields((prev: any[]) => {
			return [...prev, { fields: newBlock2Fields, delete: false }];
		});

		setTimeout(() => {
			initselect();
			multijsoninit();
		}, 500);
	}

	const removeItem = (rindex: number, block?: any) => {
		setBlock2fields((prev: any[]) => {
			const newFields = prev.map((item, index) => {
				if (index === rindex) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});

		setTimeout(() => {
			initselect();
			multijsoninit();
		}, 500);
	}


	return (
		<>
			<table className="table caption-top">
				<thead className='text-center'>
					<tr>
						<th style={{ minWidth: "50px" }}>S. No.</th>
						<th>Major Components</th>
						<th>Sub Component</th>
						<th>Item Specification</th>
						<th>Remarks</th>
						<th>Unit</th>
						<th>Sanctioned Rate (INR/unit)</th>
						<th>Quantity</th>
						<th>Cost (INR Lakh)</th>
						{(props.act != 'view') ?
							<th rowSpan={2} className='text-end pe-1' style={{ width: "30px" }}>
								<Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
							</th>
							: null
						}
					</tr>

				</thead>
				<tbody>
					{
						block2Fields.map((item: any, key: number) => {
							return (item.delete === false) ?
								<tr key={key}>
									<td className="text-center" >{(block2SrNo++) + 1}</td>
									{
										item.fields.map((field: any, fkey: number) => {
											return <>
												<td key={key + '_' + fkey}>
													<FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
											</>;
										})
									}
									{(props.act != 'view' && props.excelSwitch) ?
										<td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
										: null
									}
								</tr>
								: <tr key={key} className="d-none">
									<td className="text-center">
										<input type="hidden" name={'deleteIndex[' + blockKey + '][]'} value={key} />
									</td>
								</tr>;;
						})
					}
					<>
						{
							(block2Fields.length && props.field4)
								? <>
									<tr>
										<td colSpan={8} className="text-center"><h5>Total</h5></td>
										{/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[0].key} field={props.field4.childrens[0]} /></td>
                                     <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[1].key} field={props.field4.childrens[1]} /></td>
                                     */}
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[2].key} field={props.field4.childrens[2]} /></td>
									</tr>
								</>
								: null
						}
					</>
					{
						(_remarkFields && _remarkFields.childrens && _remarkFields.childrens.length > 0)
							? <tr>
								{_remarkFields.childrens.map((field: any, key: number) => {
									return <td key={key} colSpan={(key == 0) ? 7 : 2}>
										<label className="form-label">{field.caption}</label>
										<FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} />
									</td>;
								})}
							</tr>
							: null
					}
				</tbody>
			</table>
		</>
	);
}

const ArchiveView = ({ district, setArchiveData, archiveRevisionId }: any) => {

	const [loading, setLoading] = useStateMounted<boolean>(true);
	const [fields, setFields] = useStateMounted<any[]>([]);
	const [error, setError] = useStateMounted<string>();

	const getFields = (id?: any) => {
		(async () => {
			setArchiveData({});
			setLoading(true);
			setError('');
			setFields([]);
			const config = { headers: { 'Content-Type': 'multipart/form-data' } };
			const formData = new FormData();
			const params: any = {
				form_id: 33,
				district: district,
			};
			for (let key in params) {
				if (params[key] instanceof Object) {
					Object.keys(params[key]).forEach((k: any) => {
						formData.append(key + '[' + k + ']', params[key][k]);
					});
				} else {
					formData.append(key, params[key]);
				}
			}
			if (id) {
				formData.append('revisionId', id);
			}
			await Http.post(`/apiv1/archive-view/form/33`, formData, config)
				.then((res) => {
					if (res.resultflag && res.fields) {
						if (res.fields && Array.isArray(res.fields)) {
							if (!res.fields.length) setError("No data found.");
							setFields(res.fields);
						} else {
							setError("Invalid data type.");
						}
						setArchiveData({
							userName: res.userName,
							createdDate: res.createdDate,
							nextId: res.nextId,
							previousId: res.previousId,
						});
					} else if (res.resultflag !== undefined && !res.resultflag) {
						setError(res.message);
					} else {
						setError('No data found.');
					}
				})
				.catch((error) => {
					setError('System error occurred!! please try again.');
				}).finally(() => {
					setLoading(false);
				});
		})();
	};

	useEffect(() => {
		if (district) {
			getFields(archiveRevisionId);
		}
	}, [district, archiveRevisionId]);

	return (
		<div>
			{loading && (
				<div className="content-spinner spinner-container h-50vh">
					<div className="spinner-outer">
						<div className="loading-spinner"></div>
					</div>
				</div>
			)}
			{error && (
				<Alert className='mt-3 mb-3' variant="danger">
					{error}
				</Alert>
			)}
			{((!loading && !error) && fields?.length > 0) && (
				<Block2 field6={fields[0]} field4={fields[1]} remarkFields={fields[2]} act={'view'} />
			)}
		</div>
	);
}

const SanctionDistrictForm = (props: LossReductionProps) => {

	const layout = useLayout();
	const [refresh, setRefresh] = useState(false);
	const [show, setShow] = useState(false);
	const target = useRef(null);
	const [date, setDate] = useState()
	const [error, setError] = useStateMounted<string>();
	const [isLoaded, setIsLoaded] = useState(false);
	const [fields, setFields] = useState<any[]>([]);
	const [roleid, setRoleid] = useState<any>(0);
	const [apiurl, setApiurl] = useState<any>(null);
	const [discomid, setDiscomid] = useState<any>(0);
	const [districts, setDistricts] = useState<any[]>([]);
	const [getdistricts, setGetDistricts] = useState<any>(0);
	const [districtsresult, setDistrictsResult] = useState<any>(0);
	const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
	const [isLoading, setIsLoading] = useState(false);
	const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
	const [loading, setLoading] = useStateMounted<boolean>(true);
	const [selectDistricts, setSelectDistricts] = useState<any>(0);
	const [excelSwitch, setexcelSwitch] = useState<boolean>(true);
	const [archiveView, setArchiveView] = useState<boolean>(false);
	const [archiveData, setArchiveData] = useState<any>({});
	const [archiveRevisionId, setArchiveRevisionId] = useState<any>(0);

	const handleFileInput = (e: any) => {
		setIsLoading(true);
		setRefresh(true);
		setError('');
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		let formData = new FormData();
		formData.append("file_to_upload", e.target.files[0]);
		formData.append("role_id", roleid);
		formData.append("discom_id", discomid);
		formData.append("form_id", "33");
		setFields([]);
		Http.post('/apiv1/lossreductionupldistrict', formData, config)
			.then((res) => {
				if (res.resultflag && res.fields) {
					if (res.fields && Array.isArray(res.fields)) {
						if (!res.fields.length) setError("No data found.");
						setFields(res.fields);
						setRefresh(false);
						setTimeout(() => {
							initselect();
							multijsoninit();
						}, 2000);
						console.log("File uploaded successfully");
					} else {
						setFields([]);
						setError("This file format is wrong.");
					}
				} else if (!res.resultflag) {
					setError(res.message);
				} else {
					setError('No data found.');
				}
				setIsLoading(false);
				setRefresh(false);
			}).catch((error) => {
				setFields([]);
				setIsLoading(false);
				setRefresh(false);
				setError('This file format is wrong.');
			})
			.finally(() => {
				setIsLoading(false);
				setRefresh(false);
			});
	}

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
	}, [roleid]);

	const ChangeValue = (evt?: any) => {
		setGetDistricts(evt.target.value);
		setSelectDistricts(evt.target.value);
		setIsLoaded(true);
		/* Update parent component state */
		(evt.target.value != '0') ? props.updateBtnState(true) : props.updateBtnState(false);
	};

	const getDistricts = () => {
		(async () => {
			if (apiurl) {
				let footerspinner: any = document.getElementById('footer-spinner');
				if (footerspinner)
					footerspinner.classList.remove('d-none');
				await Http.get(apiurl)
					.then((result) => {
						setIsLoaded(true);
						setDistricts(result.district);
						setTimeout(() => {
						}, 2000);
					})
					.catch((error) => {
						setIsLoaded(true);
						setError(error);
					}).finally(() => {
						setLoading(false);
						footerspinner.classList.add('d-none');
					});
			}
		})();
	};

	useEffect(() => {
		if (discomid) {
			setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
		}
	}, [apiurl, discomid]);

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		(async () => {
			getDistricts();
		})();
	}, [apiurl]);

	useEffect(() => {
		(async () => {
			setDistrictsDefaultValue(districtsresult);
			setTimeout(() => {
				init();
			}, 500);
		})();
	}, [districtsresult]);

	useEffect(() => {
		(async () => {
			let footerspinner: any = document.getElementById('footer-spinner');
			if (footerspinner)
				footerspinner.classList.remove('d-none');
			setFields([]);
			/* Update parent component state */
			if(!getdistricts) props.updateBtnState(false);
			if (getdistricts) {
				await Http.get(props.apiurl + "&district_id=" + getdistricts)
					.then((result) => {
						setIsLoaded(true);
						setFields(result.fields);
						setDistrictsResult(result.district_id);
						if (typeof result.excelSwitch !== "undefined") setexcelSwitch(result.excelSwitch);
						setTimeout(() => {
							init();
							initselect();
						}, 1000);
						footerspinner.classList.remove('d-none');
					})
					.catch((error) => {
						setIsLoaded(true);
						setError(error);
					}).finally(() => {
						footerspinner.classList.add('d-none');
					});
			}
		})();
	}, [props.apiurl, getdistricts, props.refresh]);

	useEffect(() => {
		if (discomid) {
			let samplelink = process.env.REACT_APP_API_ENDPOINT + '/uploads/sanctionletter-excel-sample/Loss_Reduction_Discom_and_District.xlsx';
			setSampleFileLink(samplelink);
		}
	}, [discomid]);

	useEffect(() => {
		layout.set({ lsDistrictAchiveView: archiveView });
		setArchiveData({});
		setArchiveRevisionId(0);
	}, [layout, archiveView]);

	if (!isLoaded) {
		return <div>Loading...</div>;
	} else {
		return (
			fields ?
				<>
					<div className='DistrictSelect'>
						<div className='row'>
							<div className='col-md-5'>
								{
									districts ? <>
										<select className='form-select text-start w-100' onChange={ChangeValue} defaultValue={districtsdefaultvalue} name="district_id" id="district_id">
											<option value={0}>Select District</option>
											{
												districts.map((district: any) => {
													const attr = (district.id === districtsdefaultvalue) ? { value: district.id, selected: true } : { value: district.id };
													return <><option {...attr}>{district.district_name}</option></>;
												})
											}
										</select>
									</>
										: null
								}
							</div>
							{(selectDistricts != 0 && excelSwitch && archiveView == false) ?
								<>
									{props.act != 'view' ?
										<>
											<div className='col-md-3'>
												<div className="form-field">
													<div className="fileUpload position-relative text-center">
														<div className="downEqule">
															<input type="file" onChange={handleFileInput} data-prefix="fpl_expenditure_details" className="form-control" name="import_data_file" id="import_data_file" />
															<div className="customUpload justify-content-center flex-wrap">
																<span><BiUpload /></span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='col-md-2'>
												<div className="w-2">
													<Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
												</div>
											</div>
										</>
										: null
									}
								</>
								: null
							}
							{(selectDistricts != 0) && <div className="col-md-2">
								<button onClick={() => setArchiveView((prev) => {
									// Toggle the archive view
									return !prev;
								})} type="button" className={(archiveView) ? 'btn btn-secondary' : 'btn btn-primary'}>
									{archiveView ? "Back" : "Archive"}
								</button>
							</div>}
						</div>
					</div>
					<div aria-hidden={archiveView ? "true" : "false"}>
						<div className="pageMiddleTitle">
							<div className="row justify-content-between align-items-center mx-0 gx-0">
								<h5>District wise sanctioned items</h5>
							</div>
						</div>
						{error && (<Alert className='mt-3 mb-3' variant="danger">{error}</Alert>)}

						{(!error) && (fields?.length > 0) && (selectDistricts != 0) ?
							<Block2 field6={fields[0]} field4={fields[3]} remarkFields={fields[5]} act={props.act} excelSwitch={excelSwitch} />
							: null
						}
						{isLoading ? <LoadingSpinner /> : <></>}
					</div>
					{(archiveView && selectDistricts != 0) && <div>
						<div className="pageMiddleTitle">
							<div className="row justify-content-between align-items-center mx-0 gx-0">
								<h5 className="col-md-8">
									District wise sanctioned items (Archived{(archiveData.createdDate) ? ' - ' + archiveData.createdDate : ''})
									{archiveData.userName && <span className="text-muted"> by {archiveData.userName}</span>}
								</h5>
								<div className="col-md-4 d-flex justify-content-end">
									{archiveData.previousId && <button onClick={() => setArchiveRevisionId(archiveData.previousId)} type="button" className="btn btn-secondary me-2">Previous</button>}
									{archiveData.nextId && <button onClick={() => setArchiveRevisionId(archiveData.nextId)} type="button" className="btn btn-primary me-2">Next</button>}
								</div>
							</div>
						</div>
						<ArchiveView {...{ district: selectDistricts, archiveRevisionId, setArchiveData }} />
					</div>}
				</>
				: null
		);
	}
};

const SanctionDistrictForms = (props: any) => {
	const [roleid, setRoleid] = useState<any>(0);
	const [discomid, setDiscomid] = useState<any>(0);
	useEffect(() => {
		setRoleid(Storage.get('roleid'));
		setDiscomid(Storage.get('discom_id'));
	}, [roleid]);

	return (
		<>
			<div className="mainWrap">
				{roleid == 0 ? null :
					<SanctionDistrictForm apiurl={"/apiv1/formfields/?form_id=33&roleid=" + roleid + "&discom_id=" + discomid + "&act=" + props.act} act={props.act} refresh={props.refresh} updateBtnState={props.updateBtnState} />
				}
			</div>
		</>
	)
}
export default SanctionDistrictForms;