import * as React from "react";
const TwitterIcon = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.46 24.77"
    {...props}
  >
    <defs>
      <style>{"\n      .cls-1 {\n        fill: #fff;\n      }\n    "}</style>
    </defs>
    <g id="layer1">
      <path
        id="path1009"
        className="cls-1"
        d="m.06,0l10.22,13.66L0,24.77h2.31l9-9.72,7.27,9.72h7.87l-10.79-14.43L25.24,0h-2.31l-8.29,8.95L7.94,0H.06Zm3.4,1.7h3.62l15.97,21.36h-3.62L3.47,1.7Z"
      />
    </g>
  </svg>
);
export default TwitterIcon;
