import React, { useState, useEffect } from "react";
import { Http } from '../../Core/Services/HttpService';
import { init } from "../../assets/js/formbuilder";
import { Storage } from "../../Core/Services/StorageService";

type IndicatorsProps = {
    apiurl: any;
    setSelecteddrop: any;
    defaultId?: any;
};

const Indicator = (props: IndicatorsProps) => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [defaultId, setDefaultId] = useState("19");
    
    useEffect(() => {
        setDefaultId(defaultId);
    }, [props.defaultId]);

    const indicatorchange = (evt:any) => {
        console.log(evt.target.value);
        props.setSelecteddrop(evt.target.value);
    }

    useEffect(() => {
        (async () => {
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setOptions(result.indicators);
                    setTimeout(() => {
                        init();
                    }, 500);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    }, [props.apiurl]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <>
                <select onChange={indicatorchange} className="form-select" defaultValue={defaultId}>
                {options.map((option) => (
                     <option  value={option.id}>{option.indicator_name}</option>
                ))}
                </select>
            </>
        );
    }
};

const Indicators = (props: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);
    return (
        <>
            <div className="mainWrap">
                <Indicator apiurl={"/apiv1/d-board/indicators"} defaultId={props.defaultId} setSelecteddrop={props.setSelecteddrop} />
            </div>
        </>
    )
}
export default Indicators;