import React, { Component } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'

export default class ScreenReaderAccess extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Screen Reader Access" />
                <div className='main'>
                    <div className='container'>
                        <div className='screenReader'>
                            <p>REC website complies with World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.0 level AA. This will enable people with visual impairments access the website using assistive technologies, such as screen readers. The information of the website is accessible with different screen readers.</p>

                            <h2>Various Screen Readers to choose from</h2>

                            <div className="table-responsive tableStyle tableBorderLeft">
                                <table className="responsiveTable table table-hover"><thead><tr><th>Screen Reader</th>
                                    <th>Website</th>
                                    <th>Free / Commercial</th>
                                </tr></thead><tbody><tr><td>Screen Access For All (SAFA)</td>
                                    <td><a href="http://safa-reader.software.informer.com/download/">http://safa-reader.software.informer.com/download/</a></td>
                                    <td>Free</td>
                                </tr><tr><td>Non Visual Desktop Access (NVDA)</td>
                                            <td><a href="http://www.nvda-project.org/">http://www.nvda-project.org/</a></td>
                                            <td>Free</td>
                                        </tr><tr><td>System Access To Go</td>
                                            <td><a href="http://www.satogo.com/">http://www.satogo.com/</a></td>
                                            <td>Free</td>
                                        </tr><tr><td>Thunder</td>
                                            <td><a href="http://www.screenreader.net/index.php">http://www.screenreader.net/index.php</a></td>
                                            <td>Free</td>
                                        </tr><tr><td>WebAnywhere</td>
                                            <td><a href="http://webanywhere.cs.washington.edu/wa.php">http://webanywhere.cs.washington.edu/wa.php</a></td>
                                            <td>Free</td>
                                        </tr><tr><td>Hal</td>
                                            <td><a href="http://www.yourdolphin.co.uk/productdetail.asp">http://www.yourdolphin.co.uk/productdetail.asp</a></td>
                                            <td>Commercial</td>
                                        </tr><tr><td>JAWS</td>
                                            <td><a href="http://www.freedomscientific.com">http://www.freedomscientific.com</a></td>
                                            <td>Commercial</td>
                                        </tr><tr><td>Supernova</td>
                                            <td><a href="http://www.yourdolphin.co.uk/productdetail.asp">http://www.yourdolphin.co.uk/productdetail.asp</a></td>
                                            <td>Commercial</td>
                                        </tr><tr><td>Window-Eyes</td>
                                            <td><a href="http://www.gwmicro.com/Window-Eyes/">http://www.gwmicro.com/Window-Eyes/</a></td>
                                            <td>Commercial</td>
                                        </tr></tbody></table></div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
