import React, { useEffect, useState } from "react";
import SanctionDiscom from "./SanctionItemsType/SanctionDiscom";
import SanctionDistrict from "./SanctionItemsType/SanctionDistrict";
import { useLayout } from "../../../../Core/Providers/LayoutProvider";

const ItemsSanction = ({ refresh, change, act, updateBtnState }: any) => {
    const [_refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState("DISCOM");
    const layout = useLayout();

    useEffect(() => {
        setSelected("DISCOM");
    }, []);

    useEffect(() => {
        setRefresh(refresh);
    }, [refresh]);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ sanctioneditemstype: e.target.value });
        change(e.target.value); // Called parent component function for set Save as Draft button state
    };

    return (
        <>
            <div className="position-relative">
                <div className="radioYear mb-2 py-xl-2 mt-0">
                    <div className="year">
                        <div className="switch-field position-relative z-1">
                            <input type="radio" value="DISCOM" id="DISCOM" checked={selected === "DISCOM"} onChange={changeHandler} />
                            <label htmlFor="DISCOM">Discom</label>
                            <input type="radio" value="District" id="District" checked={selected === "District"} onChange={changeHandler} />
                            <label htmlFor="District">District</label>
                        </div>
                    </div>
                </div>
                <div className="table-responsive RevenueDetailsTable PqTable">
                    {
                        selected === "DISCOM" ? <SanctionDiscom act={act} refresh={_refresh} updateBtnState={updateBtnState} /> : null
                    }
                    {
                        selected === "District" ? <SanctionDistrict act={act} refresh={_refresh} updateBtnState={updateBtnState} /> : null
                    }
                </div>
            </div>
        </>
    );
}

export default ItemsSanction;