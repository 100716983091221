import React, { useEffect, useState } from "react";
import SmartMeteringSurveyed from "./Surveyed/SmartMeteringSurveyed";
import LossReductionSurveyed from "./Surveyed/LossReductionSurveyed";
import { useLayout } from "../../../../Core/Providers/LayoutProvider";

const ItemsSurveyedDetails = (props?:any) => {
    const [selected, setSelected] = useState("SMSURVEYED");
    const layout = useLayout();

    useEffect(() => {
        setSelected("SMSURVEYED");
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ surveyeditemstype: e.target.value });
    };

    return (
        <>
            <div className="position-relative">
                <div className="radioYear mb-2 py-xl-2 mt-0">
                    <div className="year">
                        <div className="switch-field">
                            <input type="radio" value="SMSURVEYED" id="SMSURVEYED" checked={selected === "SMSURVEYED"} onChange={changeHandler} />
                            <label htmlFor="SMSURVEYED">Smart Metering</label>
                            <input type="radio" value="LRSURVEYED" id="LRSURVEYED" checked={selected === "LRSURVEYED"} onChange={changeHandler} />
                            <label htmlFor="LRSURVEYED">Loss Reduction</label>
                        </div>
                    </div>
                </div>
                <div aria-hidden={selected !== "SMSURVEYED" ? true : false}><SmartMeteringSurveyed isLoading={props.isLoading} refresh={props.refresh}/></div>
                <div aria-hidden={selected !== "LRSURVEYED" ? true : false}><LossReductionSurveyed isLoading={props.isLoading} refresh={props.refresh}/></div>
            </div>
        </>
    );
}

const SurveyedSanction = (props?:any) => {
    return (
        <>
            <ItemsSurveyedDetails isLoading={props.isLoading} refresh={props.refresh} />
        </>
    )
}

export default SurveyedSanction;