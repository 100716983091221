import React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import { GrDownload } from "react-icons/gr";

export class Field {

  fieldName!: string;
  field!: any;
  options!: any;

  constructor(fieldName: string, field: any, options: any) {
    this.fieldName = fieldName;
    this.field = field;
    this.options = options;
  }

  get isMultijson() {
    if (this.field?.parent_type) {
      if (_.isArray(this.field.parent_type)) {
        return (this.field.parent_type.findIndex((type: string) => type === 'multijson') !== -1);
      } else if (this.field.parent_type === 'multijson') {
        return true;
      }
    }
    return false;
  };

  toFixed = (val: any, decimal: number = 0) => {
    val = (val + "").replace(/[^\-.0-9]+/g, '');
    if (val == '') val = decimal == 0 ? 0 : "0.00";
    val = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    }).format(val);
    return val;
  }

  get name() {
    const name = ['formdata'];
    if (this.field?.table_key) {
      name.push('[' + this.field.table_key + ']');
    }
    if (this.field?.parent) {
      if (_.isArray(this.field.parent)) {
        this.field.parent.forEach((p: any) => name.push('[' + p + ']'));
      } else {
        name.push('[' + this.field.parent + ']');
      }
    }
    name.push('[' + this.fieldName + ']');
    if (this.isMultijson) {
      const multiCount = this.field.parent_type.filter((ptype: any) => ptype === 'multijson').length - 1;
      if (multiCount) {
        const totalMultiType = this.field.parent_type.filter((ptype: any) => ptype === 'multijson');
        let totalMultiCount = totalMultiType.length;
        let pCount = 0;
        const parentIndex = this.options.parentIndex;
        totalMultiType.forEach(() => {
          if (multiCount && (totalMultiCount <= multiCount)) {
            const pindex = (parentIndex && parentIndex[pCount] !== undefined) ? parentIndex[pCount] : '';
            name.push('[' + pindex + ']');
            pCount++;
          }
          totalMultiCount--;
        });
      }
    }
    if (this.isMultijson) {
      name.push('[]');
    }
    return name.join('');
  };

  get id() {
    const fId = [];
    if (this.field?.table_key) {
      fId.push(this.field.table_key + '_');
    }
    fId.push(this.fieldName);
    if (this.options?.extraId) {
      fId.push(this.options.extraId);
    }
    return fId.join('');
  };

  get value() {
    if (this.field.type === 'number') {
      return this.toFixed(this.field.value) || 0;
    }
    if (this.field.type === 'amount') {
      return this.toFixed(this.field.value, 2) || 0.00;
    }
    return this.field.value || '';
  };

  get className() {
    const classNames = ['form-control'];
    if (this.field?.field_formula) {
      classNames.push('data-formula');
    }
    if (this.field.type === 'number' || this.field.type === 'amount') {
      classNames.push('text-end');
    }
    const val = this.value;
    if (val === 'Compliant') {
      classNames.push('green');
    } else if (val === 'Non-Compliant') {
      classNames.push('red');
    }
    if (this.isMultijson) {
      classNames.push(this.fieldName);
    }
    return classNames.join(' ');
  };

  get extraClass() {
    const classNames = [''];
    const val = this.value;
    if (val === 'Compliant') {
      classNames.push('green');
    } else if (val === 'Non-compliant') {
      classNames.push('red');
    }
    return classNames.join(' ');
  };

  get attr() {
    const attrs: any = {
      name: this.name,
      id: this.id,
      autoComplete: 'off',
      className: this.className,
      'data-prefix': this.field?.table_key,
      ...this.field?.properties
    };
    if (this.field?.field_formula || this.field?.properties?.readonly) {
      attrs.readOnly = true;
    }
    if (this.field?.required) {
      attrs["data-required"] = this.field.required;
    }
    return attrs;
  };

}

type FileUploadPropType = {
  fieldName: string;
  field: any;
  options?: any;
}

const fileUploadPropTypes = {
  fieldName: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object
};

function FileUpload({ fieldName, field, options }: FileUploadPropType) {

  const fieldObj = new Field(fieldName, field, options);

  return (
    <>
      {
        (field.view == 'Y')
          ? <>
            <div className="view-field text-center">
              {
                (fieldObj.value) && (
                  <a href={fieldObj.value} title={fieldObj.value} className="viewupload" target="_blank">
                    <span><GrDownload /></span>
                  </a>
                )
              }
            </div>
          </>
          : null
      }
    </>
  );
};

FileUpload.propTypes = fileUploadPropTypes;

type FieldRenderPropType = {
  fieldName: string;
  field: any;
  options?: any;
}

const fieldRenderPropTypes = {
  fieldName: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object
};

function FieldRender({ fieldName, field, options }: FieldRenderPropType) {

  const fieldObj = new Field(fieldName, field, options);

  switch (field.type) {
    case 'file':
      return <FileUpload fieldName={fieldName} field={field} options={options} />;
      break;
    case 'number':
      return (
        <>
          {
            (field.view === 'Y')
              ? <div className="view-field text-start">{fieldObj.value}</div>
              : <input {...fieldObj.attr} defaultValue={fieldObj.value}
                type="text" tabIndex={field.tab_index} data-number="Y" maxLength={14} />
          }
        </>
      );
      break;
    case 'amount':
      return (<>
        {
          (field.view === 'Y')
            ? <div className="view-field text-start">{fieldObj.value}</div>
            : <input {...fieldObj.attr} defaultValue={fieldObj.value}
              type="text" tabIndex={field.tab_index} data-amount="Y" maxLength={17} />
        }
      </>
      );
      break;
    case 'textarea':
      return (
        <>
          {
            (field.view === 'Y')
              ? <div className={'view-field text-start ' + fieldObj.extraClass}>{fieldObj.value}</div>
              : <textarea {...fieldObj.attr} defaultValue={fieldObj.value}></textarea>
          }
        </>
      );
      break;
    case 'select':
      return (
        <>
          {
            (field.view === 'Y' || fieldObj.attr.readOnly)
              ? <>
                <div className={'view-field text-start ' + fieldObj.extraClass}>{fieldObj.value}</div>
                {fieldObj.attr.readOnly && <input type="hidden" {...fieldObj.attr} defaultValue={fieldObj.value} />}
              </>
              : <select tabIndex={field.tab_index} {...fieldObj.attr} value={fieldObj.value}
                className={'form-control text-start form-select ' + fieldObj.className}>
                {
                  Object.keys(field.source).map((key: any, index: number) => {
                    return <option key={index} value={key}>{field.source[key]}</option>
                  })
                }
              </select>
          }
        </>
      );
      break;
    case 'checkbox':
      return (
        <>
          <div className="form-check">
            <input type="checkbox" tabIndex={field.tab_index} {...fieldObj.attr} value={fieldObj.value} className={'form-check-input' + fieldObj.attr.className} />
          </div>
        </>
      );
      break;
    case 'radio':
      return (
        <>
          <div className="form-check">
            <input type="radio" tabIndex={field.tab_index} {...fieldObj.attr} value={fieldObj.value} className={'form-check-input' + fieldObj.className} />
            <label className="form-check-label" htmlFor={fieldObj.id + '_' + fieldObj.value}>{field.caption}</label>
          </div>
        </>
      );
      break;
    case 'yesno':
      return (
        <>
          {
            (field.view === 'Y')
              ? <div className="view-field text-start">{fieldObj.value}</div>
              : <>
                <div className="d-flex align-items-center">
                  <div className="form-check me-3">
                    <input type="radio"{...fieldObj.attr}
                      id={fieldObj.id + '_yes'}
                      className={'form-control form-check-input ' + fieldObj.className}
                      value='Yes' checked={fieldObj.value == 'Yes' ? true : false} />
                    <label className="form-check-label" htmlFor={fieldObj.id + '_yes'}>Yes</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" {...fieldObj.attr}
                      id={fieldObj.id + '_no'}
                      className={'form-control form-check-input ' + fieldObj.className}
                      value='No' checked={fieldObj.value == 'No' ? true : false} />
                    <label className="form-check-label" htmlFor={fieldObj.id + '_no'}>No</label>
                  </div>
                </div>
              </>
          }
        </>
      );
      break;
    default:
      return (
        <>
          {
            (field.view === 'Y')
              ? <div className={'view-field text-start ' + fieldObj.extraClass}>{fieldObj.value}</div>
              : <input type={field.type} {...fieldObj.attr} defaultValue={fieldObj.value} />
          }
        </>
      );
      break;
  }

};

FieldRender.propTypes = fieldRenderPropTypes;

type FieldComponentPropType = {
  fieldName: string;
  field: any;
  options?: { showTitle?: boolean, extraId?: any, parentIndex?: any[] }
} & typeof defaultProps;

const defaultProps = {
  options: { showTitle: true }
};

const propTypes = {
  fieldName: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object
};

function FieldComponent({ fieldName, field, options }: FieldComponentPropType) {

  return (
    <>
      <div className={'col-md-' + field?.col}>
        <div className={"form-group " + field.type}>
          <div className={'form-field' + ((options.showTitle) ? ' form-floating' : '')}>
            <FieldRender fieldName={fieldName} field={field} options={options} />
            {
              (options.showTitle) && (
                <label className="form-label" htmlFor={fieldName}>{field.caption}</label>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

FieldComponent.propTypes = propTypes;

FieldComponent.defaultProps = defaultProps;

export default FieldComponent;