import React, { Component } from 'react'

export default class TPQMA extends Component {

  render() {
    return (
      <div>Quality Monitoring</div>
    )
  }
}
