import React, { useState, useEffect } from "react";
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
import { useLocation, useNavigate } from "react-router-dom";
import { useStateMounted } from '../../../../Core/Hooks';
import SmCumulativeGrantDiscomReports from './SmCumulativeGrantReports/SmCumulativeGrantDiscomReports';
import SmCumulativeGrantDistrictReports from './SmCumulativeGrantReports/SmCumulativeGrantDistrictReports';
import { fyByStartEnd,yearByStartEnd } from '../../../../Core/Utility/Datetime';
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

const SmCumulativeGrantReports = (props: any) => {
    const [selected, setSelected] = useState<any>(0);
    const [discoms, setDiscoms] = useState<any[]>([]);
    const [nodalAgency, setNodalAgency] = useState<any>(0);
    const [error, setError] = useState(null);
    const [apiurl, setApiurl] = useState<any>(null);
    const [defaultDiscom, setDefaultDiscom] = useState<any>(0);
    const [defaultTab, setDefaultTab] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomId] = useState<any>(0);
    const [years, setYears] = useStateMounted<[{ label: string, value: string }] | []>([]);
    const [year, setYear] = useState<any>(2021);
    const [roleid, setRoleid] = useState<any>(0);

    const location = useLocation();
    let navigate = useNavigate();
    const changeLrTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            const fyearList: any = fyByStartEnd("2021", new Date().getFullYear().toString());
            await fyearList.map((item: any) => {
                item.label = `${item.label} - ${(parseInt(item.label) + 1)}`;
                return item;
            });
            setYears(fyearList);
            setSelected("SmCumulativeGrantDiscomReports");
        })();
    }, [roleid]);

    const onChangeYear = (e: any) => {
        if (props.year !== e.target.value)
            setYear(e.target.value);
    };

    const changeTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            if (location.state) {
                setDefaultTab(location.state.sub_module_type);
                setNodalAgency(location.state.nodal_agency)
            }
        })();
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomuserid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            <div className='col-lg-12 col-xl-6 col-xxl-7'>
                                                <div className="radioYear">
                                                    <div className="row justify-content-center align-items-center mx-0 gx-0">
                                                        {(roleid == 5 || roleid == 6) ?
                                                            <div className="col-6 col-md-12">
                                                                <div className="form-check">
                                                                    <input type="radio" className="form-check-input" name="SmCumulativeGrantDiscomReports" value="SmCumulativeGrantDiscomReports" id="SmCumulativeGrantDiscomReports" checked={selected === "SmCumulativeGrantDiscomReports"} onChange={changeLrTab} />
                                                                    <label className="form-check-label" htmlFor="SmCumulativeGrantDiscomReports">Discom</label>
                                                                </div>
                                                            </div>

                                                            : null
                                                        }

                                                        {/* {(roleid == 5 || roleid == 6) ?
                                                            <>
                                                                <div className="col-3 col-md-6">
                                                                    <div className="form-check">
                                                                        <input type="radio" className="form-check-input" name="SmCumulativeGrantDistrictReports" value="SmCumulativeGrantDistrictReports" id="SmCumulativeGrantDistrictReports" checked={selected === "SmCumulativeGrantDistrictReports"} onChange={changeLrTab} />
                                                                        <label className="form-check-label" htmlFor="SmCumulativeGrantDistrictReports">District</label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div aria-hidden={selected !== "SmCumulativeGrantDiscomReports" ? true : false}><SmCumulativeGrantDiscomReports /></div>
                                    {/* <div aria-hidden={selected !== "SmCumulativeGrantDistrictReports" ? true : false}><SmCumulativeGrantDistrictReports /></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? <LoadingSpinner /> : null}
                </section>
                <Footer remove='d-none' />
            </div>
        </>
    );
};

export default SmCumulativeGrantReports;