import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import {init, validatefrm, multijsoninit, initselect, inputEventChecker} from "../../../../assets/js/formbuilder";
import { Http } from '../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiUpload } from 'react-icons/bi';
import swal from 'sweetalert';

// Images
import FieldComponent from "../../../../FieldComponent";
import PropTypes from "prop-types";
import {useStateMounted} from "../../../../Core/Hooks";
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
}

type SmartMeteringProps = {
    apiurl: string;
};

const Block3 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock3FieldsDelete, setHasBlock3FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock3fields] = useState<any>([]);
    const [newBlock3Fields, setNewBlock3fields] = useState<any>([]);
    const [years, setYears] = useState<number[]>([]);
    const [getcollspan, setCollspan] = useState<any>(0);
    const [months, setMonths] = useState("Apr")
    const [getmonths, setMonthsSmall] = useState("apr")
    const [getpagelink, setPageLink] = useState<any>(null);
    const [district, setDistricts] = useState<any>(null);
    
    const yearRange = (start: any, stop: any, step: number) => {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
    };

    useEffect(() => {
        if (props.field3) {
            if(props.field3.value != '')
            {
                setHasBlock3FieldsDeletes(false);
                setBlock3fields([]);
                setNewBlock3fields(props.field3.addnew);
                setTimeout(() => {
                    const fields2 = props.field3.childrens.map((items: any) => {
                        return {
                            fields: items,
                            delete: false
                        }
                    })
                    setBlock3fields(fields2);
                    initselect();
                    multijsoninit();
                }, 500);
            }else{
                setBlock3fields([]);
            }
        }else{
            setBlock3fields([]);
        }
    }, [props.field3]);

    useEffect(() => {
        if (props.year) {
            setYears(props.year);
        }
    }, [props.year]);

    useEffect(() => {
        if (props.month) {
            const monthstr = props.month.charAt(0).toUpperCase() + props.month.slice(1);
            setMonths(monthstr);
            setMonthsSmall(props.month);
        }
    }, [props.month]);

    useEffect(() => {
        if (props.district) {
            setDistricts(props.district);
        }
    }, [props.district]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock3FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 500);
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock3fields((prev: any[]) => {
            return [...prev, { fields: newBlock3Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock3fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    const location = useLocation();
    let navigate = useNavigate();
  
    const gottopage = (gottopage: any, id: any) => {
        setPageLink(gottopage);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { year: years, month: months,district: district} });
            }
        })();
    }, [getpagelink]);

    return (
        <>
        <table className="table caption-top newTableUcss">
            <thead className='text-center'>
                <tr>
                    <th rowSpan={2}>Sl No.</th>
                    <th rowSpan={2}>Major Components</th>
                    <th rowSpan={2}>Sub Component</th>
                    <th rowSpan={2}>Item Specification</th>
                    <th rowSpan={2}>Unit</th>
                    <th colSpan={3} className="border-end noPadding">Quantity (nos.)</th>
                    <th colSpan={2} className="border-end noPadding">Monthly Achievement  <br />{months}</th>
                    <th colSpan={1} className="noPadding">Cumulative Achievement  <br />Up to {months} {years}</th>
                </tr>    
                <tr className="text-center">
                    <th className="fw-normal">Sanctioned</th>
                    <th className="fw-normal">Awarded</th>
                    <th className="fw-normal">Surveyed</th>
                    <th className="fw-normal">%Completion</th>
                    <th className="fw-normal">Remarks</th>
                    <th className="fw-normal">%Completion</th>
                </tr>
            </thead>        
            <tbody>
                {
                    (block2Fields.length)
                        ? block2Fields.map((item: any, key: number) => {
                           
                            return (item.delete === false) ? <>
                                <tr key={key}>
                                    <td className="text-center" >{(block2SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} />
                                                    </td>
                                            </>;
                                        })
                                    }
                                </tr>
                                </>
                                : null;
                        })
                        : null
                }
            </tbody>
        </table>
        </>
    );
};

// ChildInput
type ChildInputProps = {
    fields: any[];
    index?: string | number,
    showTotal?: any
};

const childInputPropTypes = {
    fields: PropTypes.array.isRequired,
    index: PropTypes.any,
    showTotal: PropTypes.any,
};

function ChildInput({ fields, index, showTotal }: ChildInputProps) {

    const [_fields, _setFields] = useStateMounted<string[]>([]);

    useEffect(() => {
        if (fields.length) {
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
            _setFields(fields);
        }
    }, [fields]);

    // const extTD = <td colSpan={2}><span className="text-center">Sub Total</span></td>;

    return (
        <>
            <tr aria-hidden={(showTotal) ? "false" : "true"}>
                {/* <td><span className="text-center">Sub Total</span></td> */}
                {
                    (_fields.length && index !== 1)
                        ? _fields.map((field: any, i: number) => {
                            return ([
                                'sl_award_items_perunit_total',
                                'sl_award_items_cost_total',
                                'sl_award_items_surveyedcost_total',
                            ].includes(field.key))
                                ? null
                                : <>
                                    <td key={index + '-' + i + '-' + field.key}>
                                        <FieldComponent
                                            options={{ showTitle: false, extraId: '_' + index + '_' + i }}
                                            fieldName={field.key}
                                            field={field} module="award_" />
                                    </td>
                                    {
                                        ([1].includes(i)) && (
                                            <td colSpan={1}>&nbsp;</td>
                                        )
                                    }
                                </>
                        })
                        : null
                }

            </tr>
        </>
    );
}

ChildInput.propTypes = childInputPropTypes;

// MultiChild
type MultiChildProps = {
    field: any;
    pIndex: number
    jIndex: number
    index?: string | number,
    totalFields: any
};

const multiChildPropTypes = {
    field: PropTypes.array.isRequired,
    index: PropTypes.any,
    pIndex: PropTypes.number.isRequired,
    totalFields: PropTypes.any.isRequired
};

function MultiChild({ field, index, pIndex, totalFields, jIndex }: MultiChildProps) {

    let srNo = 1;
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            });
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            srNo = 1;
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                init();
                multijsoninit();
            }, 1000);
        }
    }, [fields]);

    return (
        <>
            {
                (fields.length > 0) && fields.map((item: any, i: number) => {
                    return (item.delete === false)
                        ? (
                            <>
                                <tr key={`${idRef.current}-${index}`}>
                                    {
                                        item.fields.map((field: any, j: number) => {
                                            return ([
                                                'sl_award_items_perunit',
                                                'sl_award_items_cost',
                                                'sl_award_items_surveyedcost',
                                                'sl_boq_feeder_name',
                                                'sl_boq_quantity',
                                                'sl_boq_remarks',
                                            ].includes(field.key))
                                                ? <span style={{display:'none'}} key={`${idRef.current}-${index}-${field.key}`}>
													<FieldComponent
                                                        options={
                                                            { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                                                        }
                                                        fieldName={field.key}
                                                        field={field} module="award_" />
												</span>
                                                : <td className={'td' + (jIndex + j)} key={`${idRef.current}-${index}-${field.key}`}>
                                                    <FieldComponent
                                                        options={
                                                            { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                                                        }
                                                        fieldName={field.key}
                                                        field={field} module="award_" />
                                                </td>
                                        })
                                    }

                                </tr>
                            </>
                        )
                        : null;
                })
            }

            {
                totalFields && (
                    <ChildInput fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
                )
            }

        </>
    );
}

MultiChild.propTypes = multiChildPropTypes;

// Multijson
type MultijsonProps = {
    field: any;
    index: number,
    totalField: any,
    data: any
};

const multijsonPropTypes = {
    field: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    totalField: PropTypes.any.isRequired,
    data: PropTypes.any
};

function Multijson({ field, index, totalField, data }: MultijsonProps) {

    let srNo = 1;
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();
    const [months, setMonths] = useState("Apr");

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            srNo = 1;
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                init();
                multijsoninit();
                adjustwidth();
            }, 1000);
        }
    }, [fields]);

    const adjustwidth = () => {
        let awards: any = document.querySelectorAll('.award-td');
        for (let i = 0; i < awards.length; i++) {
            let awardid = awards[i].getAttribute('id');
            if (awardid) {
                let allcss: any = document.querySelectorAll('.' + awardid);
                if (allcss.length) {
                    if (awardid == 'td1') {
                        for (let j = 0; j < allcss.length; j++) {
                            allcss[j].width = awards[i].offsetWidth;
                        }
                    }
                    else
                        awards[i].width = allcss[0].offsetWidth;
                }
            }
            let footid = document.getElementById('tdf'+i);
            if ( footid )
                footid.style.width = awards[i].offsetWidth+'px';
        }
    };

    useEffect(() => {
        if (data.month) {
            const monthstr = data.month.charAt(0).toUpperCase() + data.month.slice(1);
            setMonths(monthstr);
        }
    }, [data.month]);

    return (
        <>
        <thead>
            <tr className="text-center">
                <th rowSpan={2} style={{minWidth:40, width:40}}>Sl No.</th>
                <th rowSpan={2} style={{minWidth:150, width:150}}>Major Components</th>
                <th rowSpan={2} style={{minWidth:150, width:150}}>Sub Component</th>
                <th rowSpan={2} style={{minWidth:150, width:150}}>Item Specification</th>
                <th rowSpan={2} style={{minWidth:200, width:200}}>Remarks</th>
                <th rowSpan={2} style={{minWidth:100, width:100}}>Unit</th>
                <th colSpan={4} className="border-end noPadding">Quantity (nos.)</th>
                <th colSpan={2} className="border-end noPadding">Monthly Achievement  <br />{months}</th>
                <th colSpan={1} className="noPadding">Cumulative Achievement  <br />Up to {months} {(['Jan','Feb','Mar'].includes(months)) ? data.year : (data.year - 1)}</th>
            </tr>
            <tr className="text-center">
                <th className="fw-normal" style={{minWidth:100, width:100}}>Sanctioned</th>
                <th className="fw-normal" style={{minWidth:100, width:100}}>Awarded</th>
                <th className="fw-normal" style={{minWidth:100, width:100}}>Package No</th>
                <th className="fw-normal" style={{minWidth:100, width:100}}>Surveyed</th>
                <th className="fw-normal" style={{minWidth:100, width:100}}>%Completion</th>
                <th className="fw-normal" style={{minWidth:100, width:100}}>Remarks</th>
                <th className="fw-normal" style={{minWidth:100, width:100}}>%Completion</th>
            </tr>
        </thead>
            <tbody className="dynaWidthSubstation dynaWidthIto">

            {/*<tr className="award-margin-top"><td colSpan={7}></td></tr>*/}
            {
                fields.map((item: any, i: number) => {
                    return (item.delete === false)
                        ? (
                            <>
                                <tr className="align-top">
                                    <td><span className="text-center">{srNo++}</span></td>
                                    {
                                        item.fields.map((field: any, j: number) => {
                                            let extraId = '_' + i + '_' + j;
                                            let style: any = {};
                                            let hidefield = false;
                                            let module = 'award_';
                                            //if ([5, 6, 10, 11, 12].includes(j)) {
                                            if ([5,7,8,9,10,11,12,13].includes(j)) {
                                                module = 'award_';
                                                extraId = '_' + i + '_' + i + '_' + j;
                                                style.display = 'none';
                                                hidefield = true;
                                            }
                                            return (field.type !== 'multijson' && !field.childrens)
                                                ? (
                                                    !hidefield ?
                                                        <td key={`${idRef.current}-${index}-${field.key}`}>
                                                            <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
                                                        </td>
                                                        :
                                                        <span style={style}>
																<FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
															</span>
                                                ) : null;
                                        })
                                    }

                                    <td colSpan={6} className="multiple-award-items table-responsive">
                                        <table className="RevenueDetailsTable PqTable">
                                            <tbody>
                                            {
                                                item.fields.map((field: any, j: number) => {
                                                    const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
                                                    const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
                                                    return field.type === 'multijson' && (
                                                        <MultiChild field={field} pIndex={(srNo - 2)} jIndex={10} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} />
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </>
                        )
                        : null;
                })
            }
            </tbody>
        </>
    );
}
Multijson.propTypes = multijsonPropTypes;

const ItOtScada = (props?: any) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [apiurl, setApiurl] = useState<any>(null);
    const [districts, setDistricts] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState("2023");
    const formData: any = useRef(null);
    const [month, setMonth] = useState("apr");
    const [fieldsvalues, setFieldsValues] =  useState<any>(0);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoading(false);
                    setIsLoaded(true);
                    setFields(result.fields);
                    setYear(result.year);
                    setDistricts(result.district_id);
                    setMonth(result.month);
                    setFieldsValues(result.fields[0].value);
                    setTimeout(() => {
                        init();
                        initselect();
                    }, 1000);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    }, [props.apiurl]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>  
                    <div className="table-responsive RevenueDetailsTable PqTable">
                        <Block3 field3={fields[0]} field4={fields[1]} year={year} month={month} district={districts} />
                    </div> 
                    {fieldsvalues != '' ?
                    <>
                        <div className='row my-3 uploadUcss align-items-center'>
                        <div className='col-md-1'>
                            <label>Upload</label>
                        </div>
                        <div className='col-md-5'>
                            <div className="uploadSelect">
                                <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                            </div>
                        </div>
                        <div className='col-md-1 text-end'>
                            <label>Remarks</label>
                        </div>
                        <div className='col-md-5'>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} />
                        </div>
                    </div>
                     </> :null
                    }   
                    {isLoading ? <LoadingSpinner /> : null}
                </>
                : null
        );
    }
};

const ItOtScada1 = (props?: any) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [apiurl, setApiurl] = useState<any>(null);
    const [districts, setDistricts] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState("2023");
    const formData: any = useRef(null);
    const [month, setMonth] = useState("apr");
    const [fieldsvalues, setFieldsValues] =  useState<any>(0);
    const [viewMode, setViewMode] = useState<any>('N');
    const [updatedby, setUpdatedby] = useState<any>('');
    const [updatedat, setUpdatedat] = useState<any>('');

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            setIsLoaded(false);
            setFields([]);
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoading(false);
                    setIsLoaded(true);
                    setFields(result.fields);
                    setYear(result.year);
                    setDistricts(result.district_id);
                    setMonth(result.month);
                    setViewMode(result.viewMode);
                    setUpdatedby('');
                    setUpdatedat('');
                    if ( result.updated_by )
                        setUpdatedby(result.updated_by);
                    if ( result.updated_at )
                        setUpdatedat(result.updated_at);
                    //setFieldsValues(result.fields[0].value);
                    setTimeout(() => {
                        init();
                        initselect();
                    }, 1000);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    }, [props.apiurl]);

    useEffect(() => {
        props.viewModeFlagChild(viewMode);
    },[viewMode]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>
                    <div className="RevenueDetailsTable PqTable monitoringPdLrItOt table-responsive">
                        <table className="lrMultipleID lossIOT">
                            {
                                fields.map((field: any, index: number) => {
                                    const totalField = (fields.length && fields[1].childrens) ? fields[1].childrens : null;
                                    return field.type === 'multijson' && (
                                        <Multijson field={field} index={index} totalField={totalField} data={{month, year}} />
                                    )
                                })
                            }
                        </table>
                    </div>
                    { updatedat && updatedby ?
                    <div className="updated-info">
                        <p>Last Updated on: <span>{updatedat}</span> by <span>{updatedby}</span></p>
                    </div>
                    : null
                    }
                    {viewMode === 'N' ?
                        <>
                            <div className='row my-3 uploadUcss align-items-center'>
                                <div className='col-md-1'>
                                    <label>Upload</label>
                                </div>
                                <div className='col-md-5'>
                                    <div className="uploadSelect">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                    </div>
                                </div>
                                <div className='col-md-1 text-end'>
                                    <label>Remarks</label>
                                </div>
                                <div className='col-md-5'>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                </div>
                            </div>
                        </> :null
                    }
                    {isLoading ? <LoadingSpinner /> : null}
                </>
                : null
        );
    }
};

const ItOtScadas = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [districts, setDistricts] = useState<any>(0);
    const [year, setYear] = useState<any>(0);
    const [month, setMonth] = useState("apr");

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    useEffect(() => {
        if (props.districts) 
        {
            setDistricts(props.districts);
        }
    }, [props.districts]);

    useEffect(() => {
        if (props.year) 
        {
            setYear(props.year);
        }
    }, [props.year]);

    useEffect(() => {
        if (props.month) 
        {
            setMonth(props.month);
        }
    }, [props.month]);

    return (
        <>
        {roleid == 0 ? null :
            /*<ItOtScada apiurl={"/apiv1/formfields/?form_id=33&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_pd_ls_itotscada&district_id=" + districts + "&year=" + year+ "&month=" + month} refresh={refresh}/>*/
            <ItOtScada1 apiurl={"/apiv1/dprfields/form31PdLrItOt/?form_id=31&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_pd_ls_itotscada&district_id=0&year=" + year+ "&month=" + month} refresh={refresh} viewModeFlagChild={props.viewModeFlag}/>
        }
        </>
    )
}
export default ItOtScadas;