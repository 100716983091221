import React, { useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import '../chart.scss';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApexCharts from "apexcharts";
import jsPDF from "jspdf";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { toFixed } from "../../../../assets/js/formbuilder";
import { Http } from "../../../../Core/Services/HttpService";

function FinancialProgressMainChart({ progressData, chartLink, noDataMessage, ptype, headingTitle }: any) {

  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const [chart, setChart] = React.useState<any>(null);
  const chartRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const locationModal = location.state && location.state.modal;
  const linkState = (locationModal) ? { state: { modal: locationModal } } : {};

  const setLinks = () => {
    if (!chartLink) return;
    let xaxis = document.querySelector(`#chart-${idRef.current} .apexcharts-xaxis`);
    xaxis?.setAttribute('style', 'pointer-events: auto;');

    let barClick = document.querySelector(`#chart-${idRef.current} .apexcharts-series`);
    barClick?.setAttribute('style', 'cursor: pointer;');
    let labels = document.querySelectorAll(`#chart-${idRef.current} .apexcharts-xaxis-label`);
    if (progressData) {
      progressData.forEach((item: any, index: any) => {
        if (labels[index]) {
          labels[index].setAttribute('style', 'cursor: pointer;');
          labels[index].addEventListener('click', () => {
            navigate(chartLink + '&id=' + item.id, linkState);
          });
        }
      });
    }
  }
  const getStateName = () => {
    return (progressData) ? progressData.map((item: any) => item.title) : [];
  };
  const getProgressData = () => {
    return (progressData) ? progressData.map((item: any) => item.progress) : [];
  };

  const getProgressDataSecound = () => {
    // progress_secound => lsr_award_cost
    return (progressData) ? progressData.map((item: any) => item.lsr_award_cost) : [];
  };
  useEffect(() => {
    const series = [];
    if (ptype == 'aws_cost' || ptype == 'gbs_res') {
      series.push({
        data: getProgressData(),
        name: ''
      });
    } else if (ptype == 'gbs-sanc' || ptype == 'gbs-rel') {
      series.push({
        data: getProgressData(),
        name: 'GBS Sanctioned'
      });
      series.push({
        data: getProgressDataSecound(),
        name: 'GBS Released'
      });
    } else {
      series.push({
        data: getProgressData(),
        name: 'Sanctioned'
      });
      series.push({
        data: getProgressDataSecound(),
        name: 'Awarded'
      });
    }

    const options: any = {
      tooltip: {
        y: {
          formatter: (value: any) => {
            return toFixed(value, 2);
          }
        }
      },
      annotations: {
        points: [{
          seriesIndex: 0,
          label: {
            borderColor: '#775DD0',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#775DD0',
            },
          }
        }]
      },
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        events: {
          dataPointSelection: (event: any, chartContext: any, { seriesIndex, dataPointIndex, config }: any) => {
            if (!chartLink) return;
            if (progressData && progressData[dataPointIndex]) {
              let item = progressData[dataPointIndex];
              navigate(chartLink + '&id=' + item.id, linkState);
            }
          },
          mounted: (chartContext: any, config: any) => {
            const chartDiv: any = document.querySelector(`#chart-${idRef.current}`);
            let settings = { childList: true },
              observer = new MutationObserver(() => setLinks());

            observer.observe(chartDiv, settings);
            setLinks();
          },
          updated: (chartContext: any, config: any) => {
            const chartDiv: any = document.querySelector(`#chart-${idRef.current}`);
            let settings = { childList: true },
              observer = new MutationObserver(() => setLinks());

            observer.observe(chartDiv, settings);
            setLinks();
          },
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },

      grid: {
        borderColor: '#fff',
      },
      colors: ['#2E93fA', '#f58216'],
      xaxis: {
        labels: {
          rotate: -45,
          style: {
            fontSize: "10px",
          }
        },
        categories: getStateName(),
        tickPlacement: 'on',
      },
      fill: {
        // colors: ['#2E93fA', '#f58216'],
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      }
    };

    if (progressData && !progressData.length) {
      options['noData'] = {
        text: noDataMessage,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
      };
    }
    setChart({
      series: series,
      options: options
    });
  }, [progressData, noDataMessage, chartLink, ptype]);

  const exportToPDF = async () => {
    await chartRef.current.chart.dataURI().then((res: any) => {
      let content = document.getElementById(`chart-${idRef.current}`)?.cloneNode(true) as any;
      content = document.createDocumentFragment().appendChild(content);
      const chartCanvas = content.querySelector('.apexcharts-canvas');
      chartCanvas.querySelector('svg').remove();
      const img = document.createElement('img');
      img.src = res;
      chartCanvas.prepend(img);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const frmData = new FormData();
      frmData.append('content', encodeURIComponent(content.outerHTML));
      frmData.append('filename', 'progressdatasmchart');
      frmData.append('download', 'no');
      frmData.append('orientation', 'landscape');
      Http.post('/apiv1/contentExportPdf', frmData, config)
        .then((res) => {
          if (res._resultflag == "1") {
            window.open(res.file, "_blank");
          }
        }).catch((e) => {

        });
    });
  };

  return (
    <>
      {(chart != null) && (
        <>
          <div className="d-flex justify-content-end mt-2">
            <a title="Export to PDF" onClick={exportToPDF}><div className="pdfBox"><BsFileEarmarkPdf /></div></a>
          </div>
          <div id={`chart-${idRef.current}`}>
            <div className="row d-none">
              {ptype == 'san-cost' ? headingTitle + ' - Sanctioned and Awarded Cost – INR Cr' : ''}
              {ptype == 'awar-cost' ? headingTitle + ' - Sanctioned and Awarded Cost – INR Cr' : ''}
              {ptype == 'gbs-sanc' ? headingTitle + ' - GBS Sanctioned and GBS Released – INR Cr' : ''}
              {ptype == 'gbs-rel' ? headingTitle + ' - GBS Sanctioned and GBS Released – INR Cr' : ''}
              {ptype == 'aws_cost' ? headingTitle + ' - Awarded/ Sanctioned Cost - %' : ''}
              {ptype == 'gbs_res' ? headingTitle + ' - GBS Released/ GBS Sanctioned - %' : ''}
              <span> {new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</span>
            </div>
            <ReactApexChart ref={chartRef} options={chart.options} series={chart.series} type="bar" height={600} />
          </div>
        </>
      )}
    </>
  );
};

FinancialProgressMainChart.defaultProps = {
  noDataMessage: 'Loading...'
};

export default FinancialProgressMainChart;
