import React, { useEffect, useState } from 'react'
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import "./assetwisereport.scss";
import InnerTemplate from '../../Components/InnerTemplate/InnerTemplate';
import AssetWiseReports from './AssetWiseReports';

const AssetWise = (props:any) => {
    return (
        <>
            <div className="mainWrap">
                <Header />
                <InnerTemplate PageTitle="Assetwise MIS Reports" />
                <section className="financialForm misNew">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div><AssetWiseReports /></div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    );
}

export default AssetWise;