import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Pq8 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* PQ-8 */}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">PQ-8 : Timely Filing of Tariff and True Up Petition and Order Issuance <b>*</b></p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[220] && fields[220].value ? fields[220].value : ''} name="formdata[pqsop_details][pq8_nodal]" id="pqsop_details_pq8_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][pq8_remarks_nodal]" id="pqsop_details_pq8_remarks_nodal" defaultValue={fields[235] && fields[235].value ? fields[235].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][pq8_document_nodal]" id="pqsop_details_pq8_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[250].value != '' ?
                                            <a href={fields[250].value} title={fields[250].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>
                                    {fields[220].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[220] && fields[220].value ? fields[220].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[235].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[235] && fields[235].value ? fields[235].value : ''}</div>
                                    {/* : null
                                    } */}

                                    {fields[250].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[250] && fields[250].value ? fields[250].value : ''} title={fields[250] && fields[250].value ? fields[250].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }
                                    </>
                                }
                                </> : null
                            }

                        </div>
                        <div className={props.remove}>
                            <MyTooltip placement="left">
                                <ul>
                                    <li>Tariff order for the current year in which evaluation is being done and true up of penultimate year has been issued and implemented w.e.f. 1st April of current FY</li>
                                    <li>Documentary Evidence: Tariff order, True Up order, Tariff Petition and True Up</li>
                                </ul>
                            </MyTooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Tariff petition for FY {props.year * 1 + 1}</td>
                <td><input type="text" value="Target Date" disabled />
                    {/* <FieldComponent options={{ showTitle: false }} fieldName={fields[172].key} field={fields[172]} /> */}
                </td>
                <td><input type="text" value="Submission Date" disabled />
                    {/* <FieldComponent options={{ showTitle: false }} fieldName={fields[173].key} field={fields[173]} /> */}
                </td>
                <td><input type="text" value="Status" disabled />
                    {/* <FieldComponent options={{ showTitle: false }} fieldName={fields[174].key} field={fields[174]} /> */}
                </td>
            </tr>
            <tr>
                <td>Petition Filing</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[175].key} field={fields[175]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[176].key} field={fields[176]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[177].key} field={fields[177]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[178].key} field={fields[178]} />
                </td>
            </tr>
            <tr>
                <td>Order Issuance </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[179].key} field={fields[179]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[180].key} field={fields[180]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[181].key} field={fields[181]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[182].key} field={fields[182]} />
                </td>
            </tr>
            <tr>
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[191].key} field={fields[191]} />
                </td>
            </tr>
            {/* <tr>
                    <td colSpan={5} className="blankPara"><p className="mb-0 ps-2 fw-medium"><span className="invisible">PQ-8 : </span>True up FY 2021</p></td>
                </tr> */}
            <tr>
                <td>True up for FY {props.year - 1}</td>
                <td><input type="text" value="Target Date" disabled />
                    {/* <FieldComponent options={{ showTitle: false }} fieldName={fields[172].key} field={fields[172]} /> */}
                </td>
                <td><input type="text" value="Submission Date" disabled />
                    {/* <FieldComponent options={{ showTitle: false }} fieldName={fields[173].key} field={fields[173]} /> */}
                </td>
                <td><input type="text" value="Status" disabled />
                    {/* <FieldComponent options={{ showTitle: false }} fieldName={fields[174].key} field={fields[174]} /> */}
                </td>
            </tr>
            <tr>
                <td>Petition Filing</td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[183].key} field={fields[183]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[184].key} field={fields[184]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[185].key} field={fields[185]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[186].key} field={fields[186]} />
                </td>
            </tr>
            <tr>
                <td>Order Issuance </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[187].key} field={fields[187]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[188].key} field={fields[188]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[189].key} field={fields[189]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[190].key} field={fields[190]} />
                </td>
            </tr>

            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[206].key} field={fields[206]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Pq8;