import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { Http } from '../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye, FiEdit } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { useStateMounted } from "../../Core/Hooks";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsFileEarmarkExcel, BsFileEarmarkPdf } from 'react-icons/bs';
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import { useParams } from 'react-router-dom';
import { Storage } from "../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

const NotificationsView = (props: any) => {
    const { module } = useParams<{ module: string }>();
    const [refresh, setRefresh] = useState(false);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [getId, setId] = useState<any>(0);
    const [getMessage, setMessage] = useState<any>(null);
    const [getSubject, setSubject] = useState<any>(null);
    const [modulemaintitle, setModulemaintitle] = useState<any>(null);
    const [modulemainlink, setModulemainlink] = useState<any>(null);
    const [modulesublink, setModulesublink] = useState<any>(0);

    const location = useLocation();
    let navigate = useNavigate();

    console.log(module);

    useEffect(() => {
        if (module == 'RDSS') {
            setModulemaintitle('RDSS');
            setModulemainlink('/rdss');
            setModulesublink('/rdss#Notifications');
        } else if (module == 'CSRD') {
            setModulemaintitle('CSRD');
            setModulemainlink('/csrd');
            setModulesublink('/csrd#Notifications');
        } else if (module == 'Energy Accounting') {
            setModulemaintitle('Energy Accounting');
            setModulemainlink('/energy');
            setModulesublink('/energy#Notifications');
        } else if (module == 'KRP') {
            setModulemaintitle('KRP');
            setModulemainlink('/krp');
            setModulesublink('/krp#Notifications');
        } else if (module == 'DISCOMIR') {
            setModulemaintitle('DIR');
            setModulemainlink('/discomir');
            setModulesublink('/discomir#Notifications');
        }else if (module == 'AGSDP') {
            setModulemaintitle('AGSDP');
            setModulemainlink('/agsdp');
            setModulesublink('/agsdp#Notifications');
        }
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomid]);

    useEffect(() => {
        (async () => {
            console.log(location.state);
            if (location.state) {
                setId(location.state.id);
            } else {
                navigate('/rdss');
            }
        })();
    }, [roleid, discomid]);

    const getViewForm = () => {
        (async () => {
            if (getId) {
                setIsLoaded(true);
                setRefresh(false);
                setError('');
                setMessage('');
                setSubject('');
                const params = {
                    id: getId,
                    discom_user_id: discomuserid
                };

                await Http.get(`/apiv1/notifications-view`, { params })
                    .then((res) => {
                        if (res._resultflag && res.data) {
                            if (res.data && Array.isArray(res.data)) {
                                if (!res.data.length) setError("No data found.");
                                setMessage(res.data[0]['message']);
                                setSubject(res.data[0]['subject']);
                                setIsLoaded(false);
                            } else {
                                setError("Invalid data type.");
                                setIsLoaded(false);
                            }
                        } else if (!res._resultflag) {
                            setError(res.message);
                            setIsLoaded(false);
                        } else {
                            setError('No data found.');
                            setIsLoaded(false);
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                        setIsLoaded(false);
                    })
                    .finally(() => {
                        setIsLoaded(false);
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getViewForm();
        })();
    }, [getId]);

    if (error) {
        return <Alert className='mt-3 mb-3' variant="danger">{error}</Alert>;
    } else {
        return (<>
            <Header />
            <div className="mainWrap">
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            <li><Link to={modulemainlink} className="text-decoration-none">{modulemaintitle}</Link></li>
                            <li><Link to={modulesublink} className="text-decoration-none">Notifications</Link></li>
                            <li><Link to="#" className="text-decoration-none">Notifications View</Link></li>
                        </ul>
                    </div>
                </section>

                <section className="financialForm pt-4">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className="notification-info card p-3">
                                        <h6>{getSubject}</h6>
                                        <p>
                                            {getMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {isLoaded ? <LoadingSpinner /> : null}
            </div >
            <Footer />
        </>
        );
    }
};

export default NotificationsView;