import React from 'react';
// import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import Routes from '../../Core/Components/Routes';
import Default from '../../Layout/Default';
import { useAuth } from '../../Core/Providers/AuthProvider';
import routes from './Config/Routes';
import './praapti.scss'

function Index(props: any) {
  
  const auth = useAuth();
  let location = useLocation();

  return (
    <>
      <div className="app-praapti">
        <Routes
          location={location}
          redirect="/sign-in"
          layout={<Default />}
          isAuthorized={auth.isAuth()}
          routes={routes || []}
          notFound="/404"
        />
      </div>
    </>
  );

}

export default Index;
