import React from 'react'
import Marquee from 'react-fast-marquee'
import './marquee.scss'

function MarqueeText() {
    return (
        <>
            <section className="marqueeSection">
                <div className="container">
                    <Marquee pauseOnHover={true} speed={50} loop={0}>
                        <ul>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                            <li><a href="#" target="_blank">The portal is currently under development and is being updated by the DISCOMs</a></li>
                        </ul>                        
                    </Marquee>
                </div>
            </section>
        </>
    )
}

export default MarqueeText