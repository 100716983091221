import React, { useEffect, useRef, useState } from "react";
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import { Http } from '../Core/Services/HttpService';
import Select from "react-select";
import ExcelIcn from '../images/excel.svg';
import PdfIcn from '../images/pdf.svg';
import { RiFileExcel2Line } from "react-icons/ri";
import { BsSearch, BsFillFileEarmarkPdfFill,BsChevronDoubleLeft,BsChevronDoubleRight,BsChevronCompactLeft,BsChevronCompactRight } from "react-icons/bs";
import './misreport.scss';

const LossReductionMisReports = () => {
    const [discomList, setDiscomList] = useState([{'id': 0, 'discom_name':'All'}]);
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [districtList, setDistrictList] = useState([{'id': 0, 'district_name':'All'}]);
    const [selectedMajorComponent, setSelectedMajorComponent] = useState<any>(0);
    const [selectedSubComponent, setSelectedSubComponent] = useState<any>(0);
    const [subComponentList, setSubComponentList] = useState([{'id': 0, 'title':'All'}]);
    const [itemSpecificationList, setItemSpecificationList] = useState([{'id': 0, 'title':'All'}]);
    const [smMajorComponents, setSmMajorComponents] = useState([{'id': 0, 'title':'All'}]);
    const [sanctionLetterData, setSanctionLetterData] = useState<any>();
    const [selectedDistrict, setSelectedDistrict] = useState<any>(0);
    const [selectedItemSpecification, setSelectedItemSpecification] = useState<any>(0);
    const [reportType, setReportType] = useState<any>('Discom');
    const [misReportDataCount, setMisReportDataCount] = useState<any>(0);
    const [searchText, setSearchText] = useState<any>();

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const searchParams = new URLSearchParams(window.location.search);
    const filterVal = searchParams.get('filter');
    const filterStateVal = searchParams.get('stateid');

    const handleDiscomSelect = (selectedDiscom: any) => {
        setCurrentPage(0);
        setSelectedDiscom(selectedDiscom);
    };

    const handleDistrictSelect = (selectedDistrict: any) => {
        setCurrentPage(0);
        setSelectedDistrict(selectedDistrict);
    };

    const handleMajorComponentSelect = (selectedMajorComponent: any) => {
        setCurrentPage(0);
        setSelectedMajorComponent(selectedMajorComponent);
    };

    const handleSubComponentSelect = (selectedSubComponent: any) => {
        setCurrentPage(0);
        setSelectedSubComponent(selectedSubComponent);
    };

    const handleItemSpecificationSelect = (selectedItemSpecification: any) => {
        setCurrentPage(0);
        setSelectedItemSpecification(selectedItemSpecification);
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };
    
    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event:any) => {
        if(isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if(event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if(event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e:any) => {
        if(e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const handleSearchTextChange = (event:any) => {
        setSearchText(event.target.value);
    }

    const handleSearchEnter = (event:any) => {
        if (event.key === 'Enter') {
            fetchSanctionLetterData(searchText);
        }
    }
    
    const fetchSanctionLetterData = async (search:any) => { 
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        if(curPage >= 0) {
            await Http.get('/apiv1/getlssanctionletterdata?discomid='+selectedDiscom.value+'&distid='+selectedDistrict.value+'&majorcomponentid='+selectedMajorComponent.value+'&subcomp='+selectedSubComponent.value+'&itemSpec='+selectedItemSpecification.value+'&reportType='+reportType+'&searchtext='+search+'&page='+curPage+'&pageSize='+pageSize+'&stateid='+filterStateVal)
            .then((res) => {
                setMisReportDataCount(res.totalRecord);
                setSanctionLetterData(res.sanctionLetterData);
                setTotalItems(res.totalRecord);
                setPageCount(Math.ceil(res.totalRecord / pageSize));  
                setTotalPages(Math.ceil(res.totalRecord / pageSize));
                
                let cPage = (!currentPage) ? 1 : currentPage;
                setFirst((cPage - 1) * pageSize + 1);
                setLast(Math.min(res.totalRecord, cPage * pageSize));

            }).catch((e) => {
                console.log(e);
            });
        }
    }

    useEffect(() => {
        if(currentPage != 0) {
            fetchSanctionLetterData(searchText);
        }
    }, [currentPage]);

    useEffect(() => {
        if(filterVal) {
            setSearchText(filterVal);
            fetchSanctionLetterData(filterVal);
        }
    }, [filterVal,filterStateVal]);

    const fetchReportData = async () => {  
        await Http.get('/apiv1/mislrsmartreportdata')
        .then((res) => {
            setDiscomList(res.discoms);
            setDistrictList([]);
            setItemSpecificationList([]);
            setSmMajorComponents(res.lrmajorcomponent);
        }).catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        fetchReportData();
    }, [])

    const handleRadioChange = (e: any) => {
        let value = e.target.value;
        setReportType(e.target.value);

        setSelectedDiscom(0);
        setSelectedDistrict(0);
        setSelectedMajorComponent(0);
        setSelectedSubComponent(0);
        setSelectedItemSpecification(0);

        setMisReportDataCount(0);
        setCurrentPage(0);
        setTotalItems(0);
        setPageCount(0);

        setMisReportDataCount(0);
        setSanctionLetterData([]);

        setDistrictList([]);
        setItemSpecificationList([]);
    };

    const fetchReportDataDistrict = async () => { 
        if(reportType == 'Districts') {
            await Http.get('/apiv1/misdistrict')
            .then((res) => {
                setSelectedDistrict(0);
                setDistrictList(res.districtsList);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            setDistrictList([]);
            setSelectedDistrict(0);
        }
    }

    useEffect(() => {
        fetchReportDataDistrict();
    }, [reportType])

    const fetchDistrict = async () => {  
        if (selectedDiscom.value > 0) {
            await Http.get('/apiv1/misdistrict?discomid='+selectedDiscom.value)
            .then((res) => {
                setDistrictList(res.districtsList);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            setDistrictList([]);
            setSelectedDistrict(0);
        }
    }

    useEffect(() => {
        fetchDistrict();
    }, [selectedDiscom]);

    const fetchSubComponent = async () => {  
        if (selectedMajorComponent.value > 0) {
            await Http.get('/apiv1/mislrsubcomponents?sm_major_components_id='+selectedMajorComponent.value)
            .then((res) => {
                setSubComponentList(res.lssubcomponents);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            setSubComponentList([]);
            setSelectedSubComponent(0);
        }
    }

    useEffect(() => {
        fetchSubComponent();
    }, [selectedMajorComponent]);


    const fetchItemSpecification = async () => {  
        if (selectedSubComponent.value > 0) {
            await Http.get('/apiv1/mislritemsspecification?sm_major_components_id='+selectedSubComponent.value)
            .then((res) => {
                setItemSpecificationList(res.lssubcomponents);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            setItemSpecificationList([]);
            setSelectedItemSpecification(0);
        }
    }

    useEffect(() => {
        fetchItemSpecification();
    }, [selectedSubComponent]);

    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt+"").replace(/[^\-.0-9]+/g, '');
        if ( amt == '' ) amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>"+amt+"</mark>";
        }
        return amt;
    }

    return (
        <>
            <div className="mainWrap">
                <Header />
                <div className="container mb-5 mt-3">
                    <div className="dashboardInner detailedDashboard">
                        <div className="row gx-xxl-5">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="heading mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">MIS Reports - Loss Reduction</h4>
                                            <a href="/smart-metering-mis-report" className="text-warning text-decoration-none">Smart Metering</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="financialForm misNew">
                        <div className="financialMainFrom">
                            <div className='radioTitleSection' style={{marginBottom:'15px'}}>
                                <div className='row mx-0 gx-0'>
                                    <div className='col-lg-12 col-xl-7 col-xxl-6'>
                                        <div className="radioYear">
                                            <div className="row mx-0">
                                            <div className="col-md-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="Reoption" value="Discom" id="Discom" checked={reportType === 'Discom'} onChange={handleRadioChange}/>
                                                    <label className="form-check-label" htmlFor="Discom">Discom</label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="Reoption" value="Districts" id="Districts" checked={reportType === 'Districts'} onChange={handleRadioChange}/>
                                                    <label className="form-check-label" htmlFor="Districts">Districts</label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="Reoption" value="Constituency" id="Constituency" checked={reportType === 'Constituency'} onChange={handleRadioChange}/>
                                                    <label className="form-check-label" htmlFor="Constituency">Constituency</label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                        <div className="row align-items-center">
                            <div className="col-md">
                                <div className="">
                                    <label htmlFor="search" className="form-label">Keyword</label>
                                    <input type="text" className="form-control misReportSelect" aria-label="Search" onChange={handleSearchTextChange} onKeyDown={handleSearchEnter} value={searchText} placeholder="Search by keyword" />
                                </div>
                            </div>
                            { reportType == 'Discom'  ?
                                <div className="col-md">
                                    <div className="">
                                        <label htmlFor="discom" className="form-label">Discom</label>
                                        <Select id="discom" className="misReportSelect" aria-label="Discom" onChange={handleDiscomSelect} isSearchable={true} options={discomList} value={selectedDiscom}/>
                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className="col-md">
                                <div className="">
                                    <label htmlFor="districts" className="form-label">Districts</label>
                                    <Select id="districts" className="misReportSelect" aria-label="Districts" onChange={handleDistrictSelect} isSearchable={true} options={districtList} value={selectedDistrict}/>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="">
                                    <label htmlFor="majorcomponent" className="form-label">Major Components</label>
                                    <Select id="majorcomponent" className="misReportSelect" aria-label="Major Components" onChange={handleMajorComponentSelect} isSearchable={true} options={smMajorComponents} value={selectedMajorComponent}/>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="">
                                    <label htmlFor="subcomponent" className="form-label">Sub Components</label>
                                    <Select id="subcomponent" className="misReportSelect" aria-label="Sub Components" onChange={handleSubComponentSelect} isSearchable={true} options={subComponentList} value={selectedSubComponent}/>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="">
                                    <label htmlFor="itemspecification" className="form-label">Item Specification</label>
                                    <Select id="itemspecification" className="misReportSelect" aria-label="Item Specification" onChange={handleItemSpecificationSelect} isSearchable={true} options={itemSpecificationList} value={selectedItemSpecification}/>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="d-flex justify-content-between align-items-center misBottomBtn">
                                    <button className="bsSearchBtn" onClick={()=>{setPageInput(1);setCurrentPage(1);fetchSanctionLetterData(searchText);}}>Search</button>
                                    <div className="dIcnBtn">
                                        <a className="bsSearchBtn" target="_blank" href={process.env.REACT_APP_API_NDPOINT+'/apiv1/getlssanctionletterdata?discomid='+selectedDiscom.value+'&distid='+selectedDistrict.value+'&majorcomponentid='+selectedMajorComponent.value+'&subcomp='+selectedSubComponent.value+'&itemSpec='+selectedItemSpecification.value+'&reportType='+reportType+'&searchtext='+searchText+'&stateid='+filterStateVal+'&task=excel'}><img src={ExcelIcn}/></a>                                    
                                        <a className="bsSearchBtn" target="_blank" href={process.env.REACT_APP_API_ENDPOINT+'/apiv1/getlssanctionletterdata?discomid='+selectedDiscom.value+'&distid='+selectedDistrict.value+'&majorcomponentid='+selectedMajorComponent.value+'&subcomp='+selectedSubComponent.value+'&itemSpec='+selectedItemSpecification.value+'&reportType='+reportType+'&searchtext='+searchText+'&stateid='+filterStateVal+'&task=pdf'}><img src={PdfIcn}/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        {/* <div className="input-group mb-3 mt-3">
                            <input type="text" className="form-control" onChange={handleSearchTextChange} value={searchText} placeholder="Search" />
                            <button className="btn btn-warning text-white" type="button" onClick={()=>fetchSanctionLetterData(searchText)}>Search</button>
                        </div> */}
                        
                        <div className="">
                            {
                                totalItems ?
                                    <div>Displaying {first} to {last} of {totalItems}</div>
                                :
                                    null
                            }
                            <div className="mt-2 financialMainFrom misReportTable">
                                <div className="tab-content p-0 border-0">
                                    <div className="table-responsive RevenueDetailsTable misreportTable">
                                        <table className="table">
                                            <thead>
                                                <tr className="text-center">
                                                    <th scope="col" className="text-start">Sr. No</th>
                                                    <th scope="col">Discom</th>
                                                    <th scope="col">District</th>
                                                    <th scope="col">Major Components</th>
                                                    <th scope="col">Sub Component</th>
                                                    <th scope="Item Summary">Item Summary</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Unit</th>
                                                    <th scope="col">Sanctioned Rate (INR/unit)</th>
                                                    <th scope="col">Sanctioned Quantity</th>
                                                    <th scope="col">Sanctioned Cost (INR Lakh)</th>
                                                    <th scope="col">Award Rate (INR/unit)</th>
                                                    <th scope="col">Awarded Quantity</th>
                                                    <th scope="col">Awarded Amount (INR Lakh)</th>
                                                    <th scope="col">Surveyed Quantity</th>
                                                    <th scope="col">Surveyed Amount (INR Lakh)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {misReportDataCount > 0 && sanctionLetterData.length > 0 ? sanctionLetterData.map((sanctionData:any, index:any) => {
                                                    return <tr>
                                                        <td>{sanctionData.srNo+1}</td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.discom_name}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.district_name}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.major_component_name}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.sub_component_name}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.item_summary_name}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.remarks}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.qty_phase_2}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: toFixed(sanctionData.unit,2)}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.quantity}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: toFixed(sanctionData.cost,2)}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: toFixed(sanctionData.sl_award_items_perunit,2)}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.sl_award_items_quantity}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: toFixed(sanctionData.sl_award_items_cost,2)}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: sanctionData.sl_award_items_surveyedquantity}}></td>
                                                        <td dangerouslySetInnerHTML={{ __html: toFixed(sanctionData.sl_award_items_surveyedcost,2)}}></td>
                                                    </tr>
                                                }): <tr><td colSpan={16} className="text-center my-5"><h5>No Record Found</h5></td></tr>} 
                                            </tbody>
                                        </table>
                                    </div>
                                    { totalItems != 0 ? 
                                    <div className="mt-3">
                                        <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                            <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                        <BsChevronDoubleLeft/>
                                                    </button>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                        <BsChevronCompactLeft/>
                                                    </button>
                                                </li>
                                                <li className="page-item col-1">
                                                    <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage } onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                        <BsChevronCompactRight/>
                                                    </button>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                        <BsChevronDoubleRight/>
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className="page-item text-center">
                                            <span>
                                                Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                            </span>
                                        </div>
                                    </div>
                                    :
                                        null
                                }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer remove='d-none' />
            </div>
        </>
    );
}

export default LossReductionMisReports;