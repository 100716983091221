import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../../../assets/js/formbuilder";
import { Http } from '../../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useStateMounted } from "../../../../../Core/Hooks";
import { Link } from "react-router-dom";

// Images
import FieldComponent from "../../../../../FieldComponent";
import { Storage } from "../../../../../Core/Services/StorageService";

type SmartMeteringProps = {
    apiurl: string;
    act: any;
    refresh: any;
    updateBtnState: any;
};

const Block1 = (props: any) => {

    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);
    const [blockKey, setBlockKey] = useState<string>('');

    useEffect(() => {
        if (props.field1) {
            setBlockKey(props.field1.key);
            setHasBlock1FieldsDeletes(false);
            setBlock1fields([]);
            setNewBlock1fields(props.field1.addnew);
            const fields1 = props.field1.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field1]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }
    return (
        <>
            <table className="table caption-top">
                <thead className='text-center'>
                    <tr>
                        <th style={{ minWidth: "50px" }}>S. No.</th>
                        <th>Major Components</th>
                        <th>Item Summary</th>
                        <th style={{ width: '220px' }}>Life Cycle Cost (INR/ meter)</th>
                        <th>Quantity (Phase I)</th>
                        <th>Quantity (Phase II)</th>
                        <th>Total Quantity</th>
                        <th>Estimated Cost<br />(INR Lakh)</th>
                        {props.act != 'view' ?
                            <th rowSpan={2} className='text-end pe-1' style={{ width: "30px" }}>
                                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                            </th>
                            : null
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        block1Fields.map((item: any, key: number) => {
                            return (item.delete === false) ?
                                <tr key={key}>
                                    <td className="text-center" >{(block1SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                            </>;
                                        })
                                    }
                                    {props.act != 'view' && props.excelSwitch ?
                                        <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                        : null
                                    }
                                </tr>
                                : <tr key={key} className="d-none">
                                    <td className="text-center">
                                        <input type="hidden" name={'deleteIndex[' + blockKey + '][]'} value={key} />
                                    </td>
                                </tr>;
                        })
                    }
                    <>
                        {
                            (block1Fields.length && props.field2)
                                ? <>
                                    <tr>
                                        <td colSpan={4} className="text-center"><h5>Total</h5></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[3].key} field={props.field2.childrens[3]} /></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                </tbody>
            </table>
        </>
    );
}

const SanctionDiscomForm = (props: SmartMeteringProps) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [excelSwitch, setexcelSwitch] = useState<boolean>(true);

    // useEffect(() => {
    //     (async () => {
    //         let footerspinner:any = document.getElementById('footer-spinner');
    //         if ( footerspinner )
    //             footerspinner.classList.remove('d-none');
    //         await Http.get(props.apiurl)
    //             .then((result) => {
    //                 setIsLoaded(true);
    //                 setFields(result.fields);
    //                 setTimeout(() => {
    //                     init();
    //                 }, 500);
    //             })
    //             .catch((error) => {
    //                 setIsLoaded(true);
    //                 setError(error);
    //             }).finally(() => {
    //                 footerspinner.classList.add('d-none');
    //             })
    //     })();
    // }, [props.apiurl, props.refresh]);

    const getFields = () => {
        (async () => {
            setIsLoaded(false);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
                Http.get(props.apiurl)
                .then((res) => {
                  if (res.resultflag && res.fields) {
                      if (res.fields && Array.isArray(res.fields)) {
                          if (!res.fields.length) setError("No data found.");
                          setIsLoaded(true);
                          setFields(res.fields);
                          if(typeof res.excelSwitch !== "undefined") setexcelSwitch(res.excelSwitch);
                          setTimeout(() => {
                            init();
                        }, 500);

                      } else {
                        setError('System error occurred!! please try again.');
                        setIsLoaded(true);
                      }
                   
                  } else if (!res.resultflag) {
                      setError(res.message);
                      footerspinner.classList.remove('d-none');
                      setIsLoaded(true);
                  } else {
                      setError('No data found.');
                      footerspinner.classList.remove('d-none');
                      setIsLoaded(true);
                  }
                 
              }).catch((error) => {
                setError('System error occurred!! please try again.');
                  footerspinner.classList.remove('d-none');
                  setIsLoaded(true);
              })
              .finally(() => {
                    footerspinner.classList.add('d-none');
                    setIsLoaded(true);
              });
              setIsLoaded(true);
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
            /* Update parent component state */
            props.updateBtnState(true);
        })();
    }, [props.apiurl, props.refresh]);

    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            fields.length ?
            <>
                <div className="pageMiddleTitle">
                    <div className="row justify-content-between align-items-center mx-0 gx-0">
                        <div className="col-md-4 col-xxl-4">
                            <h5>DISCOM wise sanctioned items</h5>
                        </div>
                        <div className="col-md-8 col-xxl-8">
                            <p className="alert alert-danger p-1 mb-1" style={{"fontSize": "14px", "fontWeight": "500"}}>This page has been discontinued. Please do not enter DISCOM-wise sanctioned details. Details
                            are to be entered district-wise.</p>
                        </div>
                    </div>
                </div>
                <div className='DistrictSelect'>
                    <div className="form-field">
                        {/* <div className="fileUpload position-relative text-center">
                        <div className="downEqule"> */}
                        {/* <input type="file" data-prefix="fpl_expenditure_details" className="form-control"/>
                          <div className="customUpload justify-content-center flex-wrap">
                            <span><BiUpload/></span>
                          </div> */}
                        <div className="uploadSelect">
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                        </div>
                        {/* </div>
                      </div> */}
                    </div>
                </div>
                <Block1 field1={fields[0]} field2={fields[1]} act={props.act} excelSwitch={excelSwitch} />

                <div className="table-responsive RevenueDetailsTable PqTable">
                    <table className="table">
                        <thead className="text-center">
                            <th>Total GBS excluding incentive for Phase I</th>
                            <th>Incentives for Phase I</th>
                            <th>Total GBS including incentives for Phase I</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                </td>
                                <td>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                </td>
                                <td>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </>
            : null
        );
    }
};

const SanctionDiscomForms = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    return (
        <>
            <div className="mainWrap">
                {roleid == 0 ? null :
                    <SanctionDiscomForm apiurl={"/apiv1/formfields/?form_id=29&roleid=" + roleid + "&discom_id=" + discomid + "&act=" + props.act} act={props.act} refresh={props.refresh} updateBtnState={props.updateBtnState} />
                }
            </div>
        </>
    )
}
export default SanctionDiscomForms;