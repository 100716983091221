import { Stepper, Step } from "react-form-stepper";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';

// Forms
import FinancialForm from "./FinancialForm";
import PqSopForm from "./PqSopForm";
import EnergyForm from "./EnergyForm";
import RefForm from "./RefForm";
import { Http } from "../../../Core/Services/HttpService";
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import StepperFormat from "../../../Components/StepperFormat";
import { Storage } from "../../../Core/Services/StorageService";
import {validatefrm} from "../../../assets/js/formbuilder";

export default function FormStep(props: any) {  
  return (
    <div className="App">
      <PlaceOrder year={props.year} />
    </div>
  );
}

export const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-outer">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

export const Discomhistory = (props: any) => {
  // return <></>;
  const layout = useLayout();
  const [discomhistory, setDiscomHistory] = useState<any>([]);
  let layoutDataSubscribe: any;
 
  let discomHistoryNo = 0;

  useEffect(() => {
    (async () => {
      layoutDataSubscribe = await layout.data.subscribe((res: any) => {
        if (res.submittedStatus && res.submittedStatus.discom_history)
          setDiscomHistory(res.submittedStatus.discom_history);
      });
    })();
    return () => {
      if(layoutDataSubscribe !== undefined) {
        layoutDataSubscribe.unsubscribe();
      }
    }
  }, []);
  
  return (
    <>
    {(discomhistory.length > 0) ?
    <div className="accordion-body">
    <div className="table-responsive RevenueDetailsTable actionHistory">
    <h4 className="accordion-header blankPara">Action History</h4>
          <table className="table">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Action</th>
              <th>Remark</th>
              <th>Timestamp</th>
            </tr> 
          </thead>
          <tbody>
                  {(discomhistory.length > 0) ?
                    discomhistory.map((item: any, key: number) => (
                      <>
                      <tr key={key}>
                            <td>{(discomHistoryNo++) + 1}</td>
                            <td>{item.sender}</td>
                            <td>{item.receiver}</td>
                            <td>{item.action}</td>
                            <td>{item.remark}</td>
                            <td>{item.date}</td>
                        </tr>
                        </>
                    ))
                    : null
                }
          </tbody>
              </table>
        </div>
    </div>
    : null
    }
    </>
  );
}

export const PlaceOrder = (props: any) => {

  const formData: any = useRef(null);
  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const layout = useLayout();
  const [year, setYear] = useState<any>(props.year);
  const [refresh, setRefresh] = useState(false);
  const [tblkey, setTblkey] = useState('');
  const [roleid, setRoleid] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [isSubmittedStatus, setSubmittedStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [discomhistory, setDiscomHistory] = useState('');
  const [steps, setSteps] = useState(['active','','','']);

  let layoutDataSubscribe: any;
 
  useEffect(() => {
      setDiscomUserid(Storage.get('discom_user_id'));
  }, [discomuserid]);

  useEffect(() => {
    setDiscomid(Storage.get('discom_id'));
}, [discomid]);


  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    (async () => {
      layoutDataSubscribe = await layout.data.subscribe((res: any) => {
        setSubmittedStatus(res.submittedStatus.submittedStatus);
        setRemarks(res.submittedStatus.remarks);
        setDiscomHistory(res.submittedStatus.discom_history)
      });
    })();
    return () => {
      if(layoutDataSubscribe !== undefined) {
        layoutDataSubscribe.unsubscribe();
      }
    }
  }, []);

  useEffect(() => {
    setYear(props.year);
  }, [props.year]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('year', year);
    Http.post('/apiv1/save-form', myFormData, config)
      .then((res) => {
        setIsLoading(false);
        // window.location.reload();
        setRefresh(true);
        // popup
        // Data have been saved.
        // console.log(res);    
        swal("Input Form", "Your data has been saved.", "success");
      });
  }

  const next = (opt: number) => {
    if ( opt == 0 )
      setSteps(['active','','','']);
    if ( opt == 1 )
      setSteps(['completed','active','','']);
    if ( opt == 2 )
      setSteps(['completed','completed','active','']);
    if ( opt == 3 )
      setSteps(['completed','completed','completed','active']);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('year', year);
    Http.post('/apiv1/next-form', myFormData, config)
      .then((res) => {
        // console.log(res);
      });

    setGoSteps(opt);
  }

  const navtab = (opt: number) => {
    if ( opt == 0 )
      setSteps(['active','','','']);
    if ( opt == 1 )
      setSteps(['completed','active','','']);
    if ( opt == 2 )
      setSteps(['completed','completed','active','']);
    if ( opt == 3 )
      setSteps(['completed','completed','completed','active']);
    setGoSteps(opt);
  }

  const submittoreviewer = (form_step_no: any) => {
    /*if(!validatefrm(`#rdss-${idRef.current}-frm .form-control`)){
      let errorEle:any = document.querySelectorAll('div.has-error');
      if(errorEle.length){
        let parentTabEle = errorEle[0].closest(`form#rdss-${idRef.current}-frm .tab-pane`);
        if(parentTabEle){
          let tabID = parentTabEle.id;
          let tabBar = document.querySelector(`form#rdss-${idRef.current}-frm button#${tabID}Tab`);
          if(tabBar) tabBar.dispatchEvent(new Event('click', { cancelable: true, bubbles: true }));
        }
      }
      return;
    }*/
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);

    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('roleid', roleid);
    myFormData.append('year', year);
    myFormData.append('form_step_no', form_step_no);
    myFormData.append('discom_id', discomid);
    
    swal({
      title: "Are you sure to submit this form?",
      text: "After submit, You will not able to edit this form.",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {
      if (isConfirm) {

        Http.post('/apiv1/forward', myFormData, config)
            .then((res) => {
              if(!res._resultflag){
                swal({
                  title: 'Input Form!',
                  text: res.message,
                  icon: 'warning'
                }).then(function() {
                  setIsLoading(false);
                  return;
                });
              } else {
                swal({
                  title: 'Input Form!',
                  text: 'Your data has been submitted.',
                  icon: 'success'
                }).then(function() {
                  setIsLoading(false);
                  setRefresh(true);
                });
              }
            });

        /*swal({
          title: 'Input Form!',
          text: 'Your data has been submitted.',
          icon: 'success'
        }).then(function() {
          Http.post('/apiv1/forward', myFormData, config)
          .then((res) => {
            setIsLoading(false);
            setRefresh(true);
            // swal("Input Form", "Data has been forwarded", "success");
          });
        });*/
      } else {
        setIsLoading(false);
        // swal("Cancelled", "", "error");
      }
    });
  }

  const submittobackward = (form_step_no: any) => {
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('roleid', roleid);
    myFormData.append('year', year);
    myFormData.append('form_step_no', form_step_no);
    myFormData.append('discom_id', discomid);
    
      swal({
        title: "Are your sure want to return this form?",
        text: "",
        icon: "warning",
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true,
      }).then(function(isConfirm) {
        if (isConfirm) {
          Http.post('/apiv1/backward', myFormData, config)
          .then((res) => {
            if(res._resultflag == 0){
              setIsLoading(false);
              swal("Warning", res.message, "info");
            }else{
              setIsLoading(false);
              setRefresh(true);
              swal("Input Form", "The form has been returned for correction", "success");
            }
          });

        } else {
          setIsLoading(false);
          // swal("Cancelled", "", "error");
        }
      });
  }

  const [sticky, setSticky] = useState("");
    useEffect(() => {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    }, []);
  
    const isSticky = () => {
      const scrollTop = window.scrollY;
      const stickyClass = scrollTop >= 50 ? "is-sticky" : "";
      setSticky(stickyClass);
  };
  const classes = `formBtnSection justify-content-between is-sticky ${sticky}`;
  return (
    <>
      <div>
        {/* <Stepper activeStep={goSteps}>
          <Step onClick={() => navtab(0)} label="Financial" />
          <Step onClick={() => navtab(1)} label="Energy" />
          <Step onClick={() => setGoSteps(2)} label="PQ & SoP" />
          <Step onClick={() => setGoSteps(3)} label="REF" />
        </Stepper> */}

        <div className='container'>
            <div className='customStepper'>
                <div className="StepperContainer-0-2-1">
                    <div className="StepContainer-0-2-2">
                        <div className="StepMain-0-2-3">
                            <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 "+(steps[0] ? steps[0] : '')}>
                              <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 "+(steps[0] ? steps[0] : '')}>1</span>
                            </button>
                            <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Financial</span></div>
                        </div>
                    </div>
                    <div className="StepContainer-0-2-2">
                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                        <div className="StepMain-0-2-3">
                            <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 "+(steps[1] ? steps[1] : '')}>
                              <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 "+(steps[1] ? steps[1] : '')}>2</span>
                            </button>
                            <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Energy</span></div>
                        </div>
                    </div>
                    <div className="StepContainer-0-2-2">
                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d2-0-2-22"><span className="Connector-0-2-15 Connector-d3-0-2-23"></span></div>
                        <div className="StepMain-0-2-3">
                            <button onClick={() => navtab(2)} className={"StepButton-0-2-4 StepButton-d4-0-2-20 "+(steps[2] ? steps[2] : '')}>
                              <span className={"StepButtonContent-0-2-5 StepButtonContent-d5-0-2-21 "+(steps[2] ? steps[2] : '')}>3</span>
                            </button>
                            <div className="LabelContainer-0-2-8 LabelContainer-d4-0-2-24"><span className="Label-0-2-9 Label-d5-0-2-25">PQ &amp; SoP</span></div>
                        </div>
                    </div>
                    <div className="StepContainer-0-2-2">
                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                        <div className="StepMain-0-2-3">
                            <button onClick={() => navtab(3)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 "+(steps[3] ? steps[3] : '')}>
                              <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 "+(steps[3] ? steps[3] : '')}>4</span>
                            </button>
                            <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">REF</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <form id={`rdss-${idRef.current}-frm`} noValidate onSubmit={onSubmit} ref={formData}>
          <input type="hidden" name="year" id="year" value={year} />
          {goSteps === 0 && (
            <div>
              <div>
                <FinancialForm year={year} refresh={refresh} tblkey={setTblkey} />
              </div>
              {roleid != process.env.REACT_APP_DDE ?
                <>
                  <div className="mt-3">
                    <div className="form-group mb-4">
                      <label className="d-block" htmlFor="remarks">Remarks</label>
                      <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                    </div>
                  </div>
                </>
                : null
              }
              <Discomhistory />
              <div className={classes}>
                <div></div>
                <div>
                  {
                  isSubmittedStatus == "1"
                  ? 
                    <>
                    {
                      roleid == 3 || roleid == 4 ?
                      <>
                      {tblkey == 'fpl_key_parameters' ?
                        <Link to="#" className="btnCancle" onClick={() => submittobackward(1)} >Return</Link>
                        : null
                      } 
                      </>
                      : null
                    } 
                    {tblkey == 'fpl_key_parameters' ?
                    <>
                    <Link to="#" className="btnCancle" onClick={() => submittoreviewer(1)} >
                      { roleid == 2 ? "Submit to Reviewer" : null }
                      { roleid == 3 ? "Submit to Approver" : null }
                      { roleid == 4 ? "Submit to Nodal Agency" : null }
                    </Link>
                    </>
                    : null
                    }
                    <button className="btnDraft" type="submit">Save as Draft</button>
                    </>
                  : null
                  }
                </div>
                <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
              </div>
            </div>
          )}
          {/*{(goSteps === 0 || goSteps === 1) && (*/}
          {/*<div className={`step2 ${(goSteps === 0) ? 'd-none' : null}`}>*/}
          {goSteps === 1 && (
            <div>
              <div>
                <EnergyForm year={year} refresh={refresh} tblkey={setTblkey} />
              </div>
              {roleid != '2' ?
                <>
                  <div className="mt-3">
                    <div className="form-group mb-4">
                      <label className="d-block" htmlFor="remarks">Remarks</label>
                      <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                    </div>
                  </div>
                </>
                : null
              }

              <Discomhistory />
              <div  className={classes}>
                <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(0)}>Previous</Link>
                <div>
                {
                  isSubmittedStatus == "1"
                  ? 
                    <>
                    {
                      roleid == 3 || roleid == 4 ?
                      <>
                      {tblkey == 'es_consumer_category_volume' ?
                        <Link to="#" className="btnCancle" onClick={() => submittobackward(2)} >Return</Link>
                        : null
                      } 
                      </>
                      : null
                    }  
                    {tblkey == 'es_consumer_category_volume' ?
                    <>
                    <Link to="#" className="btnCancle" onClick={() => submittoreviewer(2)} >
                    { roleid == 2 ? "Submit to Reviewer" : null }
                    { roleid == 3 ? "Submit to Approver" : null }
                    { roleid == 4 ? "Submit to Nodal Agency" : null }
                    </Link>
                    </>
                    : null
                    }
                    <button className="btnDraft" type="submit">Save as Draft</button>
                    </>
                  : null
                }
                </div>
                <Link to="#" className="btn btnSubmit" onClick={() => next(2)}>Next</Link>
              </div>
            </div>
          )}
          {goSteps === 2 && (
            <div>
              <div>
                <PqSopForm year={year} refresh={refresh} />
              </div>
              {roleid != '2' ?
                <>
                  <div className="mt-3">
                    <div className="form-group mb-4">
                      <label className="d-block" htmlFor="remarks">Remarks</label>
                      <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                    </div>
                  </div>
                </>
                : null
              }
              <Discomhistory />
              <div className={classes}>
                <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(1)}>Previous</Link>
                <div>
                  {
                    isSubmittedStatus == "1"
                    ? 
                      <>
                      {
                        roleid == 3 || roleid == 4 ?
                        <><Link to="#" className="btnCancle" onClick={() => submittobackward(3)} >Return</Link></>
                        : null
                      }
                      <Link to="#" className="btnCancle" onClick={() => submittoreviewer(3)} >
                      { roleid == 2 ? "Submit to Reviewer" : null }
                      { roleid == 3 ? "Submit to Approver" : null }
                      { roleid == 4 ? "Submit to Nodal Agency" : null }
                      </Link>
                      <button className="btnDraft" type="submit">Save as Draft</button>
                      </>
                    : null
                  }
                  
                </div>
                <Link to="#" className="btn btnSubmit" onClick={() => next(3)}>Next</Link>
              </div>
            </div>
          )}
          {goSteps === 3 && (
            <div>
              <div>
                <RefForm year={year} refresh={refresh} tblkey={setTblkey} />
              </div>
              {roleid != '2' ?
                <>
                  <div className="mt-3">
                    <div className="form-group mb-4">
                      <label className="d-block" htmlFor="remarks">Remarks</label>
                      <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                    </div>
                  </div>
                </>
                : null
              }
              <Discomhistory />
              <div className={classes}>
              <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(2)}>Previous</Link>
                <div>
                  {isSubmittedStatus == "1" ? 
                      <>
                      {
                        roleid == 3 || roleid == 4 ?
                        <>
                        {tblkey == 'ref_submission_psr' ?
                          <Link to="#" className="btnCancle" onClick={() => submittobackward(4)} >Return</Link>
                          : null
                        } 
                        </>
                        : null
                      } 
                      {tblkey == 'ref_submission_psr' ?
                      <>
                        <Link to="#" className="btnCancle" onClick={() => submittoreviewer(4)} >
                        { roleid == 2 ? "Submit all to Reviewer" : null }
                        { roleid == 3 ? "Submit all to Approver" : null }
                        { roleid == 4 ? "Submit All to Nodal Agency" : null }
                        </Link>
                      </> : null
                      }
                      <button className="btnDraft" type="submit">Save as Draft</button>
                      </>
                    : null
                  }
                </div>
                {/* <button className="btnDraft" type="submit">Submit</button> */}
              </div>
            </div>
          )}
        </form>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
    </>
  );
};
