import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../RdssForms/rdssforms.scss";
import { init, validatefrm } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import MyTooltip from "./MyTooltip";

import { BiUpload } from "react-icons/bi";
import { OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useStateMounted } from "../../../Core/Hooks";

// Images
import Arrow from "../../../images/right-arrow.svg";
import FieldComponent from "../../../FieldComponent";
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import { Storage } from "../../../Core/Services/StorageService";

const ChildInput = (props: { fields: any, pIndex?: number }) => {

    const [fields, setFields] = useState<string[]>([]);

    useEffect(() => {
        if (props.fields.length) {
            setFields(props.fields);
        }
    }, [props.fields]);

    return (
        <>
            {
                (fields.length)
                    ? fields.map((field: any, index) => (
                        <td key={props.pIndex + '-' + index + '-' + field.key}>
                            <FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} />
                        </td>
                    ))
                    : null
            }
        </>
    );
}

type AccordianBlockProps = {
    title: string;
    apiurl: string;
    accordianId: string;
    class1: string;
    class2: string;
    discription?: any;
    tableHead?: any;
    refresh?: boolean;
    year?: string;
};

const AccordianBlock = (props: AccordianBlockProps) => {
    const layout = useLayout();
    // const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [fieldsa, setFieldsa] = useState<any[]>([]);
    const [tableHead, setTableHead] = useState<React.ReactNode>(null);
    const [selected, setSelected] = useState("target");
    const [error, setError] = useStateMounted<string>();

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        if (props.tableHead) {
            setTableHead(props.tableHead);
        }
    }, [props.tableHead])

    // const getFields = () => {
    //     if (props.year) {
    //         (async () => {
    //             setFields([]);
    //             let footerspinner:any = document.getElementById('footer-spinner');
    //             if ( footerspinner )
    //                 footerspinner.classList.remove('d-none');
    //             await Http.get(props.apiurl)
    //                 .then((result) => {
    //                     setIsLoaded(true);
    //                     setFields(result.fields);
    //                     setFieldsa(result.fieldsa);
    //                     layout.set({ submittedStatus: result });
    //                     setTimeout(() => {
    //                         init();
    //                     }, 500);
    //                 })
    //                 .catch((error) => {
    //                     setIsLoaded(true);
    //                     setError(error);
    //                 }).finally(() => {
    //                     footerspinner.classList.add('d-none');
    //                 })
    //         })();
    //     }
    // };

    const getFields = () => {
        if (props.year) 
        {
            (async () => {
                setFields([]);
                let footerspinner:any = document.getElementById('footer-spinner');
                if ( footerspinner )
                    footerspinner.classList.remove('d-none');
                    Http.get(props.apiurl)
                    .then((res) => {
                    if (res.resultflag && res.fields) {
                        if (res.fields && Array.isArray(res.fields)) {
                            if (!res.fields.length) setError("No data found.");
                            setIsLoaded(true);
                            setFields(res.fields);
                            setFieldsa(res.fieldsa);
                            layout.set({ submittedStatus: res });
                            
                        } else {
                            setError('System error occurred!! please try again.');
                            setIsLoaded(true);
                        }
                        setIsLoaded(true);
                    } else if (!res.resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');
                        
                    }
                    setIsLoaded(true);
                }).catch((error) => {
                    setError('System error occurred!! please try again.');
                    setIsLoaded(true);
                    
                })
                .finally(() => {
                    setIsLoaded(true);;
                        footerspinner.classList.add('d-none');
                });
            })();
        }
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <>
                <div className="accordion-item">
                    <h3 className="accordion-header" id="RevenueDetails">
                        <button className={'accordion-button ' + props.class1} type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.accordianId} aria-expanded="true" aria-controls={props.accordianId}>
                            {props.title}
                        </button>
                    </h3>
                    <div id={props.accordianId} className={'accordion-collapse collapse ' + props.class2} aria-labelledby="RevenueDetails" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            {props.discription}
                            <div className="table-responsive RevenueDetailsTable">
                                <table className="table ref-table">
                                    {tableHead}
                                    <tbody>
                                        {
                                            (fields.length)
                                                ? fields.map((field: any, index) => (
                                                    <>
                                                        <tr key={index + '-' + field.key} className="activeRow">
                                                            <td className={field.class1}>{field.key}</td>
                                                            <td className={field.class2}>
                                                                {field.caption}
                                                                { field.comments ?
                                                                <MyTooltip>
                                                                    <div dangerouslySetInnerHTML={{ __html:field.comments }}></div>
                                                                </MyTooltip>
                                                                : null
                                                            }
                                                            </td>
                                                            {
                                                                (field.childrens)
                                                                    ? <ChildInput pIndex={index} fields={field.childrens} />
                                                                    : null
                                                            }
                                                        </tr>
                                                    </>
                                                ))
                                                : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const REFScore = (props: any) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [weightages, setWeightages] = useState<any[]>([]);
    const [score, setScore] = useState<any[]>([]);

    const getFields = () => {
        if (props.year) {
            (async () => {
                setWeightages([]);
                setScore([]);
                await Http.get(props.apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setWeightages(result.weightages);
                        setScore(result.score);
                        setTimeout(() => {
                            init();
                        }, 500);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    })
            })();
        }
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    
    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                {
                    (weightages && weightages.length && score && score.length) ?
                        <div className="accordion-item">
                            <h3 className="accordion-header" id="RevenueDetails">
                                <button className={'accordion-button ' + props.class1} type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.accordianId} aria-expanded="true" aria-controls={props.accordianId}>
                                    {props.title}
                                </button>
                            </h3>
                            <div id={props.accordianId} className={'accordion-collapse collapse ' + props.class2} aria-labelledby="RevenueDetails" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {props.discription}
                                    <div className="table-responsive RevenueDetailsTable">
                                        <table className="table ref-score-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Item</th>
                                                    <th scope="col" className="text-center">Weightage</th>
                                                    <th scope="col" className="text-center">Score</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="ref-total" scope="col">REF score</td>
                                                    <td scope="col" className="text-center ref-total">{weightages[0].weightage_total}%</td>
                                                    <td scope="col" className="text-center ref-total">{score[0].score_total}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="col">Financial Stability</td>
                                                    <td scope="col" className="text-center">{weightages[0].weightage_one}%</td>
                                                    <td scope="col" className="text-center">{score[0].financial_stability}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="col">Outcome of Infrastructure Works</td>
                                                    <td scope="col" className="text-center">{weightages[0].weightage_two}%</td>
                                                    <td scope="col" className="text-center">{score[0].outcome_of_infrastructure_works}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="col">Infrastructure Works</td>
                                                    <td scope="col" className="text-center">{weightages[0].weightage_three}%</td>
                                                    <td scope="col" className="text-center">{score[0].infrastructure_works}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="col">Policy &amp; Structural Reforms</td>
                                                    <td scope="col" className="text-center">{weightages[0].weightage_four}%</td>
                                                    <td scope="col" className="text-center">{score[0].policy_structural_reforms}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </>
        );
    }
}

const RefForm = (props?: any) => {
    const layout = useLayout();
    const [year, setYear] = useState<any>(0);
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [act, setAct] = useState<string>();
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        layout.set({ pdfParams: { form_ids: '12,13,14,16' } });
    }, []);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setAct(props.act);
    }, [act]);

    useEffect(() => {
        props.tblkey('ref_submission_fs');
    }, []);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    const tableHead = <>
        <thead>
            <tr>
                <th scope="col">Sl.No.</th>
                <th scope="col">Items</th>
                <th scope="col" className="text-center">Quarter 1</th>
                <th scope="col" className="text-center">Quarter 2</th>
                <th scope="col" className="text-center">Quarter 3</th>
                <th scope="col" className="text-center">Quarter 4</th>
                <th scope="col" className="text-center">Cumulative</th>
                <th scope="col" className="text-center">Remarks</th>
            </tr>
        </thead>
    </>;

    const tableHead2 = <>
        <thead>
            <tr>
                <th className="text-center" rowSpan={2} scope="col">Sl.No.</th>
                <th className="text-center" rowSpan={2} scope="col">Parameter</th>
                <th className="text-center" rowSpan={2} scope="col">Max Marks</th>
                <th className="text-center" rowSpan={2} scope="col">Baseline</th>
                {/* <th className="text-center" scope="col">FY 2020</th>
                <th className="text-center" scope="col">FY 2021</th> */}
                {(year == '2022' || year == '2023') ? <th className="text-center" colSpan={5} scope="col">FY 2022</th> : null}

                {
                    year == '2023' || year == '2024' ? <th className="text-center" colSpan={5} scope="col">FY 2023</th> : ''
                }

                {
                    year == '2024' || year == '2025' ? <th className="text-center" colSpan={5} scope="col">FY 2024</th> : ''
                }
                {
                    year == '2025' ? <th className="text-center" colSpan={5} scope="col">FY 2025</th> : ''
                }
            </tr>
            <tr>
                {(year == '2022' || year == '2023')
                    ? <>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Target</th>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Achievement</th>
                        {
                            (roleid == process.env.REACT_APP_NDR || roleid == process.env.REACT_APP_NDA) ?
                                <th className="text-center" scope="col" style={{ width: 200 }}>As per Nodal Agency</th>
                            :null
                        }
                        <th className="text-center" scope="col">Revised Max Marks</th>
                        <th className="text-center" scope="col">Score</th>
                        <th className="text-center" scope="col" style={{minWidth:'200px'}}>Doc</th>
                    </>
                    : null
                }

                {(year == '2023' || year == '2024')
                    ? <>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Target</th>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Achievement</th>
                        {
                            (roleid == process.env.REACT_APP_NDR || roleid == process.env.REACT_APP_NDA) ?
                                <th className="text-center" scope="col" style={{ width: 200 }}>As per Nodal Agency</th>
                            :null
                        }
                        <th className="text-center" scope="col">Revised Max Marks</th>
                        <th className="text-center" scope="col">Score</th>
                        <th className="text-center" scope="col" style={{minWidth:'200px'}}>Doc</th>
                    </>
                    : null
                }

                {(year == '2024' || year == '2025')
                    ? <>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Target</th>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Achievement</th>
                        {
                            (roleid == process.env.REACT_APP_NDR || roleid == process.env.REACT_APP_NDA) ?
                                <th className="text-center" scope="col" style={{ width: 200 }}>As per Nodal Agency</th>
                            :null
                        }
                        <th className="text-center" scope="col">Revised Max Marks</th>
                        <th className="text-center" scope="col">Score</th>
                        <th className="text-center" scope="col" style={{minWidth:'200px'}}>Doc</th>
                    </>
                    : null
                }

                {(year == '2025')
                    ? <>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Target</th>
                        <th className="text-center" scope="col" style={{ width: 200 }}>Achievement</th>
                        {
                            (roleid == process.env.REACT_APP_NDR || roleid == process.env.REACT_APP_NDA) ?
                                <th className="text-center" scope="col" style={{ width: 200 }}>As per Nodal Agency</th>
                            :null
                        }
                        <th className="text-center" scope="col">Revised Max Marks</th>
                        <th className="text-center" scope="col">Score</th>
                        <th className="text-center" scope="col" style={{minWidth:'200px'}}>Doc</th>
                    </>
                    : null
                }
            </tr>
        </thead>
    </>;

    return (
        <>
            <div className="mainWrap">
                <section className="financialForm refUploadSelect">
                    {/* <div className="year">
                            <label>Select Year</label>
                            <select className="form-select" aria-label="Default select example">
                                <option value="2022" selected>2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                            </select>
                            <div className="inputBtn">
                                <span><img src={Arrow} /></span>
                                <input type="submit" />
                            </div>
                        </div> */}
                    <div className="financialMainFrom">
                        <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
                            <li className="nav-item col-md-2 ref-nav-item" role="presentation">
                                <button className="nav-link active" onClick={() => props.tblkey('ref_submission_fs')} id="tab1" data-bs-toggle="tab" data-bs-target="#tab1-content" type="button" role="tab" aria-controls="tab1" aria-selected="true">Financial Stability</button>
                            </li>
                            <li className="nav-item col-md-2 ref-nav-item" role="presentation">
                                <button className="nav-link" onClick={() => props.tblkey('ref_submission_oiw')} id="tab2" data-bs-toggle="tab" data-bs-target="#tab2-content" type="button" role="tab" aria-controls="tab2" aria-selected="false">Outcome of Infrastructure Works</button>
                            </li>
                            <li className="nav-item col-md-2 ref-nav-item" role="presentation">
                                <button className="nav-link" onClick={() => props.tblkey('ref_submission_iw')} id="tab3" data-bs-toggle="tab" data-bs-target="#tab3-content" type="button" role="tab" aria-controls="tab3" aria-selected="false">Infrastructure Works</button>
                            </li>
                            <li className="nav-item col-md-2 ref-nav-item" role="presentation">
                                <button className="nav-link" onClick={() => props.tblkey('ref_submission_psr')} id="tab4" data-bs-toggle="tab" data-bs-target="#tab4-content" type="button" role="tab" aria-controls="tab4" aria-selected="false">Policy & Structural Reforms</button>
                            </li>
                            <li className="nav-item col-md-2 ref-nav-item" role="presentation">
                                <button className="nav-link" onClick={() => props.tblkey('fpl_score')} id="tab5" data-bs-toggle="tab" data-bs-target="#tab5-content" type="button" role="tab" aria-controls="tab5" aria-selected="false">Score</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1-content" role="tabpanel" aria-labelledby="tab1">
                                <div className="accordion" id="accordionExample1">
                                    <AccordianBlock year={year} refresh={refresh} tableHead={tableHead2} apiurl={"/apiv1/formfields/?form_id=12&type=refnew&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} title="Financial Stability" class1="" class2="show" accordianId="accordian1" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2-content" role="tabpanel" aria-labelledby="tab2">
                                <div className="accordion" id="accordionExample2">
                                    <AccordianBlock year={year} refresh={refresh} tableHead={tableHead2} apiurl={"/apiv1/formfields/?form_id=13&type=refnew&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} title="Outcome of Infrastructure Works" class1="" class2="show" accordianId="accordian2" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                <div className="accordion" id="accordionExample3">
                                    <AccordianBlock year={year} refresh={refresh} tableHead={tableHead2} apiurl={"/apiv1/formfields/?form_id=14&type=refnew&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} title="Infrastructure Works" class1="" class2="show" accordianId="accordian3" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab4-content" role="tabpanel" aria-labelledby="tab4">
                                <div className="accordion" id="accordionExample4">
                                    <AccordianBlock year={year} refresh={refresh} tableHead={tableHead2} apiurl={"/apiv1/formfields/?form_id=16&type=refnew&roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} title="Policy & Structural Reforms" class1="" class2="show" accordianId="accordian4" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab5-content" role="tabpanel" aria-labelledby="tab5">
                                <div className="accordion" id="accordionExample5">
                                    <REFScore year={year} refresh={refresh} tableHead={tableHead2} apiurl={"/apiv1/refscore/?roleid=" + roleid + "&act=" + act + "&year=" + year + "&discom_user_id=" + discomuserid + "&discom_id=" + discomid} title="Score" class1="" class2="show" accordianId="accordian5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default RefForm;