import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom/client';
import { Http } from '../../Core/Services/HttpService';
// import ExcelIcn from '../../../images/excel.svg';
// import PdfIcn from '../../../images/pdf.svg';
import Select from "react-select";
import { RiFileExcel2Line } from "react-icons/ri";
import '../../css/misreport.scss';
import "../../css/style.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsSearch, BsFillFileEarmarkPdfFill, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { FiPlusCircle, FiTrash2, FiEye, FiEdit } from 'react-icons/fi'
import { Storage } from "../../Core/Services/StorageService";

const Notifications = ({ module }: any) => {
    const [notificationsData, setNotificationsData] = useState<any>();
    const [notificationsDataCount, setNotificationsDataCount] = useState<any>(0);
    const [searchText, setSearchText] = useState('');
    const [_discomuserid, setDiscomUserid] = useState<any>(0);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getpagelinkid, setPageLinkId] = useState<any>(0);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, []);

    let location = useLocation();
    let navigate = useNavigate();

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState<any>(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const fetchNotificationsData = async () => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        let params = {
            discom_user_id: _discomuserid,
            module_name: module,
            page: curPage,
            pageSize: pageSize,
        };
        await Http.get('/apiv1/notifications-data', { params })
            .then((res) => {

                setNotificationsDataCount(res.notificationsData.length);
                setNotificationsData(res.notificationsData);
                setTotalItems(res.totalRecord);
                setPageCount(Math.ceil(res.totalRecord / pageSize));
                setTotalPages(Math.ceil(res.totalRecord / pageSize));

                let cPage = (!currentPage) ? 1 : currentPage;
                setFirst((cPage - 1) * pageSize + 1);
                setLast(Math.min(res.totalRecord, cPage * pageSize));

            }).catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        if (currentPage != 0) {
            fetchNotificationsData();
        }
    }, [currentPage, searchText]);

    useEffect(() => {
        if (currentPage != 0) {
            fetchNotificationsData();
        }
    }, [currentPage, searchText]);

    useEffect(() => {
        if (_discomuserid) {
            fetchNotificationsData();
        }
    }, [_discomuserid, module])

    const view = (id: any) => {
        setPageLink('/notifications-view/' + module);
        setPageLinkId(id);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { id: getpagelinkid } });
            }
        })();
    }, [getpagelink]);

    return (
        <>
            <div className="mainWrap">
                <div className="container mb-5 mt-3">
                    <div className="dashboardInner detailedDashboard">
                        <div className="row gx-xxl-5">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="heading mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">Notifications</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="financialForm misNew">
                        <div className="mt-4">
                            <div>Displaying {first} to {last} of {totalItems}</div>
                            <div className="mt-2 financialMainFrom misReportTable">
                                <div className="tab-content p-0 border-0">
                                    <div className="table-responsive RevenueDetailsTable misreportTable notification-table">
                                        <table className="table">
                                            <thead>
                                                <tr className="text-center">
                                                    <th scope="col" className="text-start">Sr. No</th>
                                                    <th scope="col" className="text-start pl-0">Subject</th>
                                                    <th scope="col" className="text-start">Sender</th>
                                                    <th scope="col" className="text-start">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {notificationsDataCount > 0 && notificationsData.length > 0 ? notificationsData.map((itemData: any, index: any) => {
                                                    return <tr>
                                                        <td className={itemData.read_status != "1" ? 'fw-bold text-start' : "text-start"}><Link to="" onClick={() => view(itemData.id)}>{index + 1}</Link></td>
                                                        <td className={itemData.read_status != "1" ? 'fw-bold text-start' : "text-start"}><Link to="" onClick={() => view(itemData.id)}>{itemData.subject}</Link></td>
                                                        <td className={itemData.read_status != "1" ? 'fw-bold text-start' : "text-start"}><Link to="" onClick={() => view(itemData.id)}>{itemData.send_to}</Link></td>
                                                        <td className={itemData.read_status != "1" ? 'fw-bold text-start' : "text-start"}><Link to="" onClick={() => view(itemData.id)}>{itemData.date}</Link></td>
                                                    </tr>
                                                }) : <tr><td colSpan={5} className="text-center my-5"><h5>No Record Found</h5></td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    {totalItems != 0 ?
                                        <div className="mt-3">
                                            <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                                <ul className="pagination justify-content-center">
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                            <BsChevronDoubleLeft />
                                                        </button>
                                                    </li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                            <BsChevronCompactLeft />
                                                        </button>
                                                    </li>
                                                    <li className="page-item col-1">
                                                        <input type="input" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                                    </li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                            <BsChevronCompactRight />
                                                        </button>
                                                    </li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                            <BsChevronDoubleRight />
                                                        </button>
                                                    </li>

                                                </ul>
                                            </nav>
                                            <div className="page-item text-center">
                                                <span>
                                                    Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default Notifications;