import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper.min.css";
import {Navigation } from "swiper";

// Icon
import { FaRegCalendarAlt, FaRegFilePdf } from "react-icons/fa";

const PressRelease = () => {
    const Fade = require('react-reveal/Fade');
    const [press, setpress] = useState<any[]>([]);

    useEffect(() => {
        const pressArry = [
            {
                date: "29 December, 2022",
                title: "The Energy Conservation (Amendment) Act, 2022",
                size: "420kb"
            },
            {
                date: "29 December, 2022",
                title: "The Energy Conservation (Amendment) Act, 2022",
                size: "420kb"
            },
            {
                date: "29 December, 2022",
                title: "The Energy Conservation (Amendment) Act, 2022",
                size: "420kb"
            },
            {
                date: "29 December, 2022",
                title: "The Energy Conservation (Amendment) Act, 2022",
                size: "420kb"
            },
            {
                date: "29 December, 2022",
                title: "The Energy Conservation (Amendment) Act, 2022",
                size: "420kb"
            },
          
        ];
        setpress(pressArry);
    }, []);

    return (
        <>
            <div className="col-xl-4 col-xxl-4" data-aos="fade-right"
     data-aos-duration="1000">
                <div className="reportHeading d-flex align-items-center justify-content-between">
                    <h2 className="mb-0">Press Release</h2>
                    <div className="viewAll d-flex align-items-center">
                        <Link to="/" title="View All">View All</Link>
                        <div className="mediaNav">
                            <div className="press-prev prev" />
                            <div className="press-next next" />
                        </div>
                    </div>
                </div>
                    <Swiper
                        direction={"vertical"}
                        slidesPerView={3}
                        spaceBetween={0}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: '.press-prev',
                            nextEl: '.press-next',
                        }}
                        className="pressSwiper"
                    >
                        {
                            press.map((press, index) => {
                            return <>
                                <SwiperSlide key={index}>
                                    <Link to="/" title={press.title}>
                                        <div className="pressRelease">
                                            <div className="pdfDetails">
                                                <span><FaRegCalendarAlt />{press.date}</span>
                                                <p>{press.title} <span>({press.size})</span></p>
                                            </div>
                                            <div className="pdfIcon">
                                                <FaRegFilePdf />
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>     
                            </>
                            })
                        }               
                    </Swiper>
            </div>
        </>
    );
};

export default PressRelease;