import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
import { useLocation, useNavigate } from "react-router-dom";
import { useStateMounted } from '../../../../Core/Hooks';
import GrantReleasedReportsDiscom from './GrantReleased/GrantReleasedDiscomReports';
import GrantReleasedReportsDistrict from './GrantReleased/GrantReleasedDistrictReports';
import { fyByStartEnd,yearByStartEnd } from '../../../../Core/Utility/Datetime';
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

const GrantReleasedReports = (props: any) => {
    const [selected, setSelected] = useState<any>(0);
    const [discoms, setDiscoms] = useState<any[]>([]);
    const [nodalAgency, setNodalAgency] = useState<any>(0);
    const [error, setError] = useState(null);
    const [apiurl, setApiurl] = useState<any>(null);
    const [defaultDiscom, setDefaultDiscom] = useState<any>(0);
    const [defaultTab, setDefaultTab] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomId] = useState<any>(0);
    const [years, setYears] = useStateMounted<[{ label: string, value: string }] | []>([]);
    const [year, setYear] = useState<any>(2021);
    const [roleid, setRoleid] = useState<any>(0);
    const [dropDownYearValue, setDropDownYearValue] = useState<any>('2021-2022');

    const location = useLocation();
    let navigate = useNavigate();
    const changeLrTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            const fyearList: any = fyByStartEnd("2021", new Date().getFullYear().toString());
            await fyearList.map((item: any) => {
                item.label = `${item.label} - ${(parseInt(item.label) + 1)}`;
                return item;
            });
            setYears(fyearList);

            let currentYearValue = new Date().getFullYear();
            const d = new Date();
            if (d.getMonth() > 2) {
                currentYearValue++;
            }
            setYear(currentYearValue-1);
            let currentYear = (currentYearValue-1);
            setDropDownYearValue(currentYear);

            setSelected("GrantReleasedReportsDiscom");
        })();
    }, [roleid]);


    const onChangeYear = (e: any) => {
         if (props.year !== e.target.value){
            setYear(e.target.value);
            setDropDownYearValue(e.target.value);
        }
    };

    const changeTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            if (location.state) {
                setDefaultTab(location.state.sub_module_type);
                setNodalAgency(location.state.nodal_agency)
            }
        })();
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomuserid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            <div className='col-lg-12 col-xl-6 col-xxl-7'>
                                                <div className="radioYear">
                                                    <div className="row justify-content-center align-items-center mx-0 gx-0">
                                                        {(roleid == 5 || roleid == 6) ?
                                                            <div className="col-6 col-md-12">
                                                                <div className="form-check">
                                                                    <input type="radio" className="form-check-input" name="GrantReleasedReportsDiscom" value="GrantReleasedReportsDiscom" id="GrantReleasedReportsDiscom" checked={selected === "GrantReleasedReportsDiscom"} onChange={changeLrTab} />
                                                                    <label className="form-check-label" htmlFor="GrantReleasedReportsDiscom">Discom</label>
                                                                </div>
                                                            </div>

                                                            : null
                                                        }

                                                        {/* {(roleid == 5 || roleid == 6) ?
                                                            <>
                                                                <div className="col-3 col-md-6">
                                                                    <div className="form-check">
                                                                        <input type="radio" className="form-check-input" name="GrantReleasedReportsDistrict" value="GrantReleasedReportsDistrict" id="GrantReleasedReportsDistrict" checked={selected === "GrantReleasedReportsDistrict"} onChange={changeLrTab} />
                                                                        <label className="form-check-label" htmlFor="GrantReleasedReportsDistrict">District</label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-xl-6 col-xxl-4 ps-2'>
                                                <div className='row justify-content-end align-items-center gx-2'>
                                                    <div className='col-md-3'>
                                                        <label style={{ "width": "300px" }}>Select Year (FY)</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <select
                                                            onChange={onChangeYear}
                                                            value={dropDownYearValue}
                                                            className="form-select" aria-label="Select Year (FY)">
                                                            {
                                                                years.map((year, index) => {
                                                                    return (
                                                                        <option key={index} value={year.value}>{year.label}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div aria-hidden={selected !== "GrantReleasedReportsDiscom" ? true : false}><GrantReleasedReportsDiscom year={year} nodalAgency={nodalAgency} /></div>
                                    {/* <div aria-hidden={selected !== "GrantReleasedReportsDistrict" ? true : false}><GrantReleasedReportsDistrict year={year} nodalAgency={nodalAgency} /></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? <LoadingSpinner /> : null}
                </section>
                <Footer remove='d-none' />
            </div>
        </>
    );
};

export default GrantReleasedReports;