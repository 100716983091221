import { Stepper, Step } from "react-form-stepper";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import InnerTemplate from "../../Components/InnerTemplate/InnerTemplate";
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import swal from 'sweetalert';
import FormSteps from "../../Components/InputForms/Section1/FormStep"
import Section2 from "../../Components/InputForms/Section2/Section2"

// Forms
import { Http } from "../../Core/Services/HttpService";
import PQForm from "../../Components/EvaluationForms/PQForm";
import SopForm from "../../Components/EvaluationForms/SopForm";
import RefForm from "../../Components/InputForms/Section1/RefForm";
import FinancialForm from "../../Components/InputForms/Section1/FinancialForm";
import EnergyForm from "../../Components/InputForms/Section1/EnergyForm";
import Arrow from "../images/right-arrow.svg";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { Storage } from "../../Core/Services/StorageService";

export default function EvaluationForm(props: any) {
    return (
        <div className="App">
            <PlaceOrder />
        </div>
    );
}

export const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
  }
  
export const Discomhistory = (props: any) => {
    // return <></>;
    const layout = useLayout();
    const [discomhistory, setDiscomHistory] = useState<any>([]);
    let layoutDataSubscribe: any;

    let discomHistoryNo = 0;

    useEffect(() => {
        (async () => {
            layoutDataSubscribe = await layout.data.subscribe((res: any) => {
                if (res.submittedStatus.discom_history)
                    setDiscomHistory(res.submittedStatus.discom_history)
            });
        })();
        return () => {
            if (layoutDataSubscribe !== undefined) {
                layoutDataSubscribe.unsubscribe();
            }
        }
    }, []);

    return (
        <>
            {(discomhistory.length > 0) ?
                <div className="accordion-body">
                    <div className="table-responsive RevenueDetailsTable actionHistory">
                        <h4 className="accordion-header blankPara">Action History</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Sender</th>
                                    <th>Receiver</th>
                                    <th>Action</th>
                                    <th>Remark</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(discomhistory.length > 0) ?
                                    discomhistory.map((item: any, key: number) => (
                                        <>
                                            <tr key={key}>
                                                <td>{(discomHistoryNo++) + 1}</td>
                                                <td>{item.sender}</td>
                                                <td>{item.receiver}</td>
                                                <td>{item.action}</td>
                                                <td>{item.remark}</td>
                                                <td>{item.date}</td>
                                            </tr>
                                        </>
                                    ))
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                : null
            }
        </>
    );
}

export const PlaceOrder = (props: any) => {
    const formData: any = useRef(null);
    const layout = useLayout();
    const syear: any = useRef(null);
    const [year, setYear] = useState("2022");
    const [tblkey, setTblkey] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [selected, setSelected] = useState("Section1");
    const [steps, setSteps] = useState(['active','','','']);

    const refreshform = () => {
        setYear(syear.current.value);
        setSelected("Section1");
    }
    const [isSubmittedStatus, setSubmittedStatus] = useState('');
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [remarks, setRemarks] = useState('');

    let layoutDataSubscribe: any;
    const [roleid, setRoleid] = useState<any>(0);

    useEffect(() => {
        console.log(tblkey);

    }, [tblkey]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        (async () => {
            layoutDataSubscribe = await layout.data.subscribe((res: any) => {
                setSubmittedStatus(res.submittedStatus.submittedStatus);
                setRemarks(res.submittedStatus.remarks);
            });
        })();
        return () => {
            if (layoutDataSubscribe !== undefined) {
                layoutDataSubscribe.unsubscribe();
            }
        }
    }, []);


    function onSubmit(e: any) {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        // console.log(validatefrm());
        let myFormData = new FormData(e.target);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', tblkey);
        myFormData.append('discom_id', discomid);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        Http.post('/apiv1/savedraftevaluationform', myFormData, config)
            .then((res) => {
                setIsLoading(false);
                // window.location.reload();
                setRefresh(true);
                swal("Input Form", "Data have been saved", "success");
            });

    }

    // const next = (opt: number) => {
    //     const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    //     let myFormData = new FormData(formData.current);
    //     myFormData.append('discom_user_id', discomuserid);
    //     myFormData.append('roleid', roleid);
    //     myFormData.append('year', year);
    //     myFormData.append('form_step_no', tblkey);
    //     myFormData.append('discom_id', discomid);
    //     Http.post('/apiv1/savedraftevaluationform', myFormData, config)
    //     .then((res) => {
    //         // console.log(res);
    //     });

    //     setGoSteps(opt);
    // }

    const submittoreviewer = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);

        swal({
            title: "Are you sure to submit this form?",
            text: "After submit, You will not able to edit this form.",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Input Form!',
                    text: 'Your data has been submitted.',
                    icon: 'success'
                }).then(function () {
                    Http.post('/apiv1/forward', myFormData, config)
                        .then((res) => {
                            setIsLoading(false);
                            setRefresh(true);
                            // swal("Input Form", "Data has been forwarded", "success");
                        });
                });
            } else {
                setIsLoading(false);
                // swal("Cancelled", "Your data has been is safe :)", "error");
            }
        });

    }
     // Nav Steps
    const navtab = (opt: number) => {
        console.log(opt);
            if ( opt == 0 )
            setSteps(['active','','','']);
            if ( opt == 1 )
            setSteps(['completed','active','','']);
            if ( opt == 2 )
            setSteps(['completed','completed','active','']);
            if ( opt == 3 )
            setSteps(['completed','completed','completed','active','']);
            if ( opt == 4 )
            setSteps(['completed','completed','completed','completed','active']);
            setGoSteps(opt);
        }
    const next = (opt: number) => {
        if ( opt == 0 )
        setSteps(['active','','','']);
        if ( opt == 1 )
        setSteps(['completed','active','','']);
        if ( opt == 2 )
        setSteps(['completed','completed','active','']);
        if ( opt == 3 )
        setSteps(['completed','completed','completed','active','']);
        if ( opt == 4 )
        setSteps(['completed','completed','completed','completed','active']);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        // myFormData.append('year', year);
        Http.post('/apiv1/next-form', myFormData, config)
        .then((res) => {
            // console.log(res);
        });

        setGoSteps(opt);
  }

    const submittobackward = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);

        swal({
            title: "Are your sure want to return this form?",
            text: "",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/backward', myFormData, config)
                    .then((res) => {
                        if (res._resultflag == 0) {
                            setIsLoading(false);
                            swal("Warning", res.message, "info");
                        } else {
                            setIsLoading(false);
                            setRefresh(true);
                            swal("Input Form", "The form has been returned for correction", "success");
                        }
                    });

            } else {
                setIsLoading(false);
                // swal("Cancelled", "Your data has been is safe :)", "error");
            }
        });

    }

    const [goSteps, setGoSteps] = useState(0);

    useEffect(() => {
        setSelected("Section1");
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };
    const sanctionLetterMainForm = () => {
        document.body.classList.add('sanctionLetterMainForm');
    }
    const FinancialMainForm = () => {
        document.body.classList.remove('sanctionLetterMainForm');
    }

    console.log(tblkey);
    
    return (
        <>
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">   
                                    <div>

                                        {/* <Stepper activeStep={goSteps}>
                                            <Step onClick={() => setGoSteps(0)} label="Financial" />
                                            <Step onClick={() => setGoSteps(1)} label="PQ" />
                                            <Step onClick={() => setGoSteps(2)} label="SoP" />
                                            <Step onClick={() => setGoSteps(3)} label="Energy" />
                                            <Step onClick={() => setGoSteps(4)} label="REF" />
                                        </Stepper> */}
                                        <div className='container'>
                                            <div className='customStepper'>
                                                <div className="StepperContainer-0-2-1">
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 "+(steps[0] ? steps[0] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 "+(steps[0] ? steps[0] : '')}>1</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Financial</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 "+(steps[1] ? steps[1] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 "+(steps[1] ? steps[1] : '')}>2</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">PQ</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d2-0-2-22"><span className="Connector-0-2-15 Connector-d3-0-2-23"></span></div>
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(2)} className={"StepButton-0-2-4 StepButton-d4-0-2-20 "+(steps[2] ? steps[2] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d5-0-2-21 "+(steps[2] ? steps[2] : '')}>3</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d4-0-2-24"><span className="Label-0-2-9 Label-d5-0-2-25">SoP</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(3)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 "+(steps[3] ? steps[3] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 "+(steps[3] ? steps[3] : '')}>4</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Energy</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(4)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 "+(steps[4] ? steps[4] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 "+(steps[4] ? steps[4] : '')}>5</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">REF</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form noValidate onSubmit={onSubmit} ref={formData}>
                                            {goSteps === 0 && (
                                                <div>
                                                    <div>
                                                        <FinancialForm year={year} act="view" refresh={refresh} tblkey={setTblkey} />
                                                    </div>
                                                    {roleid != '2' ?
                                                        <>
                                                            <div>
                                                                <div className="form-group mb-4">
                                                                    <label className="d-block" htmlFor="remarks">Remarks</label>
                                                                    <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                    <Discomhistory />
                                                    <div className="formBtnSection justify-content-between">
                                                        <div></div>
                                                        {
                                                            isSubmittedStatus == "1"
                                                                ?
                                                                <>
                                                                    {roleid == 5 || roleid == 6 ?
                                                                            <>
                                                                                {tblkey == 'fpl_key_parameters' ?
                                                                                    <Link to="#" className="btnCancle" onClick={() => submittobackward(1)} >Return</Link>
                                                                                    : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }
                                                            
                                                                    {tblkey == 'fpl_key_parameters' ?
                                                                        <>
                                                                              {roleid == 5 || roleid == 6 ?
                                                                                <>
                                                                                    <Link to="#" className="btnCancle" onClick={() => submittoreviewer(1)} >
                                                                                        {roleid == 5 ? "Submit to Approver" : null}
                                                                                        {roleid == 6 ? "Approve" : null}
                                                                                    </Link>
                                                                                </>
                                                                                    : null
                                                                                }
                                                                        </>
                                                                        : null
                                                                    }

                                                                    {roleid == 5 || roleid == 6 ?
                                                                        <>
                                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 1 && (
                                                <div>
                                                    <div>
                                                        <PQForm year={year} refresh={refresh} tblkey={setTblkey} />
                                                    </div>
                                                    {roleid != '2' ?
                                                        <>
                                                            <div>
                                                                <div className="form-group mb-4">
                                                                    <label className="d-block" htmlFor="remarks">Remarks</label>
                                                                    <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                    <Discomhistory />
                                                    <div className="formBtnSection justify-content-between">
                                                        <Link to="#" className="btn btnSubmit me-4" onClick={() => next(0)}>Previous</Link>
                                                        {roleid == 5 || roleid == 6 ?
                                                                        <>
                                                                {isSubmittedStatus == "1" ?
                                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                                        : null
                                                                }

                                                        </> : null
                                                        }
                                                        <Link to="#" className="btn btnSubmit" onClick={() => next(2)}>Next</Link>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 2 && (
                                                <div>
                                                    <div>
                                                        <SopForm year={year} refresh={refresh} tblkey={setTblkey} />
                                                    </div>
                                                    {roleid != '2' ?
                                                        <>
                                                            <div>
                                                                <div className="form-group mb-4">
                                                                    <label className="d-block" htmlFor="remarks">Remarks</label>
                                                                    <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                    <Discomhistory />
                                                    <div className="formBtnSection justify-content-between">
                                                        <Link to="#" className="btn btnSubmit me-4" onClick={() => next(1)}>Previous</Link>
                                                        {
                                                            isSubmittedStatus == "1"
                                                                ?
                                                                <>
                                                                    {roleid == 5 || roleid == 6 ?
                                                                            <><Link to="#" className="btnCancle" onClick={() => submittobackward(3)} >Return</Link></>
                                                                            : null
                                                                    }
                                                                    
                                                                    {roleid == 5 || roleid == 6 ?
                                                                        <>
                                                                            <Link to="#" className="btnCancle" onClick={() => submittoreviewer(3)} >
                                                                                {roleid == 5 ? "Submit to Approver" : null}
                                                                                {roleid == 6 ? "Approve" : null}
                                                                            </Link>
                                                                            <button className="btnDraft" type="submit">Save as Draft</button>
                                                                        </> : null
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        <Link to="#" className="btn btnSubmit" onClick={() => next(3)}>Next</Link>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 3 && (
                                                <div>
                                                    <div>
                                                        <EnergyForm year={year} refresh={refresh} act="view" tblkey={setTblkey} />
                                                    </div>
                                                    {roleid != '2' ?
                                                        <>
                                                            <div>
                                                                <div className="form-group mb-4">
                                                                    <label className="d-block" htmlFor="remarks">Remarks</label>
                                                                    <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                    <Discomhistory />
                                                    <div className="formBtnSection justify-content-between">
                                                        <Link to="#" className="btn btnSubmit me-4" onClick={() => next(2)}>Previous</Link>
                                                        {
                                                            isSubmittedStatus == "1"
                                                                ?
                                                                <>
                                                                    {
                                                                        roleid == 5 || roleid == 6 ?
                                                                            <>
                                                                                {tblkey == 'es_consumer_category_volume' ?
                                                                                    <Link to="#" className="btnCancle" onClick={() => submittobackward(4)} >Return</Link>
                                                                                    : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }

                                                                    {roleid == 5 || roleid == 6 ?
                                                                        <>
                                                                            {tblkey == 'es_consumer_category_volume' ?
                                                                                <>
                                                                                    <Link to="#" className="btnCancle" onClick={() => submittoreviewer(4)} >
                                                                                        {roleid == 5 ? "Submit to Approver" : null}
                                                                                        {roleid == 6 ? "Approve" : null}
                                                                                    </Link>
                                                                                </>
                                                                                : null
                                                                            }
                                                                            <button className="btnDraft" type="submit">Save as Draft</button>
                                                                        </> : null
                                                                    }
                                                                   
                                                                </>
                                                                : null
                                                        }
                                                        <Link to="#" className="btn btnSubmit" onClick={() => next(4)}>Next</Link>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 4 && (
                                                <div>
                                                    <div>
                                                        <RefForm year={year} refresh={refresh} act="view" tblkey={setTblkey} />
                                                    </div>
                                                    {roleid != '2' ?
                                                        <>
                                                            <div>
                                                                <div className="form-group mb-4">
                                                                    <label className="d-block" htmlFor="remarks">Remarks</label>
                                                                    <textarea className="w-100" id="remarks" defaultValue={remarks} name="remarks"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                    <Discomhistory />
                                                    <div className="formBtnSection justify-content-between">
                                                        <Link to="#" className="btn btnSubmit me-4" onClick={() => next(3)}>Previous</Link>
                                                        {
                                                            isSubmittedStatus == "1"
                                                                ?
                                                                <>
                                                                    {
                                                                        roleid == 5 || roleid == 6 ?
                                                                            <>
                                                                                {tblkey == 'ref_submission_psr' ?
                                                                                    <Link to="#" className="btnCancle" onClick={() => submittobackward(5)} >Return</Link>
                                                                                    : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }

                                                                        {roleid == 5 || roleid == 6 ?
                                                                        <>
                                                                            {tblkey == 'ref_submission_psr' ?
                                                                                <>
                                                                                    <Link to="#" className="btnCancle" onClick={() => submittoreviewer(5)} >
                                                                                        {roleid == 5 ? "Submit All to Approver" : null}
                                                                                        {roleid == 6 ? "Approve All" : null}
                                                                                    </Link>
                                                                                </>
                                                                                : null
                                                                            }
                                                                            <button className="btnDraft" type="submit">Save as Draft</button>
                                                                        </> : null
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        {/* <Link to="#" className="btn btnSubmit">Submit</Link> */}
                                                    </div>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
        </>
    );
};
