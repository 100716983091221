import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect } from "../../../../assets/js/formbuilder";
import { Http } from '../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import {BiArrowBack, BiDownload, BiUpload} from 'react-icons/bi';
import swal from 'sweetalert';
import InnerTemplate from "../../../../Components/InnerTemplate/InnerTemplate";
import Header from "../../../../Layout/Default/Element/Header";
import Footer from "../../../../Layout/Default/Element/Footer";

// Images
import FieldComponent from "../../../../FieldComponent";
import {useStateMounted} from "../../../../Core/Hooks";
import { Storage } from "../../../../Core/Services/StorageService";

type SmConsumerDetailsProps = {
    apiurl: string;
};

const Block2 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
      if (props.field6) {
          setHasBlock2FieldsDeletes(false);
          setBlock2fields([]);
          setNewBlock2fields(props.field6.addnew);
          setTimeout(() => {
              const fields2 = props.field6.childrens.map((items:  any) => {
                  return {
                      fields: items,
                      delete: false
                  }
              });
              setBlock2fields(fields2);
              initselect();
              multijsoninit();
          }, 1000);
      }
  }, [props.field6]);

    useEffect(() => {
        if (block2Fields.length) {
            //console.log(block2Fields);
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });
        
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }
    

    return (
        <>
            <table className="table caption-top">                        
                <thead className='text-center'>
                    <tr>
                      <th style={{ minWidth: "50px" }}>S. No.</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>LGD Code</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>State Name</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>District Name</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Block/Sub-Division</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Village Name</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Village Census Code</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Area (Rural/Urban)</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Consumer No.</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Consumer Name</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Gender (Male/Female)</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Meter No.</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>APL_BPL</th>
                      <th style={{ minWidth: "120px", width: '120px', maxWidth:'120px' }}>Recharge Date</th>
                        {/*<th rowSpan={2} className='text-end pe-1' style={{ width: "30px" }}>
                            <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        </th>*/}
                    </tr>

                </thead>
                <tbody>
                    {
                        block2Fields.map((item: any, key: number) => {
                            return (item.delete === false) ? <>
                                <tr key={key}>
                                    <td className="text-center" >{(block2SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                            </>;
                                        })
                                    }
                                    {/*<td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>*/}
                                    </tr>
                            </>
                                : null;
                        })
                    }

                    <>
                     {
                         (block2Fields.length && props.field4)
                             ? <>
                                 <tr>
                                     <td colSpan={8} className="text-center"><h5>Total</h5></td>
                                     {/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[0].key} field={props.field4.childrens[0]} /></td>
                                     <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[1].key} field={props.field4.childrens[1]} /></td>
                                     */}
                                     <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[2].key} field={props.field4.childrens[2]} /></td>
                                 </tr>
                             </>
                             : null
                     }
                 </> 

                </tbody>
            </table>
        </>
    );
}

const SmConsumerDetailsForm = (props: any) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const [hasformerrors, setFormErrors] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any[]>([]);
    const [getdistricts, setGetDistricts] = useState<any[]>([]);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [districtid, setDistrict] = useState<any>(0);
    const [year, setYear] = useState<any>(0);
    const [month, setMonth] = useState<any>(0);
    const [indexid, setIndexId] = useState<any>(0);
    const [sampleFileLink, setSampleFileLink] = useStateMounted('#');


    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);

    const location = useLocation();
    let navigate = useNavigate();
    useEffect(() => {
        (async () => {
            if (location.state) {
                //console.log(location.state);
                setDistrict(location.state.district);
                setYear(location.state.year);
                setMonth(location.state.month);
                setIndexId(location.state.indexid);
            }
        })();
    }, []);

    const handleFileInput = (e: any) => {
        setRefresh(false);
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) footerspinner.classList.remove('d-none');
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("file_to_upload", e.target.files[0]);
        formData.append("role_id", roleid);
        formData.append("discom_id", discomid);
        formData.append("form_id", "71");
        formData.append("district", districtid);
        formData.append("year", (['Jan','Feb','Mar'].includes(month)) ? year : (year - 1));
        formData.append("month", month);
        formData.append("indexid", indexid);
        setFields([]);
        Http.post('/apiv1/smcdexcelfileimport', formData, config)
          .then((result) => {
            if ( result.resultflag == "1" )  {
                setFields(result.fields);
                setTimeout(() => {
                    initselect();
                    multijsoninit();
                }, 2000);
                ( result.fields[0].errors ) ? setFormErrors(true) : setFormErrors(false);
                swal("Consumer Details", "File uploaded successfully.", "success");
            } else {
                setFormErrors(true);
                swal("Error", result.message, "error");
            }
        }).finally(() => {
            if (footerspinner) footerspinner.classList.add('d-none');
            setRefresh(true);
        });
    };

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setRoleid(Storage.get('roleid'));
    }, []);

    const getDistricts = () => {
        (async () => {
            if(apiurl) {
            await Http.get(apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setDistricts(result.district);
                    setTimeout(() => {
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                });
            }
        })();
    };

    useEffect(() => {
        if(discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id="+discomid);
        }
    }, [apiurl, discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

    useEffect(() => {
      (async () => {
          setDistrictsDefaultValue(districtsresult);
          setTimeout(() => {
              init();
          }, 500);
      })();
  }, [districtsresult]);

    const consumerDetailsFetch = async (optparams?: {}) => {
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) footerspinner.classList.remove('d-none');
        await Http.get(props.apiurl+"&district_id="+districtid+"&year="+year+"&month="+month)
            .then((result) => {
                if (footerspinner) footerspinner.classList.add('d-none');
                setIsLoaded(true);
                setFormErrors(true);
                setFields(result.fields);
                setDistrictsResult(result.district_id);
                setTimeout(() => {
                    init();
                    initselect();
                }, 1000);
            })
            .catch((error) => {
                if (footerspinner) footerspinner.classList.add('d-none');
                setIsLoaded(true);
                setFormErrors(false);
                setError(error);
            })
    };

    useEffect(() => {
        if(districtid && year && month)
            consumerDetailsFetch();
    }, [props.apiurl+"&district_id="+districtid+"&year="+year+"&month="+month]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // setIsLoading(true);
        // setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districtid);
        myFormData.append('year', year);
        myFormData.append('month', month);
        Http.post('/apiv1/save-monitoring-details-data', myFormData, config)
          .then((res) => {
            if(res._resultflag == 0){
              swal("Warning", res.message, "info");
            }else{
              swal("Input Form", "Data have been saved", "success");
            }
          });
      };

    useEffect(() => {
        setSampleFileLink(process.env.REACT_APP_API_ENDPOINT + '/uploads/rdss-excel-sample/RDSSMonitoringConsumerDetailsSample.xlsx');
    }, []);

    const onBack = () => {
        navigate('/progress-details', { state: { year: year, month: month, tab: 'SmartMetering', district: districtid, smCurrentPage: location?.state?.smCurrentPage ?? 0 } });
    };

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                {
                    (fields && roleid != 0) ?
                        <>
                            <form noValidate onSubmit={onSubmit} ref={formData}>
                                <div className='radioYear h-100'>
                                    <div className='row justify-content-center align-items-center p-2'>
                                        <div className='col-md-3'>
                                            <select className='form-select text-start w-100' name="financial_year" id="financial_year">
                                                <option value={year} selected>{year}</option>
                                            </select>
                                        </div>
                                        <div className='col-md-3'>
                                            <select className='form-select text-start w-100' name="month" id="month">
                                                <option value={month} selected>{month}</option>
                                            </select>
                                        </div>
                                        <div className='col-md-3'>
                                            {
                                                districts ? <>
                                                        <select className='form-select text-start w-100' name="district_id" id="district_id">
                                                            {
                                                                districts.map((district: any) => {
                                                                    return (district.id == districtid) ?
                                                                        <> <option value={district.id} selected>{district.district_name}</option> </>
                                                                        :
                                                                        null
                                                                        ;
                                                                })
                                                            }
                                                        </select>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="form-field">
                                                <div className="fileUpload position-relative text-center">
                                                    <div className="downEqule">
                                                        <input type="file" onChange={handleFileInput} data-prefix="fpl_expenditure_details" className="form-control"/>
                                                        <div className="customUpload justify-content-center flex-wrap">
                                                            <span><BiUpload/></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className="customDownload justify-content-center flex-wrap text-center">
                                                <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-start mt-3 text-danger">
                                    Data for this form can only be uploaded through an Excel file. Please download the sample file and upload it to the portal for data entry.
                                </div>
                                <div className="table-responsive RevenueDetailsTable PqTable">
                                    <Block2 field6={fields[0]} field4={fields[1]} />
                                </div>
                                <div className="row">
                                    <div className="formBtnSection justify-content-between is-sticky">
                                        {
                                            hasformerrors ?
                                                <>
                                                    {/* <button className="btn btnSubmit disabled">Submit</button> */}
                                                </>
                                                :
                                                <>
                                                    <button className="btn btnSubmit" type="submit">Submit</button>
                                                </>
                                        }
                                    </div>
                                </div>
                            </form>
                        </>
                        : null
                }
                <a onClick={onBack} className="text-decoration-none pe-cursor w-100 text-center d-block text-white" style={{backgroundColor: '#1b2f68', borderRadius: '5px', padding: '10px 20px', maxWidth:'140px', marginLeft:'auto'}}>
                    <em className="arrow"><BiArrowBack/></em> Go Back
                </a>
            </>
        );
    }
};

const SmConsumerDetailsForms = (props: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
    }, []);

    return (
        <>
    <div className="mainWrap">
        <Header />
        <InnerTemplate PageTitle="Consumer Details" />
                <section className="financialForm">
                        <div className="container">
                            <div className="financialMainFrom">
                                <div className="tab-content border-0 p-0">
                                    <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                        <SmConsumerDetailsForm apiurl={"/apiv1/formfields/?form_id=71&roleid=" + roleid+"&discom_id=" + discomid}  />
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>
        <Footer />
        </div >
        </>
    )
}
export default SmConsumerDetailsForms;