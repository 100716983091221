
import { useImageViewer } from "react-image-viewer-hook";
import { Link } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper.min.css";
import {Navigation } from "swiper";

// Images
import Media from "../../../images/media.jpg"
import Media2 from "../../../images/media-2.jpg"
import "../PressRelease/pressrelease.scss"

const IMAGES = [
    Media,
    Media2,
    Media,
    Media2
];

export default function App() {
    const { getOnClick, ImageViewer } = useImageViewer();
    return (
        <>
            <div className="col-xl-4 col-xxl-4 col-md-6" data-aos="fade-left"
     data-aos-duration="1200">
                <div className="reportHeading d-flex align-items-center justify-content-between">
                    <h2 className="mb-0">Media Gallery</h2>
                    <div className="viewAll d-flex align-items-center">
                        <Link to="/" title="View All">View All</Link>
                        <div className="mediaNav">
                            <div className="media-prev prev" />
                            <div className="media-next next" />
                        </div>
                    </div>
                </div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        modules={[Navigation]}
                        breakpoints={{
                            575: {
                                slidesPerView: 1,
                            },
                            360: {
                                slidesPerView: 1,
                            }
                        }}
                        navigation={{
                            prevEl: '.media-prev',
                            nextEl: '.media-next',
                        }}
                        className="pressSwiper mediaSwiper"
                    >                    
                            {IMAGES.map((src) => (
                                <SwiperSlide key={src}>
                                <a
                                    
                                    href={`${src}?auto=compress&cs=tinysrgb&w=1200`}
                                    onClick={getOnClick(`${src}?auto=compress&cs=tinysrgb&w=1200`)} title="Media Gallery"
                                >
                                    <img src={`${src}?auto=compress&cs=tinysrgb&w=300`} className="img-fluid" alt="Media" />
                                </a>
                                </SwiperSlide>
                            ))}
                        
                    </Swiper>
            </div>


            <ImageViewer />
        </>
    );
}
