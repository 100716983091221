/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import _ from 'lodash';

export type RouteProps = {
  path: string;
  to?: string;
  private?: boolean;
  element?: React.ReactNode | JSX.Element | any | null;
  isAuthTo?: string;
  props?: any;
  redirect?: string;
  index?: boolean;
  middleware?: JSX.Element | React.ReactNode | any | null;
}

export type MiddlewareProps = {
  location?: object;
  routes?: RouteProps[];
  route?: RouteProps;
  children?: any;
};

export type RouteMiddlewareProps = {
  location?: object;
  route?: RouteProps;
  children?: any;
};

type RoutesProps = {
  routes: RouteProps[];
  isAuthorized?: boolean;
  redirect?: string;
  location?: object;
  layout?: JSX.Element | React.ReactNode | any | null;
  loading?: any;
  middleware?: JSX.Element | React.ReactNode | any | null;
  notFound: string;
  defaultProps?: object;
}

const propTypes = {
  routes: PropTypes.array.isRequired,
  isAuthorized: PropTypes.bool,
  redirect: PropTypes.string,
  location: PropTypes.object,
  layout: PropTypes.any,
  loading: PropTypes.any,
  middleware: PropTypes.any,
  notFound: PropTypes.string.isRequired,
  defaultProps: PropTypes.object,
};

// Blank Middleware
const BlankMiddleware = ({ children }: any) => <>{children}</>;

function Routes(props: RoutesProps) {

  let {
    routes,
    isAuthorized,
    location,
    redirect,
    layout,
    loading,
    middleware,
    notFound,
    defaultProps,
  } = props;

  const Layout = (layout) ? layout : <Outlet />;
  loading = (loading) ? loading : <></>;
  const Middleware = (middleware)
    ? (
      (typeof middleware === 'function')
        ? middleware
        : BlankMiddleware
    ) : BlankMiddleware;


  return (
    <ReactRoutes>
      <Route element={((typeof Layout) === 'function') ? <Layout location={location} /> : Layout}>
        <>
          {
            routes.map((route, i: number) => {
              const authTo = (route.redirect) ? route.redirect : ((redirect) ? redirect : '');
              let routeProps = _.omit(route, ['element', 'private', 'props']);
              const RouteMiddleware = (route.middleware && (typeof route.middleware === 'function')) ? route.middleware : BlankMiddleware;
              routeProps = (defaultProps) ? _.assign(defaultProps, routeProps) : routeProps;
              const elementProps = _.assign(routeProps, route.props);
              if (route.to) {
                return <Route key={i} {...routeProps} element={
                  (
                    <Middleware location={location} routes={routes} route={route}>
                      {
                        (route.private)
                          ? (
                            (isAuthorized)
                              ? <Navigate replace={true} to={route.to} state={{ from: location, path: route.path }} />
                              : <Navigate to={authTo} state={{ from: location }} />
                          )
                          : (
                            (route.isAuthTo && isAuthorized)
                              ? <Navigate replace={true} to={route.isAuthTo} state={{ from: location }} />
                              : <Navigate replace={true} to={route.to} state={{ from: location }} />
                          )
                      }
                    </Middleware>
                  )
                } />;
              } else if (route.private) {
                return <Route key={i} {...routeProps} element={
                  (
                    <Middleware location={location} routes={routes} route={route}>
                      {
                        (isAuthorized)
                          ? <RouteMiddleware location={location} route={route}>
                            <Suspense fallback={loading}><route.element {...elementProps} /></Suspense>
                          </RouteMiddleware>
                          : <Navigate to={authTo} state={{ from: location }} />
                      }
                    </Middleware>
                  )
                } />;
              } else {
                return <Route key={i} {...routeProps} element={
                  (
                    <Middleware location={location} routes={routes} route={route}>
                      {
                        (route.isAuthTo && isAuthorized)
                          ? <Navigate replace={true} to={route.isAuthTo} state={{ from: location }} />
                          : <RouteMiddleware location={location} route={route}>
                            <Suspense fallback={loading}><route.element {...elementProps} /></Suspense>
                          </RouteMiddleware>
                      }
                    </Middleware>
                  )
                } />
              }
            })
          }
          <Route path="*" element={
            <Navigate replace={true} to={notFound} state={{ from: location }} />
          } />;
        </>
      </Route>
    </ReactRoutes>
  );
}

Routes.propTypes = propTypes;

export default Routes;
