import React, {useEffect, useRef, useState} from "react";
import { Http } from '../../../../Core/Services/HttpService';
import { init, validatefrm, multijsoninit, initselect } from "../../../../assets/js/formbuilder";
import FieldComponent from "../../../../FieldComponent";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import {BiDownload, BiUpload} from "react-icons/bi";
import swal from "sweetalert";
import {BsFileEarmarkPdf, BsFileEarmarkExcel} from "react-icons/bs";
import QueryString from "qs";
import {useStateMounted} from "../../../../Core/Hooks";
import MyTooltip from "../../../MyTooltip";
import {useAuth} from "../../../../Core/Providers";
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
};

const PreAwardStatus = (props: any) => {
    const user = useAuth().user();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [pdfLink, setPdfLink] = useState('#');
    const [excelLink, setExcelLink] = useState('#');
    const [prevfields, setPrevFields] = useStateMounted<any[]>([]);
    const [userData, setUserData] = useState<any>();

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    setPrevFields(result.fields);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                }).finally(() => {
                    footerspinner.classList.add('d-none');
                })
        })();
    };

    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    },[]);

    useEffect(() => {
        const params = {
            form_id: 34,
            form_key: 'lr_award_module',
            discom_user_id: props.discomuserid,
            discom_id: props.discomid,
            form_type: 'pre',
            form_no: 0,
            discom_shortname:userData?.short_form,
        };
        let link = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/frmPdf', 34].join('/');
        link += '?' + QueryString.stringify(params);
        setPdfLink(link);

        let excellink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/excelDownload', 34].join('/');
        excellink += '?' + QueryString.stringify(params);
        setExcelLink(excellink);
    }, [props.discomid,props.discomuserid,userData]);

    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        const imprtParams: any = {
            file_to_upload: e.target.files[0],
            role_id: props.roleid,
            discom_id: props.discomid,
            table_key: 'lr_award_module',
            form_name: e.target.dataset.prefix,
            form_id: 34
        };
        for (let key in imprtParams) {
            formData.append(key, imprtParams[key]);
        }

        setFields([]);
        Http.post('/apiv1/dprfields/preawardupload', formData, config)
            .then((res) => {
                swal(res.message, "", (res.resultflag ? "success" : "error"));
                if (res.resultflag && res.fields) {
                    if (res.fields && Array.isArray(res.fields)) {
                        setFields(res.fields);
                    } else {
                        setFields(prevfields);
                    }
                } else {
                    setFields(prevfields);
                }
            })
            .catch((err) => {
                swal("System error occurred!! please try again.", "", "error");
                setFields(prevfields);
            })
            .finally(() => {
                setIsLoaded(true);
            });
        e.target.value = null;
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        props.btnLoadState(false);
        if(fields && fields.length) {
            setTimeout(() => {
                init(props.frmid+' .form-control');
                initselect(props.frmid+' .form-control');
                props.btnLoadState(true);
            }, 1000);
        }
    }, [fields]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>
                    <div className='row justify-content-end'>
                        {
                            (!roleids.includes(userData?.role_id)) &&
                            <div className="mb-3 form-field w-3">
                                <div className="fileUpload position-relative text-center">
                                    <div className="downEqule">
                                        <input type="file" onChange={handleFileInput} data-prefix="pre_award_status" className="form-control" />
                                        <div className="customUpload justify-content-center flex-wrap">
                                            <span><BiUpload /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="w-2">
                            <Link title="Download Sample Format" to={excelLink} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                        </div>
                        <div className="w-2">
                            <Link to={pdfLink} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                        </div>
                    </div>
                    <div className="table-responsive RevenueDetailsTable PqTable">
                        <Block1 field={fields[0]} frmid={props.frmid}/>
                    </div>
                </>
                : null
        );
    }
};

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const user = useAuth().user();
    const [userData, setUserData] = useState<any>();
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];
    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    },[]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
            setTimeout(() => {
                initselect(props.frmid+' .form-control');
                multijsoninit(props.frmid+' .form-control');
            }, 1500);
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        if(block1Fields.length < 100){
            setBlock1fields((prev: any[]) => {
                return [...prev, { fields: newBlock1Fields, delete: false }];
            });
        }
    };

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            });
            return newFields;
        });
    };

    const isSubmitted = (fields:  any) => {
        return (fields[5].value === '6' && fields[29].value === 'submitted');
    };

    const isAssigned = (fields:  any) => {
        return (fields[30]) ? (fields[30].value === 0) : true;
    };

    return (
        <>
            <table className="table fixUploader">
                <thead className="text-center">
                {/* <th style={{ width: "40px" }} className="freezCol">S. No.</th> */}
                <th style={{ minWidth: "250px" }} className="fixed-table-col">Name or No. of Work Package (Please ensure there is no duplication in names of work packages) <MyTooltip placement="top">Package name should follow the following format – *Short form of DISCOM*-*Name of works (LR/SM)*-*Name of package*-*Unique no.*. For example: APDCL-LR-Bajali-0001</MyTooltip></th>
                <th style={{ minWidth: "100px" }}>Type</th>
                <th style={{ minWidth: "150px" }}>Mention Major Items Covered in RFP</th>
                <th style={{ minWidth: "100px" }}>Package-wise sanctioned cost (INR Lakh)</th>
                <th style={{ minWidth: "250px" }}>Tender Value (INR Lakh)</th>
                <th style={{ minWidth: "150px" }}>Present Status</th>
                <th style={{ minWidth: "120px" }}>Expected RFP Issue Date</th>
                <th style={{ minWidth: "120px" }}>Actual RFP Issue Date</th>
                <th style={{ minWidth: "150px" }}>Upload (Evidence Document for Actual RFP Issue Date)</th>
                <th style={{ minWidth: "150px" }}>No. of Bids Received</th>
                <th style={{ minWidth: "250px" }}>Expected date of Technical Bid Opening</th>
                <th style={{ minWidth: "150px" }}>Actual date of Technical Bid Opening</th>
                <th style={{ minWidth: "150px" }}>Upload (Evidence Document for Actual Date of Technical Bid Opening)</th>
                <th style={{ minWidth: "150px" }}>Actual date of Financial Bid Opening</th>
                <th style={{ minWidth: "150px" }}>Upload (Evidence Document for Actual Date of Financial Bid Opening)</th>
                <th style={{ minWidth: "150px" }}>Name of Participants</th>
                <th style={{ minWidth: "100px" }}>Name of Successful Bidder</th>
                <th style={{ minWidth: "120px" }}>Expected Date of LOA</th>
                <th style={{ minWidth: "120px" }}>Actual Date of LOA</th>
                <th style={{ minWidth: "150px" }}>Upload (Evidence Document for Actual Date of LOA)</th>
                <th className='text-end pe-1' style={{ width: "30px" }}>
                    {
                        (!roleids.includes(userData?.role_id)) &&
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    }
                </th>
                </thead>
                <tbody>
                <>
                    {
                        block1Fields.map((item: any, key: number) => {
                            return (item.delete === false) ? <>
                                    <tr key={key} aria-hidden={isSubmitted(item.fields) ? "true" : "false"}>
                                        {/* <td className="freezCol">{!isSubmitted(item.fields) ? (block1SrNo++) + 1 : (block1SrNo++) }</td> */}
                                        <span className="d-none">{ block1SrNo++ }</span>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                let style: any = {};
                                                let hideField = false;
                                                if (fkey > 19) {
                                                    style.display = 'none';
                                                    hideField = true;
                                                }
                                                const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                                if (field.type == 'file') {
                                                    options.parentIndex = [(block1SrNo - 1)];
                                                    field.parent_type = ['multijson', 'multijson'];
                                                    field.isMultiFile = false;
                                                }
                                                return <>
                                                    {
                                                        (!hideField) ?
                                                            <td key={key + '_' + fkey} className={(field.key === 'lr_work_package_name_no') ? 'fixed-table-col' : ''}>
                                                                <FieldComponent options={options} fieldName={field.key} field={field} />
                                                            </td>
                                                            : <span style={style}>
																<FieldComponent options={options} fieldName={field.key} field={field} />
															</span>
                                                    }
                                                </>;
                                            })
                                        }
                                        <td className="text-end">
                                            <React.Fragment key={key}>
                                            {
                                                (hasBlock1FieldsDelete && isAssigned(item.fields) && !roleids.includes(userData?.role_id)) ?
                                                    <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link>
                                                : (!isAssigned(item.fields)) ?
                                                    <span className="badge bg-info text-dark" ref={element => {if (element) element.style.setProperty('padding', '5px', 'important');}}>Assigned</span>
                                                : null
                                            }
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                    {
                                        (isSubmitted(item.fields))
                                            ? <tr key={'submitted-' + key}>
                                                {/* <td>{(block1SrNo)}</td> */}
                                                {
                                                    item.fields.map((field: any, fkey: number) => {
                                                        let cloneField = JSON.parse(JSON.stringify(field));
                                                        let style: any = {};
                                                        let hideField = false;
                                                        if (fkey > 19) {
                                                            style.display = 'none';
                                                            hideField = true;
                                                        }
                                                        const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                                        if (cloneField.type == 'file') {
                                                            options.parentIndex = [(block1SrNo - 1)];
                                                            cloneField.parent_type = ['multijson', 'multijson'];
                                                            cloneField.isMultiFile = false;
                                                        }
                                                        cloneField.required = 'N'; // Forcefully required N
                                                        cloneField.view = 'Y'; // Forcefully view Y
                                                        return <>
                                                            {
                                                                (!hideField) ?
                                                                    <td key={key + '_' + fkey} className={cloneField.key == 'lr_work_package_name_no' ? "fixed-table-col": ''}>
                                                                        <FieldComponent options={options} fieldName={cloneField.key} field={cloneField} />
                                                                    </td>
                                                                    : <span style={style}>
                                                                        <FieldComponent options={options} fieldName={cloneField.key} field={cloneField} />
                                                                    </span>
                                                            }
                                                        </>;
                                                    })
                                                }
                                            {/*<td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>*/}
                                        </tr> : null
                                    }
                                </>
                                : null;
                        })
                    }
                </>
                </tbody>
            </table>
        </>
    );
};

const PostAwardStatus = (props: any) => {
    const user = useAuth().user();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [pdfLink, setPdfLink] = useState('#');
    const [excelLink, setExcelLink] = useState('#');
    const [prevfields, setPrevFields] = useStateMounted<any[]>([]);
    const [userData, setUserData] = useState<any>();

    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    },[]);

    const getFields = () => {
        (async () => {
            setFields([]);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                }).finally(() => {
                    footerspinner.classList.add('d-none');
                })
        })();
    };

    useEffect(() => {
        const params = {
            form_id: 34,
            form_key: 'lr_award_module',
            discom_user_id: props.discomuserid,
            discom_id: props.discomid,
            form_type: 'post',
            form_no: 1,
            discom_shortname:userData?.short_form,
        };
        let link = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/frmPdf', 34].join('/');
        link += '?' + QueryString.stringify(params);
        setPdfLink(link);

        let excellink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/dprfields/excelDownload', 34].join('/');
        excellink += '?' + QueryString.stringify(params);
        setExcelLink(excellink);
    }, [props.discomid,props.discomuserid,userData]);

    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        const imprtParams: any = {
            file_to_upload: e.target.files[0],
            role_id: props.roleid,
            discom_id: props.discomid,
            table_key: 'lr_award_module',
            form_name: e.target.dataset.prefix,
            form_id: 34
        };
        for (let key in imprtParams) {
            formData.append(key, imprtParams[key]);
        }

        setFields([]);
        Http.post('/apiv1/dprfields/preawardupload', formData, config)
            .then((res) => {
                swal(res.message, "", (res.resultflag ? "success" : "error"));
                if (res.resultflag && res.fields) {
                    if (res.fields && Array.isArray(res.fields)) {
                        setFields(res.fields);
                    } else {
                        setFields(prevfields);
                    }
                } else {
                    setFields(prevfields);
                }
            })
            .catch((err) => {
                swal("System error occurred!! please try again.", "", "error");
                setFields(prevfields);
            })
            .finally(() => {
                setIsLoaded(true);
            });
        e.target.value = null;
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        if(fields && fields.length) {
            setTimeout(() => {
                init(props.frmid+' .form-control');
                initselect(props.frmid+' .form-control');
            }, 1000);
        }
    }, [fields]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>
                    <div className='row justify-content-end'>
                        {/*<div className="mb-3 form-field w-3">
                            <div className="fileUpload position-relative text-center">
                                <div className="downEqule">
                                    <input type="file" onChange={handleFileInput} data-prefix="pre_award_status" className="form-control" />
                                    <div className="customUpload justify-content-center flex-wrap">
                                        <span><BiUpload /></span>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        <div className="w-2">
                            <Link title="Download Sample Format" to={excelLink} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                        </div>
                        <div className="w-2">
                            <Link to={pdfLink} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                        </div>
                    </div>
                    <div className="table-responsive RevenueDetailsTable PqTable">
                        <Block2 field={fields[0]} frmid={props.frmid} />
                    </div>
                </>
                : null
        );
    }
};

const Block2 = (props: any) => {
    let block2SrNo = 0;
    let block2SrNoFile = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            });
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect(props.frmid+' .form-control');
                multijsoninit(props.frmid+' .form-control');
            }, 1500);
        }
    }, [block2Fields]);

    const isSubmitted = (fields:  any) => {
        return (fields[5].value === '6' && fields[29].value === 'submitted');
    };

    return (
        <>
            <table className="table">
                <thead className="text-center">
                <th style={{ width: "40px" }}>S. No.</th>
                <th style={{ width: "100px" }}>Name or No. of Work Package (Unique Package Identifier)</th>
                <th style={{ minWidth: "100px" }}>Actual Date of LOA</th>
                <th style={{ minWidth: "100px" }}>Date of Contract Agreement</th>
                <th style={{ minWidth: "180px" }}>Upload (Evidence Document for date of Contract Agreement)</th>
                <th style={{ minWidth: "100px" }}>Awarded Cost (Supply Portion) (INR Lakh)</th>
                <th style={{ minWidth: "100px" }}>Awarded Cost (Erection Portion) (INR Lakh)</th>
                <th style={{ minWidth: "100px" }}>Awarded Cost of Works (INR Lakh)</th>
                <th style={{ minWidth: "100px" }}>Cost of PO Placed (INR Lakh)</th>
                <th style={{ minWidth: "100px" }}>Value of Total Supply portion of Contract for PO placed (INR Lakh)</th>
                <th style={{ minWidth: "100px" }}>Engineering Approval (incl. GTP/Drawings etc.) (%)</th>
                <th style={{ minWidth: "100px" }}>Pre-Dispatch Inspection Material Value (INR Lakh)</th>
                </thead>
                <tbody>
                <>
                    {
                        block2Fields.map((item: any, key: number) => {
                            //let areaHiddenProp = false;
                            // Check Present Status "Awarded" or not
                            /*if(item.fields[5].hidden_row){
                                areaHiddenProp = true;
                            }*/
                            return (item.delete === false) ? <>
                                    <tr key={key} aria-hidden={isSubmitted(item.fields) ? "false" : "true"}>
                                        <td>{isSubmitted(item.fields) ? (block2SrNo++) + 1 : 0 }</td>
                                        <span style={{display:"none"}}>{(block2SrNoFile++) + 1}</span>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                let style: any = {};
                                                let hideField = false;
                                                if (![0,18,20,21,22,23,24,25,26,27,28].includes(fkey)) {
                                                    style.display = 'none';
                                                    hideField = true;
                                                }
                                                const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                                if (field.type == 'file') {
                                                    options.parentIndex = [(block2SrNoFile - 1)];
                                                    field.parent_type = ['multijson', 'multijson'];
                                                    field.isMultiFile = false;
                                                }
                                                return <>
                                                    {
                                                        (!hideField) ?
                                                            <td key={key + '_' + fkey}>
                                                                <FieldComponent options={options} fieldName={field.key} field={field} />
                                                            </td>
                                                            : <span style={style}>
																<FieldComponent options={options} fieldName={field.key} field={field} />
															</span>
                                                    }
                                                </>;
                                            })
                                        }
                                    </tr>
                                </>
                                : null;
                        })
                    }
                </>
                </tbody>
            </table>
        </>
    );
};

const AwardModule = (props?: any) => {

    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const formData: any = useRef(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [goSteps, setGoSteps] = useState(0);
    const [steps, setSteps] = useState(['active', '']);
    const [isDomLoaded, setIsDomLoaded] = useState<boolean>(false);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomid, discomuserid]);

    // Nav Steps
    const navtab = (opt: number) => {
        if (opt == 0){
            setSteps(['active', '']);
        }
        if (opt == 1){
            setSteps(['completed', 'active']);
        }
        setGoSteps(opt);
    };

    const saveasdraft = (form_tab_no: any) => {
        /*const isValid = validatefrm(`#frm-pre_${idRef.current} .form-control`);
        if (!isValid) {
          return false;
        }*/
        setRefresh(false);
        setIsLoading(true);
        setIsDomLoaded(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('tbl_key', 'lr_award_module');
        myFormData.append('form_tab_no', form_tab_no);
        myFormData.append('action', 'draft');

        let frmAction = '/apiv1/dprfields/saveAwardModuleLrPrePostAward';
        Http.post(frmAction, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setRefresh(true);
                    setIsLoading(false);
                }
            });
    };

    const submit = (form_tab_no: any) => {
        const isValid = validatefrm(`#frm-pre_${idRef.current} .form-control`);
        if (!isValid) {
            return false;
        }
        setRefresh(false);
        setIsLoading(true);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('tbl_key', 'lr_award_module');
        myFormData.append('form_tab_no', form_tab_no);
        myFormData.append('action', 'submit');

        let frmAction = '/apiv1/dprfields/saveAwardModuleLrPrePostAward';
        Http.post(frmAction, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setRefresh(true);
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    const btnLoadState = (val: boolean) => {
      setIsDomLoaded(val);
    };


    return (
        <>
            {roleid == 0 ? null :
                <>
                    <div className='container'>
                        <div className='customStepper'>
                            <div className="StepperContainer-0-2-1">
                                <div className="StepContainer-0-2-2">
                                    <div className="StepMain-0-2-3">
                                        <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                                        </button>
                                        <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Pre-Award Status</span></div>
                                    </div>
                                </div>
                                <div className="StepContainer-0-2-2">
                                    <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                                    <div className="StepMain-0-2-3">
                                        <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                                        </button>
                                        <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Post-Award Status</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form id={'frm-pre_' + idRef.current} ref={formData} noValidate>
                        {goSteps === 0 && (
                            <div id="lrPreFrm">
                                {/*<div className="table-responsive RevenueDetailsTable PqTable">*/}
                                    <PreAwardStatus apiurl={"/apiv1/dprfields/form34/?form_id=34&roleid=" + roleid+"&discom_id=" + discomid} refresh={refresh} discomid={discomid} discomuserid={discomuserid} roleid={roleid} frmid={`#frm-pre_${idRef.current}`} btnLoadState={btnLoadState} />
                                {/*</div>*/}
                                <div className="formBtnSection justify-content-between is-sticky">
                                    {roleid == 2 || roleid == 3 || roleid == 4 ?
                                        <>
                                            {
                                                (isDomLoaded) &&
                                                <>
                                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)}> Save as Draft</Link>
                                                    <Link to="#" className="btnDraft" onClick={() => submit(1)}>Submit</Link>
                                                </>
                                            }
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        )}
                        {goSteps === 1 && (
                            <div id="lrPostFrm">
                                {/*<div className="table-responsive RevenueDetailsTable PqTable">*/}
                                    <PostAwardStatus apiurl={"/apiv1/dprfields/form34/?form_id=34&form_type=post&roleid=" + roleid+"&discom_id=" + discomid} refresh={refresh} discomid={discomid} discomuserid={discomuserid} roleid={roleid} frmid={`#frm-pre_${idRef.current}`} />
                                {/*</div>*/}
                                <div className="formBtnSection justify-content-between is-sticky">
                                    {roleid == 2 || roleid == 3 || roleid == 4 ?
                                        <>
                                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(2)} >
                                                Save as Draft
                                            </Link>
                                            <Link to="#" className="btnDraft" onClick={() => submit(2)} >
                                                Submit
                                            </Link>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        )}
                    </form>
                    {isLoading ? <LoadingSpinner /> : null}
                </>
            }
        </>
    )
};

export default AwardModule;
