import React, { useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useStateMounted } from '../../../../Core/Hooks';
import { Visibility } from '../../../../Core/Components';
import Vvp from '../Vvp';
import Pvtg from '../Pvtg';
import { useLocation } from 'react-router-dom';

function Main(props: any) {

  const [tabs, setTabs] = useStateMounted<{ label: string, key: string, element: React.ReactNode | any | null}[] | []>([]);
  const location = useLocation();
  const activeTab = (location.state as { subTab: any })?.subTab || "vvp";
  const [tab, setTab] = useStateMounted<string>(activeTab);

  useEffect(() => {
    // Set tabs
    setTabs([
      {
        label: "VVP",
        key: "vvp",
        element: Vvp
      },
      {
        label: "PVTG",
        key: "pvtg",
        element: Pvtg
      }
    ]);
  }, []);

  const onSelectTab = (stab: any) => {
    if (stab !== tab) {
      setTab(stab);
    }
  };

  return (
    <div className="app-rdss-vvp-pvtg-frm">
      <section className="financialForm misNew">
        <div className="container">
          <div className="financialMainFrom inputFromNew">
            <div className="tab-content border-0 px-0">
              <div className="tab-pane fade show active" id="Main" role="tabpanel" aria-labelledby="MainTab">
                {(tabs.length)
                  ? <>
                    <Tab.Container onSelect={onSelectTab} activeKey={tab}>
                      <Nav as="ul" variant="tabs" className={'justify-content-center'}>
                        {
                          tabs.map((item: any, index: number) => (
                            <Nav.Item as="li"
                              key={index + '-' + item.key}
                              className="col-12 col-md-6 col-lg-4 text-center">
                              <Nav.Link eventKey={item.key} title={item.label}>{item.label}</Nav.Link>
                            </Nav.Item>
                          ))
                        }
                      </Nav>
                      <Tab.Content style={{ border: '0px', paddingLeft: '0px', paddingRight: '0px' }}>
                        {
                          tabs.map((item, index: number) => (
                            <Tab.Pane key={index + '-' + item.key} eventKey={item.key}>
                              <Visibility options={{ threshold: 0.5 }}>
                                {(ref: any, isVisible: boolean) => (
                                  <div ref={ref}>
                                    {(isVisible) ? <item.element {...{ tab: item.key, goTo: onSelectTab }} /> : null}
                                  </div>
                                )}
                              </Visibility>
                            </Tab.Pane>
                          ))
                        }
                      </Tab.Content>
                    </Tab.Container>
                  </>
                  : <></>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
