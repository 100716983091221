import React, { useState, useEffect } from "react";
import './scheme.scss';
import { Link } from 'react-router-dom';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper.min.css";
import { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";

// Image
import Rewamp from '../../../images/rewamp.jpg';
import Aditional from '../../../images/aditional.jpg';
import Prapti from '../../../images/prapti.jpg';
import Discom from '../../../images/discom.jpg';
import DiscomeConsumer from '../../../images/discom-consumer.jpg'
import Energy from '../../../images/energy.jpg';
import Key from '../../../images/key.jpg';
import Overview from '../../../images/overview.jpg';
import PowerSmall from '../../../images/power-small.jpg';
import { Storage } from "../../../Core/Services/StorageService";

const Scheme = () => {
  const Fade = require('react-reveal/Fade');
  const [schemes, setSchemes] = useState<any[]>([]);
  const [roleid, setRoleid] = useState<any>(0);
  const [nodalagency, setNodalAgency] = useState<number>(0);

  const getSchemes = () => {

    const rddssLink = (roleid === 0 || roleid === undefined || roleid === '' || roleid === null)
      ? "/dashboard"
      : (
        (roleid === Number(process.env.REACT_APP_NDR) || roleid === Number(process.env.REACT_APP_NDA))
          ? "/nodal-view"
          : "/rdss"
      );

    const schemeArry = [
      {
        image: Rewamp,
        title: "Revamped Distribution Sector Scheme (RDSS)",
        link: rddssLink,
      },
      {
        image: Energy,
        title: "Energy Accounting",
        link: process.env.REACT_APP_API_ENDPOINT !== 'https://rdss.powermin.gov.in:8443' ? "/energy" : "#"
      },
      {
        image: Discom,
        title: "DISCOM Integrated Rating",
        link: "/discomir",
      },
      {
        image: DiscomeConsumer,
        title: "Consumer Service Rating of DISCOMs",
        link: "/csrd",
      },
      {
        image: PowerSmall,
        title: "Power Utilities Performance",
        class: "pe-none"
      },
      {
        image: Key,
        title: "Key Regulatory Parameters",
        link: process.env.REACT_APP_API_ENDPOINT !== 'https://rdss.powermin.gov.in:8443' ? "/krp" : "#",
      },
      {
        image: Aditional,
        title: "Additional GSDP Borrowing Space",
        link: process.env.REACT_APP_API_ENDPOINT !== 'https://rdss.powermin.gov.in:8443' ? '/agsdp' : "#",
      },
      {
        image: Prapti,
        title: "PRAAPTI",
        link: roleid === Number(process.env.REACT_APP_NDR) || roleid === Number(process.env.REACT_APP_NDA) ? "/praapti" : "/praapti/discom-view",
      },
      {
        image: Overview,
        title: "Overview of SERC",
        class: "pe-none"
      },
    ];
    setSchemes(schemeArry);
  };

  const setLink = (link: string) => {
    Storage.set('referer', link);
  };

  useEffect(() => {
    setRoleid(Number(Storage.get('roleid')));
    setNodalAgency(Number(Storage.get('nodal_agency')));
  }, []);

  useEffect(() => {
    getSchemes();
  }, [roleid]);

  return (
    <>
      <section className="schemeSection schemeBoxType2" id="schemeSection">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            {/* <h2 id="schemeSection"> Schemes and Reporting Modules</h2>    */}
            {/* <div className="schemeNav">
                            <div className="scheme-prev prev" />
                            <div className="scheme-next next" />
                        </div>                  */}
          </div>
          {/* <div className="row gx-lg-5 gy-4 gy-md-0"> */}
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            effect={"fade"}
            speed={1500}
            modules={[Navigation, Pagination]}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerGroup: 1,
                slidesPerView: 1,
              },
              // when window width is >= 480px
              500: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              // when window width is >= 640px
              650: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              }
            }}
            navigation={{
              prevEl: '.scheme-prev',
              nextEl: '.scheme-next',
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true
            }}

            className="pressSwiper schemeSwiper"
          >
            {
              schemes.map((scheme, SchemeIndex) => {
                return <>
                  <SwiperSlide key={SchemeIndex}>
                    <Link title={scheme.title} onClick={() => setLink(scheme.link)} to={scheme.link} className={'text-decoration-none ' + scheme.class}>
                      <div className="schemeInner" data-aos="fade-up" data-aos-duration="1200" data-aos-delay={150 * (SchemeIndex + 1)}>
                        <img src={scheme.image} title={scheme.title} alt={scheme.title} className="img-fluid" />
                        <h5>{scheme.title}</h5>
                      </div>
                    </Link>
                  </SwiperSlide>
                </>
              })
            }
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Scheme;