import React, { useState, useEffect } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { init, multijsoninit, initselect } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import FieldComponent from "../../../FieldComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import { BiUpload } from 'react-icons/bi';
import '../energy.scss';
import InnerTemplate from '../../InnerTemplate/InnerTemplate'
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Storage } from "../../../Core/Services/StorageService";

const ChildInput = (props: { fields: any, pIndex?: number }) => {
    const [fields, setFields] = useState<string[]>([]);
    useEffect(() => {
        if (props.fields.length) {
            setFields(props.fields);
        }
    }, [props.fields]);
    return (
        <>
            {
                (fields.length)
                    ? fields.map((field: any, index) => (
                        <td key={props.pIndex + '-' + index + '-' + field.key}>
                            <FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} />
                        </td>
                    ))
                    : null
            }
        </>
    );
}

type AccordianBlockProps = {
    title: string;
    apiurl: string;
    accordianId: string;
    class1: string;
    class2: string;
    discription?: any;
    tableHead?: any;
    refresh?: boolean;
};

const Block1 = (props: any) => {

    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }


    return (
        <>
            <table className="table caption-top mt-4 overflow-scroll">
                <thead className='text-center'>
                <tr>
                    <th scope="col" style={{ width: 50 }}>Sl.No.</th>
                    <th scope="col" className="text-center">Name of Generation Station</th>
                    <th scope="col" className="text-center">Generation Capacity(In MW)</th>
                    <th scope="col" className="text-center">Type of Station Generation</th>
                    <th scope="col" className="text-center">Type of Contract</th>
                    <th scope="col" className="text-center">Type of Grid</th>
                    <th scope="col" className="text-center">Voltage Level(KVA)</th>
                    <th scope="col" className="text-center">Circle Load(MW)</th>
                    <th scope="col" className="text-center">Received at Circle(KVA)</th>
                    <th scope="col" className="text-center">Received at Circle(In MU)</th>
                    <th scope="col" className="text-center">Division Level Load(MW)</th>
                    <th scope="col" className="text-center">Received at Division Level(KVA)</th>
                    <th scope="col" className="text-center">Received at Division Level(In MU)</th>
                    <th scope="col" className="text-center">Sub-Division Level Load(MW)</th>
                    <th scope="col" className="text-center">Received at Sub-Division Level(KVA)</th>
                    <th scope="col" className="text-center">Received at Sub-Division Level(In MU)</th>
                    <th scope="col" className="text-center">Remarks</th>
                    <th className='text-end pe-1' style={{ width: "50px" }}>
                         <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    block1Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                                <tr key={key}>
                                    <td className="text-center">{(block1SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                            </>;
                                        })
                                    }
                                    <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                </tr>
                            </>
                            : null;
                    })
                }
                </tbody>
            </table>
        </>
    );
}

const AccordianBlock = (props: AccordianBlockProps) => {
    const layout = useLayout();
    const [error, setError] = useState(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [tableHead, setTableHead] = useState<React.ReactNode>(null);
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Revenue deferred by SERC as per tariff order for the relevant FY
        </Tooltip>
    );
    const getFields = () => {
        (async () => {
            setFields([]);
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    layout.set({ submittedStatus: result });
                    setTimeout(() => {
                        init();
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    };

    const handleFileInput = (e: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("file_to_upload", e.target.files[0]);
        formData.append("roleid", roleid);
        formData.append("discom_id", discomid);

        let searchParams = new URLSearchParams(props.apiurl);
        let formID: any = searchParams.get('form_id');
        formData.append("form_id", formID);

        setFields([]);
        Http.post('/apiv1/finincialupload', formData, config)
            .then((result) => {
                setFields(result.fields);
                setTimeout(() => {
                    initselect();
                    multijsoninit();
                }, 2000);
                console.log("File uploaded successfully");
            });
    }

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl]);

    useEffect(() => {
        if (props.refresh !== undefined && props.refresh === true) {
            getFields();
        }
    }, [props.refresh]);

    useEffect(() => {
        if (props.tableHead) {
            setTableHead(props.tableHead);
        }
    }, [props.tableHead])

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            fields ?
                <>
                    <div className="accordion-item">
                        <div className='col-md-2 float-end'>
                            <div className="form-field">
                                <div className="fileUpload position-relative text-center">
                                    <div className="downEqule">
                                        <input type="file" onChange={handleFileInput} data-prefix="fpl_expenditure_details" className="form-control" />
                                        <div className="customUpload justify-content-center flex-wrap">
                                            <span><BiUpload /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="accordion-header" id="RevenueDetails">
                            <button className={'accordion-button ' + props.class1} type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.accordianId} aria-expanded="true" aria-controls={props.accordianId}>
                                {props.title}
                            </button>
                        </h3>
                        <div id={props.accordianId} className={'accordion-collapse collapse ' + props.class2} aria-labelledby="RevenueDetails" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {props.discription}
                                <div className="table-responsive RevenueDetailsTable">
                                    <table className="table">
                                        {tableHead}
                                        <tbody>
                                            {
                                                (fields.length)
                                                    ? fields.map((field: any, index) => (
                                                        <tr key={index + '-' + field.key} className="activeRow">
                                                            <td className={field.class1}>{field.key}</td>
                                                            <td className={field.class2}>{field.caption}
                                                                {field.key == 'C1' ?
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderTooltip}
                                                                    >
                                                                        <Link to="" className="text-decoration-none text-black">*</Link>
                                                                    </OverlayTrigger>
                                                                    : null}
                                                            </td>
                                                            {
                                                                (field.childrens)
                                                                    ? <ChildInput pIndex={index} fields={field.childrens} />
                                                                    : null
                                                            }
                                                        </tr>
                                                    ))
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null
        );
    }
}

const EmbeddedGeneration = (props?: any) => {
    const layout = useLayout();
    const [year, setYear] = useState<any>(props.year);
    const [refresh, setRefresh] = useState(false);
    const [roleid, setRoleid] = useState<any>(0);
    const [act, setAct] = useState<string>(props.act);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [gettrackerid, setTrackerId] =  useState<any>(0);

    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getreleasefundid, setReleaseFundId] = useState<any>(0);

    let navigate = useNavigate();

    let location = useLocation();

    useEffect(() => {
        console.log('main');
        (async () => {
            if(location.state)
            {
                getDocumentation(location.state.data);
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setReleaseFundId(location.state.id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
            }
            else
            {
                navigate('/details-received-sources');
            }
        })();
    }, [getcomponentid , gettrancheid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    }, [roleid]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setYear(props.year);
    }, [props.year]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    useEffect(() => {
        setAct(props.act);
    }, [props.act]);

    const navtab = () => {
        setPageLink('/details-received-sources');
    }
    useEffect(() => {
        (async () => {
            if(getpagelink){
                navigate(getpagelink , { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id,data : getdocumentation,component_name :getcomponentname,tranche_name :gettranchename,component_id :getcomponentid,tranche_id :gettrancheid,aplication_tracker_id :getaplicationtrackerid }});
            }
        })();
    }, [getpagelink]);

    useEffect(() => {
        (async () => {
            await Http.get(props.apiurl)
                .then((result) => {
                    console.log(result);
                    setIsLoaded(true);
                    setFields(result.fields);
                    setTimeout(() => {
                        init();
                    }, 500);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    }, [props.apiurl]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                {/* <InnerTemplate page="EA" PageTitle="General Information" /> */}
                <div className='eaWrap'>
                    <div className='container table-responsive'>
                        <div className="eaHeading">
                            <h4>Embedded Generation in DISCOM Area</h4>
                        </div>
                        <Block1 field={fields[0]}/>
                    </div>
                </div>

            </>
        );
    }
};

const EmbeddedGenerations = (props: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerid] = useState<any>(0);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, []);

    return (
        <>
            <div className="mainWrap">
                {roleid == 0 ? null :
                    <EmbeddedGeneration apiurl={"/apiv1/formfields/?form_id=60&roleid=" + roleid+"&component_id="+getcomponentid+"&discom_id="+discomid+"&discom_user_id="+discomuserid+"&application_id="+getaplicationtrackerid} />
                }
            </div>
        </>
    )
}

export default EmbeddedGenerations;