import React, { useEffect, useState } from 'react'
import Header from "../../../../Layout/Default/Element/Header";
import Footer from "../../../../Layout/Default/Element/Footer";
import { Link } from 'react-router-dom';
import Form249 from './Forms/Form249';

const PvtgHhData = () => {
    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                            <li><Link to="/monitoring" className="text-decoration-none">Monitoring</Link></li>
                            <li><Link to="/all-progress-details" className='text-decoration-none'>AllProgressDetails</Link></li>
                            <li><Link to="/progress-details" className='text-decoration-none'>District-wise Progress Details</Link></li>
                            <li>PVTG Household Data</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div aria-hidden="false"><Form249 /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    )
}

export default PvtgHhData;