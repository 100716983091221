import React from "react";
import "./sidebar.scss";
import { Link } from "react-router-dom";
import { BsArrowLeft, BsWindowSidebar} from "react-icons/bs";

function SideBar(){    
    return(
        <>
            <section className="sidebarSection">
                <Link to="/nodal-view" className="backBtn"><BsArrowLeft /></Link>
                <ul className="list-unstyled">
                    <li><Link to="/" className="active"><BsWindowSidebar /></Link></li>
                    
                </ul>
            </section>
        </>
    );
}

export default SideBar;
