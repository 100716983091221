import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';
import { MmqiAccess, SubmitReportAccess } from '../Middlewares/Mmqi';
import { PtiAccess, InspectionReportAccess, TpqmaReportAccess } from '../Middlewares/Pti';
import { InspectionCallAccess, WqiAccess } from '../Middlewares/Wqi';
import ReportAccess from '../Middlewares/ReportAccess';

/* Route */
const routes: RouteProps[] = [
  {
    path: '',
    element: lazy(() => import('../Pages/Main')),
    index: true,
    private: true
  },
  {
    path: 'mmqi',
    element: lazy(() => import('../Pages/Mmqi')),
    index: true,
    private: true,
    middleware: MmqiAccess
  },
  {
    path: 'mmqi/vendor/:discomId?/:viewType?',
    element: lazy(() => import('../Pages/Mmqi/Vendor')),
    index: true,
    private: true,
    middleware: MmqiAccess
  },
  {
    path: 'lab/:discomId?/:viewType?',
    element: lazy(() => import('../Pages/Lab')),
    index: true,
    private: true,
    middleware: MmqiAccess
  },
  {
    path: 'mmqi/input-form/:discomId?',
    element: lazy(() => import('../Pages/Mmqi/InputForm')),
    index: true,
    private: true,
    middleware: MmqiAccess
  },
  {
    path: 'mmqi/call/:discomId?',
    element: lazy(() => import('../Pages/Mmqi/Call')),
    index: true,
    private: true,
    middleware: MmqiAccess
  },
  {
    path: 'mmqi/submit-report/:discomId?',
    element: lazy(() => import('../Pages/Mmqi/SubmitReport')),
    index: true,
    private: true,
    middleware: SubmitReportAccess
  },
  {
    path: 'pti',
    element: lazy(() => import('../Pages/Pti')),
    index: true,
    private: true,
    middleware: PtiAccess
  },
  {
    path: 'pti/input-form/:discomId?',
    element: lazy(() => import('../Pages/Pti/InputForm')),
    index: true,
    private: true,
    middleware: PtiAccess
  },
  {
    path: 'pti/call/:discomId?',
    element: lazy(() => import('../Pages/Pti/Call')),
    index: true,
    private: true,
    middleware: PtiAccess
  },
  {
    path: 'pti/inspection-report/:discomId?',
    element: lazy(() => import('../Pages/Pti/InspectionReport')),
    index: true,
    private: true,
    middleware: InspectionReportAccess
  },
  {
    path: 'pti/tpqma-report/:discomId?',
    element: lazy(() => import('../Pages/Pti/TpqmaReport')),
    index: true,
    private: true,
    middleware: TpqmaReportAccess
  },
  {
    path: 'wqi',
    element: lazy(() => import('../Pages/Wqi')),
    index: true,
    private: true,
    middleware: WqiAccess
  },
  {
    path: 'wqi/inspection-call/:discomId?',
    element: lazy(() => import('../Pages/Wqi/InspectionCall')),
    index: true,
    private: true,
    // middleware: InspectionCallAccess
  },
  // {
  //   path: 'wqi/call-tracker/:discomId?',
  //   element: lazy(() => import('../Pages/Wqi/CallTracker')),
  //   index: true,
  //   private: true,
  //   middleware: WqiAccess
  // },
  // {
  //   path: 'wqi/tpqma-report/:discomId?',
  //   element: lazy(() => import('../Pages/Wqi/TpqmaReport')),
  //   index: true,
  //   private: true,
  //   middleware: WqiAccess
  // }
  {
    path: 'reports/:type?',
    element: lazy(() => import('../Pages/Reports')),
    index: true,
    private: true,
    middleware: ReportAccess
  },
];

export default routes;