import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Sop313 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* SoP-3.1.3*/}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara SoP">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">SoP-3.1.3</p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[227] && fields[227].value ? fields[227].value : ''} name="formdata[pqsop_details][sop313_nodal]" id="pqsop_details_sop313_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][sop313_remarks_nodal]" id="pqsop_details_sop313_remarks_nodal" defaultValue={fields[242] && fields[242].value ? fields[242].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][sop313_document_nodal]" id="pqsop_details_sop313_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[257].value != '' ?
                                            <a href={fields[257].value} title={fields[257].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>
                                    {fields[227].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[227] && fields[227].value ? fields[227].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[242].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[242] && fields[242].value ? fields[242].value : ''}</div>
                                    {/* : null
                                                    } */}

                                    {fields[257].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[257] && fields[257].value ? fields[257].value : ''} title={fields[257] && fields[257].value ? fields[257].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }
                                    </>
                                }
                                </> : null
                            }

                        </div>
                        {(props.tooltip !== undefined && props.tooltip === false)
                            ? null
                            :
                            <MyTooltip placement="left">
                                <ul>
                                    <li>Quarterly Subsidy Bills (Advance and Reconciliation)</li>
                                    <li>Quarterly subsidy release order/ statement by Govt.</li>
                                </ul>
                            </MyTooltip>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td>Please mention the Subsidy billing & collection data</td>
                <td>
                    <Modalbox field2={fields[195]} block="4" />
                    <Link to="" className="btn-warning btn d-block mx-auto w-100" data-bs-toggle="modal" data-bs-target="#Subsidybillingandcollection">Subsidy billing and collection</Link>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[101].key} field={fields[101]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[100].key} field={fields[100]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Please detail out the metering status of consumers in each subsidised category</td>
                <td>
                    <Modalbox field1={fields[197]} field2={fields[198]} field3={fields[199]} block="5" />
                    <Link to="" className="btn-warning btn d-block mx-auto w-100" data-bs-toggle="modal" data-bs-target="#Subsidydisbursalplan">Subsidy disbursal plan</Link>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[104].key} field={fields[104]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[103].key} field={fields[103]} />
                </td>
                <td></td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[105].key} field={fields[105]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Sop313;