import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import LossReduction from './Module/LossReduction';
import SmartMetering from './Module/SmartMetering';
import "../../css/style.scss";
import { Storage } from '../../Core/Services/StorageService';

export const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
}

const IndexPage = () => {
    const [roleid, setRoleid] = useState<any>(0);
    const [selected, setSelected] = useState<any>(0);
    const [discoms, setDiscoms] = useState<any[]>([]);
    const [nodalAgency, setNodalAgency] = useState<any>(0);
    const [error, setError] = useState(null);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomId] = useState<any>(0);
    const [defaultDiscom, setDefaultDiscom] = useState<any>(0);
    const [defaultTab, setDefaultTab] = useState<any>(null);
    const [subDefaultTab, setSubDefaultTab] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [getCol, setCol] = useState<any>(0);
    const [getMCol, setMCol] = useState<any>(0);

    useEffect(() => {
        if(defaultTab == 'LossReduction')
        {
            setSelected("LossReduction");
            if(roleid == 5 || roleid == 6)
            {
                setMCol(9);
                setCol(4);
            }else{
                setMCol(12);
                setCol(6);
            }
        }else{
            if(roleid == 5 || roleid == 6)
            {
                setSelected("SmartMetering");
                setMCol(9);
                setCol(4);
            }else{
                setSelected("LossReduction");
                setMCol(12);
                setCol(6);
            }
        }
    }, [defaultTab, roleid]);

    const changeTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setNodalAgency(Storage.get('nodal_agency'));
    }, [discomuserid, nodalAgency]);

    useEffect(() => {
        (async () => {
            if (location.state) {
                setDiscomId(location.state.discomid)
                setDefaultDiscom(location.state.discomid);
                setDefaultTab(location.state.module_type);
                setSubDefaultTab(location.state.module_sub_type);
            }
        })();
    }, []);

    return (
        <>
        <section className="financialForm">
            <div className="container">
                <div className="financialMainFrom inputFromNew">
                    <div className="tab-content border-0 p-0">
                        <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                            <div className='radioTitleSection'>
                                <div className='row mx-0 gx-0 justify-content-between'>
                                    <div className={'col-lg-12 col-xl-'+{getMCol}+ 'col-xxl-7'}>
                                        <div className="radioYear">
                                            <div className="row justify-content-center align-items-center mx-0 gx-0">
                                                {(roleid == 5 || roleid == 6) ?
                                                    <div className="col-5 col-md-4">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" name="SanctionLetter" value="SmartMetering" id="SmartMetering" checked={selected === "SmartMetering"} onChange={changeTab} />
                                                            <label className="form-check-label" htmlFor="SmartMetering">Smart Metering</label>
                                                        </div>
                                                    </div>
                                                    :null
                                                }
                                                <div className={'col-5 col-md-' + (getCol)}>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="LossReduction" value="LossReduction" id="LossReduction" checked={selected === "LossReduction"} onChange={changeTab} />
                                                        <label className="form-check-label" htmlFor="LossReduction">Loss Reduction</label>
                                                    </div>
                                                </div>
                                                <div className={'col-5 col-md-' + (getCol)}>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="Modernization" value="Modernization" id="Modernization" checked={selected === "Modernization"} onChange={changeTab} />
                                                        <label className="form-check-label" htmlFor="Modernization">Modernization</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-xl-3 col-xxl-4 ps-2'>
                                        <div className='col-md-auto'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div aria-hidden={selected !== "SmartMetering" ? true : false}><SmartMetering subDefaultTab={subDefaultTab} discomid={discomid} /></div>
                            <div aria-hidden={selected !== "LossReduction" ? true : false}><LossReduction subDefaultTab={subDefaultTab} discoms={discoms} /></div>
                            <div aria-hidden={selected !== "Modernization" ? true : false}>Coming Soon.</div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <LoadingSpinner /> : null}
        </section>
        </>
    );
}

export default IndexPage;