import React from "react";

class Icon extends React.PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73.651"
        height="66.05"
        viewBox="0 0 100 100"
      >
        <g
          id="Layer_1"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.25"
          transform="translate(.125 .125)"
        >
          <path d="M47.5,100.2c-7.8,0-15.6-2-22.6-5.7c-11.2-6-19.4-16.1-23.1-28.3C-1.8,54-0.5,41.1,5.5,29.9C13.9,14.4,30,4.8,47.5,4.8
            c7.8,0,15.5,1.9,22.4,5.6l9.8-9.8c0.2-0.2,0.5-0.4,0.9-0.4c0.1,0,0.2,0,0.2,0c0.8,0,1.4,0.6,1.5,1.3l1.3,8.6l1.8-1.8
            c0.3-0.3,0.7-0.5,1.1-0.5s0.8,0.2,1.1,0.5l4,4c0.6,0.6,0.6,1.6,0,2.2l-1.8,1.8l8.6,1.3c0.6,0.1,1.1,0.5,1.3,1.1
            c0.2,0.6,0,1.2-0.4,1.6L89.5,30c7.5,14.1,7.5,31-0.1,45.1C81.1,90.6,65,100.2,47.5,100.2C47.5,100.2,47.5,100.2,47.5,100.2z M47.5,8
            C35.6,8,24.4,12.6,16,21C7.6,29.4,3,40.6,3,52.5C3,77,23,97,47.5,97c0,0,0,0,0,0c7.1,0,14.2-1.7,20.6-5c21.7-11.3,30.2-38.1,19-59.9
            c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0L76,30.5c10.1,13.1,10,31.3-0.3,44.3c-6.9,8.7-17.2,13.7-28.3,13.7
            c-8.2,0-15.9-2.7-22.3-7.8c-7.6-6-12.3-14.5-13.4-24.1c-1.1-9.6,1.6-19,7.5-26.5c6.9-8.7,17.2-13.7,28.3-13.7c8,0,15.6,2.6,22,7.5
            l-1.6-10.6c0-0.2,0-0.4,0-0.5C61.6,9.7,54.6,8,47.5,8l0-0.2L47.5,8z M47.5,19.6c-7.3,0-14.4,2.4-20.1,6.9
            c-6.9,5.4-11.4,13.1-12.5,21.9c-1.1,8.7,1.2,17.3,6.6,24.3c6.3,8.1,15.8,12.8,26,12.8c7.3,0,14.3-2.4,20.1-6.9
            c14.3-11.1,16.9-31.6,6-46l-6.1,6.1c2.8,4.1,4.3,8.8,4.3,13.8c0,13.4-10.9,24.3-24.3,24.3c-13.4,0-24.3-10.9-24.3-24.3
            c0-13.4,10.9-24.3,24.3-24.3c5,0,9.7,1.5,13.8,4.3l6.1-6.1C61.7,22,54.7,19.6,47.5,19.6z M47.5,31.3c-11.7,0-21.2,9.5-21.2,21.2
            s9.5,21.2,21.2,21.2c11.7,0,21.2-9.5,21.2-21.2c0-4.1-1.2-8.1-3.5-11.6L59,47.2c1.6,3.4,1.6,7.4-0.1,10.8c-2.1,4.4-6.6,7.2-11.5,7.2
            c-1.9,0-3.7-0.4-5.5-1.2c-6.3-3-9-10.6-6-16.9c2.1-4.4,6.6-7.2,11.5-7.2c1.8,0,3.6,0.4,5.3,1.2l6.3-6.3
            C55.7,32.5,51.7,31.3,47.5,31.3l0-0.2L47.5,31.3z M47.5,42.9c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5
            c0-1-0.2-1.9-0.5-2.9l-4,4c-0.8,0.8-1.9,1.3-3.1,1.3c-1.2,0-2.3-0.5-3.1-1.3l-0.2-0.2l0,0c-1.5-1.7-1.4-4.3,0.2-6l4-4
            C49.5,43.1,48.5,43,47.5,42.9z M48.6,49.6c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4
            c0.3,0,0.6-0.1,0.9-0.4l38-38l-1.8-1.8L48.6,49.6z M86.2,28.9l8.7-8.7L87.2,19l-8.7,8.7L86.2,28.9z M72.2,21.6l8.7-8.7L79.8,5
            l-8.7,8.7L72.2,21.6z"/>
        </g>
      </svg>
      
    );
  }
}

export default Icon;
