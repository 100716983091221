import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class FeederMeteringChart extends React.Component<{ sanData: any, awaData: any, instData: any, categories: string, chartdata?: any }, any> {

  constructor(props: any) {
    super(props);
    const sanDataPer = 100;
    const awaDataPer = (parseInt(props.sanData) < parseInt(props.awaData)) ? 100 : (parseInt(props.awaData) * 100 / parseInt(props.sanData)).toFixed(2);
    const instDataPer = (parseInt(props.sanData) < parseInt(props.instData)) ? 100 : (parseInt(props.instData) * 100 / parseInt(props.sanData)).toFixed(2);

    this.state = {
      series: [sanDataPer, awaDataPer, instDataPer],
      options: {
        chart: {
          height: 165,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '40%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: '8px',
                fontFamily: 'Poppins',
                offsetY: 0,
              },
              value: {
                show: true,
                fontSize: '8px',
                fontFamily: 'Poppins',
                offsetY: 0,
              },
              total: {
                show: false,
                label: 'Total',
                formatter: function (w: any) {
                  var sum = parseInt(props.sanData) + parseInt(props.awaData) + parseInt(props.instData);
                  return sum;
                }
              }
            }
          }
        },
        colors: ['#feba3b', '#269ffa', '#62eeb6',],
        labels: ['Sanctioned', 'Awarded', 'Installed'],
      },
    };
  }
  componentDidUpdate(prevProps: any): void {
    if (prevProps != this.props) {
      const sanDataPer = 100;
      const awaDataPer = (parseInt(this.props.sanData) < parseInt(this.props.awaData)) ? 100 : (parseInt(this.props.awaData) * 100 / parseInt(this.props.sanData)).toFixed(2);
      const instDataPer = (parseInt(this.props.sanData) < parseInt(this.props.instData)) ? 100 : (parseInt(this.props.instData) * 100 / parseInt(this.props.sanData)).toFixed(2);

      const seriesData = [sanDataPer, awaDataPer, instDataPer];
      this.setState({ series: seriesData });
    }
  }
  render() {
    return (
      <div id="chart" className="progressDetailsChart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={165} />
      </div>

    );
  }
}
export default FeederMeteringChart;
