import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import InnerTemplate from '../InnerTemplate/InnerTemplate'
import './energy.scss'

export default function GeneralInformation(props:any) {
    return (
        <>
            <Header />
            <InnerTemplate page="EA" PageTitle="General Information" />
            <div className='eaWrap'>
                <div className='container'>
                    <div className="eaHeading">
                        <h4>General Information</h4>
                    </div>
                    <div className='eaTable table-responsive'>
                        
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
