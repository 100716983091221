import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect } from "../../../../assets/js/formbuilder";
import { Http } from '../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiUpload } from 'react-icons/bi';
import swal from 'sweetalert';
import CreateNewForm from './CreateNewForm';
import ViewSubmittedForms from './ViewSubmittedForms';
import { useStateMounted } from "../../../../Core/Hooks";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
// Images
import FieldComponent from "../../../../FieldComponent";
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
}

type ViewSubmittedFormsProps = {
    apiurl: string;
    districts: any;
    getData:any;
    months:any;
};

const PreDispatchInspectionForms = (props?: any) => {
    const syear: any = useRef(null);
    const formData: any = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useStateMounted<string>();
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomDistricts, setGetDistricts] = useState<any[]>([]);
    const [selected, setSelected] = useState("ViewSubmittedForms");
    const [isLoaded, setIsLoaded] = useState(false);
    const [getData, setData] = useState<any[]>([]);
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const [months, setCurrentMonth] = useState<any>(new Date().getMonth()+1);
    const [showTabClass, setTabClass] = useState<any>(null);
    const location = useLocation();

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
        setRoleid(Storage.get('roleid'));
    }, [discomid,roleid]);

    useEffect(() => {
        (async () => {
            if (props.location) {
                setDistricts(props.location.district_id);
                setCurrentMonth(parseInt(props.location.months));
            }
        })();
    }, [discomid]);

    useEffect(() => {
      setSelected("ViewSubmittedForms");
    }, []);

    const changeHandler = (e: any) => {
      setSelected(e.target.value);
    };
  
    const ChangeValue = (evt?: any) => {
        setDistricts(evt.target.value);
        setIsLoaded(true);
    };

    const ChangeValueMonth = (evt?: any) => {
        setCurrentMonth(evt.target.value);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if(apiurl) {
            await Http.get(apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setGetDistricts(result.district);
                    setTimeout(() => {
                    }, 2000);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                });
            }
        })();
    };

    useEffect(() => {
        if(discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id="+discomid);
        }
    }, [apiurl, discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

      useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);
   
    const getViewForm = () => {
        (async () => {
            if(districts || months) {
                setLoading(true);
                setRefresh(false);
                setError('');
                setData([]);
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 175,
                    district_id: districts,
                    months: months
                };
                
                await Http.get(`/apiv1/view-pre-dispatch-inspection`, { params })
                .then((res) => {
                    if (res._resultflag && res.data) {
                        if (res.data && Array.isArray(res.data)) {
                            if (!res.data.length) setError("No data found.");
                            setData(res.data);
                        } else {
                            setError("Invalid data type.");
                        }
                    } else if (!res._resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');
                    }
                })
                .catch((error) => {
                    setError('System error occurred!! please try again.');
                })
                .finally(() => {
                    setLoading(false);
                });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            if(selected == 'ViewSubmittedForms'){
                getViewForm();
            }
        })();
    }, [selected,districts,months]);

    useEffect(() => {
        if(roleid == 2){
            setTabClass('col-5 col-md-6');
        }else{
            setTabClass('col-5 col-md-12')
        }
    }, [discomid,roleid]);

    return (
            <>
            <section className="financialForm">
                <div className="container">
                        <div className="financialMainFrom inputFromNew">
                        <div className="tab-content border-0 p-0">
                            <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                <div className='radioTitleSection'>
                                <div className='row align-items-center'>
                                    <div className='col-md-2'>
                                    {
                                        discomDistricts ? <>
                                            <select className='form-select text-start w-100' onChange={ChangeValue} name="district_id" id="district_id">
                                                <option value={0}>Select District</option>
                                                {
                                                    discomDistricts.map((district: any) => {
                                                        // const attr = { value: district.id };
                                                        const attr = (district.id === parseInt(districts)) ? { value: district.id, selected: true } : { value: district.id };
                                                        return <><option {...attr}>{district.district_name}</option></>;
                                                    })
                                                }
                                            </select>
                                            </>
                                            : null
                                    }
                                    </div>
                                     <div className='col-md-2'>
                                            <select onChange={ChangeValueMonth} name="months" id="months" className="form-select" value={parseInt(months)} >
                                            <option value={0}>Select Month</option>
                                                <option value={4}>Apr</option>
                                                <option value={5}>May</option>
                                                <option value={6}>Jun</option>
                                                <option value={7}>Jul</option>
                                                <option value={8}>Aug</option>
                                                <option value={9}>Sep</option>
                                                <option value={10}>Oct</option>
                                                <option value={11}>Nov</option>
                                                <option value={12}>Dec</option>
                                                <option value={1}>Jan</option>
                                                <option value={2}>Feb</option>
                                                <option value={3}>Mar</option>
                                            </select>
                                        </div>
                                    <div className='col-md-6'>
                                    <div className="radioYear">
                                        <div className="row justify-content-center align-items-center mx-0 gx-0">
                                            <div className={showTabClass}>
                                                <div className="form-check">
                                                <input type="radio" className="form-check-input" name="PreDispatchInspection" value="ViewSubmittedForms" id="ViewSubmittedForms" checked={selected === "ViewSubmittedForms"} onChange={changeHandler} />
                                                <label className="form-check-label" htmlFor="ViewSubmittedForms">
                                                        View Submitted Forms
                                                </label>
                                                </div>
                                            </div>
                                            {(roleid == 2) ?
                                                <div className="col-5 col-md-6">
                                                    <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="PreDispatchInspection" value="CreateNewForm" id="CreateNewForm" checked={selected === "CreateNewForm"} onChange={changeHandler} />
                                                    <label className="form-check-label" htmlFor="CreateNewForm">Create New Form</label>
                                                    </div>
                                                </div>
                                             :null
                                            }
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div aria-hidden={selected !== "ViewSubmittedForms" ? true : false}><ViewSubmittedForms districts={districts} months={months} refresh={refresh} getData={getData}/></div>
                                {(roleid == 2) ?
                                    <div aria-hidden={selected !== "CreateNewForm" ? true : false}><CreateNewForm districts={districts} months={months} refresh={refresh} selected={selected}/></div>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* {isLoaded ? <LoadingSpinner /> : null} */}
            </>
    );
};
export default PreDispatchInspectionForms;