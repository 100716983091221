import React, { useEffect, useState } from "react";
import SmartMeteringAward from "./Award/SmartMeteringAward";
import LossReductionAward from "./Award/LossReductionAward";
import { useLayout } from "../../../../Core/Providers/LayoutProvider";

const ItemsAwardDetails = (props?:any) => {
    const [selected, setSelected] = useState("SMAWARD");
    const layout = useLayout();

    useEffect(() => {
        setSelected("SMAWARD");
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ awarditemstype: e.target.value });
    };

    
    return (
        <>
            <div className="position-relative">
                <div className="radioYear mb-2 py-xl-2">
                    <div className="year">
                        <div className="switch-field">
                            <input type="radio" value="SMAWARD" id="SMAWARD" checked={selected === "SMAWARD"} onChange={changeHandler} />
                            <label htmlFor="SMAWARD">Smart Metering</label>
                            <input type="radio" value="LRAWARD" id="LRAWARD" checked={selected === "LRAWARD"} onChange={changeHandler} />
                            <label htmlFor="LRAWARD">Loss Reduction</label>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div aria-hidden={selected !== "SMAWARD" ? true : false}><SmartMeteringAward isLoading={props.isLoading} refresh={props.refresh}/></div>
                    <div aria-hidden={selected !== "LRAWARD" ? true : false}><LossReductionAward isLoading={props.isLoading} refresh={props.refresh}/></div>
                </div>
            </div>
        </>
    );
}

const AwardSanction = (props?:any) => {
    return (
        <>
            <ItemsAwardDetails isLoading={props.isLoading} refresh={props.refresh}/>
        </>
    )
}

export default AwardSanction;