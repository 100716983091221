import React, { useEffect, useRef } from 'react';
import './App.css';
import './css/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import AppRoutes from './AppRoutes';
import "./css/responsive.scss";
import { LayoutProvider } from './Core/Providers/LayoutProvider';
import { TranslationProvider } from './Core/Providers/TranslationProvider';
import { AuthProvider, AuthConsumer } from './Core/Providers/AuthProvider';
import { ProviderComposer, provider } from './Core/Providers';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { inputEventChecker } from './assets/js/formbuilder';

function App() {

  const appTitle = process.env.REACT_APP_TITLE || 'Welcome to Government of India | Ministry of Power';
  
  const location = useLocation();

  // const footerContentRef = useRef<HTMLDivElement | null>(null);
  
  // useEffect(() => {
  //   footerContentRef.current = document.getElementById("footer-content") as HTMLDivElement;
  //   const root = document.getElementById("root");
  //   if (footerContentRef.current && root) {
  //     root.appendChild(footerContentRef.current);
  //   }
  //   return () => {
  //     if (footerContentRef.current && root) {
  //       root.removeChild(footerContentRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    inputEventChecker.removeAllEvent();
    const path = location.pathname;
    const body: any = document.querySelector('body');
    const classNames = path.split('/').filter((part) => part !== '');
    body.className = classNames.join(' ');
    // Clean up the class name when the component unmounts
    return () => {
      body.className = '';
    };
  }, [location]);

  return (
    // <div className='allWrap'>
      <ProviderComposer
        providers={
          [
            provider(HelmetProvider),
            provider(TranslationProvider),
            provider(AuthProvider),
            provider(LayoutProvider)
          ]
        }>
        <Helmet
          titleTemplate={'%s - ' + "Ministry of Power"}
          defaultTitle={appTitle} />
        <AuthConsumer>
          {
            () => (<AppRoutes />)
          }
        </AuthConsumer>
      </ProviderComposer>
    // </div>
  );
}

export default App;