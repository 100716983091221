import React, { useState, useEffect } from 'react'
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import SideBar from "../Components/DashboardComponents/SideBar/SideBar";
import { Link } from "react-router-dom";
import { BsArrowLeft, BsSearch } from "react-icons/bs";
import DoughnutChart from "../Components/DashboardComponents/Chart/DoughnutChart";
import LineChart from "../Components/DashboardComponents/Chart/LineChart";

// Datepicker
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import { GoCalendar } from "react-icons/go";

// Images
import TargetIcon from "../images/target-icon.svg";
import InstalledIcon from "../images/installed-icon.svg";
import PendingIcon from "../images/pending-icon.svg";
import enGB from 'date-fns/esm/locale/en-GB/index.js';


function DetailedDashboard() {
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [consumerStartDate, setConsumerStartDate] = useState<any>();
    const [consumerEndDate, setConsumerEndDate] = useState<any>();
    const [fundStartDate, setFundStartDate] = useState<any>();
    const [fundEndDate, setFundEndDate] = useState<any>();

    useEffect(() => {
        setConsumerStartDate(startDate);
        setFundStartDate(startDate);
    }, [startDate]);

    useEffect(() => {
        setConsumerEndDate(endDate);
        setFundEndDate(endDate);
    }, [endDate]);

    return (
        <>
            <div className="mainWrap dashboard">
                <Header />
                <div className="container">
                    <div className="dashboardInner detailedDashboard">
                        <div className="row gx-xxl-5">
                            <div className="col-xxl-1 col-xl-1">
                                <SideBar />
                            </div>
                            <div className="col-xxl-11 col-xl-10">
                                <div className="heading">
                                    <div className="row align-items-center mb-4">
                                        <div className="col-md-8">
                                            <h2 className="mb-0">Detailed Dashboard</h2>
                                        </div>
                                        <div className="col-md-4 text-end">
                                            <Link to="/dashboard" className="backBtn text-decoration-none"><BsArrowLeft /> Back to Main Dashboard</Link>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-md-2">
                                            <select className="form-select">
                                                <option selected>Select Discom</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-select">
                                                <option selected>Infrastructure</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-select">
                                                <option selected>Select Level</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <DateRangePicker
                                                startDate={startDate}
                                                endDate={endDate}
                                                onStartDateChange={setStartDate}
                                                onEndDateChange={setEndDate}
                                                minimumDate={new Date()}
                                                minimumLength={1}
                                                format='dd MMM yyyy'
                                                locale={enGB}
                                            >
                                                {({ startDateInputProps, endDateInputProps, focus }) => (
                                                    <div className='date-range'>
                                                        <div>
                                                            <input
                                                                className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                                                {...startDateInputProps}
                                                                placeholder='Start date'
                                                            />
                                                            <GoCalendar />
                                                        </div>
                                                        <div>
                                                            <input
                                                                className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                                                {...endDateInputProps}
                                                                placeholder='End date'
                                                            />
                                                            <GoCalendar />
                                                        </div>
                                                    </div>
                                                )}
                                            </DateRangePicker>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-select">
                                                <option selected>Select Frequency</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-md-1 text-end">
                                            <Link to="/" className="SearchBtn"><BsSearch /></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="consumer">
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <h4>Consumer Smart Metering</h4>
                                        </div>
                                        <div className="col-md-3">
                                            <DateRangePicker
                                                startDate={consumerStartDate}
                                                endDate={consumerEndDate}
                                                onStartDateChange={setConsumerStartDate}
                                                onEndDateChange={setConsumerEndDate}
                                                minimumDate={new Date()}
                                                minimumLength={1}
                                                format='dd MMM yyyy'
                                                locale={enGB}
                                            >
                                                {({ startDateInputProps, endDateInputProps, focus }) => (
                                                    <div className='date-range'>
                                                        <div>
                                                            <input
                                                                className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                                                {...startDateInputProps}
                                                                placeholder='Start date'
                                                            />
                                                            <GoCalendar />
                                                        </div>
                                                        <div>
                                                            <input
                                                                className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                                                {...endDateInputProps}
                                                                placeholder='End date'
                                                            />
                                                            <GoCalendar />
                                                        </div>
                                                    </div>
                                                )}
                                            </DateRangePicker>
                                        </div>
                                    </div>
                                    <LineChart />
                                </div>
                                <div className="fundSection">
                                    <div className="row">
                                        <div className="col-md-4 col-xxl-4">
                                            <div className="funds">
                                                <div className="target">
                                                    <img src={TargetIcon} alt="Target Icon" />
                                                    <div>
                                                        <h4>2,50,600</h4>
                                                        <p>Total Smart Meters Target</p>
                                                    </div>
                                                </div>
                                                <div className="installed">
                                                    <img src={InstalledIcon} alt="Installed Icon" />
                                                    <div>
                                                        <h4>65,850</h4>
                                                        <p>Total Smart Meters Installed</p>
                                                    </div>
                                                </div>
                                                <div className="pending">
                                                    <img src={PendingIcon} alt="Pending Icon" />
                                                    <div>
                                                        <h4>1,84,750</h4>
                                                        <p>Total Smart Meters Pending</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-xxl-8">
                                            <div className="fundUtilization">
                                                <div className='row'>
                                                    <div className='col-md-7'>
                                                        <h4>Funds Utilization</h4>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <DateRangePicker
                                                            startDate={fundStartDate}
                                                            endDate={fundEndDate}
                                                            onStartDateChange={setFundStartDate}
                                                            onEndDateChange={setFundEndDate}
                                                            minimumDate={new Date()}
                                                            minimumLength={1}
                                                            format='dd MMM yyyy'
                                                            locale={enGB}
                                                        >
                                                            {({ startDateInputProps, endDateInputProps, focus }) => (
                                                                <div className='date-range'>
                                                                    <div>
                                                                        <input
                                                                            className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                                                            {...startDateInputProps}
                                                                            placeholder='Start date'
                                                                        />
                                                                        <GoCalendar />
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                                                            {...endDateInputProps}
                                                                            placeholder='End date'
                                                                        />
                                                                        <GoCalendar />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                                <DoughnutChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer remove='d-none' />
            </div>
        </>
    );
}

export default DetailedDashboard;