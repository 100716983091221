import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm } from "../../assets/js/formbuilder";
import { Http } from '../../Core/Services/HttpService';
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';
import InnerTemplate from "../../Components/InnerTemplate/InnerTemplate";
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import FieldComponent from "../../FieldComponent";
import { Storage } from "../../Core/Services/StorageService";
import FdDiscomName from "./FdDiscomName";

const AuditCertificateForm = (props: any) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);

    useEffect(() => {
        (async () => {
            await Http.get(props.apiurl)
                .then((result) => {
                    setIsLoaded(true);
                    setFields(result.fields);
                    setTimeout(() => {
                        init();
                    }, 500);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        })();
    }, [props.apiurl]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <>
                <div className="table-responsive RevenueDetailsTable mt-4">
                    <table className="table caption-top">
                        <caption>Amount received by DISCOMs</caption>
                        <thead className='text-center'>
                            <th style={{ width: "50px" }}>S.No</th>
                            <th>Description</th>
                            <th>Amount received during the current FY (INR Cr)</th>
                            <th>Cumulative amount received under RDSS (INR Cr)</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Grant Received from MoP</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} /></td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} /></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Counterpart Fund Received from FIs/Banks</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} /></td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} /></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Own resources</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} /></td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[7].key} field={fields[7]} /></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Interest earned on GoI Grant</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} /></td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} /></td>
                            </tr>
                            <tr>
                                <td className='text-center' colSpan={2}>Total</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} /></td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive RevenueDetailsTable mt-4">
                    <table className="table caption-top">
                        <caption>Expenditure by DISCOM</caption>
                        <thead className='text-center'>
                            <th style={{ width: "50px" }}>S.No</th>
                            <th>Description</th>
                            <th>Amount received during the current FY</th>
                            <th colSpan={2}>Cumulative amount received under RDSS (INR Cr)</th>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td rowSpan={4}>1</td> */}
                                <td>1</td>
                                <td>Payment to contractors/agency</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} /></td>
                                <td colSpan={2}> <FieldComponent options={{ showTitle: false }} fieldName={fields[15].key} field={fields[15]} /></td>
                            </tr>
                            {/* <tr>
                                <td>Contractor1</td>
                                <td><input type="text"/></td>
                                <td><input type="text"/></td>
                                <td className='text-end pe-1' style={{ width: "50px" }}><Link to="#" className='p-0 text-success'><FiPlusCircle className='h5 p-0 mb-0'/></Link></td>
                            </tr>
                            <tr>
                                <td>Contractor2</td>
                                <td><input type="text"/></td>
                                <td><input type="text"/></td>
                                <td className='text-end'><Link to="#" className='text-danger'><FiTrash2 className='p-0 mb-0 h5'/></Link></td>
                            </tr>
                            <tr>
                                <td>Contractor3</td>
                                <td><input type="text"/></td>
                                <td><input type="text"/></td>
                                <td className='text-end'><Link to="#" className='text-danger'><FiTrash2 className='p-0 mb-0 h5'/></Link></td>
                            </tr> */}
                            <tr>
                                <td>2</td>
                                <td>Payment to PMA</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[11].key} field={fields[11]} /></td>
                                <td colSpan={2}> <FieldComponent options={{ showTitle: false }} fieldName={fields[16].key} field={fields[16]} /></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Interest on GoI Grant refunded to MoP</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[12].key} field={fields[12]} /></td>
                                <td colSpan={2}> <FieldComponent options={{ showTitle: false }} fieldName={fields[17].key} field={fields[17]} /></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Any other payment</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} /></td>
                                <td colSpan={2}> <FieldComponent options={{ showTitle: false }} fieldName={fields[18].key} field={fields[18]} /></td>
                            </tr>
                            <tr>
                                <td className='text-center' colSpan={2}>Total</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[14].key} field={fields[14]} /></td>
                                <td colSpan={2}> <FieldComponent options={{ showTitle: false }} fieldName={fields[19].key} field={fields[19]} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive RevenueDetailsTable mt-4">
                    <table className="table caption-top">
                        <caption>Balance Available</caption>
                        <thead className='text-center'>
                            <th style={{ width: "50px" }}>S.No</th>
                            <th>Particulars</th>
                            <th>Amount</th>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td rowSpan={4}>1</td> */}
                                <td>1</td>
                                <td>Balance in TSA Account with RBI</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[20].key} field={fields[20]} /></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Balance in Treasury A/C for tax payments & CP Funding</td>
                                <td> <FieldComponent options={{ showTitle: false }} fieldName={fields[21].key} field={fields[21]} /></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={fields[22].key} field={fields[22]} /></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Upload Document</td>
                                <td><FieldComponent options={{ showTitle: false }} fieldName={fields[23].key} field={fields[23]} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </>
        );
    }
};

const AuditCertificateForms = () => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [act, setAct] = useState<any>(0);
    const formData: any = useRef(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getreleasefundid, setReleaseFundId] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        console.log('main');
        (async () => {
            if (location.state) {
                getDocumentation(location.state.data);
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setReleaseFundId(location.state.id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
            }
            else {
                navigate('/fund-disbursal');
            }
        })();
    }, [getcomponentid, gettrancheid, getaplicationtrackerid]);

    const navtab = () => {
        setPageLink('/fund-disbursal-link');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
            }
        })();
    }, [getpagelink]);


    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        roleid == 4 ? setAct('view') : setAct('act');
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (roleid == 2 || roleid == 3 || roleid == 4) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let myFormData = new FormData(formData.current);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", getreleasefundid);
            myFormData.append('component_id', getcomponentid);
            myFormData.append("tranche_id", gettrancheid);
            myFormData.append("aplication_tracker_id", getaplicationtrackerid);
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                        navigate('/fund-disbursal-link', { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
                    }
                });
        }

    }

    return (
        <>
            <div className="mainWrap">
                <Header />
                <InnerTemplate PageTitle="Audit Certificate" />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <form noValidate onSubmit={onSubmit} ref={formData}>
                                        <div>
                                            <div>
                                                <div>
                                                    <div className="table-responsive RevenueDetailsTable PqTable">
                                                        <table className="table">
                                                            <tbody>
                                                                {roleid == 0 ? null :
                                                                    <AuditCertificateForm apiurl={"/apiv1/fdfields/?form_id=27&act=" + act + "&roleid=" + roleid + "&discom_id=" + discomid + "&discom_user_id=" + discomuserid + "&application_id=" + getaplicationtrackerid} />
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="formBtnSection justify-content-between">
                                                    <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab()}>Back</Link>
                                                    {roleid == 2 || roleid == 3 || roleid == 4 ?
                                                        <button className="btnDraft" type="submit">Save & Back</button>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    )
}
export default AuditCertificateForms;
