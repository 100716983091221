import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../../../assets/js/formbuilder";
import { Http } from '../../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { useStateMounted } from '../../../../../Core/Hooks';
import FieldComponent from "../../../../../FieldComponent";
import swal from 'sweetalert';
import { Alert } from 'react-bootstrap';
import { BiUpload, BiDownload } from 'react-icons/bi';
import { BsFileEarmarkExcel, BsFileEarmarkPdf } from 'react-icons/bs';
import QueryString from 'qs';
import { Storage } from "../../../../../Core/Services/StorageService";

type PropsItems = {
    year: any;
};

// Multijson
type MultijsonProps = {
    field: any;
    index: number,
    totalField: any,
    view: any
};

const multijsonPropTypes = {
    field: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    totalField: PropTypes.any.isRequired,
};

function Multijson({ field, index, totalField, view }: MultijsonProps) {

    let srNo = 1;
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            srNo = 1;
            setTimeout(() => {
                init();
                multijsoninit();
            }, 500);
        }
    }, [fields]);

    const addNewField = () => {
        setFields((prev: any[]) => {
            return [...prev, { fields: newFields, delete: false }];
        });
        setTimeout(() => {
            init();
            multijsoninit();
        }, 500);
    }

    const removeField = (index: number) => {
        setFields((prev: any[]) => {
            const newFields = prev.map((item, i) => {
                if (i === index) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });
        setTimeout(() => {
            init();
            multijsoninit();
        }, 500);
    }

    return (
        <>
            <thead className='text-center'>
                <tr>
                    <th style={{ minWidth: 10, width: 10 }}>S.No.</th>
                    <th style={{ minWidth: 300, width: 300 }}>Name of Discom </th>
                    <th style={{ minWidth: 100, width: 200 }}>Amount (INR)</th>
                    <th style={{ minWidth: 100, width: 200 }}>Date of Lapsed</th>
                    <th style={{ minWidth: 100, width: 200 }}>Remarks</th>
                    {view != 'Y' ?
                        <th style={{ minWidth: 10, width: 10 }}>
                            <button type="button" className='p-0 text-success btn' onClick={addNewField}>
                                <FiPlusCircle className="h5 p-0 mb-0" />
                            </button>
                        </th>
                        : null
                    }

                </tr>
            </thead>
            <tbody>
                {
                    fields.map((item: any, key: number) => {
                        return (item.delete === false)
                            ? (
                                <>
                                    <tr className="align-top">
                                        <td><span className="text-center">{srNo++}</span></td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} />
                                                    </td>
                                                </>;
                                            })
                                        }
                                        {view != 'Y' ?
                                            <td className="text-end">{(hasFieldDelete) ? <button type="button" className='btn text-danger btn' onClick={() => removeField(key)}><FiTrash2 className="p-0 mb-0 h5" /></button> : null}</td>
                                            : null
                                        }
                                    </tr>
                                </>
                            )
                            : null;
                    })
                }

            </tbody>
        </>
    );
}

const GrantLapsedDiscom = ({ year }: PropsItems) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [_discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const [error, setError] = useStateMounted<string>();
    const formData: any = useRef(null);
    const [reportlink, setReportLink] = useState<any>(null);
    const [reportCumulativeGrantlink, setCumulativeGrantLink] = useState<any>(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
    const [nodalAgencyid, setNodalAgencyid] = useState<any>(0);
    const [view, setView] = useState<any>(null);
    const [excelLink, setExcelLink] = useStateMounted('#');
    const [pdfLink, setPdfLink] = useStateMounted('#');

    let location = useLocation();
    let navigate = useNavigate();

    const exportPdfLink = () => {
        const params = {
            roleid: roleid,
            form_id: 186,
            year: year,
            nodal_agency: nodalAgencyid,
            discom_user_id: discomuserid,
            export_type: 'pdf'
        };
        let plink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/frexport'].join('/');
        plink += '?' + QueryString.stringify(params);
        setPdfLink(plink);
    }

    const exportExcelLink = () => {
        const params = {
            roleid: roleid,
            form_id: 186,
            year: year,
            nodal_agency: nodalAgencyid,
            discom_user_id: discomuserid,
            export_type: 'excel'
        };
        let elink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/frexport'].join('/');
        elink += '?' + QueryString.stringify(params);
        setExcelLink(elink);
    }

    const clikToShowReport = () => {
        setReportLink('/fr-sm-gl-report');
    }

    useEffect(() => {
        (async () => {
            if (reportlink && year && nodalAgencyid) {
                navigate(reportlink, { state: { year: year, nodal_agency: nodalAgencyid } });
            }
        })();
    }, [reportlink, year, nodalAgencyid]);


    const clikToCumulativeGrantReport = () => {
        setCumulativeGrantLink('/fr-sm-cum-grant-report');
    }

    useEffect(() => {
        (async () => {
            if (reportCumulativeGrantlink && year && nodalAgencyid) {
                navigate(reportCumulativeGrantlink, { state: { year: year, nodal_agency: nodalAgencyid } });
            }
        })();
    }, [reportCumulativeGrantlink, year, nodalAgencyid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setNodalAgencyid(Storage.get('nodal_agency'));
    }, [roleid, discomuserid]);

    const getFields = () => {
        (async () => {
            setLoading(true);
            setError('');
            setFields([]);
            if (roleid == 5 || roleid == 6) {
                const params = {
                    roleid: roleid,
                    form_id: 186,
                    year: year,
                    nodal_agency: nodalAgencyid,
                    discom_user_id: discomuserid
                };

                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/frfields`, { params })
                    .then((res) => {
                        if (res.resultflag && res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setFields(res.fields);
                                setView(res.view);
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setLoading(false);
                        footerspinner.classList.add('d-none');
                    });
            }

        })();
    };

    useEffect(() => {
        if (year && roleid) {
            getFields();
        }
    }, [year, roleid]);

    useEffect(() => {
        if (fields.length) {
            setTimeout(() => {
                init();
                multijsoninit();
            }, 1000);
        }
    }, [fields]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('year', year);
        myFormData.append('role_id', roleid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('nodal_agency', nodalAgencyid);

        Http.post('/apiv1/save-fr-sm-gl-discom', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    const importFileEle: any = document.getElementById(`sm_gl_discom_fund_released_import_data_file`);
                    if (importFileEle !== null) {
                        const dataTransfer = new DataTransfer();
                        importFileEle.files = dataTransfer.files;
                    }
                    swal("Grant Lapsed Form", "Grant lapsed form - data has been saved", "success");
                    getFields();
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    const handleFileInput = (e: any) => {
        setIsLoaded(true);
        setRefresh(false);
        setError('');
        setFields([]);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("file_to_upload", e.target.files[0]);
        formData.append("form_id", "186");
        formData.append('year', year);
        formData.append('role_id', roleid);
        formData.append('discom_user_id', discomuserid);
        formData.append('nodal_agency', nodalAgencyid);

        Http.post('/apiv1/sm-import-fr-gl-discom', formData, config)
            .then((res) => {
                if (res.resultflag && res.fields) {
                    if (res.fields && Array.isArray(res.fields)) {
                        if (!res.fields.length) setError("No data found.");
                        setFields(res.fields);
                        console.log("File uploaded successfully");
                    } else {
                        setFields([]);
                        setError("This file format is wrong.");
                    }
                } else if (!res.resultflag) {
                    setError(res.message);
                } else {
                    setError('No data found.');
                }
                setIsLoaded(false);
            }).catch((error) => {
                setFields([]);
                setIsLoaded(false);
                setError('This file format is wrong.');
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }

    useEffect(() => {
        if (year) {
            let samplelink = process.env.REACT_APP_API_ENDPOINT + '/uploads/fund-released/FR-Smart-Metering-GL-Discom.xlsx';
            setSampleFileLink(samplelink);
        }
    }, [year]);

    return (
        <>
            <form noValidate onSubmit={onSubmit} ref={formData}>
                <section className="financialForm mt-3">
                    <div className='row p-2 align-items-center'>
                        {
                            (year != '' && view != 'Y') ? <>
                                <div className='col-md-8'></div>
                                <div className='col-md-1'>
                                    <div className="form-field">
                                        <div className="fileUpload position-relative text-center">
                                            <div className="downEqule">
                                                <input type="file" onChange={handleFileInput} data-prefix="sm_gl_fund_released" className="form-control form-group" id="sm_gl_discom_fund_released_import_data_file" name="sm_gl_discom_fund_released_import_data_file" data-files-allowed="xlsx" data-size="auto" data-size-allowed="Y" />
                                                <div className="customUpload justify-content-center flex-wrap">
                                                    <span><BiUpload /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1'>
                                    <div className="w-2">
                                        <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                                    </div>
                                </div>
                            </>
                                :
                                <div className='col-md-10'></div>
                        }

                        <div className='col-md-2'>
                            <Link title="Export in Excel" to={excelLink} onClick={() => exportExcelLink()} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                            <Link title="Export in PDF" to={pdfLink} onClick={() => exportPdfLink()} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                        </div>

                        {error && (
                            <>
                                <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
                            </>
                        )}
                    </div>
                    {
                        ((!loading && !error) && fields?.length > 0) && (
                            <>
                                {(year != '') ?
                                    <div className="award-table pe-3">
                                        <table className="table-responsive RevenueDetailsTable PqTable multiTableTD">
                                            {
                                                fields.map((field: any, index: number) => {
                                                    const totalField = (fields.length && fields[0].childrens) ? fields[0].childrens : null;
                                                    return field.type === 'multijson' && (
                                                        <Multijson field={field} index={index} totalField={totalField} view={view} />
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                    :
                                    <>
                                        <div className='col-md-12 p-2'>
                                            <div className="alert alert-info w-100 text-center" role="alert">
                                                Please select Year
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        )
                    }
                    <div className="formBtnSection is-sticky d-block">
                        <div className="d-flex justify-content-between">
                            {(year != '') && (roleid == 5 || roleid == 6) ?
                                <>
                                    <button className='btn btn-info' type="button" onClick={() => clikToShowReport()}>Show Report</button>
                                    <button className='btn btn-info' type="button" onClick={() => clikToCumulativeGrantReport()}>Cumulative Grant Report</button>
                                </>
                                : null
                            }
                            {(year != '') && (roleid == 5) ?
                                <>
                                    {(view != 'Y') ?
                                        <>
                                            <button className="btn btnSubmit" type="submit">Save</button>
                                        </>
                                        : null
                                    }
                                    {/* <button className="btn btnSubmit" type="submit">Save as Draft</button> */}
                                </>
                                : null
                            }
                        </div>
                    </div>
                </section>
            </form>
        </>
    );
};

export default GrantLapsedDiscom;