import React, { useEffect, useState } from "react";
import SanctionLetter from "../Section2/SanctionLettter/SanctionLetter";
import DPR from "./DPR/DPR";
import { Http } from "../../../Core/Services/HttpService";
import { Storage } from "../../../Core/Services/StorageService";

const Section2 = (propr:any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submittedStatus, setSubmittedStatus] = useState<any>(0);
    const getSubmittedStatus = () => {
        (async () => {
            if(discomid) {
              setIsLoading(true);
            await Http.get("/apiv1/sanction-block-status?form_id=&discom_id="+discomid)
                .then((res) => {
                    setIsLoaded(true);
                    setIsLoading(false);
                    setSubmittedStatus(res.submitted_status);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                });
            }
        })();
      };

      useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
      }, [discomid]);
        
      useEffect(() => {
        (async () => {
            getSubmittedStatus();
        })();
      }, [discomid]);
      
    return (
        <>
            <section className="financialForm">
                <div className="financialMainFrom">
                    <ul className="nav nav-tabs row gx-0 tabNavigation border-0" id="financialForm" role="tablist">
                        <li className="nav-item col-sm-4 col-md-3 col-lg-2" role="presentation">
                            <button className="nav-link active" id="SanctionLetterTab" data-bs-toggle="tab" data-bs-target="#SanctionLetter" type="button" role="tab" aria-controls="SanctionLetter" aria-selected="true">Sanction Details</button>
                        </li>
                        <li className="nav-item col-sm-4 col-md-3 col-lg-2" role="presentation">
                            <button className="nav-link" id="DPRTab" data-bs-toggle="tab" data-bs-target="#DPR" type="button" role="tab" aria-controls="DPR" aria-selected="false">Award Details</button>
                        </li>
                    </ul>
                    <div className="tab-content p-0 border-0" id="myTabContent">
                        <div className="tab-pane fade show active" id="SanctionLetter" role="tabpanel" aria-labelledby="SanctionLetterTab">
                            <SanctionLetter/>
                        </div>
                        <div className="tab-pane fade" id="DPR" role="tabpanel" aria-labelledby="DPRTab">
                            { submittedStatus == 1 ?
                                <DPR/>
                                : <div className="mt-2 alert alert-info">Please submit sanction data</div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Section2;