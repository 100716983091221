import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import Header from './Element/Header';
import Footer from './Element/Footer';
import { useAuth } from '../../Core/Providers';
import { Storage } from '../../Core/Services/StorageService';

function Default(props: any) {

  const isAuthorized = useAuth().isAuth();

  useEffect(() => {
    if (!isAuthorized) {
      Storage.delete('roleid');
      Storage.delete('username');
    }
  }, [isAuthorized]);

  return (
    <>
      <Header />
      <div className="mainWrap">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default Default;
