import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';

import { Http } from "../../Core/Services/HttpService";

import GenerationTransmissionPeriphery from "../../Components/EngeryAccounting/ReceivedSourcesForms/GenerationTransmissionPeriphery";
import EmbeddedGeneration from "../../Components/EngeryAccounting/ReceivedSourcesForms/EmbeddedGeneration";

import { useLayout } from "../../Core/Providers/LayoutProvider";

import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import InnerTemplate from '../InnerTemplate/InnerTemplate'
import { Storage } from "../../Core/Services/StorageService";

export default function EvaluationForm(props: any) {
    return (
        <div className="App">
            <PlaceOrder />
        </div>
    );
}

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}



export const PlaceOrder = (props: any) => {
    const formData: any = useRef(null);
    const layout = useLayout();
    const syear: any = useRef(null);
    const [year, setYear] = useState("2022");
    const [tblkey, setTblkey] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [selected, setSelected] = useState("Section1");
    const [steps, setSteps] = useState(['active', '', '', '']);

    const refreshform = () => {
        setYear(syear.current.value);
        setSelected("Section1");
    }
    const [isSubmittedStatus, setSubmittedStatus] = useState('');
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [remarks, setRemarks] = useState('');

    let layoutDataSubscribe: any;
    const [roleid, setRoleid] = useState<any>(0);

    useEffect(() => {
        console.log(tblkey);

    }, [tblkey]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        (async () => {
            layoutDataSubscribe = await layout.data.subscribe((res: any) => {
                setSubmittedStatus(res.submittedStatus.submittedStatus);
                setRemarks(res.submittedStatus.remarks);
            });
        })();
        return () => {
            if (layoutDataSubscribe !== undefined) {
                layoutDataSubscribe.unsubscribe();
            }
        }
    }, []);


    function onSubmit(e: any) {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        // console.log(validatefrm());
        let myFormData = new FormData(e.target);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', tblkey);
        myFormData.append('discom_id', discomid);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        Http.post('/apiv1/save-form', myFormData, config)
            .then((res) => {
                setIsLoading(false);
                // window.location.reload();
                setRefresh(true);
                swal("Input Form", "Data have been saved", "success");
            });

    }
    const submittoreviewer = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);

        swal({
            title: "Are you sure to submit this form?",
            text: "After submit, You will not able to edit this form.",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Input Form!',
                    text: 'Your data has been submitted.',
                    icon: 'success'
                }).then(function () {
                    Http.post('/apiv1/forward', myFormData, config)
                        .then((res) => {
                            setIsLoading(false);
                            setRefresh(true);
                            // swal("Input Form", "Data has been forwarded", "success");
                        });
                });
            } else {
                setIsLoading(false);
                // swal("Cancelled", "Your data has been is safe :)", "error");
            }
        });

    }
    // Nav Steps
    const navtab = (opt: number) => {
        console.log(opt);
        if (opt == 0)
            setSteps(['active', '', '', '']);
        if (opt == 1)
            setSteps(['completed', 'active', '', '']);
        setGoSteps(opt);
    }
    const next = (opt: number) => {
        if (opt == 0)
            setSteps(['active', '', '', '']);
        if (opt == 1)
            setSteps(['completed', 'active', '', '']);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        // myFormData.append('year', year);
        Http.post('/apiv1/next-form', myFormData, config)
            .then((res) => {
                // console.log(res);
            });

        setGoSteps(opt);
    }

    const submittobackward = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);

        swal({
            title: "Are your sure want to return this form?",
            text: "",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/backward', myFormData, config)
                    .then((res) => {
                        if (res._resultflag == 0) {
                            setIsLoading(false);
                            swal("Warning", res.message, "info");
                        } else {
                            setIsLoading(false);
                            setRefresh(true);
                            swal("Input Form", "The form has been returned for correction", "success");
                        }
                    });

            } else {
                setIsLoading(false);
                // swal("Cancelled", "Your data has been is safe :)", "error");
            }
        });

    }

    const [goSteps, setGoSteps] = useState(0);

    useEffect(() => {
        setSelected("Section1");
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };
    const sanctionLetterMainForm = () => {
        document.body.classList.add('sanctionLetterMainForm');
    };
    const FinancialMainForm = () => {
        document.body.classList.remove('sanctionLetterMainForm');
    };

    return (
        <>
            <Header />
            <InnerTemplate page="EA" PageTitle="Details of Received Sources" />
            <div className='eaWrap'>
                <div className="container">
                    <div className="financialMainFrom">
                        <div className="tab-content border-0 p-0">
                            <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                <div>
                                    <div className='container'>
                                        <div className='customStepper'>
                                            <div className="StepperContainer-0-2-1">
                                                <div className="StepContainer-0-2-2">
                                                    <div className="StepMain-0-2-3">
                                                        <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                                                        </button>
                                                        <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Generation at Transmission Periphery</span></div>
                                                    </div>
                                                </div>
                                                <div className="StepContainer-0-2-2">
                                                    <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                                                    <div className="StepMain-0-2-3">
                                                        <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                                                            <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                                                        </button>
                                                        <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Embedded Generation in DISCOM Area</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form noValidate onSubmit={onSubmit} ref={formData}>
                                        {goSteps === 0 && (
                                            <div>
                                                <div>
                                                    <GenerationTransmissionPeriphery year={year} act="view" refresh={refresh} tblkey={setTblkey} />
                                                </div>
                                                <div className="formBtnSection justify-content-between">
                                                    <div></div>
                                                    <button className="btnDraft" type="submit">Save as Draft</button>
                                                    {/*{roleid == 2 || roleid == 3 || roleid == 4 ?
                                                        <>
                                                            {isSubmittedStatus == "1" ?
                                                                <button className="btnDraft" type="submit">Save as Draft</button>
                                                                : null
                                                            }

                                                        </> : null
                                                    }*/}
                                                    <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
                                                </div>
                                            </div>
                                        )}
                                        {goSteps === 1 && (
                                            <div>
                                                <div>
                                                    <EmbeddedGeneration year={year} refresh={refresh} tblkey={setTblkey} />
                                                </div>
                                                <div className="formBtnSection justify-content-between">
                                                    <Link to="#" className="btn btnSubmit me-4" onClick={() => next(0)}>Previous</Link>
                                                    <button className="btnDraft" type="submit">Save as Draft</button>
                                                    {/*{roleid == 2 || roleid == 3 || roleid == 4 ?
                                                        <>
                                                            {isSubmittedStatus == "1" ?
                                                                <button className="btnDraft" type="submit">Save as Draft</button>
                                                                : null
                                                            }

                                                        </> : null
                                                    }*/}
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
