import React from 'react';
import { useLocation } from 'react-router';
import Routes from '../../Core/Components/Routes';
import routes from './Config/Routes';
import Default from '../../Layout/Default';
import { useAuth } from '../../Core/Providers/AuthProvider';
import './rdss-cb.scss';

function Index(props: any) {

  const auth = useAuth();
  let location = useLocation();

  const Loading = () => (
    <div className='container mt-3'>
      <div className="content-spinner spinner-container h-50vh">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="app-rdss-cb">
        <Routes
          loading={<Loading />}
          location={location}
          redirect="/sign-in"
          layout={<Default />}
          isAuthorized={auth.isAuth()}
          routes={routes || []}
          notFound="/404"
        />
      </div>
    </>
  );

}

export default Index;
