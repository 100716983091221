import React, { useEffect, useRef, useState } from "react";
import { Http } from '../../Core/Services/HttpService';
import ExcelIcn from '../../images/excel.svg';
import PdfIcn from '../../images/pdf.svg';
import Select from "react-select";
import { RiFileExcel2Line } from "react-icons/ri";
import { BsSearch, BsFillFileEarmarkPdfFill, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { constant, set } from "lodash";
import { Link } from "react-router-dom";
import { sub } from "date-fns";

const SmCustomReports = (props: any) => {
    
    const [sanctionLetterData, setSanctionLetterData] = useState<any>();
    const [selectedDistrict, setSelectedDistrict] = useState<any>(0);
    const [selectedState, setSelectedState] = useState<any>(0);
    const [reportType, setReportType] = useState<any>('Allindia');
    const [misReportDataCount, setMisReportDataCount] = useState<any>(0);
    const [searchText, setSearchText] = useState<any>();
    const [statesList, setStatesList] = useState([{ 'id': 0, 'title': 'All' }]);
    const [getstate, setState] = useState<any>(0);
    const [district, setDistrict] = useState<any>(0);
    const [selectedSummaryReport, setSelectedSummaryReport] = useState<any>(0);
    const [getstatesapiurl, setStatesApiurl] = useState<any>(null);
    const [reportCols, setReportCols] = useState<any>(null);
    const [drildown, setDrildown] = useState(false);
    const [option, setOption] = useState('C');
    const [subtotal, setSubtotal] = useState('N');

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const searchParams = new URLSearchParams(window.location.search);
    const statefilter = searchParams.get('statefilter');

    const handleStateSelect = (SelectedState: any) => {
        setCurrentPage(0);
        if (reportType == 'DisStates')
            setState(SelectedState.map((item: any) => item.value).join(","));
        else
            setState(SelectedState.value);
        setSelectedState(SelectedState);
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value);
    }

    const handleSearchEnter = (event: any) => {
        if (event.key === 'Enter') {
            fetchSanctionLetterData(searchText);
        }
    }

    const fetchSanctionLetterData = async (search: any, optParams?: {}) => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        let params = {
            reportType: reportType,
            searchText: searchText,
            stateid: getstate,
            module: 'sm',
            page: curPage,
            pageSize: pageSize,
            consolidated: option,
            subtotal: subtotal,
        };
        params = Object.assign(params, optParams);
        if (curPage >= 0) {
            setSanctionLetterData([]);
            setTotalItems(0);
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/miscustomreportdata', { params })
                .then((res) => {
                    setReportCols(res.cols);
                    setMisReportDataCount(res.totalRecord);
                    setSanctionLetterData(res.rows);
                    setTotalItems(res.totalRecord);
                    setPageCount(Math.ceil(res.totalRecord / pageSize));
                    setTotalPages(Math.ceil(res.totalRecord / pageSize));

                    let cPage = (!currentPage) ? 1 : currentPage;
                    setFirst((cPage - 1) * pageSize + 1);
                    setLast(Math.min(res.totalRecord, cPage * pageSize));
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    footerspinner.classList.add('d-none');
                    setDrildown(false);
                });
        }
    }

    useEffect(() => {
        if (currentPage != 0) {
            fetchSanctionLetterData(searchText);
        }
        if (props.reptype == 'smart-metering') {
            fetchSanctionLetterData('');
        }
    }, [currentPage]);

    useEffect(() => {
        if (statefilter) {
            fetchSanctionLetterData(searchText);
        }
    }, [statefilter]);

    const drilDown = (col: any) => {
        setDrildown(true);
        setCurrentPage(0);
        if (reportType == 'DisStates') {
            setReportType('Discom');
            setState(col.id);
            let SelectedState = { value: col.id, label: col.title1 };
            setSelectedState(SelectedState);
            const optParams = {
                reportType: 'Discom',
                stateid: col.id,
                page: 0,
            };
            fetchSanctionLetterData(searchText, optParams);
        }
    }

    const handleSubTotalChange = (e: any) => {
        setSubtotal(e.target.value);
    }

    const handleRadioChange = (e: any) => {
        let value = e.target.value;
        setReportType(e.target.value);
        clearparams();
    };

    const clearparams = () => {
        setSearchText('');
        setState(0);
        setSelectedState(0);
        setCurrentPage(0);
        setTotalItems(0);
        setPageCount(0);
        setMisReportDataCount(0);
        setReportCols([]);
        setSanctionLetterData([]);
    }

    const clearSelect = [
        { value: "0", label: 'All' }
    ];


    const getStates = () => {
        (async () => {
            if (getstatesapiurl) {
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner) footerspinner.classList.remove('d-none');
                await Http.get(getstatesapiurl)
                    .then((res) => {
                        setStatesList(res.statesList);
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        if (!drildown)
                            footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        if (reportType == 'Discom' || reportType == 'DisStates' || reportType == 'Districts') {
            setStatesApiurl("/apiv1/mis-states");
        }
    }, [getstatesapiurl, reportType]);

    useEffect(() => {
        (async () => {
            getStates();
        })();
    }, [getstatesapiurl, reportType]);

    //
    const [getcomponentsapiurl, setComponentsApiurl] = useState<any>(null);

    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt + "").replace(/[^\-.0-9]+/g, '');
        if (amt == '') amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>" + amt + "</mark>";
        }
        return amt;
    }

    return (
        <>
                            
                            <section className="financialForm">
                                <div className="radioYear">
                                    <div className="row align-items-center mx-0">
                                        <div className="col-md-auto">
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input" name="ReoptionSm" value="Allindia" id="Allindia" checked={reportType === 'Allindia'} onChange={handleRadioChange} />
                                                <label className="form-check-label" htmlFor="Allindia">All India </label>
                                            </div>
                                        </div>

                                        <div className="col-md-auto">
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input" name="ReoptionSm" value="DisStates" id="States" checked={reportType === 'DisStates'} onChange={handleRadioChange} />
                                                <label className="form-check-label" htmlFor="States">State </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    {reportType != 'Allindia' ?
                                        <>
                                            {reportType == 'DisStates'
                                                ?
                                                <div className="col-md-3">
                                                    <div className="misSelectReportPosition">
                                                        <label htmlFor="states" className="form-label">States</label>
                                                        {
                                                            reportType == 'DisStates' ?
                                                                <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} isMulti />
                                                                :
                                                                <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} />
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </>
                                        : null
                                    }

                                    
                                    <div className="col-md-12 text-center">
                                        <div className="row justify-content-between align-items-center misBottomBtn">
                                            <div className="col-md-3">
                                                <input type="text" className="form-control" onChange={handleSearchTextChange} value={searchText} placeholder="Search" onKeyDown={handleSearchEnter} style={{ height: 38 }} />
                                            </div>
                                            <div className="col-md-2 text-start">
                                                <button className="bsSearchBtn mt-0" onClick={() => { setPageInput(1); setCurrentPage(1); fetchSanctionLetterData(searchText); }}>Search</button>
                                            </div>
                                            <div className="col-md-4"></div>
                                            <div className="col-md-3 text-end">
                                                <div className="dIcnBtn">
                                                    <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/miscustomreportdata?reportType=' + reportType + '&stateid=' + getstate + '&summaryReport=' + selectedSummaryReport.value + '&subtotal=' + subtotal + '&task=excel'}><img src={ExcelIcn} /></a>
                                                    <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/miscustomreportdata?reportType=' + reportType + '&stateid=' + getstate + '&summaryReport=' + selectedSummaryReport.value + '&subtotal=' + subtotal + '&task=pdf'}><img src={PdfIcn} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                {/* <div className="input-group mb-3 mt-3">
                                            <input type="text" className="form-control" onChange={handleSearchTextChange} value={searchText} placeholder="Search" />
                                            <button className="btn btn-warning text-white" type="button" onClick={()=>fetchSanctionLetterData(searchText)}>Search</button>
                                        </div> */}

                                <div className="">
                                    {
                                        totalItems ?
                                            <div>Displaying {first} to {last} of {totalItems}</div>
                                            :
                                            null
                                    }
                                    <div className="mt-2 financialMainFrom misReportTable">
                                        <div className="tab-content p-0 border-0">
                                            <div id="data-table" className="table-responsive RevenueDetailsTable misreportTable">
                                                <table className="table">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th scope="col" className="text-start">Sr. No</th>
                                                            {reportCols ?
                                                                <>
                                                                    {
                                                                        reportCols.map((reportcol: any, index: number) =>
                                                                            (reportcol.display && reportcol.display == 'N') ? null : <th scope="col">{reportcol['title']}</th>
                                                                        )
                                                                    }
                                                                </>
                                                                : null
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sanctionLetterData ?
                                                            sanctionLetterData.map((reportData: any, index: number) => {
                                                                return <tr>
                                                                    <td>{(reportData.srNo)}</td>
                                                                    {
                                                                        reportCols.map((reportcol: any) => (
                                                                            (reportcol.display && reportcol.display == 'N') ? null
                                                                                : (
                                                                                    (reportcol.drildown && reportcol.drildown == 'Y' && reportData[reportcol.key] != 'Sub Total.') ? <>
                                                                                        <td className={"mis" + reportcol.key}>
                                                                                            <Link to="#" onClick={() => drilDown(reportData)}>{
                                                                                                (reportcol.formatted == 'Y' && reportData[reportcol.key] != '')
                                                                                                    ? <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], 2) }}></div>
                                                                                                    : <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                                            }</Link>
                                                                                        </td>
                                                                                    </>
                                                                                        : <>
                                                                                            <td className={"mis" + reportcol.key}>
                                                                                                {
                                                                                                    (reportcol.formatted == 'Y' && reportData[reportcol.key] != '')
                                                                                                        ? <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], 2) }}></div>
                                                                                                        : <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                                                }
                                                                                            </td>
                                                                                        </>
                                                                                )
                                                                        ))
                                                                    }
                                                                </tr>
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={10} className="text-center my-5">
                                                                    <h5>No Record Found</h5>
                                                                </td>
                                                            </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {totalItems != 0 ?
                                                <div className="mt-3">
                                                    <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                                        <ul className="pagination justify-content-center">
                                                            <li className="page-item">
                                                                <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                                    <BsChevronDoubleLeft />
                                                                </button>
                                                            </li>
                                                            <li className="page-item">
                                                                <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                                    <BsChevronCompactLeft />
                                                                </button>
                                                            </li>
                                                            <li className="page-item col-1">
                                                                <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                                            </li>
                                                            <li className="page-item">
                                                                <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                                    <BsChevronCompactRight />
                                                                </button>
                                                            </li>
                                                            <li className="page-item">
                                                                <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                                    <BsChevronDoubleRight />
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                    <div className="page-item text-center">
                                                        <span>
                                                            Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
        </>
    );
}

export default SmCustomReports;