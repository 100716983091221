import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Sop32 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* SoP-3.2*/}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara SoP">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">SoP-3.2</p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[221] && fields[221].value ? fields[221].value : ''} name="formdata[pqsop_details][sop32_nodal]" id="pqsop_details_sop32_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][sop32_remarks_nodal]" id="pqsop_details_sop32_remarks_nodal" defaultValue={fields[236] && fields[236].value ? fields[236].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][sop32_document_nodal]" id="pqsop_details_sop32_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[251].value != '' ?
                                            <a href={fields[251].value} title={fields[251].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>
                                    {fields[221].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[221] && fields[221].value ? fields[221].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[236].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[236] && fields[236].value ? fields[236].value : ''}</div>
                                    {/* : null
                                    } */}

                                    {fields[251].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[251] && fields[251].value ? fields[251].value : ''} title={fields[251] && fields[251].value ? fields[251].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }


                        </div>
                        {(props.tooltip !== undefined && props.tooltip === false)
                            ? null
                            :
                            <MyTooltip placement="left">
                                <ul>
                                    <li>Revised REF as per LPS Rules 2022 after approval by Monitoring Committee</li>
                                </ul>
                            </MyTooltip>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td>Whether the revised trajectory has been worked out as per LPS rules 2022 in consultation with Nodal Agency and incorporated in REF</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[152].key} field={fields[152]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[153].key} field={fields[153]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[154].key} field={fields[154]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Revised REF approved by Monitoring Committee</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[158].key} field={fields[158]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[156].key} field={fields[156]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[157].key} field={fields[157]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Whether DISCOM is making the payment of dues to a generating company or transmission company or a trading company as per LPS rules 2022</td>
                <td>
                    <Modalbox field1={fields[201]} field2={fields[212]} block="7" />
                    <Link to="" className="btn-warning btn d-block mx-auto w-100" data-bs-toggle="modal" data-bs-target="#PaymentofDues">Payment of Dues
                            </Link>
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[159].key} field={fields[159]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[160].key} field={fields[160]} />
                </td>
                <td></td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[161].key} field={fields[161]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Sop32;