import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import "../../../RdssForms/rdssforms.scss";
import Modalbox from "../../DashboardComponents/ModalBox/ModalBox";
import { Link } from "react-router-dom";
import { GrDownload, GrUpload } from "react-icons/gr";

// Images
import FieldComponent from "../../../FieldComponent";
import MyTooltip from "./MyTooltip";
import { Storage } from "../../../Core/Services/StorageService";

const Sop33 = (props: any) => {
    const [fields, setFields] = useState<any[]>([]);
    useEffect(() => {
        setFields(props.fields)
    }, [props.fields]);
    const [roleid, setRoleid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'))
    }, [roleid]);
    return (
        <>
        {/* SoP-3.3*/}
        {fields.length ?
            <>
            <tr>
                <td colSpan={5}></td>
            </tr>
            <tr>
                <td colSpan={5} className="blankPara SoP">
                    <div className="d-flex justify-content-between align-item-center">
                        <div className="d-flex align-items-center w-100">
                            <p className="mb-0 ps-2 fw-medium pe-3">SoP-3.3</p>
                            {roleid == 5 || roleid == 6 || props.iscompletedsubmittedstatus == 1 ?
                                <>
                                {props.submittedStatus == 1 ?
                                    <>
                                    <select defaultValue={fields[222] && fields[222].value ? fields[222].value : ''} name="formdata[pqsop_details][sop33_nodal]" id="pqsop_details_sop33_nodal" className="form-select text-start">
                                        <option value="">Select</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Partial Compliant">Partial Compliant</option>
                                        <option value="Non-Compliant">Non-Compliant</option>
                                        <option value="Compliant with Delay">Compliant with Delay</option>
                                    </select>

                                    <div className="col-md-0">
                                        {/* <p className="mb-0 ps-2 fw-medium pe-3">Remarks</p> */}
                                        <div className="form-group text">
                                            <div className="form-field">
                                                <input autoComplete="off" className="form-control" type="text" name="formdata[pqsop_details][sop33_remarks_nodal]" id="pqsop_details_sop33_remarks_nodal" defaultValue={fields[237] && fields[237].value ? fields[237].value : ''} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fileUpload position-relative text-center">
                                        <div className="dowupEqule">
                                            <input type="file" autoComplete="off" className="form-control" name="formdata[pqsop_details][sop33_document_nodal]" id="pqsop_details_sop33_document_nodal" />
                                            <div className="customUpload justify-content-center flex-wrap">
                                                <span> <GrUpload /> </span>
                                                {/* <span>.PDF, .Docx, .XLS</span> */}
                                            </div>
                                        </div>
                                        {fields[252].value != '' ?
                                            <a href={fields[252].value} title={fields[252].value} className="viewupload" target="_blank"><GrDownload /></a>
                                            : null
                                        }
                                    </div>

                                    </>
                                    :
                                    <>

                                    {fields[222].value != '' ?
                                        <div className="col-md-0">
                                            <div className="form-group select">
                                                <div className="form-field">
                                                    <div className="view-field text-start">
                                                        {fields[222] && fields[222].value ? fields[222].value : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* {fields[237].value != '' ? */}
                                    <div className={"view-field text-start"}> {fields[237] && fields[237].value ? fields[237].value : ''}</div>
                                    {/* : null
                                                } */}

                                    {fields[252].value != '' ?
                                        <div className="view-field text-center">
                                            <a href={fields[252] && fields[252].value ? fields[252].value : ''} title={fields[252] && fields[252].value ? fields[252].value : ''} className="viewupload" target="_blank">
                                                <span><GrDownload /></span>
                                            </a>
                                        </div>
                                        : null
                                    }

                                    </>
                                }
                                </> : null
                            }

                        </div>
                        {(props.tooltip !== undefined && props.tooltip === false)
                            ? null
                            :
                            <MyTooltip placement="left">
                                <ul>
                                    <li>Relevant Extract from relevant Regulation, Tariff order and True up order</li>
                                </ul>
                            </MyTooltip>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td>Whether mechanism for automatic pass through of fuel cost adjustment on monthly/quarterly basis without the need for any prior approval is in place?</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[162].key} field={fields[162]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[163].key} field={fields[163]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[164].key} field={fields[164]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Whether such charges are reconciled at True Up stage?</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[165].key} field={fields[165]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[166].key} field={fields[166]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[167].key} field={fields[167]} />
                </td>
                <td></td>
            </tr>
            <tr>
                <td>Whether the ACS-ARR gap is showing a downward trajectory on quarterly basis?</td>
                <td className="tableRadio">
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[168].key} field={fields[168]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[169].key} field={fields[169]} />
                </td>
                <td>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[170].key} field={fields[170]} />
                </td>
                <td></td>
            </tr>
            <tr className="borderThik">
                <td>Remarks</td>
                <td colSpan={4}>
                    <FieldComponent options={{ showTitle: false }} fieldName={fields[171].key} field={fields[171]} />
                </td>
            </tr>
            </>
            : null
        }
        </>
    );
};

export default Sop33;