import React, { useEffect, useRef, useState } from "react";
import "../../RdssForms/rdssforms.scss";
import FdDiscomName from "./FdDiscomName";

export const FdDiscomHistory = (FdHistoryBlock: any) => {

  let discomHistoryNo = 0;
  const [getFdHistoryBlock, setFdHistoryBlock] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      setFdHistoryBlock(FdHistoryBlock.FdHistoryBlock);
    })();
  }, [FdHistoryBlock.FdHistoryBlock]);

  return (
    <>
      {(getFdHistoryBlock.length > 0) ?
        <div className="accordion-body">
          <div className="table-responsive RevenueDetailsTable actionHistory">
            <caption className='text-center mb-4 mt-4 w-100 d-block'>
              <div className='d-flex justify-content-between align-items-center'>
                  <div>Action History</div>                 
              </div>
            </caption>
            <table className="table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Sender</th>
                  <th>Receiver</th>
                  <th>Action</th>
                  <th>Remark</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {(getFdHistoryBlock.length > 0) ?
                  getFdHistoryBlock.map((item: any, key: number) => (
                    <>
                      <tr key={key}>
                        <td>{(discomHistoryNo++) + 1}</td>
                        <td>{item.sender}</td>
                        <td>{item.receiver}</td>
                        <td>{item.action}</td>
                        <td>{item.remark}</td>
                        <td>{item.date}</td>
                      </tr>
                    </>
                  ))
                  : null
                }
              </tbody>
            </table>
          </div>
        </div>
        : null
      }
    </>
  );
}
export default FdDiscomHistory;

